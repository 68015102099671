import React, { RefObject } from 'react';
import { CSVLink } from 'react-csv';
import { Data, LabelKeyObject } from 'react-csv/lib/core';
import { i18n } from '../../services/i18n';
import { Menu } from 'primereact/menu';

interface Props {
  headers: LabelKeyObject[];
  data: Data;
  filename: string;
  menu: RefObject<Menu>;
  onExport: () => void;
}

const ExportButton: React.FC<Props> = ({ headers, data, filename, menu, onExport }) => {
  const focusedClassName = 'p-focus';

  const getListElement = (): Element | undefined => menu.current?.getElement().children[0];

  const addMenuItemClass = (listElement: Element, childIndex: number, className: string) => {
    listElement.children[childIndex].classList.add(className);
  };

  const removeMenuItemClass = (listElement: Element, childIndex: number, className: string) => {
    listElement.children[childIndex].classList.remove(className);
  };

  const handleMouseEnter = () => {
    const listElement = getListElement();
    if (!listElement) {
      return;
    }

    addMenuItemClass(listElement, 1, focusedClassName);

    for (let i = 2; i < listElement?.children.length; i++) {
      removeMenuItemClass(listElement, i, focusedClassName);
    }
  };

  const handleMouseLeave = () => {
    const listElement = getListElement();
    if (!listElement) {
      return;
    }
    removeMenuItemClass(listElement, 1, focusedClassName);
  };

  return (
    <div className="p-menuitem-content" data-pc-section="content">
      <CSVLink
        filename={filename}
        className="p-menuitem-link"
        headers={headers}
        data={data}
        onClick={onExport}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-pc-section="action"
      >
        <span data-testid="header-desktop-logout" data-pc-section="label" className="p-menuitem-text">
          {i18n('ConsumersPage_ExportCsvConsumerButton')}
        </span>
      </CSVLink>
    </div>
  );
};

export default ExportButton;
