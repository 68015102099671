import { accountIdMaxLength } from '../features/ConsumerTab/AccountsTab/AccountsFormConstants';
import { ItemPositionModel } from '../models/Consumer/Account/ItemPositionModel';

export const getInvalidItems = (
  items: ItemPositionModel[],
  key: keyof ItemPositionModel,
): ItemPositionModel[] =>
  items.filter(
    (item1) =>
      items.filter((item2) => String(item2[key]) === String(item1[key])).length > 1 ||
      item1.item.length > accountIdMaxLength,
  );

export const getExistingItemsDuplicates = <T, K>(
  items: T[],
  keyFunc: (item: T) => K,
  existingItemsKeys: K[],
): T[] => items.filter((item) => existingItemsKeys.includes(keyFunc(item)));

export const getUniqueKeys = <T, K>(items: T[], keyFunc: (item: T) => K): K[] => {
  const keys = items.map((item) => keyFunc(item));
  return keys.filter((item, index) => keys.indexOf(item) === index);
};
