import clsx from 'clsx';
import React from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import './styles.scss';

interface Props {
  title: string;
  popup: string;
  className?: string;
  'data-testid'?: string;
}

const HeaderCellWithTooltip: React.FC<Props> = (props) => {
  return (
    <Table.HeaderCell className={clsx('header-cell', props.className)} data-testid={props['data-testid']}>
      <div className="header-cell-container">
        <div className="header-cell-text">{props.title}</div>
        <Popup offset={[-11]} trigger={<Icon name="info circle" color="grey" />} content={props.popup} />
      </div>
    </Table.HeaderCell>
  );
};

export default HeaderCellWithTooltip;
