import _ from 'lodash';
import { emailRegex } from '../features/User/UserForm/UserFormConstants';
import { i18n } from '../services/i18n';

const tripIdentifierRegex = /^[a-zA-Z-\d]{1,100}$/;
const hcnRegex = /^[A-Z\d]{1,100}$/;
const smidRegex = /^\d{1,100}$/;
const travelerNameRegex = /^[a-zA-Z'.,-\s]{1,100}$/;

export const getTravelerRule = () => ({
  pattern: {
    value: travelerNameRegex,
    message: i18n('Form_InvalidCharactersMessage'),
  },
});

export const getSourceIdRule = () => ({
  pattern: {
    value: tripIdentifierRegex,
    message: i18n('Form_InvalidCharactersMessage'),
  },
});

export const getGlobalCustomerNumberRule = () => ({
  pattern: {
    value: hcnRegex,
    message: i18n('Form_InvalidCharactersMessage'),
  },
});

export const getAccountIdRule = () => ({
  pattern: {
    value: smidRegex,
    message: i18n('Form_InvalidCharactersMessage'),
  },
});

export const getEmailRule = () => ({
  pattern: {
    value: emailRegex,
    message: i18n('TripSearchForm_Email_IncorrectMessage'),
  },
});

export const getAccountIdValidationRule = (value: string): string | undefined => {
  let message: string | undefined = undefined;

  if (value.length > 15) message = i18n('TripSearchForm_SMID_ToLongMessage');
  if (value.length < 6 && value.length !== 0) message = i18n('TripSearchForm_SMID_ToShortMessage');

  return message;
};

export const isOtherFieldsDefault = <T>(formValue: T, defaultFormValue: T, fieldName: keyof T) => {
  let isOtherFieldsEmpty = true;
  _.forOwn(formValue, (value, key) => {
    if (key !== fieldName && value != defaultFormValue[key as keyof T]) {
      isOtherFieldsEmpty = false;
      return false;
    }
  });

  return isOtherFieldsEmpty;
};
