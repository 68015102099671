import { Action, createAsyncThunk } from '@reduxjs/toolkit';

import {
  sendEventActionName,
  resetSendEventActionName,
  clearSendEventErrorActionName,
  clearSendEventSuccessMessageActionName,
} from './actionTypes';
import { TripsService } from '../../services/TripsService';
import { SendEventRequestDTO } from '../../models/Events/SendEventModel';

const service = TripsService;

export const sendEventAction = createAsyncThunk<void, SendEventRequestDTO>(
  sendEventActionName,
  async (model, thunkApi) => {
    try {
      return await service.sendEvents(model);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const resetSendEventAction: () => Action = () => ({
  type: resetSendEventActionName,
});

export const clearSendEventErrorAction: () => Action = () => ({
  type: clearSendEventErrorActionName,
});

export const clearSendEventSuccessMessageAction: () => Action = () => ({
  type: clearSendEventSuccessMessageActionName,
});
