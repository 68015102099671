import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useBreakpoint } from '../../../../hooks/BreakPointProvider/breakpoint';
import ConditionalWrapper from '../../../ConditionalWrapper/ConditionalWrapper';

interface Props {
  left: React.ReactElement;
  right: React.ReactElement;
}

const TripAccessBaseContainer: React.FC<Props> = ({ left, right }) => {
  const breakpoints = useBreakpoint();
  return (
    <Grid padded="vertically" columns={breakpoints.sm ? 1 : 2} divided={breakpoints.sm ? 'vertically' : true}>
      <ConditionalWrapper
        condition={breakpoints.sm}
        wrapper={(children) => <Grid.Row className="first-row">{children}</Grid.Row>}
      >
        <Grid.Column>{left}</Grid.Column>
      </ConditionalWrapper>
      <ConditionalWrapper condition={breakpoints.sm} wrapper={(children) => <Grid.Row>{children}</Grid.Row>}>
        <Grid.Column>{right}</Grid.Column>
      </ConditionalWrapper>
    </Grid>
  );
};

export default TripAccessBaseContainer;
