import React from 'react';
import { Icon, Label, SemanticCOLORS } from 'semantic-ui-react';
import './styles.scss';

export interface BooleanLabelProps {
  value?: boolean;
  textTrue?: string;
  textFalse?: string;
  colorFalse?: SemanticCOLORS;
  'data-testid'?: string;
  children?: React.ReactNode;
}

const BooleanLabel: React.FC<BooleanLabelProps> = ({
  value,
  textTrue,
  textFalse,
  colorFalse,
  'data-testid': dataTestId,
  children,
}) => {
  return (
    <div className="boolean-label-container">
      {value !== undefined && (
        <Label data-testid={dataTestId}>
          <Icon name="circle" size="small" color={value ? 'blue' : colorFalse ?? 'red'} />
          {value ? textTrue : textFalse}
        </Label>
      )}
      {children}
    </div>
  );
};

export default BooleanLabel;
