import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import { i18n } from '../../../services/i18n';
import NamedInput from '../../NamedInput/NamedInput';
import { EventsFormViewModel, defaultEventsFormValue } from '../../../models/Events/EventsFormViewModel';
import {
  sourceIdRules,
  globalCustomerNumberRules,
  accountIdRules,
  travelerEmailAddressRules,
  travelerFirstNameRules,
  travelerLastNameRules,
  isOtherFieldsDefault,
} from '../../../helpers/EventsFormHelpers';
import { useValidationUtils } from '../../../hooks/Form/useValidationUtils';
import InfoTitle from '../../InfoTitle/InfoTitle';
import { useTrips } from '../../../store/hooks/useTrips/useTrips';

import './styles.scss';

interface Props {
  onClear: () => void;
}

const EventsForm: React.FC<Props> = ({ onClear }) => {
  const [{ isLoading }, { getEventsTrips, handleResetTrips }] = useTrips();

  const [state, setState] = useState<EventsFormViewModel>(defaultEventsFormValue);
  const [isAllFieldsCleared, setIsAllFieldsCleared] = useState(false);
  const useFormValue = useForm<EventsFormViewModel>({ defaultValues: defaultEventsFormValue });
  const { register, formState } = useFormValue;
  const [{ isFieldsChanged }, { handleFormSubmit, handleStateChange, handlePropsChange }] =
    useValidationUtils<EventsFormViewModel>({
      state,
      useFormValue,
      setState,
      showOrHideSaveModal: () => getEventsTrips(state),
      updateOnChange: true,
    });

  useEffect(() => {
    register('sourceId', sourceIdRules());
    register('globalCustomerNumber', globalCustomerNumberRules());
    register('accountId', accountIdRules());
    register('travelerEmailAddress', travelerEmailAddressRules());
    register('travelerFirstName', travelerFirstNameRules());
    register('travelerLastName', travelerLastNameRules());
  }, [register]);

  const handleChange = (value: string, fieldName: keyof EventsFormViewModel) => {
    void handleStateChange(value, fieldName);
    if (value !== defaultEventsFormValue[fieldName]) {
      setIsAllFieldsCleared(false);
      return;
    }

    const isOtherFieldsCleared = isOtherFieldsDefault(state, defaultEventsFormValue, fieldName);
    setIsAllFieldsCleared(isOtherFieldsCleared);
  };

  const onClearSearch = () => {
    onClear();
    handlePropsChange(defaultEventsFormValue);
    handleResetTrips();
  };

  return (
    <Grid className="events-form-container" data-testid="events-form">
      <Grid.Row columns={8}>
        <Grid.Column>
          <NamedInput
            title={
              <InfoTitle
                content={i18n('TripSearchForm_TripIdentifier')}
                popupContent={i18n('TripSearchForm_TripIdentifier_PopupMessage')}
                required
              />
            }
            value={state.sourceId}
            onChange={(v) => void handleChange(v, 'sourceId')}
            error={formState.errors.sourceId}
            data-testid="events-form-trip-identifier"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_GCN')}
            value={state.globalCustomerNumber}
            onChange={(v) => void handleChange(v, 'globalCustomerNumber')}
            error={formState.errors.globalCustomerNumber}
            data-testid="events-form-gcn"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_SMID')}
            value={state.accountId}
            onChange={(v) => void handleChange(v, 'accountId')}
            error={formState.errors.accountId}
            data-testid="events-form-smid"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_Email')}
            value={state.travelerEmailAddress}
            onChange={(v) => void handleChange(v, 'travelerEmailAddress')}
            error={formState.errors.travelerEmailAddress}
            data-testid="events-form-email"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_TravelerFirstName')}
            value={state.travelerFirstName}
            onChange={(v) => void handleChange(v, 'travelerFirstName')}
            error={formState.errors.travelerFirstName}
            data-testid="events-form-first-name"
          />
        </Grid.Column>
        <Grid.Column>
          <NamedInput
            title={i18n('TripSearchForm_TravelerLastName')}
            value={state.travelerLastName}
            onChange={(v) => void handleChange(v, 'travelerLastName')}
            error={formState.errors.travelerLastName}
            data-testid="events-form-last-name"
          />
        </Grid.Column>
        <Grid.Column textAlign="left">
          <Button
            primary
            onClick={() => void handleFormSubmit()}
            disabled={isLoading}
            data-testid="events-form-submit-control"
          >
            {i18n('SearchButtonTitle')}
          </Button>
          {isFieldsChanged && !isAllFieldsCleared && (
            <Button
              basic
              onClick={onClearSearch}
              color="blue"
              className="icon-square-button"
              data-testid="clear-results-button"
            >
              <Icon link name="close" color="blue" />
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EventsForm;
