import React from 'react';
import { Permission } from '../../enums/Permission';
import { useUserPermissions } from '../../hooks/Auth/useUserPermissions';
import { Role } from '../../enums/UserRole';
import { userRoles } from '../../helpers/PermissionsHelper';

interface Props {
  permission?: Permission;
  roles?: Role[];
  children: React.ReactElement;
  negativeResponse?: React.ReactElement;
  isOpposite?: boolean;
}

const CanUser: React.FC<Props> = ({
  permission,
  roles,
  children,
  negativeResponse = null,
  isOpposite = false,
}) => {
  const hasPermissions = useUserPermissions();
  const userRole = userRoles(hasPermissions);
  const hasPermission =
    roles?.some((role) => userRole[role]) || (permission && hasPermissions(permission)) || false;
  const resPermission = isOpposite ? !hasPermission : hasPermission;

  return resPermission ? children : negativeResponse;
};

export default CanUser;
