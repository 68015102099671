import React from 'react';
import { Label } from 'semantic-ui-react';
import './styles.scss';

export interface LabelGroupProps {
  names?: string[];
  'data-testid'?: string;
  noDataMessage?: string;
}

const LabelGroup: React.FC<LabelGroupProps> = ({ names, 'data-testid': dataTestId, noDataMessage }) => {
  return (
    <div className="label-group-container" data-testid={dataTestId}>
      {names && names?.length > 0 ? names?.map((name) => <Label key={name}>{name}</Label>) : noDataMessage}
    </div>
  );
};

export default LabelGroup;
