import { isEmpty } from 'lodash';
import {
  FieldPath,
  FieldValues,
  UnpackNestedValue,
  FieldPathValue,
  UseFormTrigger,
  UseControllerReturn,
} from 'react-hook-form';

interface HandleChangeProps<T extends FieldValues, TName extends FieldPath<T>> {
  value: UnpackNestedValue<FieldPathValue<T, TName>>;
  trigger: UseFormTrigger<T>;
  event: UseControllerReturn<T, TName>;
}

interface HandleBlurProps<T extends FieldValues, TName extends FieldPath<T>> {
  trigger: UseFormTrigger<T>;
  event: UseControllerReturn<T, TName>;
}

export const handleChange = <T extends FieldValues, TName extends FieldPath<T>>({
  value,
  trigger,
  event: {
    field: { onChange, name },
    fieldState: { invalid },
    formState: { isSubmitted },
  },
}: HandleChangeProps<T, TName>): void => {
  onChange(value);
  invalid && !isSubmitted && void trigger(name);
};

export const handleBlur = <T extends FieldValues, TName extends FieldPath<T>>({
  trigger,
  event: {
    field: { onBlur, name },
    fieldState: { isDirty },
    formState: { isSubmitted, dirtyFields },
  },
}: HandleBlurProps<T, TName>): void => {
  (isDirty || isSubmitted) && onBlur();
  !isEmpty(dirtyFields) && !isSubmitted && void trigger(name);
};
