import { getDataFromApi } from '../helpers/ApiHelper';
import { RawTripResponseDTO, TripDTO, TripRequestDTO, TripResponseDTO } from '../models/Trip/TripModel';

export class TripService {
  private static readonly searchTripsPath = '/search-trips';
  private static readonly tripsSubpath = '/trips-format';
  private static readonly itinerarySubpath = '/itinerary-format';
  private static readonly rawSubpath = '/raw-format';

  public static async getTrip(payload: TripRequestDTO): Promise<TripResponseDTO> {
    return await getDataFromApi<TripDTO>(`${this.searchTripsPath}${this.tripsSubpath}/${payload.id}`);
  }

  public static async getItineraryTrip(payload: TripRequestDTO): Promise<TripResponseDTO> {
    return await getDataFromApi<TripDTO>(`${this.searchTripsPath}${this.itinerarySubpath}/${payload.id}`);
  }

  public static async getRawTrip(payload: TripRequestDTO): Promise<RawTripResponseDTO> {
    return await getDataFromApi<RawTripResponseDTO>(
      `${this.searchTripsPath}${this.rawSubpath}/${payload.id}`,
    );
  }
}
