import { ConsumerTypeModel } from '../models/Consumer/ConsumerTypeModel';
import { DropdownOption } from '../features/NamedDropdown/NamedDropdown';

export const mapToDropdownOption = (consumerType: ConsumerTypeModel): DropdownOption => {
  return {
    key: consumerType.consumerTypeId,
    value: consumerType.name,
    text: consumerType.name,
    disabled: !consumerType.isAllowedForCurrentUser,
  };
};
