import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { updateTripAccessChildrenState } from '../../../../../helpers/TripAccessHelper';
import { TripsModel } from '../../../../../models/Consumer/ApiAccess/TripsModel';
import { TripAccessSettingEntry } from '../../../../../models/TripAccess/TripAccessSettingEntry';

export const useAdvancedSettings: (
  getAdvancedSettingsEntries: () => TripAccessSettingEntry[],
  source?: TripsModel,
) => [TripAccessSettingEntry[], Dispatch<SetStateAction<TripAccessSettingEntry[]>>] = (
  getAdvancedSettingsEntries: () => TripAccessSettingEntry[],
  source?: TripsModel,
) => {
  const [settingsEntries, setSettingsEntries] = useState<TripAccessSettingEntry[]>([]);

  const updateSettingEntry = useCallback((entry: TripAccessSettingEntry, value: boolean) => {
    entry.enabled = value;
    if (entry.children) {
      entry.children = updateTripAccessChildrenState(entry.children, value);
    }
  }, []);

  const updateSettingsEntries = useCallback(
    (entries: TripAccessSettingEntry[], model: object) => {
      Object.entries(model).forEach(([key, value]) => {
        const entry = entries.find((e) => e.value === key);

        if (entry) {
          if (typeof value === 'object') {
            if (entry.children) {
              updateSettingsEntries(entry.children, value as object);
            }
          } else {
            updateSettingEntry(entry, (value as boolean) ?? true);
          }
        }
      });
    },
    [updateSettingEntry],
  );

  const downloadSettings = useCallback(() => {
    const settings = getAdvancedSettingsEntries();
    if (source?.scopes) {
      updateSettingsEntries(settings, source.scopes);
    }
    setSettingsEntries(settings);
  }, [getAdvancedSettingsEntries, source, updateSettingsEntries]);

  useEffect(() => {
    void downloadSettings();
  }, [downloadSettings]);

  return [settingsEntries, setSettingsEntries];
};
