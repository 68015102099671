import { configureStore, Tuple } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import claimsReducer from './reducers/claimsReducer';
import consumerInfoReducer from './reducers/consumerInfoReducer';
import consumerTypesReducer from './reducers/consumerTypesReducer';
import consumersReducer from './reducers/consumersReducer';
import tripAccessAdvancedSettingsReducer from './reducers/tripAccessAdvancedSettingsReducer';
import tripAccessScopesPresetsReducer from './reducers/tripAccessScopesPresetsReducer';
import usersReducer from './reducers/usersReducer';
import accountsLookupReducer from './reducers/accountsLookupReducer';
import sendEventReducer from './reducers/sendEventReducer';
import tripsReducer from './reducers/tripsReducer';
import dataSourceFilterReducer from './reducers/dataSourceFilterReducer';

const store = configureStore({
  reducer: {
    claims: claimsReducer,
    consumers: consumersReducer,
    consumerInfo: consumerInfoReducer,
    consumerTypes: consumerTypesReducer,
    tripAccessAdvancedSettings: tripAccessAdvancedSettingsReducer,
    tripAccessScopesPresets: tripAccessScopesPresetsReducer,
    users: usersReducer,
    accountsLookup: accountsLookupReducer,
    trips: tripsReducer,
    sendEvent: sendEventReducer,
    dataSourceFilters: dataSourceFilterReducer,
  },
  middleware: () => new Tuple(thunk),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
