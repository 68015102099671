export const formatXml = (xml: string): string => {
  let formatted = '',
    indent = '';
  const tab = '    ';
  xml.split(/>\s*</).forEach((node) => {
    if (/^\/\w/.exec(node)) indent = indent.substring(tab.length);
    formatted += `${indent}<${node}>\r\n`;
    if (/^<?\w[^>]*[^/]$/.exec(node)) indent += tab;
  });
  return formatted.substring(1, formatted.length - 3);
};
