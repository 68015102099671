import { Auth } from 'aws-amplify';
import { IpResponse } from '../models/Common/IpResponse';
import { CognitoUserInfo } from '../models/User/CognitoUserInfo';
const geolocationUrl = 'https://geolocation-db.com/json/';

export async function getIpInfo(): Promise<IpResponse | undefined> {
  try {
    const response = await fetch(geolocationUrl);
    return (await response.json()) as IpResponse;
  } catch (error) {
    console.warn(`Fail to get geolocation data for IPv4.`, error);
  }
}

export async function getUsername(): Promise<string> {
  try {
    return ((await Auth.currentUserInfo()) as CognitoUserInfo)?.username;
  } catch (error) {
    console.warn(`Fail to get username from Cognito.`, error);
    return '';
  }
}
