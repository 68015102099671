import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConsumerService } from '../../services/ConsumerService';
import { fetchTripAccessScopesPresetsActionName } from './actionTypes';

const service = ConsumerService;

export const fetchTripAccessScopesPresetsAction = createAsyncThunk(
  fetchTripAccessScopesPresetsActionName,
  async () => {
    return service.getTripAccessScopesPresets();
  },
);
