import clsx from 'clsx';
import { debounce } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

interface Props {
  children: React.ReactNode;
}

const HorizontalScroll: React.FC<Props> = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [displayScroll, setDisplayScroll] = React.useState({
    start: false,
    end: false,
  });

  const scroll = (scrollValue: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollValue;
    }
  };

  const updateScrollButtonState = useCallback(() => {
    if (scrollRef.current) {
      const { scrollWidth, clientWidth } = scrollRef.current;

      const { scrollLeft } = scrollRef.current;
      // use 1 for the potential rounding error with browser zooms.
      const showStartScroll = scrollLeft > 1;
      const showEndScroll = scrollLeft < scrollWidth - clientWidth - 1;

      if (showStartScroll !== displayScroll.start || showEndScroll !== displayScroll.end) {
        setDisplayScroll({ start: showStartScroll, end: showEndScroll });
      }
    }
  }, [displayScroll.end, displayScroll.start]);

  const handleTabsScroll = debounce(() => {
    updateScrollButtonState();
  });

  const moveTabsScroll = (delta: number) => {
    const scrollValue = scrollRef.current?.scrollLeft ?? 0;
    scroll(scrollValue + delta);
  };

  const handleStartScrollClick = () => {
    moveTabsScroll(-(scrollRef.current?.clientWidth ?? 0));
  };

  const handleEndScrollClick = () => {
    moveTabsScroll(scrollRef.current?.clientWidth ?? 0);
  };

  React.useEffect(() => {
    updateScrollButtonState();
    window.addEventListener('resize', updateScrollButtonState, false);

    return () => {
      window.removeEventListener('resize', updateScrollButtonState, false);
    };
  });

  return (
    <div className="horizontal-scroll-root">
      <Icon
        link
        name="angle left"
        className={clsx('scroll-button', { hidden: !displayScroll.start })}
        onClick={handleStartScrollClick}
      />
      <div className="horizontal-scroll-content" ref={scrollRef} onScroll={handleTabsScroll}>
        {children}
      </div>
      <Icon
        link
        name="angle right"
        onClick={handleEndScrollClick}
        className={clsx('scroll-button', { hidden: !displayScroll.end })}
      />
    </div>
  );
};

export default HorizontalScroll;
