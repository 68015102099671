import { getDataFromApi } from '../helpers/ApiHelper';
import { AccountLookupModel } from '../models/Consumer/Account/AccountLookupModel';
import { AccountLookupSearch } from '../models/Consumer/Account/AccountLookupSearch';

export class AccountLookupService {
  private static readonly endpoint = '/account-lookup';

  public static getAccountsLookup(): Promise<AccountLookupModel[]> {
    return getDataFromApi<AccountLookupModel[]>(this.endpoint);
  }

  public static searchAccountsLookup(
    allAccountsLookup: AccountLookupModel[],
    search: AccountLookupSearch,
  ): AccountLookupModel[] {
    const filteredAccounts = allAccountsLookup.filter((account) => {
      if (!this.includesLowerCase(search.accountName, account.name)) {
        return false;
      }
      if (!this.includesLowerCase(search.countryCode, account.countryCode)) {
        return false;
      }
      if (search.accountId && !account.accountId?.includes(search.accountId)) {
        return false;
      }
      return !search.gcn || account.gcn?.includes(search.gcn);
    });

    filteredAccounts.sort((a, b) => a.name.localeCompare(b.name));
    return filteredAccounts;
  }

  private static includesLowerCase(searchValue: string | undefined, value: string): string | boolean {
    return !searchValue || value.toLowerCase().includes(searchValue.toLowerCase());
  }
}
