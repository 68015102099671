import React, { useState, useEffect, createContext, useContext } from 'react';
import { MediaQueries } from '../../variables';
import { SemanticWIDTHS } from 'semantic-ui-react';

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

export interface MediaQueriesMatches {
  [key: string]: boolean;
}

export interface Props {
  children: React.ReactElement | React.ReactElement[];
  queries: MediaQueries;
}

const BreakpointProvider: React.FC<Props> = (props) => {
  const [queryMatch, setQueryMatch] = useState<MediaQueriesMatches>({});

  useEffect(() => {
    const mediaQueryLists: { [key: string]: MediaQueryList } = {};
    const keys = Object.keys(props.queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc: MediaQueriesMatches, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: MediaQueriesMatches = {};
      keys.forEach((media) => {
        if (typeof props.queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(props.queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys
        .filter((media) => typeof props.queries[media] === 'string')
        .forEach((media) => {
          mediaQueryLists[media].addListener(handleQueryListener); // Deprecated is used to support Safari
        });
    }

    return () => {
      if (isAttached) {
        keys
          .filter((media) => typeof props.queries[media] === 'string')
          .forEach((media) => {
            mediaQueryLists[media].removeListener(handleQueryListener); // Deprecated is used to support Safari
          });
      }
    };
  }, [props.queries]);
  return <BreakpointContext.Provider value={queryMatch}>{props.children}</BreakpointContext.Provider>;
};

function useBreakpoint(): MediaQueriesMatches {
  const context = useContext(BreakpointContext);

  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context;
}

function useColumnBreakpoint(): [
  {
    getFormColumnsCount: () => SemanticWIDTHS;
    getTableColumnsCount: (isFormOpen: boolean) => SemanticWIDTHS;
  },
] {
  const breakpoints = useBreakpoint();

  const getFormColumnsCount = () => {
    if (breakpoints.sm) {
      return 16;
    }
    return breakpoints.md ? 7 : 5;
  };

  const getTableColumnsCount = (isFormOpen: boolean) => {
    if (!isFormOpen) {
      return 16;
    }
    return breakpoints.md ? 9 : 11;
  };

  return [{ getFormColumnsCount, getTableColumnsCount }];
}

export { useBreakpoint, useColumnBreakpoint, BreakpointProvider };
