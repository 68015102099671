import { createSlice } from '@reduxjs/toolkit';

import { PageModel } from '../../models/Store/PageModel{T}';
import { dataSourceFiltersPrefix } from '../actions/actionTypes';
import { DataSourceFilterModel } from '../../models/Consumer/DataSourceFilterModel';
import { fetchDataSourceFiltersAction } from '../actions/dataSourceFiltersActions';
import { ApiError } from '../../models/Api/ApiError';

const initialState: PageModel<DataSourceFilterModel[]> = {
  items: [] as DataSourceFilterModel[],
  isLoading: false,
};

const dataSourceFilterSlice = createSlice({
  name: dataSourceFiltersPrefix,
  initialState,
  reducers: {
    resetDataSourceFilter: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataSourceFiltersAction.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchDataSourceFiltersAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(fetchDataSourceFiltersAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as ApiError;
      });
  },
});

export default dataSourceFilterSlice.reducer;
export const { resetDataSourceFilter } = dataSourceFilterSlice.actions;
