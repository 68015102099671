import React from 'react';
import { i18n } from '../../services/i18n';
import { PRIVACY_POLICY, TERMS_AND_CONDITION } from '../../constants/externalLinks';

const AuthFooter = (): JSX.Element => {
  return (
    <div className="form-container-footer">
      {i18n('Authentication_FooterAllRights')}
      <br />
      <br />
      {i18n('Authentication_FooterTrademarks')}
      <br />
      <br />
      <a href={PRIVACY_POLICY} className="link">
        {i18n('Privacy_policy')}
      </a>
      <a href={TERMS_AND_CONDITION} className="link">
        {i18n('Terms_and_conditions')}
      </a>
    </div>
  );
};

export default AuthFooter;
