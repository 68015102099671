import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'semantic-ui-react';
import { i18n } from '../../services/i18n';
import { recordPageNotFoundEvent } from '../../services/LoggerService';
import './styles.scss';

const NotFoundPage: React.FC = () => {
  useEffect(() => {
    void recordPageNotFoundEvent(window.location.href);
  }, []);

  return (
    <div className="not-found-page-container">
      <div className="not-found-page-container-title">
        <Icon name="info circle" color="grey" />
        <h2>{i18n('NotFoundPage_Title')}</h2>
      </div>
      <div className="not-found-page-container-content">{i18n('NotFoundPage_Content')}</div>
      <Link to="/">
        <Button primary className="not-found-page-container-link-button">
          {i18n('NotFoundPage_Link')}
        </Button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
