import { format } from 'date-fns';
import { getUsername } from '../helpers/UserMetaHelper';
import { CustomEventAttributes } from '../models/Amplify/CustomEventAttributes';
import { DefaultMeta } from '../models/Amplify/DefaultMeta';
import { amplifyConfiguration as logger } from './configurationBuilder';
import { getUTCDateByDate } from '../helpers/TimeHelper';

const eventName = 'reactLogEvent';
const providerName = 'aws-amplify';
const timestampFormat = 'yyyy-MM-HH:mm:ss.SSSSSSS';
const messageUrlViewed = 'Url has been viewed.';
const messageUrlNotFould = 'Page not found.';

export async function getAttributesPageView(): Promise<DefaultMeta> {
  return getDefaultMetaWithUserName(messageUrlViewed);
}

export async function recordPageNotFoundEvent(url: string): Promise<boolean> {
  return recordEvent(messageUrlNotFould, { url });
}

export async function recordEvent(
  message: string,
  eventAttributes?: CustomEventAttributes,
  customUserName?: string,
): Promise<boolean> {
  return recordEventWithAttributes(
    await getDefaultMetaWithUserName(message, customUserName),
    eventAttributes,
  );
}

export async function recordEventWithoutUserName(
  message: string,
  eventAttributes?: CustomEventAttributes,
): Promise<boolean> {
  return recordEventWithAttributes(await getDefaultMeta(message), eventAttributes);
}

async function recordEventWithAttributes(
  defaultEventAttributes: DefaultMeta,
  eventAttributes?: CustomEventAttributes,
): Promise<boolean> {
  const eventAttributesWithDefault = {
    name: eventName,
    ...defaultEventAttributes,
    ...eventAttributes,
  };

  return (await logger).record(eventAttributesWithDefault);
}

async function getDefaultMetaWithUserName(message: string, userName?: string): Promise<DefaultMeta> {
  return {
    userName: userName || (await getUsername()),
    ...(await getDefaultMeta(message)),
  };
}

async function getDefaultMeta(message: string): Promise<DefaultMeta> {
  return {
    message,
    timestamp: format(getUTCDateByDate(new Date()), timestampFormat),
    userAgent: window.navigator.userAgent,
    provider: providerName,
  };
}
