import { AccountLookupModel } from '../../models/Consumer/Account/AccountLookupModel';
import { AccountsLookupModel } from '../../models/Consumer/Account/AccountsLookupModel';
import { accountsLookupPrefix } from '../actions/actionTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAccountsLookupAction } from '../actions/fetchAccountsLookupAction';

const initialState: AccountsLookupModel = {
  items: [] as AccountLookupModel[],
  isLoading: true,
  selectedAccountsIds: [],
  tooManyAccountsSelected: false,
};

const accountsLookupSlice = createSlice({
  name: accountsLookupPrefix,
  initialState,
  reducers: {
    reset: () => initialState,
    addSelectedAccount: (state, action: PayloadAction<string>) => {
      state.selectedAccountsIds = [...state.selectedAccountsIds, action.payload];
    },
    removeSelectedAccount: (state, action: PayloadAction<string>) => {
      state.selectedAccountsIds = state.selectedAccountsIds.filter((id) => id !== action.payload);
    },
    setSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.selectedAccountsIds = [...action.payload];
    },
    removeAllSelectedAccounts: (state) => {
      state.selectedAccountsIds = [];
    },
    setTooManyAccountsSelected: (state, action: PayloadAction<boolean>) => {
      state.tooManyAccountsSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountsLookupAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAccountsLookupAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(fetchAccountsLookupAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default accountsLookupSlice.reducer;
export const resetAccountLookup = accountsLookupSlice.actions.reset;
