/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react';
import { mapToUserModel } from '../../mappers/UserMapper';
import { ClaimModel } from '../../models/User/ClaimModel';
import { UserFormViewModel } from '../../models/User/UserFormViewModel';
import { UserModel } from '../../models/User/UserModel';
import { useAppSelector } from './useAppSelector';
import { ApiError } from './../../models/Api/ApiError';
import {
  clearUsersErrorAction,
  clearUsersSuccessMessageAction,
  createUserAction,
  fetchUsersAction,
  updateUserAction,
} from '../actions/usersActions';
import { useAppDispatch } from './useAppDispatch';
import { resetConsumerTypes } from '../reducers/consumerTypesReducer';
import { UserRoles } from '../../enums/UserRole';

export const useUsers: () => [
  {
    users: UserModel[];
    isLoading: boolean;
    error?: ApiError;
    successMessage?: string;
  },
  {
    createUser: (model: UserFormViewModel, claims: ClaimModel[]) => void;
    updateUser: (model: UserFormViewModel, claims: ClaimModel[]) => void;
    handleError: () => void;
    handleSuccessMessage: () => void;
  },
] = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector((s) => s.users);

  useEffect(() => {
    if (users.items.length === 0) {
      dispatch(fetchUsersAction());
    }
  }, [users.items.length, dispatch]);

  useEffect(() => {
    dispatch(resetConsumerTypes());
  }, [dispatch]);

  const createUser = (model: UserFormViewModel, claims: ClaimModel[]) => {
    dispatch(createUserAction(mapToUserModel(UserRoles, model, claims)))
      .unwrap()
      .then(() => {
        dispatch(fetchUsersAction());
      });
  };

  const updateUser = (model: UserFormViewModel, claims: ClaimModel[]) => {
    dispatch(updateUserAction(mapToUserModel(UserRoles, model, claims)))
      .unwrap()
      .then(() => {
        dispatch(fetchUsersAction());
      });
  };

  const handleError = () => {
    dispatch(clearUsersErrorAction());
  };

  const handleSuccessMessage = () => {
    dispatch(clearUsersSuccessMessageAction());
  };

  return [
    {
      users: users.items,
      isLoading: users.isLoading,
      error: users.error,
      successMessage: users.successMessage,
    },
    {
      createUser,
      updateUser,
      handleError,
      handleSuccessMessage,
    },
  ];
};
