import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { CustomCognitoUser } from '../../../models/User/CustomCognitoUser';
import { i18n } from '../../../services/i18n';
import FormFieldLabel from '../../../shared/FormFieldLabel/FormFieldLabel';

interface Props {
  email: string;
  userWithoutPassword: CustomCognitoUser | undefined;
  setUser: (newUser: CustomCognitoUser | undefined) => void;
}

const ChangePassword = ({ email, userWithoutPassword, setUser }: Props): React.JSX.Element => {
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const loadingClassName = isLoading ? 'loading' : '';

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await Auth.completeNewPassword(userWithoutPassword, password);
      const user = (await Auth.signIn(email, password)) as CustomCognitoUser;
      setIsLoading(false);
      setUser(user);
    } catch (error) {
      setIsLoading(false);
      setAlertMessage((error as Error)?.message);
    }
  };

  return (
    <Form onSubmit={() => void handleSubmit()} className="form form-changePassword">
      <h2>{i18n('Authentication_ChangePassword')}</h2>
      <div>{i18n('Authentication_ChangeInfo')}</div>
      <Form.Field>
        <FormFieldLabel required>{i18n('Authentication_Password')}</FormFieldLabel>
        <input value={password} type="password" onChange={(e) => setPassword(e.target.value)} />
      </Form.Field>
      {alertMessage && <div className="error-message">{alertMessage}</div>}

      <Button primary type="submit" size="medium" fluid className={loadingClassName}>
        {i18n('Authentication_Change')}
      </Button>
    </Form>
  );
};

export default ChangePassword;
