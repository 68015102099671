import { intersection, isEmpty, isEqual } from 'lodash';

import { Role, UserRole, UserRoles } from '../enums/UserRole';
import { UserFormViewModel } from '../models/User/UserFormViewModel';
import { UserModel } from '../models/User/UserModel';
import { ClaimModel } from './../models/User/ClaimModel';
import { Permission } from '../enums/Permission';
import { UserClaimModel } from '../models/User/UserClaimModel';
import { isRolesWithConsumersTypes } from '../features/User/UserForm/helpers';

// check that array of roles includes a particular permissions inside one of role
export const isRolesIncludesPermissions = (roles: UserRole[], permissions: Permission[]): boolean =>
  !isEmpty(permissions)
    ? roles.map((role) => isEqual(intersection(role.permissions, permissions), permissions)).includes(true)
    : false;

// check that user permissions includes all permissions that a particular role has
export const isPermissionsIncludes = (
  permissions: Permission[],
  requiredPermissions: Permission[],
): boolean =>
  !isEmpty(requiredPermissions)
    ? isEqual(intersection(requiredPermissions, permissions), requiredPermissions)
    : false;

export const mapPermissionsToRoles = (userPermissions: Permission[], userRoles: UserRole[]): Role[] =>
  userRoles
    // discarding roles which have more permissions than user has
    .filter((role) => userPermissions.length >= role.permissions.length)
    // get the roles with the most permissions from the start
    .sort((a, b) => b.permissions.length - a.permissions.length)
    .reduce<UserRole[]>((previousValue, currentValue, _currentIndex, _array) => {
      // if role permissions are matching with one of role and other matched roles does not include permissions from this role => include this role to the list of user roles
      return isPermissionsIncludes(userPermissions, currentValue.permissions) &&
        !isRolesIncludesPermissions(previousValue, currentValue.permissions)
        ? [...previousValue, { ...currentValue }]
        : previousValue;
    }, [])
    .map((r) => r.role);

export const mapClaimsToPermissions = (
  userClaims: UserClaimModel[],
  possibleClaims: ClaimModel[],
): Permission[] => {
  const filteredClaims = userClaims.filter(
    (c) => Boolean(JSON.parse(c.claimValue ?? 'false')) && c.claimId !== undefined,
  );

  return filteredClaims.reduce<Permission[]>((previousValue, currentValue, _currentIndex, _array) => {
    const claimName = possibleClaims.find((c) => c.id === currentValue.claimId)?.name;

    return claimName ? [...previousValue, claimName as Permission] : [...previousValue];
  }, []);
};

export const getUserRoles = (
  user: UserModel,
  possibleClaims: ClaimModel[],
  possibleUserRoles: UserRole[],
): Role[] => {
  const userPermissions = mapClaimsToPermissions(user.claims ?? [], possibleClaims);

  return mapPermissionsToRoles(userPermissions, possibleUserRoles);
};

export const mapToUserFormViewModel = (source?: UserModel, claims?: ClaimModel[]): UserFormViewModel => {
  return {
    id: source?.id,
    firstName: source?.firstName ?? '',
    lastName: source?.lastName ?? '',
    email: source?.email ?? '',
    phoneNumber: source?.phoneNumber ?? '',
    roleIds: (source && claims && getUserRoles(source, claims, UserRoles)) ?? [],
    isEnabled: source?.isEnabled ?? true,
    isFederated: source?.isFederated ?? false,
    consumerTypes: source?.consumerTypes ?? [],
  };
};

export const mapToUserModel = (
  userRoles: UserRole[],
  source?: UserFormViewModel,
  claims?: ClaimModel[],
): UserModel => {
  const permissions =
    (source &&
      userRoles.reduce<Permission[]>(
        (previousValue, currentValue) =>
          source.roleIds.includes(currentValue.role)
            ? [...previousValue, ...currentValue.permissions]
            : previousValue,
        [],
      )) ||
    [];
  const isWithConsumerTypes = source && isRolesWithConsumersTypes(source.roleIds);

  return {
    id: source?.id,
    email: source?.email.trim(),
    firstName: source?.firstName.trim(),
    lastName: source?.lastName,
    phoneNumber: source?.phoneNumber,
    isEnabled: source?.isEnabled,
    isFederated: source?.isFederated,
    consumerTypes: isWithConsumerTypes ? source?.consumerTypes : [],
    claims: permissions?.map((p) => {
      const claim = claims?.find((c) => c.name === p);
      return {
        claimId: claim?.id,
        claimValue: 'true',
      };
    }),
  };
};
