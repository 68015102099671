import { createSlice } from '@reduxjs/toolkit';

import { ApiError } from '../../models/Api/ApiError';
import { eventsPrefix } from '../actions/actionTypes';
import { SendEventStore } from '../../models/Store/SendEventStore';
import { sendEventAction } from '../actions/sendEventActions';
import { i18n } from '../../services/i18n';
import { EventType } from '../../enums/EventTypes';

const initialState: SendEventStore = {
  isLoading: false,
};

const eventsSlice = createSlice({
  name: eventsPrefix,
  initialState,
  reducers: {
    setEventTypePayload: (state, action: { payload: EventType }) => {
      state.sendEventType = action.payload;
    },
    reset: () => initialState,
    clearError: (state) => {
      state.error = undefined;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendEventAction.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
        state.successMessage = undefined;
      })
      .addCase(sendEventAction.fulfilled, (state) => {
        state.isLoading = false;
        state.successMessage = i18n('SendEvents_Successfully');
      })
      .addCase(sendEventAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as ApiError;
      });
  },
});

export default eventsSlice.reducer;
export const { setEventTypePayload } = eventsSlice.actions;
