import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import ToastMessage from '../../features/ToastMessage/ToastMessage';

export const useSuccessToast = (
  successMessage?: string | React.JSX.Element,
  handleSuccessMessage?: () => void,
): void => {
  useEffect(() => {
    if (successMessage) {
      toast.success(<ToastMessage text={successMessage} />);
      handleSuccessMessage && handleSuccessMessage();
    }
  }, [handleSuccessMessage, successMessage]);
};
