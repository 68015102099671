import { API } from 'aws-amplify';
import { ApiError } from '../models/Api/ApiError';
import { ApiResponse } from '../models/Api/ApiResponse';
import { amplifyApiName } from '../services/configurationBuilder';

export async function getDataFromApi<T>(endpoint: string): Promise<T> {
  try {
    const response = (await API.get(amplifyApiName, endpoint, {})) as ApiResponse<T>;
    return response.result;
  } catch (error) {
    console.error((error as ApiError).response);
    throw error;
  }
}

export async function postDataToApi<T, TResult>(endpoint: string, body: T): Promise<TResult> {
  try {
    const response = (await API.post(amplifyApiName, endpoint, { body })) as ApiResponse<TResult>;
    return response.result;
  } catch (error) {
    console.error((error as ApiError).response);
    throw error;
  }
}

export async function putDataToApi<T, TResult>(endpoint: string, body: T): Promise<TResult> {
  try {
    const response = (await API.put(amplifyApiName, endpoint, { body })) as ApiResponse<TResult>;
    return response.result;
  } catch (error) {
    console.error((error as ApiError).response);
    throw error;
  }
}

export const parseObjectToParams = (obj: { [x: string]: string | number | boolean | undefined }): string => {
  return new URLSearchParams(
    Object.entries(obj).reduce(
      (result, [key, value]) =>
        typeof value === 'number' || value ? Object.assign(result, { [key]: value }) : result,
      {},
    ),
  ).toString();
};
