import clsx from 'clsx';
import React, { useRef, useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import './styles.scss';

interface Props {
  text: string;
  className?: string;
  'data-testid'?: string;
}

const OverflowTip: React.FC<Props> = ({ text, className, 'data-testid': dataTestId }) => {
  const textElementRef = useRef<null | HTMLDivElement>(null);

  const compareSize = () => {
    const compare = textElementRef.current
      ? textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      : false;
    setIsOverflowing(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  const [isOverflowing, setIsOverflowing] = useState(false);

  return (
    <Popup
      content={text}
      disabled={!isOverflowing}
      trigger={
        <div data-testid={dataTestId} className={clsx(className, 'overflow-tip')} ref={textElementRef}>
          <span>{text}</span>
        </div>
      }
    />
  );
};

export default OverflowTip;
