import { useCallback } from 'react';
import { EventType } from '../../enums/EventTypes';
import { mapSendEventPayload } from '../../mappers/EventsTypesMapper';
import { LoadingModel } from '../../models/Store/LoadingModel';
import { SendEventStore } from '../../models/Store/SendEventStore';
import {
  clearSendEventErrorAction,
  clearSendEventSuccessMessageAction,
  resetSendEventAction,
  sendEventAction,
} from '../actions/sendEventActions';
import { setEventTypePayload } from '../reducers/sendEventReducer';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export const useSendEvent: () => [
  LoadingModel,
  {
    sendEvent: (tripId: string) => void;
    handleChangeEventType: (newEventType: EventType) => void;
    handleReset: () => void;
    handleError: () => void;
    handleSuccess: () => void;
  },
] = () => {
  const dispatch = useAppDispatch();
  const sendEventState: SendEventStore = useAppSelector((s) => s.sendEvent);

  const sendEvent = useCallback(
    (tripId: string) => {
      if (!sendEventState.sendEventType) {
        return;
      }

      const newPayload = mapSendEventPayload(tripId, sendEventState.sendEventType);
      void dispatch(sendEventAction(newPayload));
    },
    [dispatch, sendEventState],
  );

  const handleChangeEventType = useCallback(
    (newEventType: EventType) => {
      dispatch(setEventTypePayload(newEventType));
    },
    [dispatch],
  );

  const handleReset = useCallback(() => {
    dispatch(resetSendEventAction());
  }, [dispatch]);

  const handleError = () => {
    dispatch(clearSendEventErrorAction());
  };

  const handleSuccess = () => {
    dispatch(clearSendEventSuccessMessageAction());
  };

  return [
    sendEventState,
    {
      sendEvent,
      handleChangeEventType,
      handleReset,
      handleError,
      handleSuccess,
    },
  ];
};
