export const claimsPrefix = 'claims';
export const fetchClaimsActionName = `${claimsPrefix}/fetchClaims`;

export const consumerInfoPrefix = 'consumerInfo';
export const fetchConsumerInfoActionName = `${consumerInfoPrefix}/fetchConsumerInfo`;
export const clearConsumerInfoErrorActionName = `${consumerInfoPrefix}/clearError`;

export const consumersPrefix = 'consumers';
export const fetchConsumersActionName = `${consumersPrefix}/fetchConsumers`;
export const createConsumerActionName = `${consumersPrefix}/createConsumer`;
export const updateConsumerActionName = `${consumersPrefix}/updateConsumer`;
export const clearConsumersErrorActionName = `${consumersPrefix}/clearError`;
export const clearConsumersSuccessMessageActionName = `${consumersPrefix}/clearSuccessMessage`;

export const consumerTypesPrefix = 'consumerTypes';
export const fetchConsumerTypesActionName = `${consumerTypesPrefix}/fetchConsumerTypes`;

export const tripsPrefix = 'trips';
export const fetchTripsActionName = `${tripsPrefix}/fetchTrips`;
export const resetTripsActionName = `${tripsPrefix}/reset`;

export const eventsPrefix = 'events';
export const sendEventActionName = `${eventsPrefix}/sendEvent`;
export const resetSendEventActionName = `${eventsPrefix}/reset`;
export const clearSendEventErrorActionName = `${eventsPrefix}/clearError`;
export const clearSendEventSuccessMessageActionName = `${eventsPrefix}/clearSuccessMessage`;

export const tripAccessAdvancedSettingsPrefix = 'tripAccessAdvancedSettings';
export const fetchTripAccessAdvancedSettingsActionName = `${tripAccessAdvancedSettingsPrefix}/fetchTripAccessAdvancedSettings`;

export const tripAccessScopesPresetsPrefix = 'tripAccessScopesPresets';
export const fetchTripAccessScopesPresetsActionName = `${tripAccessScopesPresetsPrefix}/fetchTripAccessScopesPresets`;

export const dataSourceFiltersPrefix = 'dataSourceFilters';
export const dataSourceFiltersActionName = `${dataSourceFiltersPrefix}/fetchDataSourceFilters`;

export const accountsLookupPrefix = 'accountsLookup';
export const fetchAccountsLookupActionName = `${accountsLookupPrefix}/fetchAccountsLookup`;
export const addSelectedAccountActionName = `${accountsLookupPrefix}/addSelectedAccount`;
export const removeSelectedAccountActionName = `${accountsLookupPrefix}/removeSelectedAccount`;
export const setSelectedAccountsActionName = `${accountsLookupPrefix}/setSelectedAccounts`;
export const removeAllSelectedAccountsActionName = `${accountsLookupPrefix}/removeAllSelectedAccounts`;
export const setTooManyAccountsSelectedActionName = `${accountsLookupPrefix}/setTooManyAccountsSelected`;

export const usersPrefix = 'users';
export const fetchUsersActionName = `${usersPrefix}/fetchUsers`;
export const createUserActionName = `${usersPrefix}/createUser`;
export const updateUserActionName = `${usersPrefix}/updateUser`;
export const clearUsersErrorActionName = `${usersPrefix}/clearError`;
export const clearUsersSuccessMessageActionName = `${usersPrefix}/clearSuccessMessage`;
