import { EventType } from '../enums/EventTypes';
import { DropdownOption } from '../features/NamedDropdown/NamedDropdown';
import { SendEventRequestDTO } from '../models/Events/SendEventModel';
import { i18n } from '../services/i18n';

export const getEventsTypesForDropdown = (): DropdownOption[] =>
  Object.keys(EventType)
    .filter((value) => !isNaN(Number(value)))
    .map((type) => ({
      key: String(type),
      value: type,
      text: i18n(`EventType_${type}`),
    }));

export const mapSendEventPayload = (tripId: string, eventType: EventType): SendEventRequestDTO => ({
  eventType: EventType[eventType] as keyof typeof EventType,
  tripId: tripId,
});
