import CanUser from '../../features/Permissions/CanUser';
import clsx from 'clsx';
import ConsumerForm from '../../features/ConsumerForm/ConsumerForm';
import ConsumersActionsMenu from './ConsumersActionsMenu';
import ConsumerTable from '../../features/ConsumerTable';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dimmer, Grid, Input, Loader } from 'semantic-ui-react';
import { ConsumerFormViewModel } from '../../models/Consumer/ConsumerFormViewModel';
import { ConsumerService } from '../../services/ConsumerService';
import { consumersPath } from '../../paths';
import { i18n } from '../../services/i18n';
import { Permission } from '../../enums/Permission';
import { ScopesPresetModel } from '../../models/Consumer/Scopes/ScopesPresetModel';
import { useAutoFocus } from '../../hooks/Form/useAutoFocus';
import { useBreakpoint, useColumnBreakpoint } from '../../hooks/BreakPointProvider/breakpoint';
import { useConsumers } from '../../store/hooks/useConsumers';
import { useErrorToast } from '../../hooks/Alert/useErrorToast';
import { usePageNavigation } from '../../hooks/Navigation/usePageNavigation';
import { useSuccessToast } from '../../hooks/Alert/useSuccessToast';
import './styles.scss';

const ConsumersPage: React.FC = () => {
  const [
    { consumers, isLoading, error, successMessage },
    { createConsumer, handleError, handleSuccessMessage },
  ] = useConsumers();

  const [createdConsumerId, setCreatedConsumerId] = useState<string>();
  const [{ isFormOpen, searchTerm }, { goToMainPage, goToSubPage, goToModal, goToUrl, handleSearchChanged }] =
    usePageNavigation();
  const [{ inputFocusRef }, { focus }] = useAutoFocus();

  const breakpoints = useBreakpoint();
  const [{ getFormColumnsCount, getTableColumnsCount }] = useColumnBreakpoint();

  const service = ConsumerService;

  const filteredConsumers = useMemo(
    () => service.searchConsumers(consumers, searchTerm),
    [consumers, searchTerm, service],
  );

  const handleConsumerOpen = (consumerId: string) => {
    goToSubPage(`${consumerId}`);
  };

  const handleConsumerSave = async (
    model: ConsumerFormViewModel,
    tripAccessScopesPresets: ScopesPresetModel[],
  ) => {
    goToMainPage();
    const consumerId = await createConsumer(model, tripAccessScopesPresets);
    setCreatedConsumerId(consumerId);
  };

  const getConsumerCreatedMessage = (successMessage: string | undefined) => {
    if (!successMessage || !createdConsumerId) {
      return;
    }
    const consumerLink = `${consumersPath}/${createdConsumerId}`;
    return (
      <>
        {successMessage}{' '}
        <a href={consumerLink} className="link">
          {i18n('ConsumersPage_ConsumerCreatedLink')}
        </a>
      </>
    );
  };

  useEffect(() => {
    focus(!isFormOpen);
  }, [focus, isFormOpen]);

  useErrorToast(error, handleError);
  useSuccessToast(getConsumerCreatedMessage(successMessage), handleSuccessMessage);

  return (
    <>
      {isLoading && (
        <Dimmer active inverted>
          <Loader size="massive" className="consumers-page-loader" />
        </Dimmer>
      )}
      <div className="page-title">
        <h2>{i18n('ConsumersPage_Title')}</h2>
      </div>
      <div className="page-title-container consumers-title">
        <Input
          value={searchTerm}
          data-testid="consumers-search-input"
          size="mini"
          icon="search"
          iconPosition="left"
          placeholder={i18n('ConsumersPage_SearchPlaceholder')}
          ref={inputFocusRef}
          onChange={(e) => handleSearchChanged(e.target.value)}
        />
        <div>
          <CanUser permission={Permission.ConsumersUpdate}>
            <Button
              data-testid="create-consumer-button"
              primary
              className="create-button"
              onClick={() => goToModal()}
            >
              <span>{i18n('ConsumersPage_CreateConsumerButton')}</span>
            </Button>
          </CanUser>
          <ConsumersActionsMenu consumers={filteredConsumers} />
        </div>
      </div>

      <Grid columns="equal" className="page-body-container">
        {(!breakpoints.sm || !isFormOpen) && (
          <Grid.Column width={getTableColumnsCount(isFormOpen)}>
            <div
              className={clsx('consumers-content-container', {
                'table-sticky-container': !breakpoints.xs,
              })}
            >
              <ConsumerTable
                items={filteredConsumers}
                noDataMessage={searchTerm ? i18n('Table_No_Result') : i18n('ConsumerTable_No_Data')}
                onConsumerOpen={handleConsumerOpen}
              />
            </div>
          </Grid.Column>
        )}
        {isFormOpen && (
          <Grid.Column width={getFormColumnsCount()}>
            <div className="consumers-content-container">
              <ConsumerForm
                consumers={consumers}
                onSave={(model, presets) => void handleConsumerSave(model, presets)}
                onClose={(redirectUrl) => goToUrl(redirectUrl)}
              />
            </div>
          </Grid.Column>
        )}
      </Grid>
    </>
  );
};

export default ConsumersPage;
