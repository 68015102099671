import { RegisterOptions } from 'react-hook-form';

import { i18n } from '../services/i18n';
import { EventsFormViewModel } from '../models/Events/EventsFormViewModel';
import {
  getTravelerRule,
  getSourceIdRule,
  getGlobalCustomerNumberRule,
  getAccountIdRule,
  getEmailRule,
  getAccountIdValidationRule,
  isOtherFieldsDefault,
} from './FormHelpers';

export const sourceIdRules: () => RegisterOptions<EventsFormViewModel, 'sourceId'> = () => ({
  required: i18n('Form_RequiredMessage'),
  ...getSourceIdRule(),
});

export const globalCustomerNumberRules: () => RegisterOptions<EventsFormViewModel, 'globalCustomerNumber'> =
  getGlobalCustomerNumberRule;

export const accountIdRules: () => RegisterOptions<EventsFormViewModel, 'accountId'> = () => ({
  ...getAccountIdRule(),
  validate: getAccountIdValidationRule,
});

export const travelerEmailAddressRules: () => RegisterOptions<EventsFormViewModel, 'travelerEmailAddress'> =
  getEmailRule;

export const travelerFirstNameRules: () => RegisterOptions<EventsFormViewModel, 'travelerFirstName'> =
  getTravelerRule;

export const travelerLastNameRules: () => RegisterOptions<EventsFormViewModel, 'travelerLastName'> =
  getTravelerRule;

export { isOtherFieldsDefault };
