import clsx from 'clsx';
import React, { RefObject } from 'react';
import { FieldError } from 'react-hook-form';
import { Input } from 'semantic-ui-react';

import './styles.scss';

interface Props {
  title: string | React.JSX.Element;
  value?: string;
  maxLength?: number;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  autoFocus?: boolean;
  inputRef?: RefObject<Input>;
  onChange?: (v: string) => void;
  onBlur?: () => void;
  'data-testid'?: string;
}

const NamedInput: React.FC<Props> = ({
  title,
  value,
  maxLength,
  className,
  placeholder,
  disabled,
  error,
  autoFocus,
  inputRef,
  onChange,
  onBlur,
  'data-testid': dataTestId,
}) => {
  return (
    <div className={clsx(className, 'named-input')}>
      <div className="named-input-title">{title}</div>
      <Input
        value={value ?? ''}
        data-testid={dataTestId}
        maxLength={maxLength}
        fluid
        placeholder={placeholder}
        disabled={disabled}
        error={!!error}
        autoFocus={autoFocus}
        ref={inputRef}
        onChange={(_, v) => onChange && onChange(v.value)}
        onBlur={() => onBlur && onBlur()}
      />
      {error && <p className="error-message named-input-error-msg">{error?.message}</p>}
    </div>
  );
};

export default NamedInput;
