import HighlightWithinTextarea from 'react-highlight-within-textarea';
import ModalBase from '../../../Modal/ModalBase/ModalBase';
import React from 'react';
import { i18n } from '../../../../services/i18n';
import { ModalsModel } from '../../../../models/Common/ModalsModel';
import './styles.scss';

interface Props {
  accountsIds: string[];
  modals: ModalsModel;
  onSave: () => void;
}

const AddAccountsModal: React.FC<Props> = ({ accountsIds, modals, onSave }) => {
  if (!modals.isSavePopupOpen || !accountsIds?.length) {
    return <></>;
  }

  return (
    <ModalBase
      isOpen={modals.isSavePopupOpen}
      header={i18n('AccountsForm_ModalSaveCreate_Title')}
      content={
        <div className="new-accounts-grid">
          {accountsIds.map((accountId, index) => {
            return (
              <span key={accountId}>
                {accountId}
                {index < accountsIds.length - 1 && ','}
              </span>
            );
          })}
        </div>
      }
      cancelButtonText={i18n('Modals_SaveAdding_CancelButton')}
      okButtonText={i18n('Modals_Save_SaveButton')}
      onClose={(isSuccess) => (isSuccess ? onSave() : modals.showOrHideSaveModal(false))}
    />
  );
};

export default AddAccountsModal;
