import { getDataFromApi } from '../helpers/ApiHelper';
import { ConsumerTypeModel } from '../models/Consumer/ConsumerTypeModel';

export class ConsumerTypeService {
  private static readonly endpoint = '/consumer-types';

  public static getConsumerTypes(): Promise<ConsumerTypeModel[]> {
    return getDataFromApi<ConsumerTypeModel[]>(this.endpoint);
  }
}
