import React from 'react';
import './styles.scss';
import { Tag } from 'primereact/tag';
import { i18n } from '../../services/i18n';

export interface LabelGroupPrimeProps {
  names?: string[];
  'data-testid'?: string;
  noDataMessage?: string;
}

const LabelGroupPrime: React.FC<LabelGroupPrimeProps> = ({
  names,
  'data-testid': dataTestId,
  noDataMessage,
}) => {
  return (
    <div className="label-group-prime-container" data-testid={dataTestId}>
      {names && names?.length > 0
        ? names?.map((name) => <Tag value={name} key={name} className="label-tag"></Tag>)
        : noDataMessage}
    </div>
  );
};

export default LabelGroupPrime;
