import { useEffect } from 'react';
import { AccountLookupModel } from '../../models/Consumer/Account/AccountLookupModel';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import {
  addSelectedAccountActionName,
  removeAllSelectedAccountsActionName,
  removeSelectedAccountActionName,
  setSelectedAccountsActionName,
  setTooManyAccountsSelectedActionName,
} from '../actions/actionTypes';
import { maxAccountsPerTime } from '../../features/ConsumerTab/AccountsTab/AccountsFormConstants';

export const useAccountSelection: (
  accountsLookupFiltered: AccountLookupModel[],
  accountsIds: string[] | undefined,
) => {
  accountIsSelected: (account: AccountLookupModel) => boolean;
  allOrMaxAccountsSelected: () => boolean;
  handleAllAccountsSelected: (isChecked: boolean | undefined) => void;
  isAlreadyAdded: (accountLookup: AccountLookupModel) => boolean | undefined;
  isDisabled: (accountLookup: AccountLookupModel) => boolean | undefined;
  handleAccountSelected: (accountId: string, isChecked: boolean | undefined) => void;
  removeAllSelectedAccounts: () => void;
} = (accountsLookupFiltered, accountsIds) => {
  const dispatch = useAppDispatch();
  const accountsLookup = useAppSelector((s) => s.accountsLookup);

  const accountIsSelected = (account: AccountLookupModel): boolean => {
    return accountsLookup.selectedAccountsIds.includes(account.accountId);
  };

  const allOrMaxAccountsSelected = (): boolean => {
    return (
      accountsLookup.selectedAccountsIds.length >= maxAccountsPerTime ||
      (accountsLookupFiltered.length < maxAccountsPerTime &&
        accountsLookup.selectedAccountsIds.length === accountsLookupFiltered.length &&
        accountsLookup.selectedAccountsIds.length > 0)
    );
  };

  const isAlreadyAdded = (accountLookup: AccountLookupModel): boolean | undefined =>
    accountsIds?.includes(accountLookup.accountId);

  const isDisabled = (accountLookup: AccountLookupModel): boolean | undefined =>
    isAlreadyAdded(accountLookup) ||
    (accountsLookup.selectedAccountsIds.length >= maxAccountsPerTime &&
      !accountsLookup.selectedAccountsIds.includes(accountLookup.accountId));

  const handleAccountSelected = (accountId: string, isChecked: boolean | undefined) => {
    isChecked
      ? dispatch({ type: addSelectedAccountActionName, payload: accountId })
      : dispatch({ type: removeSelectedAccountActionName, payload: accountId });
  };

  const handleAllAccountsSelected = (isChecked: boolean | undefined) => {
    if (isChecked) {
      const accountLookupFilteredNumbers = accountsLookupFiltered
        .map((item) => {
          return item.accountId;
        })
        .filter((item) => !accountsIds?.includes(item))
        .slice(0, maxAccountsPerTime);

      dispatch({ type: setSelectedAccountsActionName, payload: accountLookupFilteredNumbers });
    } else {
      removeAllSelectedAccounts();
    }
  };

  const removeAllSelectedAccounts = () => {
    dispatch({ type: removeAllSelectedAccountsActionName });
  };

  useEffect(() => {
    if (
      accountsLookup.tooManyAccountsSelected &&
      accountsLookup.selectedAccountsIds.length < maxAccountsPerTime
    ) {
      dispatch({
        type: setTooManyAccountsSelectedActionName,
        payload: false,
      });
    }
    if (
      !accountsLookup.tooManyAccountsSelected &&
      accountsLookup.selectedAccountsIds.length >= maxAccountsPerTime
    ) {
      dispatch({
        type: setTooManyAccountsSelectedActionName,
        payload: true,
      });
    }
  }, [dispatch, accountsLookup]);

  return {
    accountIsSelected,
    allOrMaxAccountsSelected,
    handleAllAccountsSelected,
    isAlreadyAdded,
    isDisabled,
    handleAccountSelected,
    removeAllSelectedAccounts,
  };
};
