import { Auth } from 'aws-amplify';
import React, { SyntheticEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { i18n } from '../../../services/i18n';
import FormFieldLabel from '../../../shared/FormFieldLabel/FormFieldLabel';

const ForgotPassword: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [email, setEmail] = useState('');

  const history = useHistory();

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      await Auth.forgotPassword(email);

      history.push({
        pathname: '/password/reset',
        state: {
          email: email,
        },
      });
    } catch (error) {
      if ((error as Error).name === 'UserNotFoundException') {
        setAlertMessage(i18n('Authentication_UserDoesNotExist'));
      } else setAlertMessage((error as Error)?.message);
    }
  };

  return (
    <Form
      onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
        void handleSubmit(event);
      }}
      className="form form-changePassword"
    >
      <h2>{i18n('Authentication_ForgotPassword')}</h2>
      <div>{i18n('Authentication_ForgotPasswordInfo')}</div>
      <Form.Field>
        <FormFieldLabel required>{i18n('Authentication_Email')}</FormFieldLabel>
        <input value={email} autoFocus onChange={(e) => setEmail(e.target.value.trim())} />
      </Form.Field>

      {alertMessage && <div className="error-message">{alertMessage}</div>}

      <Button primary type="submit" size="medium" fluid>
        {i18n('Authentication_VerificationCodeBtn')}
      </Button>

      <div className="center-content">
        <Link to="/" className="link-text">
          {i18n('Authentication_GoBack')}
        </Link>
      </div>
    </Form>
  );
};

export default ForgotPassword;
