import NoDataRow from '../../../Table/NoDataRow';
import React, { Key, useMemo, useState } from 'react';
import { AccountLookupSearch } from '../../../../models/Consumer/Account/AccountLookupSearch';
import { Checkbox, Dimmer, Icon, Input, Loader, Popup, Table, Visibility } from 'semantic-ui-react';
import { i18n } from '../../../../services/i18n';
import { useAccountsLookup } from '../../../../store/hooks/useAccountsLookup';
import { useAccountSelection } from '../../../../store/hooks/useAccountSelection';
import { useLazyItemsView } from '../../../../hooks/Table/useLazyItemsView';
import './styles.scss';
import '../../../Table/styles.scss';

interface Props {
  accountsIds: string[] | undefined;
}

const AccountLookupTable: React.FC<Props> = ({ accountsIds }) => {
  const [search, setSearch] = useState<AccountLookupSearch>({ isSearchApplied: false });

  const [{ isLoadingAccountsLookup, allAccountsLookup }, { searchAccountsLookup }] = useAccountsLookup();
  const accountsLookupFiltered = useMemo(
    () => searchAccountsLookup(search, allAccountsLookup),
    [allAccountsLookup, search, searchAccountsLookup],
  );
  const [{ itemsVisible: accountsLookupVisible, refTableWrapper }, { handleUpItemsCount }] =
    useLazyItemsView(accountsLookupFiltered);
  const {
    accountIsSelected,
    allOrMaxAccountsSelected,
    handleAllAccountsSelected,
    isAlreadyAdded,
    isDisabled,
    handleAccountSelected,
    removeAllSelectedAccounts,
  } = useAccountSelection(accountsLookupFiltered, accountsIds);

  const handleKeyPressOnlyNumbers = (e: React.KeyboardEvent<HTMLInputElement>) => {
    !/\d/.test(e.key) && e.preventDefault();
  };

  const handleSearchFieldChange =
    (fieldName: keyof AccountLookupSearch) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      setSearch({
        ...search,
        [fieldName]: e.target.value,
        isSearchApplied: value !== '',
      });

      removeAllSelectedAccounts();
    };

  const getTextToNoDataRow = search.isSearchApplied ? i18n('Table_No_Result') : i18n('AccountTable_No_Data');

  const getAccountAddedText = (accountId: string) =>
    i18n('AccountLookupTable_CheckboxTooltip').replace('{{accountId}}', accountId);

  return (
    <>
      {isLoadingAccountsLookup && (
        <Dimmer active inverted>
          <Loader size="massive" className="account-lookup-loader" />
        </Dimmer>
      )}
      <div ref={refTableWrapper} className="table-sticky-container accounts-content-container">
        <Visibility
          continuous={true}
          context={refTableWrapper as unknown as HTMLElement}
          onBottomVisible={() => handleUpItemsCount(accountsLookupFiltered?.length)}
        >
          <Table data-testid="account-lookup-table" className="data-table account-lookup-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell key="AccountLookupTable_Column_AccountName" className="column-account-name">
                  <div className="header-with-search">{i18n('AccountLookupTable_Column_AccountName')}</div>
                  <div className="header-searchbox">
                    <Input
                      value={search.accountName}
                      data-testid="accounts-search-input"
                      size="mini"
                      icon="search"
                      iconPosition="left"
                      onChange={handleSearchFieldChange('accountName')}
                    />
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell key="AccountLookupTable_Column_AccountId" className="column-account-id">
                  <div className="header-with-search">{i18n('AccountLookupTable_Column_AccountId')}</div>
                  <div className="header-searchbox">
                    <Input
                      value={search.accountId}
                      data-testid="accounts-search-input"
                      size="mini"
                      icon="search"
                      iconPosition="left"
                      onKeyPress={handleKeyPressOnlyNumbers}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={handleSearchFieldChange('accountId')}
                    />
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell key="AccountLookupTable_Column_Gcn" className="column-gcn">
                  <div className="header-with-search">{i18n('AccountLookupTable_Column_Gcn')}</div>
                  <div className="header-searchbox">
                    <Input
                      value={search.gcn}
                      data-testid="accounts-search-input"
                      size="mini"
                      icon="search"
                      iconPosition="left"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onKeyPress={handleKeyPressOnlyNumbers}
                      onChange={handleSearchFieldChange('gcn')}
                    />
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell key="AccountLookupTable_Column_CountryCode" className="column-country-code">
                  <div className="header-with-search">{i18n('AccountLookupTable_Column_CountryCode')}</div>
                  <div className="header-searchbox">
                    <Input
                      value={search.countryCode}
                      data-testid="accounts-search-input"
                      size="mini"
                      icon="search"
                      iconPosition="left"
                      onChange={handleSearchFieldChange('countryCode')}
                    />
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell key="AccountLookupTable_Column_CountryName" className="column-country-name">
                  {i18n('AccountLookupTable_Column_CountryName')}
                </Table.HeaderCell>
                <Table.HeaderCell className="column-selection">
                  <Checkbox
                    className="checkbox-input"
                    checked={allOrMaxAccountsSelected()}
                    onChange={(_, props) => handleAllAccountsSelected(props.checked)}
                  />
                  <Popup
                    offset={[-11]}
                    content={i18n('AccountLookupTable_SelectAllCheckboxTooltip')}
                    trigger={<Icon name="info circle" color="grey" />}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body data-testid="account-lookup-table-body">
              {accountsLookupVisible && accountsLookupVisible.length > 0 ? (
                accountsLookupVisible.map((item) => {
                  return (
                    <Table.Row key={item.accountId as Key}>
                      <Table.Cell data-testid="account-lookup-table-name">{item.name}</Table.Cell>
                      <Table.Cell data-testid="account-lookup-table-account-id">{item.accountId}</Table.Cell>
                      <Table.Cell data-testid="account-lookup-table-gcn">{item.gcn}</Table.Cell>
                      <Table.Cell data-testid="account-lookup-table-country-code">
                        {item.countryCode}
                      </Table.Cell>
                      <Table.Cell data-testid="account-lookup-table-country-name">
                        {item.countryName}
                      </Table.Cell>
                      <Table.Cell data-testid="account-lookup-table-account-selected">
                        <Checkbox
                          className="checkbox-input"
                          disabled={isDisabled(item)}
                          checked={accountIsSelected(item)}
                          onChange={(_, props) => handleAccountSelected(item.accountId, props.checked)}
                        />
                        {isAlreadyAdded(item) && (
                          <Popup
                            offset={[-11]}
                            trigger={<Icon name="info circle" color="grey" />}
                            content={getAccountAddedText(item.accountId)}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <NoDataRow colSpan={5} text={getTextToNoDataRow} />
              )}
            </Table.Body>
          </Table>
        </Visibility>
      </div>
    </>
  );
};

export default AccountLookupTable;
