import { i18n } from '../../services/i18n';
import { RadioOption } from '../NamedRadioGroup/NamedRadioGroup';

export const getTripAccessOptions: () => RadioOption[] = () => [
  {
    key: '1',
    value: false,
    text: i18n('TripAccessPresetName'),
  },
  {
    key: '2',
    value: true,
    text: i18n('TripAccessAdvancedName'),
  },
];

export const nameRegex = /^[\w\s+=,.@-]+$/i;
