import React from 'react';
import { Container, Icon } from 'semantic-ui-react';

import { i18n } from '../../../services/i18n';

import './styles.scss';

interface Props {
  dataTestid: string;
  message: string;
}

const NotFoundMessage: React.FC<Props> = (props) => {
  return (
    <Container textAlign="center" className="not-found-container" data-testid={props.dataTestid}>
      <Icon name="archive" size="huge" />
      <div className="not-found-title">{i18n('SearchTrips_Modal_NotFound_Title')}</div>
      <div className="text">{props.message}</div>
    </Container>
  );
};

export default NotFoundMessage;
