import { i18n } from '../../../services/i18n';
import React, { FC } from 'react';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import Nav from '../Nav/Nav';
import './styles.scss';
import { usersPath, consumersPath, eventsPath, searchTripsPath } from '../../../paths';
import CanUser from '../../Permissions/CanUser';
import { Permission } from '../../../enums/Permission';

interface Props {
  onLogout: () => void;
}

export const HeaderActionsMobile: FC<Props> = (props) => {
  const [navigationExpanded, setNavigationExpanded] = React.useState(false);
  return (
    <div className="actions-container-mobile">
      <Icon
        data-testid="header-mobile-navigation"
        link
        onClick={() => setNavigationExpanded(true)}
        name="content"
      />
      <Sidebar
        as={Menu}
        vertical
        className="actions-container-mobile-sidebar"
        animation="overlay"
        direction="right"
        visible={navigationExpanded}
        onHide={() => setNavigationExpanded(false)}
      >
        <Menu.Item>
          <div
            className="actions-container-mobile-sidebar-close"
            data-testid="header-mobile-navigation-close"
          >
            <Icon link name="close" width="thin" onClick={() => setNavigationExpanded(false)} />
          </div>
        </Menu.Item>
        <CanUser permission={Permission.ConsumersRead}>
          <Menu.Item>
            <div>
              <Nav
                data-testid="header-mobile-consumers-link"
                name={i18n('Consumers_Link')}
                to={consumersPath}
                horizontal
              />
            </div>
          </Menu.Item>
        </CanUser>
        <CanUser permission={Permission.UsersRead}>
          <Menu.Item>
            <div>
              <Nav
                data-testid="header-mobile-users-link"
                name={i18n('Users_Link')}
                to={usersPath}
                horizontal
              />
            </div>
          </Menu.Item>
        </CanUser>
        <CanUser permission={Permission.EventsRead}>
          <Menu.Item>
            <div>
              <Nav
                data-testid="header-mobile-events-link"
                name={i18n('Events_Link')}
                to={eventsPath}
                exact
                horizontal
              />
            </div>
          </Menu.Item>
        </CanUser>
        <CanUser permission={Permission.SearchTripsRead}>
          <Menu.Item>
            <div>
              <Nav
                data-testid="header-mobile-search-trips-link"
                name={i18n('SearchTrips_Link')}
                to={searchTripsPath}
                exact
                horizontal
              />
            </div>
          </Menu.Item>
        </CanUser>
        <Menu.Item>
          <div>
            <Nav data-testid="header-mobile-faq-link" name={i18n('Faq_Link')} to="/" exact horizontal />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div>
            <div data-testid="header-mobile-logout" className="logout" onClick={props.onLogout}>
              {i18n('Header_Logout')}
            </div>
          </div>
        </Menu.Item>
      </Sidebar>
    </div>
  );
};
