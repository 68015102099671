import clsx from 'clsx';
import React from 'react';
import { Label } from 'semantic-ui-react';
import { getAccountsCustomerNumbersLogicOptions } from './InclusiveLogicSwitchConstants';
import './styles.scss';

interface Props {
  isInclusive: boolean;
  readonly?: boolean;
  onChange: (isInclusive: boolean) => void;
}

const InclusiveLogicSwitch: React.FC<Props> = ({ isInclusive, readonly, onChange }) => {
  const options = getAccountsCustomerNumbersLogicOptions();
  return (
    <div className="inclusive-logic-switch-container">
      {options.map((o) => {
        return (
          <Label
            key={o.key}
            circular
            className={clsx({ active: isInclusive === o.value, readonly: readonly })}
            onClick={() => !readonly && o.value !== isInclusive && onChange(o.value)}
          >
            {o.text}
          </Label>
        );
      })}
    </div>
  );
};

export default InclusiveLogicSwitch;
