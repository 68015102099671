export const getFilterCondition: (searchString: string, property?: string) => boolean = (
  searchString,
  property,
) => {
  return !!property?.toLowerCase().includes(searchString);
};

export const getSearchFilter: (searchText: string | undefined) => string = (searchText) => {
  return searchText ? searchText.trim().toLowerCase() : '';
};
