import { getDataFromApi, putDataToApi } from '../helpers/ApiHelper';
import { mapToAccountsModel } from '../mappers/ConsumerAccountMapper';
import { ConsumerModel } from '../models/Consumer/ConsumerModel';
import { ConsumerMetadataModel } from '../models/Consumer/ConsumerMetadataModel';
import { sortBy } from 'lodash';
import { AccountModel } from '../models/Consumer/Account/AccountModel';
import { AccountsModel } from '../models/Consumer/Account/AccountsModel';
import { SubSmIdModel } from '../models/Consumer/SmIdModel';
import { ScopesPresetModel } from '../models/Consumer/Scopes/ScopesPresetModel';
import { getFilterCondition, getSearchFilter } from './serviceUtils';

export class ConsumerService {
  private static readonly endpointConsumers = '/consumers';

  public static async getConsumers(): Promise<ConsumerMetadataModel[]> {
    const consumers = await getDataFromApi<ConsumerMetadataModel[]>(this.endpointConsumers);
    return this.sortConsumers(consumers);
  }

  public static getConsumer(id: string): Promise<ConsumerModel> {
    return getDataFromApi<ConsumerModel>(`${this.endpointConsumers}/${id}`);
  }

  public static getAccountsFromConsumer(consumer: ConsumerModel): AccountsModel {
    const accountsModel = mapToAccountsModel(consumer);
    const sortedIds = this.sortSmIdModels(accountsModel.ids);

    return {
      ...accountsModel,
      ids: sortedIds.map<AccountModel>(
        (id): AccountModel => ({
          ...id,
          customerNumbers: {
            ids:
              (id.customerNumbers && this.sortSmIdModels(id.customerNumbers.ids as SubSmIdModel[])) ||
              ([] as SubSmIdModel[]),
            isInclusive: id.customerNumbers?.isInclusive ?? false,
          },
        }),
      ),
    };
  }

  public static searchConsumers(
    consumers?: ConsumerMetadataModel[],
    searchText?: string,
  ): ConsumerMetadataModel[] {
    const searchStringToFilter = getSearchFilter(searchText);
    return (
      consumers?.filter(
        (consumer) =>
          getFilterCondition(searchStringToFilter, consumer.consumerId) ||
          getFilterCondition(searchStringToFilter, consumer.consumerKey) ||
          getFilterCondition(searchStringToFilter, consumer.oauthClientId),
      ) ?? []
    );
  }

  public static searchAccounts(accounts?: AccountModel[], searchText?: string): AccountModel[] {
    const searchStringToFilter = getSearchFilter(searchText);
    return (
      accounts?.filter(
        (account) =>
          getFilterCondition(searchStringToFilter, account.id) ||
          getFilterCondition(searchStringToFilter, account.accountName),
      ) ?? []
    );
  }

  public static async upsertConsumerModel(model: ConsumerModel): Promise<string> {
    return putDataToApi<ConsumerModel, string>(this.endpointConsumers, model);
  }

  public static getTripAccessScopesPresets(): Promise<ScopesPresetModel[]> {
    return getDataFromApi<ScopesPresetModel[]>('/scope-presets');
  }

  public static sortSmIdModels(smIds: SubSmIdModel[]): SubSmIdModel[] {
    return sortBy(smIds, (x) => x.id.toLowerCase());
  }

  private static sortConsumers(consumers: ConsumerMetadataModel[]): ConsumerMetadataModel[] {
    return sortBy(consumers, (x) => x.consumerKey.toLowerCase());
  }
}
