import React from 'react';
import { Button } from 'semantic-ui-react';
import { i18n } from '../../../services/i18n';

interface Props {
  handleSignIn: () => void;
  errorMessage: string;
  isBasic?: boolean;
}

const FederatedSignIn = ({ handleSignIn, errorMessage, isBasic }: Props): JSX.Element => {
  return (
    <>
      <Button
        className="button-federatedSignIn"
        type="button"
        onClick={handleSignIn}
        fluid
        color="blue"
        basic={isBasic}
        data-testid="federatedSignIn-btn"
      >
        {i18n('Authentication_BCDsingin')}
      </Button>

      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </>
  );
};

export default FederatedSignIn;
