import { debounce } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Checkbox, Divider, Button, Icon } from 'semantic-ui-react';
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrow-right.svg';
import {
  getTripAccessCheckboxStateFromArray,
  updateTripAccessChildrenState,
} from '../../../../../helpers/TripAccessHelper';
import { CheckboxState } from '../../../../../models/Common/CheckboxState';
import { TripAccessAnchorRef } from '../../../../../models/TripAccess/TripAccessAnchorRef';
import { i18n } from '../../../../../services/i18n';
import HorizontalScroll from '../../../../HorizontalScroll/HorizontalScroll';
import { TripAccessSettingEntry } from './../../../../../models/TripAccess/TripAccessSettingEntry';
import './styles.scss';
import TripAccessAdvancedSettingsEntries from './TripAccessAdvancedSettingsEntries/TripAccessAdvancedSettingsEntries';
import TripAccessAdvancedSettingsFullViewModal from './TripAccessAdvancedSettingsFullViewModal/TripAccessAdvancedSettingsFullViewModal';
import { useAdvancedSettingsRefs } from './useAdvancedSettingsRefs';

interface Props {
  disabled?: boolean;
  settingsEntries: TripAccessSettingEntry[];
  setSettingsEntries: (param: (prev: TripAccessSettingEntry[]) => TripAccessSettingEntry[]) => void;
}

const TripAccessAdvancedSettings: React.FC<Props> = ({
  disabled = false,
  settingsEntries,
  setSettingsEntries,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [isFullViewOpen, setIsFullViewOpen] = React.useState(false);
  const [refs] = useAdvancedSettingsRefs();

  const checkboxState = useMemo(
    () => getTripAccessCheckboxStateFromArray(settingsEntries),
    [settingsEntries],
  );

  const handleChange = useCallback(() => {
    const nextCheckboxState = checkboxState !== CheckboxState.Checked;
    setSettingsEntries((prev) => updateTripAccessChildrenState(prev, nextCheckboxState));
  }, [checkboxState, setSettingsEntries]);

  const handleUpdated = useCallback(
    (name: string, newState: TripAccessSettingEntry) => {
      setSettingsEntries((prev) =>
        [...prev.filter((c) => c.name !== name), newState].sort((a, b) => a.order - b.order),
      );
    },
    [setSettingsEntries],
  );

  const handleExpandedChange = debounce(
    () => {
      setIsExpanded((prev) => !prev);
    },
    500,
    {
      leading: true,
    },
  );

  const handleNavigation = (r: TripAccessAnchorRef) => {
    const parent = settingsEntries.find((c) => c.name === r.parentName);
    if (parent && parent.isCollapsed) {
      handleUpdated(parent.name, {
        ...parent,
        isCollapsed: false,
      });
      setTimeout(() => r.ref.current?.scrollIntoView(), 500); // wait expanding animation to complete
    } else {
      r.ref.current?.scrollIntoView();
    }
  };

  const handleFullViewSave = (newEntries: TripAccessSettingEntry[]) => {
    setSettingsEntries(() => newEntries);
    setIsFullViewOpen(false);
  };

  return (
    <>
      <div className="advanced-settings-container page-embedded" data-testid="trip-access-advanced-settings">
        <div className="advanced-settings-navigation-container">
          <div
            className="advanced-settings-dropdown-icon advanced-settings-dropdown-icon-root advanced-settings-navigation-entry"
            onClick={handleExpandedChange}
          >
            {isExpanded ? <ArrowDown /> : <ArrowRight />}
          </div>
          <Checkbox
            className="advanced-settings-api-response-checkbox advanced-settings-navigation-entry"
            label={i18n('ConsumerTripAccess_ApiResponseLabel')}
            disabled={disabled}
            checked={checkboxState === CheckboxState.Checked}
            indeterminate={checkboxState === CheckboxState.Indeterminate}
            onChange={handleChange}
          />
          {!disabled && (
            <HorizontalScroll>
              <div className="advanced-settings-navigation-item-row">
                {refs.map((r) => (
                  <div
                    className="advanced-settings-navigation-item"
                    key={r.name}
                    onClick={() => handleNavigation(r)}
                  >
                    {r.label}
                  </div>
                ))}
              </div>
            </HorizontalScroll>
          )}
          {!disabled && (
            <Button
              basic
              color="blue"
              className="advanced-settings-full-view-button"
              onClick={() => {
                setIsFullViewOpen(true);
              }}
            >
              <Icon name="expand" />
              {i18n('ConsumerTripAccess_FullViewButtonLabel')}
            </Button>
          )}
        </div>
        <Divider className="advanced-settings-divider" />
        {!isFullViewOpen && (
          <TripAccessAdvancedSettingsEntries
            pageEmbedded
            isExpanded={isExpanded}
            settingsEntries={settingsEntries}
            disabled={disabled}
            onUpdated={handleUpdated}
            refs={refs}
          />
        )}
      </div>
      {isFullViewOpen && (
        <TripAccessAdvancedSettingsFullViewModal
          originalSettingsEntries={settingsEntries}
          onClose={() => {
            setIsFullViewOpen(false);
          }}
          onSave={handleFullViewSave}
        />
      )}
    </>
  );
};

export default TripAccessAdvancedSettings;
