import React from 'react';
import { Grid } from 'semantic-ui-react';
import { i18n } from '../../services/i18n';

const ErrorPage: React.FC = () => (
  <Grid celled="internally">
    <Grid.Row>
      <Grid.Column width={16} textAlign="center">
        <h1>{i18n('ErrorPageMessage')}</h1>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default ErrorPage;
