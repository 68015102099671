import { FormState, UseFormSetValue } from 'react-hook-form';
import { intersection, isEmpty } from 'lodash';

import { Permission } from '../../../enums/Permission';
import { Role, UserRole } from '../../../enums/UserRole';
import { federatedEmailDomains } from './UserFormConstants';
import { UserFormViewModel } from '../../../models/User/UserFormViewModel';

const rolesWithConsumerTypes = [Role.Admin, Role.Consumers, Role.ConsumersRead];

export const getAvailableRoles = (hasPermission: boolean, userRoles: UserRole[]): Role[] =>
  hasPermission
    ? userRoles.map((ur) => ur.role)
    : userRoles
        .filter(
          (r) =>
            !r.permissions.some(
              (p) =>
                p === Permission.UsersUpdate || p === Permission.UsersCreate || p === Permission.UsersRead,
            ),
        )
        .map((ur) => ur.role);

export const isFederatedEmail = (text: string) =>
  federatedEmailDomains.some((ending) => text.endsWith(ending));

export const isRolesWithConsumersTypes = (roles: Role[]): boolean =>
  !isEmpty(intersection(rolesWithConsumerTypes, roles)) || false;

export const handleManageFederatedState = (
  email: string,
  setFederatedFieldsState: (value: React.SetStateAction<boolean>) => void,
  setFederatedValue: UseFormSetValue<UserFormViewModel>,
) => {
  if (isFederatedEmail(email)) {
    setFederatedFieldsState(false);
    setFederatedValue('isFederated', true);
  } else {
    setFederatedFieldsState(true);
    setFederatedValue('isFederated', false);
  }
};

export const isSubmitAvailable = (formState: FormState<UserFormViewModel>): boolean =>
  !formState.isDirty || formState.isSubmitting;

export const filterUserRoles = (roles: Role[], userRoles: UserRole[]): UserRole[] =>
  userRoles.filter((userRole) => roles.includes(userRole.role));

export const filterUserRolesByPermissions = (
  userRoles: UserRole[],
  chosenUserRoles: UserRole[],
): UserRole[] =>
  userRoles.filter(
    (role) => !chosenUserRoles.some((cr) => intersection(cr.permissions, role.permissions).length > 0),
  );
