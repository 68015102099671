import clsx from 'clsx';
import React from 'react';
import RadioGroup from '../RadioGroup/RadioGroup';
import './styles.scss';

export interface RadioOption {
  key: string;
  value: boolean;
  text: string;
  popupMessage?: string;
}

interface Props {
  title: string;
  value?: boolean;
  groupName: string;
  className?: string;
  disabled?: boolean;
  options: RadioOption[];
  onChange: (v: boolean) => void;
  dataTestId?: string;
}

const NamedRadioGroup: React.FC<Props> = ({
  title,
  value,
  groupName,
  className,
  disabled,
  options,
  onChange,
  dataTestId,
}) => {
  return (
    <div className={clsx(className, 'named-radio-group')}>
      <div className="named-radio-group-title">{title}</div>
      <RadioGroup
        value={value}
        groupName={groupName}
        disabled={disabled}
        options={options}
        onChange={onChange}
        dataTestId={dataTestId}
      />
    </div>
  );
};

export default NamedRadioGroup;
