import React, { useState, useMemo } from 'react';
import { Checkbox, List, Dimmer, Loader, Popup, Icon } from 'semantic-ui-react';

import { DataSourceFilterModel } from '../../../../../models/Consumer/DataSourceFilterModel';
import { useDataSourceFilters } from '../../../../../store/hooks/useDataSources';
import { i18n } from '../../../../../services/i18n';
import { getChosenDataSourceFilters } from '../helpers';

import './styles.scss';

interface Props {
  values?: DataSourceFilterModel[];
}

const DEFAULT_PROVIDERS_TO_DISPLAY = 3;

const DataSourceAccessInfo: React.FC<Props> = ({ values = [] }) => {
  const [{ dataSourceFilters, isLoading }] = useDataSourceFilters();
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const chosenDataSourceFilters = useMemo(
    () => getChosenDataSourceFilters(dataSourceFilters, values),
    [dataSourceFilters, values],
  );
  const chosenDataTypes = chosenDataSourceFilters.map((v) => v.dataSource);

  if (isLoading)
    return (
      <Dimmer active inverted data-testid="data-source-info-filters-loader">
        <Loader size="massive" />
      </Dimmer>
    );

  return (
    <List className="data-source-view-list">
      {dataSourceFilters.map((source, index) => {
        const isChoseDataType = chosenDataTypes.includes(source.dataSource);
        const dataProviders =
          chosenDataSourceFilters[chosenDataTypes.indexOf(source.dataSource)]?.dataProviders ?? [];
        const dataProvidersLength = dataProviders.length;
        const visibleDataProviders = visibleItems.includes(source.dataSource)
          ? dataProviders
          : dataProviders.slice(0, DEFAULT_PROVIDERS_TO_DISPLAY);

        return (
          <List.Item key={source.dataSource}>
            <div className="data-source-view-checkbox-container">
              <Checkbox
                disabled
                checked={isChoseDataType}
                className="checkbox-input"
                label={source.dataSource}
                data-testid={`data-source-info-type-${index}-checkbox`}
              />
            </div>
            <List horizontal className="data-source-view-providers-list">
              {isChoseDataType && dataProvidersLength === 0 && (
                <List.Item data-testid={`data-source-info-type-${index}-providers-not-set`}>
                  {i18n('DataSource_NotSet_Text')}
                  <Popup
                    offset={[-11]}
                    trigger={
                      <Icon
                        name="info circle"
                        color="grey"
                        className="data-source-view-provider-not-set-popup"
                      />
                    }
                    content={i18n('DataSource_NotSet_Text_Details')}
                  />
                </List.Item>
              )}

              {visibleDataProviders.map((provider, providerIndex) => (
                <List.Item
                  key={provider}
                  data-testid={`data-source-info-type-${index}-provider-${providerIndex}`}
                >
                  {provider}
                </List.Item>
              ))}

              {dataProvidersLength > DEFAULT_PROVIDERS_TO_DISPLAY &&
                !visibleItems.includes(source.dataSource) && (
                  <List.Item
                    className="data-source-more-control"
                    onClick={() => setVisibleItems([...visibleItems, source.dataSource])}
                    data-testid={`data-source-info-type-${index}-more-providers-control`}
                  >
                    {`...+${dataProvidersLength - DEFAULT_PROVIDERS_TO_DISPLAY} ${i18n(
                      'DataSource_More_Control',
                    )}`}
                  </List.Item>
                )}
            </List>
          </List.Item>
        );
      })}
    </List>
  );
};

export default DataSourceAccessInfo;
