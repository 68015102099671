import React from 'react';

import { TripScopesModel } from '../../../../models/Consumer/Scopes/TripScopesModel';
import { sortTripAccessPreset } from './helpers';
import { TripAccessPresetEntryType } from '../../../../models/TripAccess/TripAccessPresetEntry';

interface Props {
  model: TripScopesModel;
  indent?: number;
  className?: string;
}

const TripAccessPresetVisualization: React.FC<Props> = ({ model, indent = 0, className }) => {
  const entries = sortTripAccessPreset(Object.entries(model) as TripAccessPresetEntryType[]);

  return (
    <div className={className}>
      {entries.map(([key, value]) => (
        <>
          {typeof value === 'boolean' && (
            <div key={key} style={{ marginLeft: `${indent * 8}px` }}>{`${key}: "${value.toString()}"`}</div>
          )}
          {typeof value === 'object' && (
            <React.Fragment key={key}>
              <div className="preset-object-label" style={{ marginLeft: `${indent * 8}px` }}>
                {key}
              </div>
              <TripAccessPresetVisualization model={value as TripScopesModel} indent={indent + 1} />
            </React.Fragment>
          )}
        </>
      ))}
    </div>
  );
};

export default TripAccessPresetVisualization;
