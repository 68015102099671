import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { HighlightModel } from '../../models/Consumer/Account/HighlightModel';
import { HighlightWithinTextarea } from 'react-highlight-within-textarea';
import './styles.scss';

interface Props {
  highlights: HighlightModel[];
  error?: FieldError;
  'data-testid'?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChangeValue: (text: string) => void;
}

const HighlightsInTextArea: React.FC<Props> = ({
  highlights,
  error,
  'data-testid': dataTestId,
  onBlur,
  onChangeValue,
}) => {
  const [className, setClassName] = useState('');
  const [value, setValue] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const focus = () => textAreaRef.current?.focus();

  const handleFocus = () => setClassName('textarea-focused');

  const handleTextAreaBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setClassName('');
    onBlur?.(event);
  };

  const handleTextAreaChange = (currentValue: string) => {
    if (currentValue !== value) {
      setValue(currentValue);
      onChangeValue(currentValue);
    }
  };

  const textAreaClassName = error ? clsx('textarea-error', 'textarea') : clsx(className, 'textarea');

  useEffect(focus, []);

  return (
    <>
      <div className={textAreaClassName} onClick={focus} data-testid={dataTestId}>
        <HighlightWithinTextarea
          placeholder=""
          value={value}
          ref={textAreaRef}
          highlight={highlights}
          onFocus={handleFocus}
          onBlur={handleTextAreaBlur}
          onChange={handleTextAreaChange}
        />
      </div>
      {error && <p className="error-message textarea-error-msg">{error.message}</p>}
    </>
  );
};

export default HighlightsInTextArea;
