/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { fetchConsumerTypesAction } from '../actions/consumerTypesActions';
import { ConsumerTypeModel } from '../../models/Consumer/ConsumerTypeModel';
import { resetConsumerTypes } from '../reducers/consumerTypesReducer';

export const useConsumerTypes: () => [
  {
    consumerTypes: ConsumerTypeModel[];
    isLoadingConsumerTypes: boolean;
  },
] = () => {
  const dispatch = useAppDispatch();
  const consumerTypes = useAppSelector((s) => s.consumerTypes);

  useEffect(() => {
    if (consumerTypes.items.length === 0) {
      dispatch(fetchConsumerTypesAction());
    }
  }, [dispatch, consumerTypes.items.length]);

  useEffect(() => {
    dispatch(resetConsumerTypes());
  }, [dispatch]);

  return [{ consumerTypes: consumerTypes.items, isLoadingConsumerTypes: consumerTypes.isLoading }];
};
