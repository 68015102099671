import { i18n } from '../../../services/i18n';
import React, { FC } from 'react';
import { Popup } from 'semantic-ui-react';
import Nav from '../Nav/Nav';
import './styles.scss';
import { usersPath, consumersPath, eventsPath, searchTripsPath } from '../../../paths';
import CanUser from '../../Permissions/CanUser';
import { Permission } from '../../../enums/Permission';

interface Props {
  username: string;
  onLogout: () => void;
}

export const HeaderActionsDesktop: FC<Props> = ({ username, onLogout }) => {
  const [open, setOpen] = React.useState(false);

  const handleLogout = () => {
    setOpen(false);
    onLogout();
  };

  return (
    <div className="actions-container-desktop">
      <CanUser permission={Permission.ConsumersRead}>
        <Nav data-testid="header-desktop-consumers-link" name={i18n('Consumers_Link')} to={consumersPath} />
      </CanUser>

      <CanUser permission={Permission.UsersRead}>
        <Nav data-testid="header-desktop-users-link" name={i18n('Users_Link')} to={usersPath} />
      </CanUser>
      <CanUser permission={Permission.EventsRead}>
        <Nav data-testid="header-desktop-events-link" name={i18n('Events_Link')} to={eventsPath} exact />
      </CanUser>
      <CanUser permission={Permission.SearchTripsRead}>
        <Nav
          data-testid="header-desktop-search-trips-link"
          name={i18n('SearchTrips_Link')}
          to={searchTripsPath}
          exact
        />
      </CanUser>
      <Nav data-testid="header-desktop-faq-link" name={i18n('Faq_Link')} to="/" exact />
      <div className="user-logo-container">
        <span className="user-logo" />
        <Popup
          trigger={
            <p className="username" data-testid="header-desktop-username">
              {username}
            </p>
          }
          on="click"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          className="dropdown-popup"
          content={
            <div
              className="dropdown-popup-content"
              onClick={handleLogout}
              data-testid="header-desktop-logout"
            >
              {i18n('Header_Logout')}
            </div>
          }
          basic
        />
      </div>
    </div>
  );
};
