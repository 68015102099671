import { createSlice } from '@reduxjs/toolkit';
import { PageModel } from '../../models/Store/PageModel{T}';
import { consumerTypesPrefix } from '../actions/actionTypes';
import { fetchConsumerTypesAction } from '../actions/consumerTypesActions';
import { ConsumerTypeModel } from './../../models/Consumer/ConsumerTypeModel';

const initialState: PageModel<ConsumerTypeModel[]> = {
  items: [] as ConsumerTypeModel[],
  isLoading: false,
};

const consumerTypesSlice = createSlice({
  name: consumerTypesPrefix,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsumerTypesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchConsumerTypesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(fetchConsumerTypesAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default consumerTypesSlice.reducer;
export const resetConsumerTypes = consumerTypesSlice.actions.reset;
