export enum Permission {
  UsersRead = 'permissions:users:read',
  UsersCreate = 'permissions:users:create',
  UsersUpdate = 'permissions:users:update',
  UsersCanAssignUserPermissions = 'permissions:users:canAssignUserPermissions',
  ConsumersRead = 'permissions:consumers:read',
  ConsumersUpdate = 'permissions:consumers:update',
  EventsRead = 'permissions:events:read',
  SearchTripsRead = 'permissions:search-trips:read',
}
