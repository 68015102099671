import React, { useState } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { groupBy, chunk } from 'lodash';

import { i18n } from '../../../services/i18n';
import { useTrips } from '../../../store/hooks/useTrips/useTrips';
import NoDataRow from '../../Table/NoDataRow/NoDataRow';
import { SendEventModal } from '../EventsModal/SendEventModal';
import { AirSegment, TripModel } from '../../../models/Trip/TripModel';

import './styles.scss';
import OverflowTip from '../../../shared/OverflowTip/OverflowTip';

const formatSegment = (segment: AirSegment): string => {
  const departureAirportCode = segment.departureAirport.airport.code;
  const departureCountryCode = segment.departureAirport.airport.address.country.code;
  const arrivalAirportCode = segment.arrivalAirport.airport.code;
  const arrivalCountryCode = segment.arrivalAirport.airport.address.country.code;
  return `${departureAirportCode}/${departureCountryCode}-${arrivalAirportCode}/${arrivalCountryCode}`;
};

const formatGroup = (direction: string, group: AirSegment[]): string => {
  const chunks = chunk(group, 2);
  const directionText = i18n(`TripsTable_Direction_${direction}`);
  const paddedDirection = directionText.padEnd(9);
  return chunks.map((chunk) => `${paddedDirection}\t${chunk.map(formatSegment).join(' | ')}`).join('\n');
};

const getEventRouting = (tripModel: TripModel): string => {
  if (!tripModel?.tripDTO?.segments) return '';

  const segments: AirSegment[] = tripModel.tripDTO.segments.filter((s) => s.type === 'Air') as AirSegment[];
  const groups = groupBy(segments, 'flightDirection');

  return Object.entries(groups)
    .map(([direction, group]) => formatGroup(direction, group))
    .join('\n');
};

const EventsTripsTable: React.FC = () => {
  const [{ trips }] = useTrips();
  const [sendingTripId, setSendingTripId] = useState<string | undefined>(undefined);

  return (
    <>
      <Table data-testid="events-trips-table" className="events-trips-table-container data-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{i18n('TripsTable_CustomerName')}</Table.HeaderCell>
            <Table.HeaderCell>{i18n('TripsTable_GCN')}</Table.HeaderCell>
            <Table.HeaderCell>{i18n('TripsTable_SMID')}</Table.HeaderCell>
            <Table.HeaderCell>
              {i18n('TripsTable_GDS')} / {i18n('TripsTable_CreationOffice')}
            </Table.HeaderCell>
            <Table.HeaderCell>{i18n('TripsTable_TripId')}</Table.HeaderCell>
            <Table.HeaderCell>{i18n('TripsTable_SourceId')}</Table.HeaderCell>
            <Table.HeaderCell>{i18n('TripsTable_RecordLocator')}</Table.HeaderCell>
            <Table.HeaderCell>
              {i18n('TripsTable_Traveler')} / {i18n('TripsTable_Email')}
            </Table.HeaderCell>
            <Table.HeaderCell>{i18n('TripsTable_Routing')}</Table.HeaderCell>
            <Table.HeaderCell>{i18n('TripsTable_StartEndDate')}</Table.HeaderCell>
            {trips.length !== 0 && (
              <Table.HeaderCell
                className="trips-table-send-control-header-cell"
                data-testid="events-trips-table-send-control-header-cell"
              />
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {trips.map((item) => (
            <Table.Row key={item.id} data-testid="events-trips-table-row">
              <Table.Cell data-testid="trips-table-account-name">{item.customerName}</Table.Cell>
              <Table.Cell data-testid="trips-table-gcn">{item.globalCustomerNumber}</Table.Cell>
              <Table.Cell data-testid="trips-table-smid">{item.accountId}</Table.Cell>
              <Table.Cell data-testid="trips-table-trip-gds-creationOffice">
                {item.gds && <span className="no-word-break">{item.gds}</span>}
                {item.gds && item.creationOfficeId && ' / '}
                {item.creationOfficeId && <span className="no-word-break">{item.creationOfficeId}</span>}
              </Table.Cell>
              <Table.Cell data-testid="trips-table-trip-id">
                <span className="word-break">{item.id}</span>
              </Table.Cell>
              <Table.Cell data-testid="trips-table-source-id">
                <span className="word-break">{item.sourceId}</span>
              </Table.Cell>
              <Table.Cell data-testid="trips-table-record-locator">{item.recordLocator}</Table.Cell>
              <Table.Cell data-testid="trips-table-first-last-name" className="travelers-email-content">
                {item.travelers}
                <OverflowTip text={item.email} data-testid="trips-table-email" className="email-content" />
              </Table.Cell>
              <Table.Cell data-testid="trips-table-routing">
                <span className="word-break" style={{ whiteSpace: 'pre-wrap' }}>
                  {getEventRouting(item)}
                </span>
              </Table.Cell>
              <Table.Cell data-testid="trips-table-start-end-date">
                <span className="no-word-break dates-content">
                  {item.tripStartDate}&nbsp;/&nbsp;{item.tripEndDate}
                </span>
              </Table.Cell>
              <Table.Cell className="trips-table-send-control-cell">
                <Button
                  onClick={() => {
                    setSendingTripId(item.id);
                  }}
                  color="blue"
                  size="small"
                  data-testid="events-trips-table-send-control"
                >
                  {i18n('TripsTable_Send_Control')}
                  <Icon name="paper plane" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}

          {trips.length === 0 && <NoDataRow colSpan={10} text={i18n('Table_No_Result')} />}
        </Table.Body>
      </Table>

      <SendEventModal sendingTripId={sendingTripId} onModalClose={() => setSendingTripId(undefined)} />
    </>
  );
};

export default EventsTripsTable;
