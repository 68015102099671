import { useEffect } from 'react';
import { AccountLookupModel } from '../../models/Consumer/Account/AccountLookupModel';
import { fetchAccountsLookupAction } from '../actions/fetchAccountsLookupAction';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { AccountLookupService } from '../../services/AccountLookupService';
import { AccountLookupSearch } from '../../models/Consumer/Account/AccountLookupSearch';

export const useAccountsLookup: () => [
  {
    allAccountsLookup: AccountLookupModel[];
    isLoadingAccountsLookup: boolean;
  },
  {
    searchAccountsLookup: (s: AccountLookupSearch, a: AccountLookupModel[]) => AccountLookupModel[];
  },
] = () => {
  const dispatch = useAppDispatch();
  const accountsLookup = useAppSelector((s) => s.accountsLookup);

  useEffect(() => {
    if (accountsLookup.items.length === 0) {
      void dispatch(fetchAccountsLookupAction());
    }
  }, [dispatch, accountsLookup.items.length]);

  const searchAccountsLookup = (
    search: AccountLookupSearch,
    accountsLookup: AccountLookupModel[],
  ): AccountLookupModel[] => {
    return AccountLookupService.searchAccountsLookup(accountsLookup, search);
  };

  return [
    {
      allAccountsLookup: accountsLookup.items,
      isLoadingAccountsLookup: accountsLookup.isLoading,
    },
    {
      searchAccountsLookup,
    },
  ];
};
