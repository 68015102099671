import React from 'react';
import { i18n } from '../../../services/i18n';
import ModalBase from '../ModalBase/ModalBase';

interface Props {
  isOpen: boolean;
  isEdit?: boolean;
  isAdding?: boolean;
  onClose: (isLeft?: boolean) => void;
}

const ModalLeave: React.FC<Props> = ({ isOpen, isEdit, isAdding, onClose }) => {
  const getHeaderForAddOrCreate = () =>
    isAdding ? i18n('Modals_Leave_Add_Title') : i18n('Modals_Leave_Create_Title');

  const getCancelButtonTextForAddOrCreate = () =>
    isAdding ? i18n('Modals_Leave_Add_ContinueButton') : i18n('Modals_Leave_Create_ContinueButton');

  return (
    <ModalBase
      isOpen={isOpen}
      header={isEdit ? i18n('Modals_Leave_Edit_Title') : getHeaderForAddOrCreate()}
      content={i18n('Modals_Leave_Content')}
      cancelButtonText={
        isEdit ? i18n('Modals_Leave_Edit_ContinueButton') : getCancelButtonTextForAddOrCreate()
      }
      okButtonText={i18n('Modals_Leave_LeaveButton')}
      onClose={onClose}
    />
  );
};

export default ModalLeave;
