import { ApiError } from './../../models/Api/ApiError';
import { toast } from 'react-toastify';
import React, { useCallback, useEffect } from 'react';
import { i18n } from '../../services/i18n';
import ToastScrollableMessage from '../../features/ToastScrollableMessage/ToastScrollableMessage';
import ToastMessage from '../../features/ToastMessage/ToastMessage';
import { ApiErrorCodes } from '../../enums/ApiCodes';

export const useErrorToast = (error?: ApiError, handleError?: () => void): void => {
  const showErrorToast = useCallback((e: ApiError) => {
    if (e?.response?.status === ApiErrorCodes.BadRequest) {
      toast.error(
        <ToastScrollableMessage>
          <div>{i18n('Messages_BadRequestMessage')}</div>
          <>
            {e.response.data.messages.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </>
        </ToastScrollableMessage>,
      );
    } else {
      toast.error(<ToastMessage text={i18n('Messages_ErrorMessage')} />);
    }
  }, []);

  useEffect(() => {
    if (error) {
      showErrorToast(error);
      handleError && handleError();
    }
  }, [error, handleError, showErrorToast]);
};
