import { cloneDeep } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Button, Divider, Icon, Modal } from 'semantic-ui-react';
import { TripAccessAnchorRef } from '../../../../../../models/TripAccess/TripAccessAnchorRef';
import { TripAccessSettingEntry } from '../../../../../../models/TripAccess/TripAccessSettingEntry';
import { i18n } from '../../../../../../services/i18n';
import HorizontalScroll from '../../../../../HorizontalScroll/HorizontalScroll';
import TripAccessAdvancedSettingsEntries from '../TripAccessAdvancedSettingsEntries/TripAccessAdvancedSettingsEntries';
import { useAdvancedSettingsRefs } from '../useAdvancedSettingsRefs';
import './styles.scss';

interface Props {
  onClose: () => void;
  onSave: (settings: TripAccessSettingEntry[]) => void;
  originalSettingsEntries: TripAccessSettingEntry[];
}

const TripAccessAdvancedSettingsFullViewModal: React.FC<Props> = ({
  originalSettingsEntries,
  onClose,
  onSave,
}) => {
  const [settingsEntries, setSettingsEntries] = React.useState(cloneDeep(originalSettingsEntries));
  const [refs] = useAdvancedSettingsRefs();
  const [isChanged, setIsChanged] = useState(false);

  const handleNavigation = (r: TripAccessAnchorRef) => {
    const parent = settingsEntries.find((c) => c.name === r.parentName);
    if (parent && parent.isCollapsed) {
      handleUpdated(parent.name, {
        ...parent,
        isCollapsed: false,
      });
      setTimeout(() => r.ref.current?.scrollIntoView(), 500); // wait expanding animation to complete
    } else {
      r.ref.current?.scrollIntoView();
    }
  };

  const handleUpdated = useCallback(
    (name: string, newState: TripAccessSettingEntry) => {
      setSettingsEntries((prev) =>
        [...prev.filter((c) => c.name !== name), newState].sort((a, b) => a.order - b.order),
      );
      setIsChanged(true);
    },
    [setSettingsEntries],
  );

  return (
    <Modal
      open
      closeOnEscape
      size="small"
      data-testid="full-view-modal"
      className="advanced-settings-full-view-container"
    >
      <div className="advanced-settings-full-view-container-title advanced-settings-navigation-container">
        <h3>{i18n('ConsumerTripAccess_ApiResponseLabel')}</h3>
        <HorizontalScroll>
          <div className="advanced-settings-navigation-item-row">
            {refs.map((r) => (
              <div
                className="advanced-settings-navigation-item"
                key={r.name}
                onClick={() => handleNavigation(r)}
              >
                {r.label}
              </div>
            ))}
          </div>
        </HorizontalScroll>
        <Icon link name="close" width="thin" onClick={() => onClose()} />
      </div>
      <Divider className="advanced-settings-divider" />
      <div className="advanced-settings-container">
        <TripAccessAdvancedSettingsEntries
          isExpanded
          settingsEntries={settingsEntries}
          onUpdated={handleUpdated}
          refs={refs}
        />
      </div>
      <Divider className="advanced-settings-footer-divider" />
      <div className="advanced-settings-full-view-container-footer">
        <Button basic color="blue" onClick={() => onClose()}>
          {i18n('UserForm_CancelButtonTitle')}
        </Button>
        <Button disabled={!isChanged} primary onClick={() => onSave(settingsEntries)}>
          {i18n('UserForm_SaveButtonTitle')}
        </Button>
      </div>
    </Modal>
  );
};

export default TripAccessAdvancedSettingsFullViewModal;
