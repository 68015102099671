import React from 'react';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { toast } from 'react-toastify';

import ToastMessage from '../../ToastMessage/ToastMessage';

import './styles.scss';
import { i18n } from '../../../services/i18n';

interface CodeContentProps {
  data: string;
}

const CodeContent: React.FC<CodeContentProps> = ({ data }) => {
  const autoCloseDelay = 3000;

  const handleCopyData = () => {
    void navigator.clipboard.writeText(data);
    toast.success(<ToastMessage text={i18n('SearchTrips_CodeContent_DataCopiedMessage')} />, {
      autoClose: autoCloseDelay,
    });
  };

  return (
    <Panel className="code-content-wrapper" unstyled>
      <Button icon="pi pi-copy" onClick={() => handleCopyData()} className="code-content-controls" />
      <pre>{data}</pre>
    </Panel>
  );
};

export default CodeContent;
