import { createSlice } from '@reduxjs/toolkit';
import { PageModel } from '../../models/Store/PageModel{T}';
import { ApiError } from './../../models/Api/ApiError';
import { i18n } from '../../services/i18n';
import {
  createConsumerAction,
  fetchConsumersAction,
  updateConsumerAction,
} from '../actions/consumersActions';
import { consumersPrefix } from '../actions/actionTypes';
import { ConsumerMetadataModel } from '../../models/Consumer/ConsumerMetadataModel';

const initialState: PageModel<ConsumerMetadataModel[]> = {
  items: [] as ConsumerMetadataModel[],
  isLoading: false,
};

const consumersSlice = createSlice({
  name: consumersPrefix,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = undefined;
    },
    resetConsumers: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsumersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchConsumersAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(fetchConsumersAction.rejected, (state, action) => {
        state.items = [];
        state.isLoading = false;
        state.error = action.payload as ApiError;
      })
      .addCase(createConsumerAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createConsumerAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = undefined;
        state.successMessage = i18n('ConsumersPage_ConsumerCreatedMessage');
      })
      .addCase(createConsumerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as ApiError;
      })
      .addCase(updateConsumerAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateConsumerAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = undefined;
        state.successMessage = i18n('ConsumerInfoPage_ConsumerEditedMessage');
      })
      .addCase(updateConsumerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as ApiError;
      });
  },
});

export default consumersSlice.reducer;
export const { resetConsumers } = consumersSlice.actions;
