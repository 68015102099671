import { useRef, RefObject, useCallback } from 'react';
import { Input } from 'semantic-ui-react';

export const useAutoFocus = (): [
  { inputFocusRef: RefObject<Input> },
  {
    focus: (condition?: boolean) => void;
  },
] => {
  const inputFocusRef = useRef<Input>(null);

  const focus = useCallback((condition?: boolean) => {
    condition && inputFocusRef.current && inputFocusRef.current.focus();
  }, []);

  return [
    {
      inputFocusRef,
    },
    { focus },
  ];
};
