import { useState } from 'react';
import { ConsumerFormViewModel } from '../../models/Consumer/ConsumerFormViewModel';
import { ConsumerTypeModel } from '../../models/Consumer/ConsumerTypeModel';

interface Props {
  consumerTypes: ConsumerTypeModel[];
  handleStateChange: (
    value: ConsumerFormViewModel[keyof ConsumerFormViewModel],
    fieldName: keyof ConsumerFormViewModel,
  ) => Promise<void>;
}

export const useConsumerTypesValidationUtils = ({
  consumerTypes,
  handleStateChange,
}: Props): [
  {
    isUserConsumerTypesDeleted: boolean;
  },
  {
    handleConsumerTypesStateChange: (value: string[]) => void;
  },
] => {
  const [isUserConsumerTypesDeleted, setIsUserConsumerTypesDeleted] = useState(false);

  const handleConsumerTypesStateChange = (value: string[]) => {
    if (
      value.filter(
        (rct) => consumerTypes.filter((uct) => uct.name === rct && uct.isAllowedForCurrentUser).length > 0,
      ).length === 0
    ) {
      setIsUserConsumerTypesDeleted(true);
    } else {
      setIsUserConsumerTypesDeleted(false);
    }

    void handleStateChange(value, 'consumerTypes');
  };

  return [
    {
      isUserConsumerTypesDeleted,
    },
    {
      handleConsumerTypesStateChange,
    },
  ];
};
