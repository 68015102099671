import { createSlice } from '@reduxjs/toolkit';
import { PageModel } from '../../models/Store/PageModel{T}';
import { tripAccessScopesPresetsPrefix } from '../actions/actionTypes';
import { fetchTripAccessScopesPresetsAction } from '../actions/tripAccessScopesPresetsActions';
import { ScopesPresetModel } from '../../models/Consumer/Scopes/ScopesPresetModel';

const initialState: PageModel<ScopesPresetModel[]> = {
  items: [] as ScopesPresetModel[],
  isLoading: false,
};

const tripAccessScopesPresetsSlice = createSlice({
  name: tripAccessScopesPresetsPrefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTripAccessScopesPresetsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTripAccessScopesPresetsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(fetchTripAccessScopesPresetsAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default tripAccessScopesPresetsSlice.reducer;
