import React from 'react';
import { Element } from 'react-scroll';
import { Grid, Segment } from 'semantic-ui-react';
import { i18n } from '../../services/i18n';
import CustomLink from './NavScrollLink';
import QuestionItem from './QuestionItem';
import './styles.scss';

const HomePage: React.FC = () => {
  return (
    <>
      <div className="page-title">
        <h2>{i18n('Faq_Link')}</h2>
      </div>
      <div className="page-body-container faq-container">
        <Segment className="page-form-container">
          <div className="page-form">
            <h3 className="faq-title" data-testid="faq-title">
              {i18n('Faq_Title')}
            </h3>
            <div className="page-form-body">
              <Grid divided>
                <Grid.Row padded="vertically">
                  <Grid.Column className="faq-side-bar faq-column" mobile={16} tablet={5} computer={5}>
                    <div className="sidebar">
                      <div className="sidebar-item">
                        <CustomLink to="general-questions">
                          <div className="sidebar-item-header">{i18n('Faq_GeneralQuestions')}</div>
                        </CustomLink>
                        <div className="sidebar-item-list">
                          <CustomLink to="what-is-admin-ui">
                            <div className="li">{i18n('Faq_GeneralQuestions_WhatIsAdminUi')}</div>
                          </CustomLink>
                        </div>
                      </div>
                      <div className="sidebar-item">
                        <CustomLink to="getting-started">
                          <div className="sidebar-item-header">{i18n('Faq_GettingStarted')}</div>
                        </CustomLink>
                        <div className="sidebar-item-list">
                          <CustomLink to="create-user">
                            <div className="li">{i18n('Faq_GettingStarted_CreateUser')}</div>
                          </CustomLink>
                          <CustomLink to="set-up-role">
                            <div className="li">{i18n('Faq_GettingStarted_SetUp_Role')}</div>
                          </CustomLink>
                          <CustomLink to="deactivate-user">
                            <div className="li">{i18n('Faq_GettingStarted_DeactivateUser')}</div>
                          </CustomLink>
                          <CustomLink to="log-in">
                            <div className="li">{i18n('Faq_GettingStarted_LogIn')}</div>
                          </CustomLink>
                          <CustomLink to="bcd-sso">
                            <div className="li">{i18n('Faq_GettingStarted_BcdSso')}</div>
                          </CustomLink>
                        </div>
                      </div>
                      <div className="sidebar-item">
                        <CustomLink to="consumer-configuration">
                          <div className="sidebar-item-header">{i18n('Faq_ConsumerConfiguration')}</div>
                        </CustomLink>
                        <div className="sidebar-item-list">
                          <CustomLink to="create-consumer">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_HowCreateConsumer')}</div>
                          </CustomLink>
                          <CustomLink to="disable-consumer">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_HowDisableConsumer')}</div>
                          </CustomLink>
                          <CustomLink to="add-accounts">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_HowAddAccounts')}</div>
                          </CustomLink>
                          <CustomLink to="disable-access">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_HowDisableAccess')}</div>
                          </CustomLink>
                          <CustomLink to="accounts-logic">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_WhatIsAccountsLogic')}</div>
                          </CustomLink>
                          <CustomLink to="add-customer-id">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_HowAddCustomerId')}</div>
                          </CustomLink>
                          <CustomLink to="what-is-customer-id">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_WhatIsCustomerId')}</div>
                          </CustomLink>
                          <CustomLink to="enable-access">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_HowEnableAccess')}</div>
                          </CustomLink>
                          <CustomLink to="configure-access">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_HowConfigureAccess')}</div>
                          </CustomLink>
                          <CustomLink to="schema-presets">
                            <div className="li">{i18n('Faq_ConsumerConfiguration_WhatAreSchemaPresets')}</div>
                          </CustomLink>
                          <CustomLink to="use-advanced-settings">
                            <div className="li">
                              {i18n('Faq_ConsumerConfiguration_HowUseAdvancedSettings')}
                            </div>
                          </CustomLink>
                          <CustomLink to="not-available-byPII-data">
                            <div className="li">
                              {i18n('Faq_ConsumerConfiguration_NotAvailableOnDisabledPII')}
                            </div>
                          </CustomLink>
                        </div>
                      </div>
                      <div className="sidebar-item">
                        <CustomLink to="search">
                          <div className="sidebar-item-header">{i18n('Faq_Search')}</div>
                        </CustomLink>
                        <div className="sidebar-item-list">
                          <CustomLink to="use-search">
                            <div className="li">{i18n('Faq_Search_WhatIsSearch')}</div>
                          </CustomLink>
                          <CustomLink to="search-parameters">
                            <div className="li">{i18n('Faq_Search_WhatParametersCanUtilize')}</div>
                          </CustomLink>
                        </div>
                      </div>
                      <div className="sidebar-item">
                        <CustomLink to="events">
                          <div className="sidebar-item-header">{i18n('Faq_Events')}</div>
                        </CustomLink>
                        <div className="sidebar-item-list">
                          <CustomLink to="use-events">
                            <div className="li">{i18n('Faq_Events_WhatIsEvents')}</div>
                          </CustomLink>
                          <CustomLink to="events-parameters">
                            <div className="li">{i18n('Faq_Events_WhatParametersCanUtilize')}</div>
                          </CustomLink>
                        </div>
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="faq-content " mobile={16} tablet={11} computer={11}>
                    <Element name="containerElement" className="element faq-column" id="containerElement">
                      <Element name="general-questions" className="faq-content-section">
                        <Element name="what-is-admin-ui">
                          <h3 data-testid="faq-content-general-questions">{i18n('Faq_GeneralQuestions')}</h3>
                          <div className="faq-content-section-item">
                            <h4>{i18n('Faq_GeneralQuestions_WhatIsAdminUi')}</h4>
                            <div>{i18n('Faq_GeneralQuestions_WhatIsAdminUi_Content')}</div>
                          </div>
                        </Element>
                      </Element>
                      <Element name="getting-started" className="faq-content-section">
                        <Element name="create-user">
                          <h3 data-testid="faq-content-getting-started">{i18n('Faq_GettingStarted')}</h3>
                          <QuestionItem
                            name={i18n('Faq_GettingStarted_CreateUser')}
                            description={i18n('Faq_GettingStarted_CreateUser_Description')}
                            list={[
                              i18n('Faq_GettingStarted_CreateUser_First'),
                              i18n('Faq_GettingStarted_CreateUser_Second'),
                              i18n('Faq_GettingStarted_CreateUser_Third'),
                              i18n('Faq_GettingStarted_CreateUser_Fourth'),
                            ]}
                            message={i18n('Faq_GettingStarted_CreateUser_Message')}
                          />
                        </Element>

                        <Element name="set-up-role">
                          <QuestionItem
                            name={i18n('Faq_GettingStarted_SetUp_Role')}
                            description={i18n('Faq_GettingStarted_SetUp_Role_Description')}
                            list={[
                              i18n('Faq_GettingStarted_SetUp_Role_First'),
                              i18n('Faq_GettingStarted_SetUp_Role_Second'),
                              i18n('Faq_GettingStarted_SetUp_Role_Third'),
                              i18n('Faq_GettingStarted_SetUp_Role_Fourth'),
                            ]}
                            message={i18n('Faq_GettingStarted_SetUp_Role_Message')}
                          />
                        </Element>

                        <Element name="deactivate-user">
                          <QuestionItem
                            name={i18n('Faq_GettingStarted_DeactivateUser')}
                            description={i18n('Faq_GettingStarted_DeactivateUser_Description')}
                            list={[
                              i18n('Faq_GettingStarted_DeactivateUser_First'),
                              i18n('Faq_GettingStarted_DeactivateUser_Second'),
                              i18n('Faq_GettingStarted_DeactivateUser_Third'),
                              i18n('Faq_GettingStarted_DeactivateUser_Fourth'),
                            ]}
                          />
                        </Element>

                        <Element name="log-in">
                          <QuestionItem
                            name={i18n('Faq_GettingStarted_LogIn')}
                            description={i18n('Faq_GettingStarted_LogIn_Description')}
                            list={[
                              i18n('Faq_GettingStarted_LogIn_First'),
                              i18n('Faq_GettingStarted_LogIn_Second'),
                              i18n('Faq_GettingStarted_LogIn_Third'),
                            ]}
                            message={i18n('Faq_GettingStarted_LogIn_Message')}
                          />
                        </Element>

                        <Element name="bcd-sso">
                          <QuestionItem
                            name={i18n('Faq_GettingStarted_BcdSso')}
                            description={i18n('Faq_GettingStarted_BcdSso_Description')}
                          />
                        </Element>
                      </Element>
                      <Element name="consumer-configuration" className="faq-content-section">
                        <Element name="create-consumer">
                          <h3 data-testid="faq-content-getting-started">
                            {i18n('Faq_ConsumerConfiguration')}
                          </h3>
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_HowCreateConsumer')}
                            description={i18n('Faq_ConsumerConfiguration_HowCreateConsumer_Description')}
                            list={[
                              i18n('Faq_ConsumerConfiguration_HowCreateConsumer_First'),
                              i18n('Faq_ConsumerConfiguration_HowCreateConsumer_Second'),
                              i18n('Faq_ConsumerConfiguration_HowCreateConsumer_Third'),
                              i18n('Faq_ConsumerConfiguration_HowCreateConsumer_Fourth'),
                            ]}
                            message={i18n('Faq_ConsumerConfiguration_HowCreateConsumer_Message')}
                          />
                        </Element>

                        <Element name="disable-consumer">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_HowDisableConsumer')}
                            description={i18n('Faq_ConsumerConfiguration_HowDisableConsumer_Description')}
                            list={[
                              i18n('Faq_ConsumerConfiguration_HowDisableConsumer_First'),
                              i18n('Faq_ConsumerConfiguration_HowDisableConsumer_Second'),
                              i18n('Faq_ConsumerConfiguration_HowDisableConsumer_Third'),
                              i18n('Faq_ConsumerConfiguration_HowDisableConsumer_Fourth'),
                              i18n('Faq_ConsumerConfiguration_HowDisableConsumer_Fifth'),
                            ]}
                          />
                        </Element>

                        <Element name="add-accounts">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_HowAddAccounts')}
                            description={i18n('Faq_ConsumerConfiguration_HowAddAccounts_Description')}
                            list={[
                              i18n('Faq_ConsumerConfiguration_HowAddAccounts_First'),
                              i18n('Faq_ConsumerConfiguration_HowAddAccounts_Second'),
                              i18n('Faq_ConsumerConfiguration_HowAddAccounts_Third'),
                              i18n('Faq_ConsumerConfiguration_HowAddAccounts_Fourth'),
                              i18n('Faq_ConsumerConfiguration_HowAddAccounts_Fifth'),
                              i18n('Faq_ConsumerConfiguration_HowAddAccounts_Sixth'),
                              i18n('Faq_ConsumerConfiguration_HowAddAccounts_Seventh'),
                            ]}
                            message={
                              <span>
                                {i18n('Faq_ConsumerConfiguration_HowAddAccounts_Message')}
                                <CustomLink to="accounts-logic">
                                  <b>{i18n('Faq_ConsumerConfiguration_HowAddAccounts_MessageBold')}</b>
                                </CustomLink>
                              </span>
                            }
                          />
                        </Element>

                        <Element name="disable-access">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_HowDisableAccess')}
                            description={i18n('Faq_ConsumerConfiguration_HowDisableAccess_Description')}
                            list={[
                              i18n('Faq_ConsumerConfiguration_HowDisableAccess_First'),
                              i18n('Faq_ConsumerConfiguration_HowDisableAccess_Second'),
                              i18n('Faq_ConsumerConfiguration_HowDisableAccess_Third'),
                              i18n('Faq_ConsumerConfiguration_HowDisableAccess_Fourth'),
                            ]}
                          />
                        </Element>

                        <Element name="accounts-logic">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_WhatIsAccountsLogic')}
                            description={i18n('Faq_ConsumerConfiguration_WhatIsAccountsLogic_Description')}
                            message={i18n('Faq_ConsumerConfiguration_WhatIsAccountsLogic_Message')}
                            table={{
                              headers: [
                                i18n('Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnStatus'),
                                i18n('Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnDescription'),
                              ],
                              rows: [
                                [
                                  '1',
                                  i18n(
                                    'Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnStatus_Inclusive',
                                  ),
                                  i18n(
                                    'Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnDescription_Inclusive',
                                  ),
                                ],
                                [
                                  '2',
                                  i18n(
                                    'Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnStatus_Exclusive',
                                  ),
                                  i18n(
                                    'Faq_ConsumerConfiguration_WhatIsAccountsLogic_ColumnDescription_Exclusive',
                                  ),
                                ],
                              ],
                            }}
                          />
                        </Element>

                        <Element name="add-customer-id">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_HowAddCustomerId')}
                            description={i18n('Faq_ConsumerConfiguration_HowAddCustomerId_Description')}
                            underDescription={i18n(
                              'Faq_ConsumerConfiguration_HowAddCustomerId_UnderDescription',
                            )}
                            list={[
                              i18n('Faq_ConsumerConfiguration_HowAddCustomerId_First'),
                              i18n('Faq_ConsumerConfiguration_HowAddCustomerId_Second'),
                              i18n('Faq_ConsumerConfiguration_HowAddCustomerId_Third'),
                              i18n('Faq_ConsumerConfiguration_HowAddCustomerId_Fourth'),
                              i18n('Faq_ConsumerConfiguration_HowAddCustomerId_Fifth'),
                            ]}
                            message={
                              <span>
                                {i18n('Faq_ConsumerConfiguration_HowAddCustomerId_Message')}
                                <CustomLink to="what-is-customer-id">
                                  <b>{i18n('Faq_ConsumerConfiguration_HowAddCustomerId_MessageBold')}</b>
                                </CustomLink>
                              </span>
                            }
                          />
                        </Element>

                        <Element name="what-is-customer-id">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_WhatIsCustomerId')}
                            description={i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Description')}
                            message={
                              <span>
                                {i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Message')}
                                <b>{i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_MessageBold')}</b>
                              </span>
                            }
                            table={{
                              headers: [
                                i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Column_AccountStatus'),
                                i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Column_CustomerIdStatus'),
                                i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Column_Description'),
                              ],
                              rows: [
                                [
                                  '1',
                                  i18n('InclusiveLogicName'),
                                  i18n('InclusiveLogicName'),
                                  i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Row1_Decription'),
                                ],
                                [
                                  '2',
                                  i18n('InclusiveLogicName'),
                                  i18n('ExclusiveLogicName'),
                                  i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Row2_Decription'),
                                ],
                                [
                                  '3',
                                  i18n('ExclusiveLogicName'),
                                  i18n('InclusiveLogicName'),
                                  i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Row3_Decription'),
                                ],
                                [
                                  '4',
                                  i18n('ExclusiveLogicName'),
                                  i18n('ExclusiveLogicName'),
                                  i18n('Faq_ConsumerConfiguration_WhatIsCustomerId_Row4_Decription'),
                                ],
                              ],
                            }}
                          />
                        </Element>

                        <Element name="enable-access">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_HowEnableAccess')}
                            description={i18n('Faq_ConsumerConfiguration_HowEnableAccess_Description')}
                          />
                        </Element>

                        <Element name="configure-access">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_HowConfigureAccess')}
                            description={i18n('Faq_ConsumerConfiguration_HowConfigureAccess_Description')}
                            list={[
                              i18n('Faq_ConsumerConfiguration_HowConfigureAccess_First'),
                              i18n('Faq_ConsumerConfiguration_HowConfigureAccess_Second'),
                              i18n('Faq_ConsumerConfiguration_HowConfigureAccess_Third'),
                            ]}
                          />
                        </Element>

                        <Element name="schema-presets">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_WhatAreSchemaPresets')}
                            description={i18n('Faq_ConsumerConfiguration_WhatAreSchemaPresets_Description')}
                            message={i18n('Faq_ConsumerConfiguration_WhatAreSchemaPresets_Message')}
                          />
                        </Element>

                        <Element name="use-advanced-settings">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_HowUseAdvancedSettings')}
                            description={i18n('Faq_ConsumerConfiguration_HowUseAdvancedSettings_Description')}
                            message={i18n('Faq_ConsumerConfiguration_HowUseAdvancedSettings_Message')}
                          />
                        </Element>

                        <Element name="not-available-byPII-data">
                          <QuestionItem
                            name={i18n('Faq_ConsumerConfiguration_NotAvailableOnDisabledPII')}
                            description={i18n(
                              'Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description',
                            )}
                            list={[
                              i18n('Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_Trip'),
                              i18n('Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_Air'),
                              i18n(
                                'Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_HotelSegment',
                              ),
                              i18n(
                                'Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_TourSegment',
                              ),
                              i18n(
                                'Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_CarSegment',
                              ),
                              i18n(
                                'Faq_ConsumerConfiguration_NotAvailableOnDisabledPII_Description_TicketsNode',
                              ),
                            ]}
                          />
                        </Element>
                      </Element>
                      <Element name="search" className="faq-content-section">
                        <h3 data-testid="faq-content-search">{i18n('Faq_Search')}</h3>
                        <Element name="use-search">
                          <QuestionItem
                            name={i18n('Faq_Search_WhatIsSearch')}
                            description={i18n('Faq_Search_WhatIsSearch_Description')}
                          />
                        </Element>
                        <Element name="search-parameters">
                          <QuestionItem
                            name={i18n('Faq_Search_WhatParametersCanUtilize')}
                            list={[
                              [
                                i18n('Faq_Search_WhatParametersCanUtilize_First'),
                                i18n('Faq_Search_WhatParametersCanUtilize_First_A'),
                                i18n('Faq_Search_WhatParametersCanUtilize_First_B'),
                                i18n('Faq_Search_WhatParametersCanUtilize_First_C'),
                                i18n('Faq_Search_WhatParametersCanUtilize_First_D'),
                              ],
                              i18n('Faq_Search_WhatParametersCanUtilize_Second'),
                              i18n('Faq_Search_WhatParametersCanUtilize_Third'),
                            ]}
                          />
                        </Element>
                      </Element>
                      <Element name="events" className="faq-content-section">
                        <h3 data-testid="faq-content-events">{i18n('Faq_Events')}</h3>
                        <Element name="use-events">
                          <QuestionItem
                            name={i18n('Faq_Events_WhatIsEvents')}
                            description={i18n('Faq_Events_WhatIsEvents_Description')}
                          />
                        </Element>
                        <Element name="events-parameters">
                          <QuestionItem
                            name={i18n('Faq_Events_WhatParametersCanUtilize')}
                            list={[
                              [
                                i18n('Faq_Events_WhatParametersCanUtilize_First'),
                                i18n('Faq_Events_WhatParametersCanUtilize_First_A'),
                                i18n('Faq_Events_WhatParametersCanUtilize_First_B'),
                                i18n('Faq_Events_WhatParametersCanUtilize_First_C'),
                                i18n('Faq_Events_WhatParametersCanUtilize_First_D'),
                              ],
                              [
                                i18n('Faq_Events_WhatParametersCanUtilize_Second'),
                                i18n('Faq_Events_WhatParametersCanUtilize_Second_A'),
                                i18n('Faq_Events_WhatParametersCanUtilize_Second_B'),
                                i18n('Faq_Events_WhatParametersCanUtilize_Second_C'),
                                i18n('Faq_Events_WhatParametersCanUtilize_Second_D'),
                                i18n('Faq_Events_WhatParametersCanUtilize_Second_E'),
                              ],
                            ]}
                          />
                        </Element>
                      </Element>
                    </Element>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </Segment>
      </div>
    </>
  );
};

export default HomePage;
