import React, { useCallback } from 'react';

export const useNotification = (
  openTimeout = 10000,
): [
  { isNotificationOpen: boolean; notificationMessage: string },
  { openNotification: (message: string, isFailed?: boolean) => void; closeNotification: () => void },
] => {
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const [, setTimeountId] = React.useState<NodeJS.Timeout>();

  const openNotification = useCallback(
    (message: string) => {
      setIsNotificationOpen(true);
      setNotificationMessage(message);
      setTimeountId((prev) => {
        prev && clearTimeout(prev);
        return setTimeout(() => {
          closeNotification();
        }, openTimeout);
      });
    },
    [openTimeout],
  );

  const closeNotification = () => {
    setIsNotificationOpen(false);
  };

  return [
    { isNotificationOpen, notificationMessage },
    { openNotification, closeNotification },
  ];
};
