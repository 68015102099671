import React, { useState } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import ConditionalWrapper from '../../ConditionalWrapper/ConditionalWrapper';
import ConsumerForm from '../../ConsumerForm/ConsumerForm';
import ConsumerMainInfo from '../../ConsumerInfo/ConsumerMainInfo/ConsumerMainInfo';
import ConsumerStaticInfo from '../../ConsumerInfo/ConsumerStaticInfo/ConsumerStaticInfo';
import { useBreakpoint } from '../../../hooks/BreakPointProvider/breakpoint';
import { useConsumerInfoNavigation } from '../../../hooks/Navigation/useConsumerInfoNavigation';
import { ConsumerFormViewModel } from '../../../models/Consumer/ConsumerFormViewModel';
import { ConsumerModel } from '../../../models/Consumer/ConsumerModel';
import './styles.scss';
import { ScopesPresetModel } from '../../../models/Consumer/Scopes/ScopesPresetModel';
import { ConsumerMetadataModel } from '../../../models/Consumer/ConsumerMetadataModel';

interface Props {
  model?: ConsumerModel;
  consumers?: ConsumerMetadataModel[];
  onSave: (m: ConsumerFormViewModel, scopes: ScopesPresetModel[]) => void;
}

const GeneralTab: React.FC<Props> = ({ model, consumers, onSave }) => {
  const [isEdit, setIsEdit] = useState(false);

  const breakpoints = useBreakpoint();
  const [, { goToUrl }] = useConsumerInfoNavigation();

  const handleConsumerSave = (m: ConsumerFormViewModel, scopes: ScopesPresetModel[]) => {
    setIsEdit(false);
    onSave(m, scopes);
  };

  return (
    <>
      <div>
        {model && (
          <Segment className="consumer-info-container">
            <Grid columns={breakpoints.sm ? 1 : 2} divided={breakpoints.sm ? 'vertically' : true}>
              <ConditionalWrapper
                condition={breakpoints.sm}
                wrapper={(children) => <Grid.Row>{children}</Grid.Row>}
              >
                <Grid.Column className="consumer-column">
                  {isEdit ? (
                    <ConsumerForm
                      model={model}
                      consumers={consumers}
                      onSave={handleConsumerSave}
                      onClose={(redirectUrl) => (redirectUrl ? goToUrl(redirectUrl) : setIsEdit(false))}
                    />
                  ) : (
                    <ConsumerMainInfo consumer={model} onEdit={() => setIsEdit(true)} />
                  )}
                </Grid.Column>
              </ConditionalWrapper>
              <ConditionalWrapper
                condition={breakpoints.sm}
                wrapper={(children) => <Grid.Row>{children}</Grid.Row>}
              >
                <Grid.Column className="consumer-column">
                  <ConsumerStaticInfo consumer={model} />
                </Grid.Column>
              </ConditionalWrapper>
            </Grid>
          </Segment>
        )}
      </div>
    </>
  );
};

export default GeneralTab;
