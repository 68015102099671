import { i18n } from '../../services/i18n';
import { RadioOption } from '../NamedRadioGroup/NamedRadioGroup';

export const getAccountsCustomerNumbersLogicOptions: () => RadioOption[] = () => [
  {
    key: '1',
    value: true,
    text: i18n('InclusiveLogicName'),
  },
  {
    key: '2',
    value: false,
    text: i18n('ExclusiveLogicName'),
  },
];
