import React, { useMemo } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Breadcrumb, Dimmer, Loader } from 'semantic-ui-react';
import { Permission } from '../../enums/Permission';
import AccountsTab from '../../features/ConsumerTab/AccountsTab/AccountsTab';
import GeneralTab from '../../features/ConsumerTab/GeneralTab/GeneralTab';
import Nav from '../../features/Header/Nav/Nav';
import { useUserPermissions } from '../../hooks/Auth/useUserPermissions';
import { useConsumerInfoNavigation } from '../../hooks/Navigation/useConsumerInfoNavigation';
import { ConsumerFormViewModel } from '../../models/Consumer/ConsumerFormViewModel';
import {
  consumerIdParameter,
  consumerAccountsAddLookupPath,
  consumerAccountsAddPath,
  consumerAccountsEditPath,
  consumerAccountsPath,
  consumerCustomerNumbersAddPath,
  consumerCustomerNumbersEditPath,
  consumerCustomerNumbersPath,
  consumerPath,
  consumerTripAccessPath,
  consumersPath,
} from '../../paths';
import { i18n } from '../../services/i18n';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TripAccessTab from './../../features/ConsumerTab/TripAccessTab/TripAccessTab';
import { ScopesPresetModel } from '../../models/Consumer/Scopes/ScopesPresetModel';
import { useErrorToast } from '../../hooks/Alert/useErrorToast';
import { useConsumers } from '../../store/hooks/useConsumers';
import { useConsumerInfo } from '../../store/hooks/useConsumerInfo';
import { useSuccessToast } from '../../hooks/Alert/useSuccessToast';
import NoAccessPage from '../NoAccessPage/NoAccessPage';
import { ApiErrorCodes } from '../../enums/ApiCodes';

import './styles.scss';

const ConsumerInfoPage: React.FC = () => {
  const [{ id }] = useConsumerInfoNavigation();
  const [{ consumers, isLoading, error, successMessage }, { handleError, handleSuccessMessage }] =
    useConsumers();
  const [
    { consumer, isLoading: isConsumerLoading, error: consumerInfoError },
    {
      updateConsumer,
      updateConsumerAccount,
      updateConsumerCustomerNumbers,
      updateConsumerTripAccess,
      updateConsumerIsInclusive,
      updateConsumerAccountsStatus,
    },
  ] = useConsumerInfo(id);
  const isAccessDenied = consumerInfoError?.response?.status === ApiErrorCodes.Forbidden;
  const isNotFound = consumerInfoError?.response?.status === ApiErrorCodes.NotFound;
  const isInternalServerError = consumerInfoError?.response?.status === ApiErrorCodes.InternalServer;

  const restConsumers = useMemo(() => consumers.filter((d) => d.consumerId !== id), [consumers, id]);

  const hasPermission = useUserPermissions();

  const handleConsumerSave = (model: ConsumerFormViewModel, scopesPresets: ScopesPresetModel[]) => {
    updateConsumer(model, scopesPresets, consumer);
  };

  useErrorToast(error, handleError);
  useSuccessToast(successMessage, handleSuccessMessage);

  return (
    <>
      {(isLoading || isConsumerLoading) && (
        <Dimmer active inverted>
          <Loader size="massive" className="consumer-info-loader" />
        </Dimmer>
      )}
      <div>
        {isAccessDenied && <NoAccessPage />}
        {(isNotFound || isInternalServerError) && <NotFoundPage />}

        {consumer && (
          <>
            <div className="consumer-info-header-container">
              <Breadcrumb>
                <Breadcrumb.Section>
                  <Link data-testid="consumer-info-breadcrumb-link" to={consumersPath}>
                    {i18n('ConsumersPage_Title')}
                  </Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section>{consumer?.consumerKey}</Breadcrumb.Section>
              </Breadcrumb>
            </div>

            <div className="consumer-info-navigation-container">
              <Nav
                tabNavigation
                exact
                data-testid="consumer-info-general-link"
                name={i18n('ConsumerInfoGeneral_Link')}
                to={consumerPath.replace(consumerIdParameter, id)}
              />
              <Nav
                tabNavigation
                data-testid="consumer-info-accounts-link"
                name={i18n('ConsumerInfoAccounts_Link')}
                to={consumerAccountsPath.replace(consumerIdParameter, id)}
              />
              <Nav
                tabNavigation
                data-testid="consumer-info-trip-link"
                name={i18n('ConsumerInfoTrip_Link')}
                to={consumerTripAccessPath.replace(consumerIdParameter, id)}
              />
            </div>

            <Switch>
              <Route path={consumerPath} exact>
                <GeneralTab model={consumer} consumers={restConsumers} onSave={handleConsumerSave} />
              </Route>
              <Route
                path={[
                  ...(hasPermission(Permission.ConsumersUpdate)
                    ? [
                        consumerCustomerNumbersEditPath,
                        consumerCustomerNumbersAddPath,
                        consumerAccountsEditPath,
                        consumerAccountsAddPath,
                        consumerAccountsAddLookupPath,
                      ]
                    : []),
                  consumerCustomerNumbersPath,
                  consumerAccountsPath,
                ]}
                exact
              >
                <AccountsTab
                  consumer={consumer}
                  onConsumerAccountUpdated={updateConsumerAccount}
                  onConsumerCustomerNumbersUpdated={updateConsumerCustomerNumbers}
                  onConsumerIsInclusiveUpdated={updateConsumerIsInclusive}
                  onConsumerAccountsStatusUpdated={updateConsumerAccountsStatus}
                />
              </Route>
              <Route path={consumerTripAccessPath} exact>
                <TripAccessTab consumer={consumer} onConsumerTripAccessUpdated={updateConsumerTripAccess} />
              </Route>
              <NotFoundPage />
            </Switch>
          </>
        )}
      </div>
    </>
  );
};

export default ConsumerInfoPage;
