import React, { useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useErrorToast } from '../../../hooks/Alert/useErrorToast';
import { useSuccessToast } from '../../../hooks/Alert/useSuccessToast';
import { i18n } from '../../../services/i18n';
import { useSendEvent } from '../../../store/hooks/useSendEvent';
import ModalBase from '../../Modal/ModalBase/ModalBase';
import NamedDropdown from '../../NamedDropdown';
import { getEventsTypesForDropdown } from '../../../mappers/EventsTypesMapper';
import { DropdownValueType } from '../../../models/Form/DropdownValueType';
import { EventType } from '../../../enums/EventTypes';
import './styles.scss';

interface Props {
  sendingTripId?: string;
  onModalClose: () => void;
}

export const SendEventModal: React.FC<Props> = (props) => {
  const [{ isLoading, error, successMessage }, { sendEvent, handleReset, handleError, handleSuccess }] =
    useSendEvent();

  const [eventType, setEventType] = useState<number | undefined>(undefined);
  const [, { handleChangeEventType }] = useSendEvent();

  useErrorToast(error, handleError);
  useSuccessToast(successMessage, handleSuccess);

  const handleSend = (isSuccess?: boolean): void => {
    if (isSuccess && props.sendingTripId) {
      sendEvent(props.sendingTripId);
    } else {
      handleReset();
    }

    setEventType(undefined);
    props.onModalClose();
  };

  const handleEventTypeChange = (v: DropdownValueType) => {
    handleChangeEventType(v as EventType);
    setEventType(v as EventType);
  };

  return (
    <ModalBase
      isOpen={!!props.sendingTripId || isLoading}
      header={i18n('SendEvents_Modal_Headline')}
      content={
        <>
          {i18n('SendEvents_Modal_Content')}
          <NamedDropdown
            className="event-type-dropdown"
            title={i18n('SendEvents_Modal_EventTypeDropdown_Title')}
            value={eventType}
            required
            requiredOnTitle
            options={getEventsTypesForDropdown()}
            placeholder={i18n('SendEvents_Modal_EventTypeDropdown_Placeholder')}
            onChange={handleEventTypeChange}
            data-testid="send-event-modal-event-type"
          />
          {isLoading && (
            <Dimmer active inverted data-testid="send-event-loader">
              <Loader size="medium" />
            </Dimmer>
          )}
        </>
      }
      cancelButtonText={i18n('Modals_Base_CancelButton')}
      okButtonText={i18n('Modals_Base_ConfirmButton')}
      okButtonDisabled={!eventType}
      onClose={handleSend}
    />
  );
};
