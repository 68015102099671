import { RefObject, useRef, useState } from 'react';

export const COUNT_ITEMS_IN_A_PART = 25;
export const useLazyItemsView = <T>(
  items: T[],
): [
  {
    itemsVisible: T[];
    countItemsInPage: number;
    refTableWrapper: RefObject<HTMLDivElement>;
  },
  {
    handleUpItemsCount: (totalItemsCount: number) => void;
    handleScrollToTop: (e: HTMLElement | null) => void;
    setCountItemsInPage: (count: number) => void;
  },
] => {
  const [countItemsInPage, setCountItemsInPage] = useState<number>(COUNT_ITEMS_IN_A_PART);
  const refTableWrapper = useRef<HTMLDivElement>(null);
  const itemsVisible = items.slice(0, countItemsInPage);

  const handleUpItemsCount = (totalItemsCount: number): void => {
    if (totalItemsCount > countItemsInPage) {
      const nextCount = countItemsInPage + COUNT_ITEMS_IN_A_PART;
      setCountItemsInPage(nextCount);
    }
  };

  const handleScrollToTop = (element: HTMLElement | null): void => {
    if (element && element.scrollTop !== 0) element.scrollTo(0, 0);
  };

  return [
    {
      itemsVisible,
      countItemsInPage,
      refTableWrapper,
    },
    {
      handleUpItemsCount,
      handleScrollToTop,
      setCountItemsInPage,
    },
  ];
};
