import React from 'react';
import { i18n } from '../../services/i18n';
import './styles.scss';

const Footer: React.FC = () => {
  return (
    <div className="footer-container">
      <span>{i18n('Footer_RightsLabel')}</span>
      <span>
        <a
          href="https://www.bcdtravel.com/privacy-policy/"
          className="footer-link-text"
          target="_blank"
          rel="noreferrer"
        >
          {i18n('Footer_PrivacyPolicyLinkLabel')}
        </a>
        <a
          href="https://www.bcdtravel.com/legal/"
          className="footer-link-text"
          target="_blank"
          rel="noreferrer"
        >
          {i18n('Footer_TermsAndConditionLinkLabel')}
        </a>
      </span>
    </div>
  );
};

export default Footer;
