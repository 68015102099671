import React, { useEffect } from 'react';
import clsx from 'clsx';
import classNames from 'classnames';
import { DeepMap, FieldError } from 'react-hook-form';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { isUndefined } from 'lodash';

import { ReactComponent as DropdownIcon } from '../../assets/icons/dropdown.svg';
import { DropdownValueType } from '../../models/Form/DropdownValueType';

import './styles.scss';
import { i18n } from '../../services/i18n';

export interface DropdownOption extends DropdownItemProps {
  key: string;
  value: string | number;
  text: string;
  disabled?: boolean;
}

interface Props {
  title: string;
  disabled?: boolean;
  value?: DropdownValueType;
  placeholder?: string;
  required?: boolean;
  requiredOnTitle?: boolean;
  multiple?: boolean;
  search?: boolean;
  noResultsMessage?: string;
  className?: string;
  options: DropdownOption[];
  error?: FieldError | DeepMap<string[] | undefined, FieldError>;
  blurOnChange?: boolean;
  'data-testid'?: string;
  onChange: (v: DropdownValueType) => void;
  onBlur?: () => void;
}

const renderLabel = (label: DropdownItemProps) => ({
  color: label.disabled ? 'grey' : null,
  content: label.text,
  removeIcon: label.disabled ? null : 'close',
});

const NamedDropdown: React.FC<Props> = ({
  title,
  disabled = false,
  value,
  placeholder,
  required,
  requiredOnTitle,
  multiple,
  search,
  noResultsMessage = i18n('NoResultsFound'),
  className,
  options,
  error,
  blurOnChange = true,
  'data-testid': dataTestId,
  onChange,
  onBlur,
}) => {
  const handleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (event instanceof KeyboardEvent && event.key === 'Backspace') {
      const values = value as string[];
      const removingOption = options.find((o) => String(o.value) === String(values[values.length - 1]));
      if (removingOption?.disabled) {
        return;
      }
    }

    onChange(data.value);
  };

  const getOptions = () => {
    const selectedValues = value as string[];
    if (!selectedValues || !options || options.length !== selectedValues.length || search) {
      return options;
    }

    return [
      ...options,
      {
        key: noResultsMessage,
        text: noResultsMessage,
        value: noResultsMessage,
        disabled: true,
      },
    ];
  };

  useEffect(() => {
    !isUndefined(value) && blurOnChange && onBlur?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={clsx(className, 'named-dropdown')}>
      <div
        className={classNames('named-dropdown-title', { required: requiredOnTitle })}
        data-testid="namedDropdown-title"
      >
        {title}
      </div>
      <Dropdown
        disabled={disabled}
        value={value ?? (multiple ? [] : '')}
        data-testid={dataTestId}
        placeholder={placeholder}
        required={required}
        icon={<DropdownIcon className="icon" />}
        fluid
        multiple={multiple}
        search={search}
        selection
        error={!!error}
        options={getOptions()}
        noResultsMessage={noResultsMessage}
        onChange={handleChange}
        onBlur={() => onBlur?.()}
        renderLabel={renderLabel}
      />
      {error && <p className="error-message named-input-error-msg">{(error as FieldError)?.message}</p>}
    </div>
  );
};

export default NamedDropdown;
