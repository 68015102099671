import { postDataToApi } from '../helpers/ApiHelper';
import { SendEventRequestDTO } from '../models/Events/SendEventModel';
import { TripsRequestDTO, TripDTO } from '../models/Trip/TripModel';

export class TripsService {
  private static readonly endpointSendEvent = '/events/send-event';
  private static readonly endpointSearchEventsTrips = '/events/search-trips';
  private static readonly endpointSearchTrips = '/search-trips';

  public static async sendEvents(payload: SendEventRequestDTO): Promise<void> {
    return await postDataToApi<SendEventRequestDTO, void>(this.endpointSendEvent, payload);
  }

  public static async getEventsTrips(payload: TripsRequestDTO): Promise<TripDTO[]> {
    return await postDataToApi<TripsRequestDTO, TripDTO[]>(this.endpointSearchEventsTrips, payload);
  }

  public static async getSearchTrips(payload: TripsRequestDTO): Promise<TripDTO[]> {
    return await postDataToApi<TripsRequestDTO, TripDTO[]>(this.endpointSearchTrips, payload);
  }
}
