import clsx from 'clsx';
import React, { Key, useMemo } from 'react';
import { Table } from 'semantic-ui-react';
import { intersection, isEmpty } from 'lodash';

import { Permission } from '../../../enums/Permission';
import { Role, UserRoles } from '../../../enums/UserRole';
import { useUserPermissions } from '../../../hooks/Auth/useUserPermissions';
import { getUserRoles } from '../../../mappers/UserMapper';
import { ClaimModel } from '../../../models/User/ClaimModel';
import { UserModel } from '../../../models/User/UserModel';
import { i18n } from '../../../services/i18n';
import BooleanLabelCell from '../../Table/BooleanLabelCell/BooleanLabelCell';
import LabelGroupCell from '../../Table/LabelGroupCell';
import NoDataRow from '../../Table/NoDataRow';
import HeaderCellWithTooltip from '../../Table/HeaderCellWithTooltip';

import '../../Table/styles.scss';
import './UserTable.scss';

interface Props {
  activeItem?: UserModel;
  items?: UserModel[];
  claims: ClaimModel[];
  onUserEdit: (m: UserModel) => void;
  noDataMessage: string;
}

const UserTable: React.FC<Props> = ({ activeItem, items, claims, onUserEdit, noDataMessage }) => {
  const hasPermission = useUserPermissions();

  const getUserRoleLabel = (role?: Role): string => {
    return role ? i18n(`Users_RoleName_${role}`) : '';
  };

  const availableRolesToEdit = useMemo(() => {
    if (!hasPermission(Permission.UsersUpdate)) {
      return [];
    }

    const canAssignUserEditProperties = hasPermission(Permission.UsersCanAssignUserPermissions);
    return canAssignUserEditProperties
      ? UserRoles
      : UserRoles.filter((r) => {
          return !r.permissions.some(
            (p) => p === Permission.UsersUpdate || p === Permission.UsersCreate || p === Permission.UsersRead,
          );
        });
  }, [hasPermission]);

  return (
    <Table
      data-testid="users-table"
      singleLine
      selectable={hasPermission(Permission.UsersUpdate)}
      className="data-table users-table"
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="col-email">{i18n('UserTable_Header_UserIdEmail')}</Table.HeaderCell>
          <Table.HeaderCell className="col-first-name">{i18n('UserTable_Header_FirstName')}</Table.HeaderCell>
          <Table.HeaderCell className="col-last-name">{i18n('UserTable_Header_LastName')}</Table.HeaderCell>
          <Table.HeaderCell className="col-active">{i18n('UserTable_Header_IsActive')}</Table.HeaderCell>
          <Table.HeaderCell className="col-role">{i18n('UserTable_Header_Role')}</Table.HeaderCell>
          <HeaderCellWithTooltip
            title={i18n('UserTable_Header_ConsumerTypes')}
            popup={i18n('UserTable_Header_ConsumerTypesTooltip')}
          />
        </Table.Row>
      </Table.Header>
      <Table.Body data-testid="users-table-body">
        {items && items?.length > 0 ? (
          items?.map((item) => {
            const roles = getUserRoles(item, claims, UserRoles);
            const canEditUser =
              hasPermission(Permission.UsersUpdate) &&
              !isEmpty(roles) &&
              intersection(
                availableRolesToEdit.map((r) => r.role),
                roles,
              ).length === roles.length;
            return (
              <Table.Row
                key={item.id as Key}
                active={activeItem === item}
                onClick={canEditUser ? () => onUserEdit(item) : undefined}
                className={clsx({
                  selectable: canEditUser,
                  'disabled-status-row': !item.isEnabled || !canEditUser,
                })}
              >
                <Table.Cell data-testid="users-table-email">{item.email}</Table.Cell>
                <Table.Cell data-testid="users-table-first-name">{item.firstName}</Table.Cell>
                <Table.Cell data-testid="users-table-last-name">{item.lastName}</Table.Cell>
                <BooleanLabelCell
                  data-testid="users-table-enabled"
                  value={item.isEnabled}
                  textTrue={i18n('ActiveStatusName')}
                  textFalse={i18n('InactiveStatusName')}
                />
                <Table.Cell data-testid="users-table-role">
                  {roles.map((role) => getUserRoleLabel(role)).join(', ')}
                </Table.Cell>
                <LabelGroupCell data-testid="users-table-consumer-types" names={item.consumerTypes} />
              </Table.Row>
            );
          })
        ) : (
          <NoDataRow colSpan={5} text={noDataMessage} />
        )}
      </Table.Body>
    </Table>
  );
};

export default UserTable;
