export enum ApiAccess {
  Trips,
  Expenses,
  WaiversStandAlone,
  WaiversMatching,
  Xx1,
  Receipts,
  Tickets,
  FlightAlerts,
  Enh,
}
