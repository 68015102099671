import React from 'react';
import { Label, Table } from 'semantic-ui-react';
import './styles.scss';

interface Props {
  names?: string[];
  'data-testid'?: string;
}

const LabelGroupCell: React.FC<Props> = (props) => {
  return (
    <Table.Cell className="label-group-cell" data-testid={props['data-testid']}>
      <div className="label-group-container">
        {props.names?.map((name) => <Label key={name}>{name}</Label>)}
      </div>
    </Table.Cell>
  );
};

export default LabelGroupCell;
