import { AnalyticsProvider, AWSKinesisProvider } from 'aws-amplify';
import { AnalyticsEvent } from '../models/Amplify/AnalyticsEvent';
import { AnalyticsEventData } from '../models/Amplify/AnalyticsEventParams';
import { AWSKinesisTrackerParameters } from '../models/Amplify/AWSKinesisTrackerParameters';

export class AWSKinesisTrackerProvider implements AnalyticsProvider {
  private awsKinesisProvider: AWSKinesisProvider;
  private streamName: string;
  private partitionKey?: string;

  constructor(trackerParams: AWSKinesisTrackerParameters, awsKinesisProvider: AWSKinesisProvider) {
    this.partitionKey = trackerParams.partitionKey;
    this.streamName = trackerParams.streamName;
    this.awsKinesisProvider = awsKinesisProvider;
  }

  configure(config: Record<string, unknown>): Record<string, unknown> {
    return this.awsKinesisProvider.configure(config) as Record<string, unknown>;
  }

  record(eventData: AnalyticsEventData): Promise<boolean> {
    const event: AnalyticsEvent = {
      data: eventData,
      streamName: this.streamName,
    };

    if (this.partitionKey) {
      Object.assign(event, { partitionKey: this.partitionKey });
    }
    return this.awsKinesisProvider.record({ event });
  }

  public getCategory(): string {
    return 'Analytics';
  }

  public getProviderName(): string {
    return `AWSKinesisTracker-${this.streamName}`;
  }
}
