import React, { createContext, useContext, useState } from 'react';
import { CustomCognitoUser } from '../../models/User/CustomCognitoUser';

const defaultValue: [CustomCognitoUser | undefined, (newUser: CustomCognitoUser | undefined) => void] = [
  undefined,
  () => {
    console.error('UserContext was not initialized');
  },
];

const UserContext = createContext(defaultValue);

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<CustomCognitoUser | undefined>();

  return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>;
};

const useUser: () => [
  CustomCognitoUser | undefined,
  (newUser: CustomCognitoUser | undefined) => void,
] = () => {
  const context = useContext(UserContext);
  if (context === defaultValue) {
    throw new Error('useUser must be used within BreakpointProvider');
  }

  return context;
};

export { UserProvider, useUser };
