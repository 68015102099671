import AccountsForm from '../AccountsForm/AccountsForm';
import ModalLeave from '../../../Modal/ModalLeave/ModalLeave';
import ModalSave from '../../../Modal/ModalSave/ModalSave';
import NewAccountsSection from '../NewAccountsSection/NewAccountsSection';
import React, { useEffect, useState } from 'react';
import { accountIdMaxLength, accountsIdsRegex, maxAccountsPerTime } from '../AccountsFormConstants';
import { AccountModel } from '../../../../models/Consumer/Account/AccountModel';
import { AccountsFormSaveModel } from '../../../../models/Consumer/Account/AccountsFormSaveModel';
import { AccountsFormViewModel } from '../../../../models/Consumer/Account/AccountsFormViewModel';
import { HighlightModel } from '../../../../models/Consumer/Account/HighlightModel';
import { i18n } from '../../../../services/i18n';
import { ModalsModel } from '../../../../models/Common/ModalsModel';
import { useForm } from 'react-hook-form';
import { useModals } from '../../../../hooks/Alert/useModals';
import { useValidationUtils } from '../../../../hooks/Form/useValidationUtils';
import { ValidationUtilsModel } from '../../../../models/Common/ValidationUtilsModel';
import {
  getAccountsIdsHighlights,
  getIncorrectCharactersHighlights,
} from '../../../../services/AccountsIdsHighlightsService';

interface Props {
  consumerIsInclusive: boolean;
  isInclusiveChangeStarted: boolean;
  existingAccounts: AccountModel[];
  onClose: (redirectUrl?: string) => void;
  onSave: (form: AccountsFormSaveModel) => void;
  onShowHideInclusiveModal: (isVisible: boolean) => void;
}

const NewAccountsForm: React.FC<Props> = (props) => {
  const existingAccountsIds = props.existingAccounts.map((item) => item.id);
  const [selectedAccounts, setSelectedAccounts] = useState([] as string[]);
  const [highlights, setHighlights] = useState([] as HighlightModel[]);
  const [isHighlightOnChange, setIsHighlightOnChange] = useState(false);
  const [state, setState] = useState<AccountsFormViewModel>({});

  const useModalsValue = useModals(props.onClose, props.onShowHideInclusiveModal);
  const modals: ModalsModel = { ...useModalsValue[0], ...useModalsValue[1] };

  const useFormValue = useForm<AccountsFormViewModel>();
  const { register, getValues, formState } = useFormValue;

  const [{ isFieldsChanged }, { handleStateChange, handleBlur, handleFormSubmit, handlePropsChange }] =
    useValidationUtils<AccountsFormViewModel>({
      state,
      useFormValue,
      setState,
      showOrHideSaveModal: modals.showOrHideSaveModal,
      relatedFieldsArray: [['startDate', 'endDate']],
    });
  const validationUtils: ValidationUtilsModel<AccountsFormViewModel> = {
    isFieldsChanged,
    handleStateChange,
    handleBlur,
    handleFormSubmit,
    handlePropsChange,
  };

  const handleTextAreaChange = (value: string) => {
    const accountsIdsHighlights = getAccountsIdsHighlights(value, existingAccountsIds);
    const incorrectCharactersHighlights = getIncorrectCharactersHighlights(value);
    const allHighlights = incorrectCharactersHighlights.concat(accountsIdsHighlights.highlights);
    setHighlights(allHighlights);
    setSelectedAccounts(accountsIdsHighlights.accountsState.accountsIds);
  };

  const handleTextAreaBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    void handleBlur('id');

    if (!isHighlightOnChange) {
      handleTextAreaChange(event.target.innerText);
      setIsHighlightOnChange(true);
    }
  };

  const getAccountsSaveModel = (): AccountsFormSaveModel => ({
    viewModel: state,
    accountsIds: selectedAccounts,
  });

  const getViewModel = (consumerIsInclusive: boolean): AccountsFormViewModel => ({
    enabled: consumerIsInclusive ? false : undefined,
  });

  useEffect(() => {
    const getValidationMessage = (): string | undefined => {
      const id: string | undefined = getValues('id');

      const {
        accountsState: { accountsIds, existingDuplicateAccountIds, hasInvalidAccountsIds },
      } = getAccountsIdsHighlights(id ?? '', existingAccountsIds);

      if (accountsIds.some((a) => a.length > accountIdMaxLength))
        return i18n('AccountsForm_TooLongAccountId_Message');

      if (accountsIds.length > maxAccountsPerTime) return i18n('AccountsForm_TooManyAccounts_Message');

      if (hasInvalidAccountsIds) return i18n('AccountsForm_DuplicateAccounts_Message');

      if (existingDuplicateAccountIds)
        return getExistingAccountsMessage(existingDuplicateAccountIds) || undefined;
    };

    const getExistingAccountsMessage = (existingDuplicateAccountIds: string[]): string => {
      switch (existingDuplicateAccountIds.length) {
        case 0:
          return '';
        case 1:
          return i18n('AccountsForm_AccountExistsMessage').replace(
            '{{accountId}}',
            existingDuplicateAccountIds[0],
          );
        default:
          return i18n('AccountsForm_MultipleAccountsExistMessage');
      }
    };

    register('id', {
      required: i18n('AccountsForm_IncorrectFormatMessage'),
      pattern: {
        value: accountsIdsRegex,
        message: i18n('AccountsForm_IncorrectFormatMessage'),
      },
      validate: {
        invalidAccountsIds: (_) => getValidationMessage(),
      },
    });
  }, [existingAccountsIds, getValues, register]);

  useEffect(() => {
    handlePropsChange(getViewModel(props.consumerIsInclusive));
  }, [props.consumerIsInclusive, handlePropsChange]);

  return (
    <AccountsForm
      {...props}
      title={i18n('AccountsForm_Create_Title')}
      AccountsIds={
        <NewAccountsSection
          highlights={highlights}
          error={formState.errors.id}
          onBlur={handleTextAreaBlur}
          onChangeValue={(value) => {
            void handleStateChange(value, 'id', isHighlightOnChange);
            if (isHighlightOnChange) {
              handleTextAreaChange(value);
            }
          }}
        />
      }
      ModalLeave={
        <ModalLeave
          isOpen={modals.isLeavePopupOpen}
          isEdit={false}
          isAdding={true}
          onClose={(isLeft) => modals.handleModalLeaveClose(isLeft, props.isInclusiveChangeStarted)}
        />
      }
      ModalSave={
        <ModalSave
          isAdding
          isEdit={false}
          isOpen={modals.isSavePopupOpen}
          header={i18n('AccountsForm_ModalSaveCreate_Title')}
          onClose={(isSuccess) =>
            isSuccess ? props.onSave(getAccountsSaveModel()) : modals.showOrHideSaveModal(false)
          }
        />
      }
      viewModel={state}
      useFormValue={useFormValue}
      modals={modals}
      validationUtils={validationUtils}
      areRequiredFormFields={true}
    />
  );
};

export default NewAccountsForm;
