import clsx from 'clsx';
import ErrorMessage from '../../features/ErrorMessage/ErrorMessage';
import EventsTripsTable from '../../features/Events/EventsTripsTable/EventsTripsTable';
import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useBreakpoint } from '../../hooks/BreakPointProvider/breakpoint';
import { useTrips } from '../../store/hooks/useTrips/useTrips';
import './styles.scss';

const EventsSearchResult: React.FC = () => {
  const [{ isLoading: isTripsLoading, error: tripsError }] = useTrips();
  const breakpoints = useBreakpoint();
  const isNotFound = (tripsError?.response?.status && tripsError.response.status === 404) || false;

  return (
    <div className="events-page-content-container">
      {isTripsLoading && (
        <Dimmer active inverted data-testid="events-page-loader">
          <Loader size="massive" />
        </Dimmer>
      )}

      <div
        className={clsx('events-content-container', {
          'table-sticky-container': !breakpoints.xs,
        })}
        data-testid="events-content-container"
      >
        {!isTripsLoading && (!tripsError || isNotFound) && <EventsTripsTable />}
        {tripsError && !isNotFound && <ErrorMessage dataTestid="events-page-error-message" />}
      </div>
    </div>
  );
};

export default EventsSearchResult;
