import { ApiError } from '../../models/Api/ApiError';
import { RawTripResponseDTO, TripRequestDTO } from '../../models/Trip/TripModel';
import { useQuery } from '@tanstack/react-query';
import { TripService } from '../../services/TripService';
import { getBaseQueryOptions } from '../baseQueryOptions';

export interface UseRawTripValuesInterface {
  trip: RawTripResponseDTO | undefined;
  isLoading: boolean;
  error?: ApiError | null;
}

export const useRawTripQuery: (tripId: string) => UseRawTripValuesInterface = (tripId: string) => {
  const { isFetching, error, data } = useQuery<RawTripResponseDTO, ApiError>({
    ...getBaseQueryOptions(false),
    queryKey: ['tripRaw', tripId],
    queryFn: () => TripService.getRawTrip(<TripRequestDTO>{ id: tripId }),
  });

  return {
    trip: data,
    isLoading: isFetching,
    error: error,
  };
};
