import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-scroll';

interface Props {
  to: string;
  children: React.ReactNode;
}

const NavScrollLink: React.FC<Props> = ({ to, children }) => {
  return (
    <Link
      activeClass="active-link"
      to={to}
      spy={true}
      smooth={true}
      duration={250}
      offset={-20}
      containerId="containerElement"
      onSetActive={() => {
        document.querySelector(`[name=${to}]`)?.classList.add('active');
        document.querySelector(`.nav-link.${to}`)?.scrollIntoView({ behavior: 'smooth' });
      }}
      onSetInactive={() => document.querySelector(`[name=${to}]`)?.classList.remove('active')}
    >
      <span
        className={clsx('nav-link', to)}
        onClick={() => document.querySelector(`[name=${to}]`)?.scrollIntoView({ behavior: 'smooth' })}
      >
        {children}
      </span>
    </Link>
  );
};

export default NavScrollLink;
