import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

interface Props {
  to: string;
  name: string;
  horizontal?: boolean;
  'data-testid'?: string;
  tabNavigation?: boolean;
  exact?: boolean;
}

const Nav: React.FC<Props> = ({
  to,
  name,
  horizontal = false,
  tabNavigation = false,
  exact = false,
  'data-testid': dataTestId,
}) => {
  return (
    <div className="navigation-item">
      <NavLink
        data-testid={dataTestId}
        to={to}
        exact={exact}
        activeClassName="navigation-item-link-active"
        className={clsx({
          'navigation-item-link': !horizontal,
          'navigation-item-link-horizontal': horizontal,
          'navigation-item-link-tab': tabNavigation,
        })}
      >
        {name}
      </NavLink>
    </div>
  );
};

export default Nav;
