/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { isEmpty } from 'lodash';

type ObjectWithIndexSignature = Record<string | number | symbol, unknown>;

function hasIndexSignature(obj: unknown): obj is ObjectWithIndexSignature {
  return typeof obj === 'object' && obj !== null;
}

export const cleanupTrueValues = <T extends ObjectWithIndexSignature>(obj?: T): T => {
  if (obj === undefined) return {} as T;

  const result = { ...obj };
  Object.keys(result).forEach((key) => {
    const value = result[key];
    if (hasIndexSignature(value)) {
      const cleanedNestedObject = cleanupTrueValues(value);
      if (isEmpty(cleanedNestedObject)) {
        delete result[key as keyof T];
      } else {
        result[key as keyof T] = cleanedNestedObject as T[keyof T];
      }
    } else if (result[key as keyof T]) {
      delete result[key as keyof T];
    }
  });
  return result;
};
