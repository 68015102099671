import React, { useState, useEffect, useMemo } from 'react';
import { isUndefined } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { Button, Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import { Location } from 'history';

import { Permission } from '../../../enums/Permission';
import { useModals } from '../../../hooks/Alert/useModals';
import { useUserPermissions } from '../../../hooks/Auth/useUserPermissions';
import { useAutoFocus } from '../../../hooks/Form/useAutoFocus';
import { mapToUserFormViewModel } from '../../../mappers/UserMapper';
import { ClaimModel } from '../../../models/User/ClaimModel';
import { UserFormViewModel } from '../../../models/User/UserFormViewModel';
import { UserModel } from '../../../models/User/UserModel';
import { i18n } from '../../../services/i18n';
import { useConsumerTypes } from '../../../store/hooks/useConsumerTypes';
import ModalLeave from '../../Modal/ModalLeave/ModalLeave';
import ModalSave from '../../Modal/ModalSave/ModalSave';
import NamedDropdown from '../../NamedDropdown';
import NamedInput from '../../NamedInput';
import NamedRadioGroup from '../../NamedRadioGroup';
import { getConsumerTypesForDropdown } from '../../../helpers/ConsumerTypesHelper';
import { getUserFederatedOptions, getUserRolesOptions, getUserStatusOptions } from './UserFormConstants';
import {
  getAvailableRoles,
  handleManageFederatedState,
  isRolesWithConsumersTypes,
  isSubmitAvailable,
} from './helpers';
import {
  userConsumerTypesRules,
  userEmailRules,
  userNameRules,
  userPhoneNumberRules,
  userRoleRules,
} from './../../../helpers/UsersFormHelper';
import { handleBlur, handleChange } from '../../../helpers/ReactHookFormHelpers';
import { Role, UserRoles } from '../../../enums/UserRole';

import '../../styles.scss';

interface Props {
  model?: UserModel;
  claims?: ClaimModel[];
  onClose: (redirectUrl?: string) => void;
  onSave: (m: UserFormViewModel) => void;
}

const UserForm: React.FC<Props> = ({ model, claims, onClose, onSave }) => {
  const [{ consumerTypes, isLoadingConsumerTypes }] = useConsumerTypes();
  const [isFederatedDisabled, setIsFederatedDisabled] = useState<boolean>(true);
  const { control, formState, watch, setValue, getValues, handleSubmit, reset, trigger } =
    useForm<UserFormViewModel>({
      defaultValues: mapToUserFormViewModel(model, claims),
    });
  const hasPermission = useUserPermissions();
  const [
    { isLeavePopupOpen, isSavePopupOpen },
    { showOrHideSaveModal, handleRedirect, handleModalLeaveClose, handleFormClose },
  ] = useModals(onClose);
  const [{ inputFocusRef }, { focus }] = useAutoFocus();
  const availableRoles = useMemo(
    () => getAvailableRoles(hasPermission(Permission.UsersCanAssignUserPermissions), UserRoles),
    [hasPermission],
  );
  const roleIdsValue = watch('roleIds');
  const emailValue = watch('email');
  const idValue = watch('id');
  const isEditUser = !isUndefined(idValue);
  const isWithConsumerTypes = isRolesWithConsumersTypes(roleIdsValue);
  const nameRule = userNameRules();
  const emailRule = (!isEditUser && userEmailRules()) || {};
  const phoneNumberRule = userPhoneNumberRules();
  const roleRules = userRoleRules();
  const consumerTypesRule = userConsumerTypesRules(roleIdsValue);
  const userRolesOptions = getUserRolesOptions(availableRoles, roleIdsValue, UserRoles);
  const userStatusOptions = getUserStatusOptions();
  const userFederatedOptions = getUserFederatedOptions();
  const isSubmitDisabled = isSubmitAvailable(formState);

  useEffect(() => {
    reset(mapToUserFormViewModel(model, claims));
  }, [model, claims]);

  useEffect(() => {
    focus(true);
    if (isEditUser) {
      setIsFederatedDisabled(true);
    }
  }, [focus, isEditUser]);

  useEffect(() => {
    !isEditUser && emailValue && handleManageFederatedState(emailValue, setIsFederatedDisabled, setValue);
  }, [emailValue, setValue]);

  return (
    <>
      {isLoadingConsumerTypes && (
        <Dimmer active inverted>
          <Loader size="massive" />
        </Dimmer>
      )}
      <Prompt when={formState.isDirty} message={(v: Location) => handleRedirect(v)} />
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
          void handleSubmit(() => showOrHideSaveModal(true))(event);
        }}
      >
        <Segment className="page-form-container">
          <div className="page-form">
            <h3 data-testid="users-form-title">
              {i18n(isEditUser ? 'UserForm_Edit_Title' : 'UserForm_Create_Title')}
            </h3>
            <div className="page-form-body">
              <Grid>
                <Grid.Row stretched columns={2}>
                  <Grid.Column stretched>
                    <Controller
                      name="firstName"
                      rules={nameRule}
                      control={control}
                      render={(event) => (
                        <NamedInput
                          title={i18n('UserForm_FirstNameTitle')}
                          value={event.field.value}
                          maxLength={100}
                          data-testid="users-form-first-name"
                          error={event.fieldState.error}
                          inputRef={inputFocusRef}
                          onChange={(value) =>
                            handleChange<UserFormViewModel, typeof event.field.name>({
                              value,
                              trigger,
                              event,
                            })
                          }
                          onBlur={() =>
                            handleBlur<UserFormViewModel, typeof event.field.name>({ trigger, event })
                          }
                        />
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Controller
                      name="lastName"
                      rules={nameRule}
                      control={control}
                      render={(event) => (
                        <NamedInput
                          title={i18n('UserForm_LastNameTitle')}
                          value={event.field.value}
                          maxLength={100}
                          data-testid="users-form-last-name"
                          error={event.fieldState.error}
                          onChange={(value) =>
                            handleChange<UserFormViewModel, typeof event.field.name>({
                              value,
                              trigger,
                              event,
                            })
                          }
                          onBlur={() =>
                            handleBlur<UserFormViewModel, typeof event.field.name>({ trigger, event })
                          }
                        />
                      )}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Controller
                name="email"
                rules={emailRule}
                control={control}
                render={(event) => (
                  <NamedInput
                    title={i18n('UserForm_EmailTitle')}
                    value={event.field.value}
                    maxLength={100}
                    data-testid="users-form-email"
                    className="page-form-body-row"
                    disabled={isEditUser}
                    error={event.fieldState.error}
                    onChange={(value) =>
                      handleChange<UserFormViewModel, typeof event.field.name>({ value, trigger, event })
                    }
                    onBlur={() => handleBlur<UserFormViewModel, typeof event.field.name>({ trigger, event })}
                  />
                )}
              />
              <Controller
                name="phoneNumber"
                rules={phoneNumberRule}
                control={control}
                render={(event) => (
                  <NamedInput
                    value={event.field.value}
                    title={i18n('UserForm_NumberTitle')}
                    data-testid="users-form-phone"
                    className="page-form-body-row"
                    error={event.fieldState.error}
                    onChange={(value) =>
                      handleChange<UserFormViewModel, typeof event.field.name>({ value, trigger, event })
                    }
                    onBlur={() => handleBlur<UserFormViewModel, typeof event.field.name>({ trigger, event })}
                  />
                )}
              />
              <Controller
                name="roleIds"
                rules={roleRules}
                control={control}
                render={(event) => (
                  <NamedDropdown
                    title={i18n('UserForm_RolesTitle')}
                    value={event.field.value}
                    data-testid="users-form-role"
                    className="page-form-body-row"
                    placeholder={i18n('UserForm_Placeholder')}
                    error={event.fieldState.error}
                    options={userRolesOptions}
                    noResultsMessage={i18n('UserForm_NoRolesFound')}
                    onChange={(value) => {
                      const nextValue = value as Role[];
                      handleChange<UserFormViewModel, typeof event.field.name>({
                        value: nextValue,
                        trigger,
                        event,
                      });
                    }}
                    onBlur={() => handleBlur<UserFormViewModel, typeof event.field.name>({ trigger, event })}
                    blurOnChange={false}
                    multiple
                  />
                )}
              />
              {isWithConsumerTypes && (
                <Controller
                  name="consumerTypes"
                  rules={consumerTypesRule}
                  control={control}
                  render={(event) => (
                    <NamedDropdown
                      title={i18n('UserForm_ConsumerTypesTitle')}
                      value={event.field.value}
                      data-testid="user-form-consumer-types"
                      placeholder={i18n('UserForm_ConsumerTypesPlaceholder')}
                      required={true}
                      multiple
                      search
                      className="page-form-body-row"
                      options={getConsumerTypesForDropdown(consumerTypes, event.field.value)}
                      error={event.fieldState.error}
                      onChange={(value) => {
                        const nextValue = value as string[];
                        handleChange<UserFormViewModel, typeof event.field.name>({
                          value: nextValue,
                          trigger,
                          event,
                        });
                      }}
                      onBlur={() =>
                        handleBlur<UserFormViewModel, typeof event.field.name>({ trigger, event })
                      }
                      blurOnChange={false}
                    />
                  )}
                />
              )}
              <Controller
                name="isEnabled"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NamedRadioGroup
                    title={i18n('UserForm_StatusTitle')}
                    groupName="page-enabled"
                    className="page-form-body-row"
                    value={value}
                    onChange={(v) => onChange(v)}
                    options={userStatusOptions}
                  />
                )}
              />
              <Controller
                name="isFederated"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NamedRadioGroup
                    title={i18n('UserForm_FederatedTitle')}
                    groupName="page-federated"
                    className="page-form-body-row"
                    disabled={isFederatedDisabled}
                    value={value}
                    onChange={(v) => onChange(v)}
                    options={userFederatedOptions}
                  />
                )}
              />
            </div>
          </div>
          <div className="page-form-footer">
            <Button basic color="blue" type="button" onClick={() => handleFormClose(formState.isDirty)}>
              {i18n('UserForm_CancelButtonTitle')}
            </Button>
            <Button primary disabled={isSubmitDisabled} type="submit">
              {i18n('UserForm_SaveButtonTitle')}
            </Button>
          </div>
        </Segment>
      </form>
      <ModalLeave isOpen={isLeavePopupOpen} isEdit={isEditUser} onClose={handleModalLeaveClose} />
      <ModalSave
        isEdit={isEditUser}
        isOpen={isSavePopupOpen}
        header={i18n(isEditUser ? 'UserForm_ModalSaveEdit_Title' : 'UserForm_ModalSaveCreate_Title')}
        onClose={(isSuccess) => (isSuccess ? onSave(getValues()) : showOrHideSaveModal(false))}
      />
    </>
  );
};

export default UserForm;
