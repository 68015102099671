/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react';

import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { DataSourceFilterModel } from '../../models/Consumer/DataSourceFilterModel';
import { fetchDataSourceFiltersAction } from '../actions/dataSourceFiltersActions';

export const useDataSourceFilters: () => [
  {
    dataSourceFilters: DataSourceFilterModel[];
    isLoading: boolean;
  },
] = () => {
  const dispatch = useAppDispatch();
  const dataSourceFilters = useAppSelector((s) => s.dataSourceFilters);

  useEffect(() => {
    if (dataSourceFilters.items.length === 0) {
      dispatch(fetchDataSourceFiltersAction());
    }
  }, [dispatch, dataSourceFilters.items.length]);

  return [{ dataSourceFilters: dataSourceFilters.items, isLoading: dataSourceFilters.isLoading }];
};
