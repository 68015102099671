import { debounce } from 'lodash';
import { i18n } from '../services/i18n';
import {
  emailRegex,
  nameRegex,
  phoneNumberRegex,
  whiteSpaceValueRegex,
} from '../features/User/UserForm/UserFormConstants';
import { UserService } from '../services/UserService';
import { Role } from '../enums/UserRole';
import { isRolesWithConsumersTypes } from '../features/User/UserForm/helpers';

const requiredRule = () => ({
  required: i18n('Form_RequiredMessage'),
});

export const userNameRules = () => ({
  ...requiredRule(),
  pattern: {
    value: nameRegex,
    message: i18n('UserForm_InvalidNameMessage'),
  },
  validate: (v = '') => (whiteSpaceValueRegex.test(v) ? i18n('Form_RequiredMessage') : undefined),
});

export const userEmailRules = () => ({
  ...requiredRule(),
  pattern: {
    value: emailRegex,
    message: i18n('UserForm_InvalidEmailMessage'),
  },
  validate: async (v = '') => {
    const emailExists = debounce((value: string) => UserService.isEmailExists(value), 250, {
      leading: true,
    });

    return (await emailExists(v)) ? i18n('UserForm_EmailExistsMessage') : undefined;
  },
});

export const userPhoneNumberRules = () => ({
  pattern: {
    value: phoneNumberRegex,
    message: i18n('UserForm_InvalidPhoneMessage'),
  },
});

export const userRoleRules = () => ({ ...requiredRule() });

export const userConsumerTypesRules = (roles: Role[]) => ({
  validate: (v: string[]) =>
    (isRolesWithConsumersTypes(roles) && v.length > 0) || i18n('Form_RequiredMessage'),
});
