/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react';
import { useAppSelector } from './useAppSelector';
import { ScopesPresetModel } from '../../models/Consumer/Scopes/ScopesPresetModel';
import { fetchTripAccessScopesPresetsAction } from '../actions/tripAccessScopesPresetsActions';
import { useAppDispatch } from './useAppDispatch';

export const useTripAccessScopesPresets: () => [
  {
    tripAccessScopesPresets: ScopesPresetModel[];
    isLoading: boolean;
  },
] = () => {
  const dispatch = useAppDispatch();
  const tripAccessScopesPresets = useAppSelector((s) => s.tripAccessScopesPresets);

  useEffect(() => {
    if (tripAccessScopesPresets.items.length === 0) {
      dispatch(fetchTripAccessScopesPresetsAction());
    }
  }, [dispatch, tripAccessScopesPresets.items.length]);

  return [
    { tripAccessScopesPresets: tripAccessScopesPresets.items, isLoading: tripAccessScopesPresets.isLoading },
  ];
};
