import AccountsForm from '../AccountsForm/AccountsForm';
import ModalLeave from '../../../Modal/ModalLeave/ModalLeave';
import ModalSave from '../../../Modal/ModalSave/ModalSave';
import NamedInput from '../../../NamedInput';
import React, { useEffect, useState } from 'react';
import { AccountModel } from '../../../../models/Consumer/Account/AccountModel';
import { AccountsFormSaveModel } from '../../../../models/Consumer/Account/AccountsFormSaveModel';
import { AccountsFormViewModel } from '../../../../models/Consumer/Account/AccountsFormViewModel';
import { i18n } from '../../../../services/i18n';
import { mapToAccountsFormViewModel } from '../../../../mappers/ConsumerAccountMapper';
import { useModals } from '../../../../hooks/Alert/useModals';
import { useValidationUtils } from '../../../../hooks/Form/useValidationUtils';
import { useForm } from 'react-hook-form';
import { ModalsModel } from '../../../../models/Common/ModalsModel';
import { ValidationUtilsModel } from '../../../../models/Common/ValidationUtilsModel';

interface Props {
  account: AccountModel;
  consumerIsInclusive: boolean;
  isInclusiveChangeStarted: boolean;
  onClose: (redirectUrl?: string) => void;
  onSave: (form: AccountsFormSaveModel) => void;
  onShowHideInclusiveModal: (isVisible: boolean) => void;
}

const EditAccountForm: React.FC<Props> = (props) => {
  const [state, setState] = useState<AccountsFormViewModel>({});

  const useModalsValue = useModals(props.onClose, props.onShowHideInclusiveModal);
  const modalsModel: ModalsModel = { ...useModalsValue[0], ...useModalsValue[1] };

  const useFormValue = useForm<AccountsFormViewModel>();
  const [{ isFieldsChanged }, { handleStateChange, handleBlur, handleFormSubmit, handlePropsChange }] =
    useValidationUtils<AccountsFormViewModel>({
      state,
      useFormValue,
      setState,
      showOrHideSaveModal: modalsModel.showOrHideSaveModal,
      relatedFieldsArray: [['startDate', 'endDate']],
    });
  const validationUtils: ValidationUtilsModel<AccountsFormViewModel> = {
    isFieldsChanged,
    handleStateChange,
    handleBlur,
    handleFormSubmit,
    handlePropsChange,
  };

  const getAccountSaveModel = (): AccountsFormSaveModel => ({
    viewModel: state,
    accountsIds: [props.account.id],
  });

  const getViewModel = (account: AccountModel): AccountsFormViewModel => mapToAccountsFormViewModel(account);

  useEffect(() => {
    handlePropsChange(getViewModel(props.account));
  }, [props.account, handlePropsChange]);

  return (
    <AccountsForm
      {...props}
      title={i18n('AccountsForm_Edit_Title')}
      AccountsIds={
        <NamedInput
          title={i18n('AccountsForm_AccountIdTitle')}
          value={props.account.id}
          disabled={true}
          maxLength={100}
          data-testid="accounts-form-account-name"
        />
      }
      ModalLeave={
        <ModalLeave
          isOpen={modalsModel.isLeavePopupOpen}
          isEdit={true}
          isAdding={false}
          onClose={(isLeft) => modalsModel.handleModalLeaveClose(isLeft, props.isInclusiveChangeStarted)}
        />
      }
      ModalSave={
        <ModalSave
          isAdding
          isEdit={true}
          isOpen={modalsModel.isSavePopupOpen}
          header={i18n('AccountsForm_ModalSaveEdit_Title')}
          onClose={(isSuccess) =>
            isSuccess ? props.onSave(getAccountSaveModel()) : modalsModel.showOrHideSaveModal(false)
          }
        />
      }
      viewModel={state}
      useFormValue={useFormValue}
      modals={modalsModel}
      validationUtils={validationUtils}
      areRequiredFormFields={true}
    />
  );
};

export default EditAccountForm;
