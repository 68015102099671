import { isEqualWithCase } from '../../../../helpers/StringHelper';
import { DataSourceFilterModel } from '../../../../models/Consumer/DataSourceFilterModel';

export const handleToggleType = (
  isChecked: boolean,
  currentValues: DataSourceFilterModel[],
  currentDataSource: DataSourceFilterModel,
): DataSourceFilterModel[] =>
  isChecked
    ? currentValues.filter((x) => !isEqualWithCase(x.dataSource, currentDataSource.dataSource))
    : [
        ...currentValues,
        {
          ...currentDataSource,
          dataProviders: [],
        },
      ];

export const handleToggleProvider = (
  isChecked: boolean,
  currentProviders: string[],
  choseProvider: string,
): string[] =>
  isChecked
    ? currentProviders.filter((provider) => provider !== choseProvider)
    : [...currentProviders, choseProvider];

export const isDataTypeExists = (
  dataSources: DataSourceFilterModel[],
  dataSource: DataSourceFilterModel,
): boolean => dataSources.some((v) => isEqualWithCase(v.dataSource, dataSource.dataSource));

export const isDataProviderExists = (
  dataSources: DataSourceFilterModel[],
  dataSource: DataSourceFilterModel,
  dataProvider: string,
): boolean => getDataProviders(dataSources, dataSource).includes(dataProvider);

export const getDataProviders = (
  dataSources: DataSourceFilterModel[],
  dataSource: DataSourceFilterModel,
): string[] =>
  dataSources.find((v) => isEqualWithCase(v.dataSource, dataSource.dataSource))?.dataProviders ?? [];
