import React from 'react';
import { Message } from 'semantic-ui-react';
import { i18n } from '../../services/i18n';

interface Props {
  dataTestid: string;
}

const ErrorMessage: React.FC<Props> = (props) => {
  return (
    <Message className="faq-content-section-item-row" color="red" data-testid={props.dataTestid}>
      <Message.Content>
        <Message.Header>{i18n('ErrorPageMessage')}</Message.Header>
        {i18n('Messages_ErrorMessage')}
      </Message.Content>
    </Message>
  );
};

export default ErrorMessage;
