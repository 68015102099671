import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { i18n } from '../../../services/i18n';
import { useTripsQuery } from '../../../queries/hooks/useTripsQuery/useTripsQuery';
import {
  defaultSearchTripsFormValue,
  SearchTripsFormViewModel,
} from '../../../models/SearchTrips/SearchTripsFormViewModel';

import './styles.scss';
import NamedInputPrime from '../../NamedInputPrime/NamedInputPrime';
import { Button } from 'primereact/button';
import { handleValidateSearchTripsForm } from '../../../helpers/SearchTripsFormHelpers';

interface Props {
  isDirty?: boolean;
  onClear: () => void;
}

const SearchTripsForm: React.FC<Props> = ({ isDirty: isParentDirty, onClear }) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty: isFormDirty },
  } = useForm<SearchTripsFormViewModel>({
    defaultValues: defaultSearchTripsFormValue,
  });
  const [formError, setFormError] = useState('');
  const [{ isLoading }, { getSearchTrips }] = useTripsQuery();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    void handleSubmit(performSearch)(event);
  };

  const performSearch = (data: SearchTripsFormViewModel): void => {
    const { isValid, message } = handleValidateSearchTripsForm(data);
    if (!isValid) {
      setFormError(message);
      return;
    }

    setFormError('');
    getSearchTrips(data);
  };

  const clear = () => {
    setFormError('');
    reset();
    onClear && onClear();
  };

  const renderSearchFormTextInputFor = (
    name: keyof SearchTripsFormViewModel,
    props: {
      title: string;
      'data-testid': string;
      tooltip?: string;
      autoFocus?: boolean;
    },
  ): React.ReactElement => (
    <NamedInputPrime
      {...register(name)}
      {...props}
      value={watch(name)}
      maxLength={100}
      autoComplete="off"
      onBlur={(e: React.FocusEvent<HTMLInputElement>): void => {
        setValue(name, e.target.value.trim());
      }}
      className="search-input-element p-inputtext-sm"
    />
  );

  return (
    <div className="search-trips-form-container" data-testid="search-trips-form">
      <form onSubmit={onSubmit} className="search-inputs-container">
        {renderSearchFormTextInputFor('sourceId', {
          autoFocus: true,
          title: i18n('TripSearchForm_TripIdentifier'),
          tooltip: i18n('TripSearchForm_TripIdentifier_PopupMessage'),
          'data-testid': 'search-trips-form-trip-identifier',
        })}
        {renderSearchFormTextInputFor('travelerEmailAddress', {
          title: i18n('TripSearchForm_Email'),
          'data-testid': 'search-trips-form-email',
        })}
        {renderSearchFormTextInputFor('travelerLastName', {
          title: i18n('TripSearchForm_TravelerLastName'),
          'data-testid': 'search-trips-form-last-name',
        })}
        {renderSearchFormTextInputFor('travelerFirstName', {
          title: i18n('TripSearchForm_TravelerFirstName'),
          'data-testid': 'search-trips-form-first-name',
        })}
        {renderSearchFormTextInputFor('globalCustomerNumber', {
          title: i18n('TripSearchForm_GCN'),
          'data-testid': 'search-trips-form-gcn',
        })}
        {renderSearchFormTextInputFor('accountId', {
          title: i18n('TripSearchForm_SMID'),
          'data-testid': 'search-trips-form-smid',
        })}
        <Button
          type="submit"
          label={i18n('SearchButtonTitle')}
          disabled={isLoading}
          className="search-input-element"
          size="small"
          data-testid="search-trips-form-submit-control"
        />
        {(isFormDirty || isParentDirty || formError) && (
          <Button
            onClick={clear}
            icon="pi pi-times"
            outlined
            size="small"
            data-testid="clear-results-button"
            className="search-input-element"
          />
        )}
      </form>
      {formError && (
        <span className="search-form-error" data-testid="search-trips-form-validation-message">
          {formError}
        </span>
      )}
    </div>
  );
};

export default SearchTripsForm;
