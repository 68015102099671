import { ScopesPresetModel } from '../../../models/Consumer/Scopes/ScopesPresetModel';
import { DropdownOption } from '../../NamedDropdown/NamedDropdown';

export const getTripAccessPresetOption: (scopesPresets: ScopesPresetModel[]) => DropdownOption[] = (
  scopesPresets,
) => {
  return scopesPresets.map((preset) => {
    return {
      key: preset.id.toString(),
      text: preset.name,
      value: preset.id,
    };
  });
};
