import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { i18n } from '../../../services/i18n';
import { useTripsQuery } from '../../../queries/hooks/useTripsQuery/useTripsQuery';
import OverflowTip from '../../../shared/OverflowTip/OverflowTip';
import { useTripQuery } from '../../../queries/hooks/useTripQuery';
import './styles.scss';
import { TripModel } from '../../../models/Trip/TripModel';
import {
  searchTripItineraryResponsePath,
  searchTripRawResponsePath,
  searchTripResponsePath,
} from '../../../paths';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import NoDataRowPrime from '../../Table/NoDataRow/NoDataRowPrime';

const SearchTripsTable: React.FC = () => {
  const [{ trips, isNotFound }] = useTripsQuery();

  const renderGdsCreationOffice = (rowData: TripModel) => (
    <>
      {rowData.gds && <span className="no-word-break">{rowData.gds}</span>}
      {rowData.gds && rowData.creationOfficeId && ' / '}
      {rowData.creationOfficeId && <span className="no-word-break">{rowData.creationOfficeId}</span>}
    </>
  );

  const renderEmailTraveler = (rowData: TripModel) => (
    <>
      <OverflowTip text={rowData.email} data-testid="trips-table-email" className="email-content" />
      {rowData.travelers}
    </>
  );

  const renderDropdown = (rowData: TripModel) => (
    <Dropdown icon="ellipsis vertical" direction="right" className="search-trips-table-row-control">
      <Dropdown.Menu className="search-trips-table-dropdown-menu">
        <Dropdown.Item as={Link} to={searchTripResponsePath(rowData.id)}>
          {i18n('SearchTrips_Trips_Title')}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={searchTripItineraryResponsePath(rowData.id)}>
          {i18n('SearchTrips_Itinerary_Title')}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={searchTripRawResponsePath(rowData.id)}>
          {i18n('SearchTrips_Raw_Title')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <>
      {
        <DataTable
          rowClassName={(_) => 'search-trips-table-row'}
          sortField="lastModifiedDateTime"
          sortOrder={-1}
          value={!isNotFound ? trips : undefined}
          tableClassName="search-trips-table-container"
          size="small"
          dataKey="id"
          data-testid="search-trips-table"
          pt={{ bodyRow: { 'data-testid': 'search-trips-table-row' } }}
          stripedRows
          emptyMessage={<NoDataRowPrime text={i18n('Table_No_Result')} />}
          scrollable
          scrollHeight="flex"
        >
          <Column
            field="customerName"
            header={i18n('TripsTable_CustomerName')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-account-name' } }}
          ></Column>
          <Column
            field="globalCustomerNumber"
            header={i18n('TripsTable_GCN')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-gcn' } }}
          ></Column>
          <Column
            field="accountId"
            header={i18n('TripsTable_SMID')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-smid' } }}
          ></Column>
          <Column
            field="gds"
            header={`${i18n('TripsTable_GDS')} / ${i18n('TripsTable_CreationOffice')}`}
            body={renderGdsCreationOffice}
            pt={{ bodyCell: { 'data-testid': 'trips-table-trip-gds-creationOffice' } }}
          ></Column>
          <Column
            bodyClassName="word-break"
            field="id"
            header={i18n('TripsTable_TripId')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-trip-id' } }}
          ></Column>
          <Column
            bodyClassName="word-break"
            field="sourceId"
            header={i18n('TripsTable_SourceId')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-source-id' } }}
          ></Column>
          <Column
            field="recordLocator"
            header={i18n('TripsTable_RecordLocator')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-record-locator' } }}
          ></Column>
          <Column
            bodyClassName="travelers-email-content"
            header={`${i18n('TripsTable_Email')} / ${i18n('TripsTable_Traveler')}`}
            body={renderEmailTraveler}
            pt={{ bodyCell: { 'data-testid': 'trips-table-first-last-name' } }}
          ></Column>
          <Column
            bodyClassName="no-word-break"
            field="bookingDateTime"
            header={i18n('TripsTable_BookingDate')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-booking-date-time' } }}
          ></Column>
          <Column
            bodyClassName="no-word-break"
            field="sourceModifiedDateTime"
            header={i18n('TripsTable_SourceModifiedDate')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-source-modified-date-time' } }}
          ></Column>
          <Column
            bodyClassName="no-word-break"
            field="lastModifiedDateTime"
            header={i18n('TripsTable_LastModifiedDate')}
            pt={{ bodyCell: { 'data-testid': 'trips-table-last-modified-date-time' } }}
            sortable
          ></Column>
          <Column body={renderDropdown} />
        </DataTable>
      }
    </>
  );
};

export default SearchTripsTable;
