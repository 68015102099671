import { createSlice } from '@reduxjs/toolkit';
import { ApiError } from '../../models/Api/ApiError';
import { PageModel } from '../../models/Store/PageModel{T}';
import { UserModel } from '../../models/User/UserModel';
import { i18n } from '../../services/i18n';
import { usersPrefix } from '../actions/actionTypes';
import { createUserAction, fetchUsersAction, updateUserAction } from '../actions/usersActions';

const initialState: PageModel<UserModel[]> = {
  items: [] as UserModel[],
  isLoading: false,
};

const usersSlice = createSlice({
  name: usersPrefix,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload ?? [];
      })
      .addCase(fetchUsersAction.rejected, (state, action) => {
        state.items = [];
        state.isLoading = false;
        state.error = action.payload as ApiError;
      })
      .addCase(createUserAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUserAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = undefined;
        state.successMessage = i18n('UsersPage_UserCreatedMessage');
      })
      .addCase(createUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as ApiError;
      })
      .addCase(updateUserAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = undefined;
        state.successMessage = i18n('UsersPage_UserEditedMessage');
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as ApiError;
      });
  },
});

export default usersSlice.reducer;
