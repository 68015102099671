import { DropdownOption } from '../features/NamedDropdown/NamedDropdown';
import { mapToDropdownOption } from '../mappers/ConsumerTypeMapper';
import { ConsumerTypeModel } from '../models/Consumer/ConsumerTypeModel';

export const getConsumerTypesForDropdown = (
  globalConsumerTypes: ConsumerTypeModel[],
  resourceConsumerTypes?: string[],
): DropdownOption[] => {
  const allowedForCurrentUserConsumerTypes: ConsumerTypeModel[] = globalConsumerTypes.filter(
    (ct) => ct.isAllowedForCurrentUser,
  );
  if (resourceConsumerTypes?.length) {
    let consumerTypeIdCounter = 0;
    const mappedResourceConsumerTypes: ConsumerTypeModel[] = resourceConsumerTypes.map((ct) => ({
      consumerTypeId: `${--consumerTypeIdCounter}`,
      name: ct,
      isAllowedForCurrentUser: false,
    }));
    const disabledConsumerTypes = mappedResourceConsumerTypes.filter(
      (rct) => !allowedForCurrentUserConsumerTypes.some((act) => act.name === rct.name),
    );
    allowedForCurrentUserConsumerTypes.push(...disabledConsumerTypes);
  }

  return allowedForCurrentUserConsumerTypes.map((c) => mapToDropdownOption(c));
};
