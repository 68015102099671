import React from 'react';
import './styles.scss';

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

const ToastScrollableMessage: React.FC<Props> = ({ children }) => {
  return (
    <div data-testid="toast-scrollable-message" className="toast-scrollable-message">
      {children}
    </div>
  );
};

export default ToastScrollableMessage;
