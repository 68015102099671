import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import classNames from 'classnames';

import './styles.scss';

interface InfoTitleProps {
  content: string;
  popupContent: string;
  required?: boolean;
}

const InfoTitle: React.FC<InfoTitleProps> = ({ content, popupContent, required }): React.JSX.Element => {
  return (
    <div className="info-popup">
      <span className={classNames('info-popup-title', { required: required })} data-testid="info-popup-title">
        {content}
      </span>
      <Popup content={popupContent} offset={[-11]} trigger={<Icon name="info circle" color="grey" />} />
    </div>
  );
};

export default InfoTitle;
