import { Hub } from 'aws-amplify';
import { recordEvent, recordEventWithoutUserName } from './LoggerService';

const AuthListener = async (data: {
  payload: { event: any; data?: { username: string; message?: string }; message?: string };
}) => {
  switch (data.payload.event) {
    case 'signIn':
      await recordEvent('User signed in successfully.');
      break;
    case 'signIn_failure':
      await recordEventWithoutUserName('User signed in failed.', getError(data.payload));
      break;
    case 'tokenRefresh':
      await recordEvent('Token was refreshed successfully.');
      break;
    case 'tokenRefresh_failure':
      await recordEvent('Token refresh failed.', getError(data.payload, '.'));
  }
};

const getError = (
  payload: {
    event: any;
    data?: { username: string; message?: string };
    message?: string;
  },
  separator?: string,
) => {
  return {
    error: `${payload.data?.message || ''}${separator || ''} ${payload.message || ''}`,
  };
};

export const listenAuthEvent = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  Hub.listen('auth', AuthListener);
};
