import { format } from 'date-fns';
import { i18n } from '../services/i18n';

export const defaultDateFormat = 'yyyy-MM-dd';
export const defaultTimeFormat = 'HH:mm';
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`;

export const defaultDateTimeUtcFormatText = 'YYYY-MM-DD hh:mm';
export const DATETIME_LENGTH_DATEPICKER = defaultDateTimeFormat.length;
export const COUNT_AVAILABLE_YEARS_DATEPICKER = 20;

const NUMBERS_ONLY_REGEX = /\d+/g;
const DATE_REGEX = /\d{4}-(0?[1-9]|1[012])-(0[1-9]|[12]\d|30|31)/;
const DATE_TIME_REGEX = /\d{4}-(0?[1-9]|1[012])-(0[1-9]|[12]\d|30|31) (2[0-3]|[01]\d):[0-5]\d$/;

export const formatDateTimePicker = (date?: Date): string | undefined => {
  return date && format(date, defaultDateTimeFormat);
};

export const formatUTCDateTime = (date?: string): string | undefined => {
  return date && format(getUTCDateByString(date), defaultDateTimeFormat);
};

export const formatUTCDate = (date?: string): string | undefined => {
  return date && format(getUTCDateByString(date), defaultDateFormat);
};

export const formatUTCDateTimeWithUtcTextEnding = (date?: string): string | undefined => {
  return date && `${format(getUTCDateByString(date), defaultDateTimeFormat)} ${i18n('TimeHelper_Utc')}`;
};

export const getUTCDateByDate = (date: Date): Date => {
  const month: number = date.getMonth() + 1;
  return new Date(
    `${month}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} UTC`,
  );
};

export const getUTCDateByString = (date: string): Date => {
  return new Date(new Date(date).toLocaleString('en-US', { timeZone: 'UTC' }));
};

export const getCoverageMaskDate = (value: string): string => {
  // data mask - yyyy-MM-dd HH:mm
  const DATA_MASK_NUMBERS_COUNT = 12;

  const valueInput: string = value;
  const clearInputValue =
    valueInput.match(NUMBERS_ONLY_REGEX)?.join('').slice(0, DATA_MASK_NUMBERS_COUNT) ?? '';

  return clearInputValue
    .split('')
    .map((letter, index) => {
      let replacement = '';

      if (index === 4 || index === 6) {
        replacement = '-';
      } else if (index === 8) {
        replacement = ' ';
      } else if (index === 10) {
        replacement = ':';
      }

      return `${replacement}${letter}`;
    })
    .join('');
};

export const getCurrentISODate = (): string => {
  return new Date().toISOString();
};

export const fillDateTimeZeros = (dateTimeString: string): Date | undefined => {
  const dateTimeList = splitStringByIndex(dateTimeString, defaultDateTimeFormat.indexOf(' '));
  const timeList = splitStringByIndex(dateTimeList[1], defaultTimeFormat.indexOf(':'));

  const resultString = `${dateTimeList[0]} ${timeList[0].padStart(2, '0')}:${timeList[1].padStart(2, '0')}`;

  const dateRegex = new RegExp(DATE_TIME_REGEX);
  return dateRegex.test(resultString) ? new Date(resultString) : undefined;
};

const splitStringByIndex = (str: string, index: number) => {
  return [str.slice(0, index), str.slice(index + 1)];
};

export const isContainCorrectDate = (dateString: string): boolean => {
  // data mask - yyyy-MM-dd
  const dateRegex = new RegExp(DATE_REGEX);
  return dateRegex.test(dateString);
};

export const isDateTimeCorrect = (dateString: string): boolean => {
  // data mask - yyyy-MM-dd HH:mm
  const dateRegex = new RegExp(DATE_TIME_REGEX);
  return dateRegex.test(dateString) && formatDateTimePicker(new Date(dateString)) === dateString;
};

export const isInDateRange = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  date: Date,
): boolean => {
  let isInDateRange = true;
  if (startDate && date < startDate) {
    isInDateRange = false;
  }
  if (endDate && date > endDate) {
    isInDateRange = false;
  }
  return isInDateRange;
};
