import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

export const useConsumerInfoNavigation = (): [
  { id: string; path: string },
  { goToUrl: (url: string) => void },
] => {
  const params = useParams<{ consumerId: string }>();
  const history = useHistory();
  const match = useRouteMatch();

  const goToUrl = (url: string) => {
    history.push(url);
  };

  return [
    {
      id: params.consumerId,
      path: match.path,
    },
    { goToUrl },
  ];
};
