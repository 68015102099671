import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'semantic-ui-react';
import { consumersPath } from '../../paths';
import { i18n } from '../../services/i18n';
import './styles.scss';

const NoAccessPage: React.FC = () => {
  return (
    <div className="no-access-page-container">
      <div className="no-access-page-container-title">
        <Icon name="info circle" color="grey" />
        <h2>{i18n('NoAccessPage_Title')}</h2>
      </div>
      <div className="no-access-page-container-content">{i18n('NoAccessPage_Content')}</div>
      <Link to={consumersPath}>
        <Button primary className="no-access-page-container-link-button">
          {i18n('ConsumersPage_Title')}
        </Button>
      </Link>
    </div>
  );
};

export default NoAccessPage;
