import { createSlice } from '@reduxjs/toolkit';

import { ApiError } from '../../models/Api/ApiError';
import { tripsPrefix } from '../actions/actionTypes';
import { fetchTripsAction } from '../actions/tripsActions';
import { TripModel } from '../../models/Trip/TripModel';
import { PageModel } from '../../models/Store/PageModel{T}';

const initialState: PageModel<TripModel[]> = {
  items: [] as TripModel[],
  isLoading: false,
};

const tripsSlice = createSlice({
  name: tripsPrefix,
  initialState,
  reducers: {
    reset: () => initialState,
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTripsAction.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchTripsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload.items;
      })
      .addCase(fetchTripsAction.rejected, (state, action) => {
        state.items = [];
        state.isLoading = false;
        state.error = action.payload as ApiError;
      });
  },
});

export default tripsSlice.reducer;
