import React, { RefObject } from 'react';
import { Button } from 'semantic-ui-react';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { ReactComponent as EllipsisIcon } from '../../assets/icons/ellipsis.svg';
import './styles.scss';

interface Props {
  menu: RefObject<Menu>;
  menuItems: MenuItem[];
}

const DropdownMenu: React.FC<Props> = ({ menu, menuItems }) => {
  return (
    <>
      <Menu model={menuItems} popup ref={menu} popupAlignment="right" />
      <Button
        data-testid="consumers-options-button"
        basic
        color="blue"
        icon
        className="consumers-options-button"
        onClick={(event) => {
          menu?.current?.toggle(event);
        }}
        aria-controls="popup_menu_right"
        aria-haspopup
      >
        <EllipsisIcon />
      </Button>
    </>
  );
};

export default DropdownMenu;
