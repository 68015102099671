const cacheTimeout = 30000;

interface BaseQueryOptionsInterface {
  enabled: boolean;
  refetchOnWindowFocus: boolean;
  retry: boolean;
  staleTime: number;
}

export const getBaseQueryOptions: (manualRefetch: boolean) => BaseQueryOptionsInterface = (
  manualRefetch: boolean,
) => {
  return {
    enabled: !manualRefetch,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: manualRefetch ? 0 : cacheTimeout,
  };
};
