import clsx from 'clsx';
import React, { useCallback } from 'react';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { Button, Checkbox, Divider, Grid, Icon } from 'semantic-ui-react';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ConsumerFormViewModel } from '../../../models/Consumer/ConsumerFormViewModel';
import { ContactModel } from '../../../models/Consumer/ContactModel';
import { i18n } from '../../../services/i18n';
import NamedDropdown from '../../NamedDropdown';
import NamedInput from '../../NamedInput';
import { emailRegex } from '../../User/UserForm/UserFormConstants';
import { DropdownOption } from './../../NamedDropdown/NamedDropdown';
import './styles.scss';

interface Props {
  emails: Email[];
  onChange: (m: Email[]) => void;
  onBlur: () => void;
  useFormValue: UseFormReturn<ConsumerFormViewModel>;
}

export interface Email {
  id: number;
  model: ContactModel;
}

enum EmailType {
  To = 'TO',
  Cc = 'CC',
  Bcc = 'BCC',
}

const getDropdownOption: () => DropdownOption[] = () => {
  return [
    { key: '1', value: EmailType.To.toString(), text: EmailType.To.toString() },
    { key: '2', value: EmailType.Cc.toString(), text: EmailType.Cc.toString() },
    { key: '3', value: EmailType.Bcc.toString(), text: EmailType.Bcc.toString() },
  ];
};

const ConsumerEmails: React.FC<Props> = ({ emails, useFormValue, onChange }) => {
  const globalPrimaryValidationField = 'contacts.-1.model.isPrimary';
  const { register, unregister, formState, setValue, trigger } = useFormValue;
  const [state, setState] = React.useState<Email[]>(emails);
  const createModel: () => Email = () => {
    return {
      id: Math.max(...state.map((s) => s.id), 0) + 1,
      model: {} as ContactModel,
    };
  };

  const handleStateChanged = (updateStateAction: (prev: Email[]) => Email[]) => {
    setState((prev) => {
      const newState = updateStateAction(prev);
      newState.sort((a, b) => a.id - b.id);
      return newState;
    });
  };

  const registerContacts = useCallback(
    (model: Email) => {
      register(`contacts.${model.id}.model.email`, {
        required: i18n('Form_RequiredMessage'),
        pattern: {
          value: emailRegex,
          message: i18n('ConsumerForm_InvalidEmailMessage'),
        },
      });
      register(`contacts.${model.id}.model.type`, {
        required: i18n('Form_RequiredMessage'),
      });
    },
    [register],
  );

  React.useEffect(() => {
    registerContacts(emails[0]);
  }, [emails, registerContacts]);

  React.useEffect(() => {
    register(globalPrimaryValidationField, {
      validate: () => {
        return state.some((c) => c.model?.isPrimary)
          ? undefined
          : i18n('ConsumerForm_IsPrimaryMissingMessage');
      },
    });
  }, [register, state]);

  const handleAdd = () => {
    const model = createModel();
    registerContacts(model);
    handleStateChanged((prev) => {
      return [...prev, model];
    });
  };

  const handleEmailChange = async (
    value: string | boolean,
    field: keyof ContactModel,
    id: number,
    isForcedTrigger?: boolean,
  ) => {
    setValue(`contacts.${id}.model.${field}`, value, { shouldTouch: false });
    handleStateChanged((prev) => [
      ...prev.filter((m) => m.id !== id),
      {
        ...prev.find((m) => m.id === id),
        model: {
          ...prev.find((m) => m.id === id)?.model,
          [field]: value,
        },
      } as Email,
    ]);

    if (formState.touchedFields.contacts?.[id]?.model?.[field] || formState.isValidating || isForcedTrigger) {
      await trigger(`contacts.${id}.model.${field}`);
    }

    if (field === 'isPrimary') {
      await handleBlur('isPrimary', -1);
      await trigger(globalPrimaryValidationField);

      if (value) {
        await handleEmailChange(EmailType.To.toString(), 'type', id, true);
      }
    }
  };

  const handleBlur = async (field: keyof ContactModel, id: number) => {
    setValue(`contacts.${id}.model.${field}`, state.find((e) => e.id === id)?.model[field], {
      shouldTouch: true,
    });
    await trigger(`contacts.${id}.model.${field}`);
  };

  const handleDelete = (id: number) => {
    unregister(`contacts.${id}.model.email`);
    unregister(`contacts.${id}.model.type`);

    handleStateChanged((prev) => {
      return [...prev.filter((m) => m.id !== id)];
    });
  };

  React.useEffect(() => {
    if (state !== emails) {
      onChange(state);
    }
  }, [emails, onChange, state]);

  const primaryEmail = state.find((e) => e.model.isPrimary);
  const canAdd = state.length < 10;

  return (
    <div>
      <div className="create-consumer-emails-title-container">
        <div className="create-consumer-emails-title">{i18n('ConsumerForm_ContactsTitle')}</div>
        <Button
          data-testid="create-consumer-emails-add-button"
          basic
          color="blue"
          className="create-consumer-emails-add-button"
          disabled={!canAdd}
          onClick={handleAdd}
        >
          <PlusIcon />
        </Button>
      </div>
      {state.map((email, i) => {
        return (
          <React.Fragment key={email.id}>
            {i > 0 && <Divider className="create-consumer-emails-entry-divider" />}
            <div className="create-consumer-emails-entry">
              <div className="create-consumer-emails-entry-email-container">
                <NamedInput
                  className="create-consumer-emails-input"
                  maxLength={100}
                  value={email.model.email}
                  title={i18n('ConsumerForm_ContactsEmailTitle')}
                  onChange={(m) => void handleEmailChange(m, 'email', email.id)}
                  onBlur={() => void handleBlur('email', email.id)}
                  error={formState.errors.contacts?.[email.id]?.model?.email}
                />
                {state.length > 1 && (
                  <Button
                    basic
                    color="red"
                    className={clsx('create-consumer-emails-delete-button', {
                      'create-consumer-emails-delete-button-validation-errors':
                        formState.errors.contacts && !!formState.errors.contacts[email.id]?.model?.email,
                    })}
                    onClick={() => handleDelete(email.id)}
                    data-testid="create-consumer-emails-delete-button"
                  >
                    <Icon name="trash" />
                  </Button>
                )}
              </div>
              <Grid>
                <Grid.Row stretched>
                  <Grid.Column stretched width={10}>
                    <NamedDropdown
                      disabled={email.model.isPrimary}
                      value={email.model.type}
                      placeholder={i18n('ConsumerForm_ContactsTypePlaceholder')}
                      className="create-consumer-emails-entry-row"
                      title={i18n('ConsumerForm_ContactsTypeTitle')}
                      onChange={(v) => void handleEmailChange(v as string, 'type', email.id)}
                      onBlur={() => void handleBlur('type', email.id)}
                      options={getDropdownOption()}
                      error={formState.errors.contacts?.[email.id]?.model?.type as FieldError}
                    />
                  </Grid.Column>
                  <Grid.Column className="consumer-emails-is-primary-column" width={6}>
                    <Checkbox
                      checked={email.model.isPrimary}
                      label={i18n('ConsumerForm_ContactsPrimaryTitle')}
                      className="create-consumer-emails-checkbox"
                      onChange={() => void handleEmailChange(!email.model.isPrimary, 'isPrimary', email.id)}
                      disabled={primaryEmail && email.id !== primaryEmail.id}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ConsumerEmails;
