import { isUndefined } from 'lodash';
import React, { useEffect, useCallback, useState } from 'react';
import {
  FieldPath,
  FieldValues,
  PathValue,
  SetValueConfig,
  UnpackNestedValue,
  UseFormReturn,
} from 'react-hook-form';

interface Props<T extends FieldValues> {
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
  showOrHideSaveModal: (isVisible?: boolean) => void;
  useFormValue: UseFormReturn<T>;
  relatedFieldsArray?: FieldPath<T>[][];
  updateOnChange?: boolean;
}

export const useValidationUtils = <T extends FieldValues>({
  state,
  setState,
  showOrHideSaveModal,
  useFormValue,
  relatedFieldsArray,
  updateOnChange,
}: Props<T>): [
  {
    isFieldsChanged: boolean;
  },
  {
    handleStateChange: (
      value: T[FieldPath<T>],
      fieldName: FieldPath<T>,
      isForcedValidation?: boolean,
    ) => Promise<void>;
    handlePropsChange: (newState: T) => void;
    handleBlur: (key: FieldPath<T>) => void;
    handleFormSubmit: () => Promise<void>;
    getIsValidating: (fieldName: FieldPath<T>) => boolean;
  },
] => {
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const { formState, handleSubmit, setValue, trigger, clearErrors } = useFormValue;

  const updateStateAndFormState = (
    fieldName: FieldPath<T>,
    value?: UnpackNestedValue<PathValue<T, FieldPath<T>>>,
    options?: SetValueConfig,
  ) => {
    setState((prev) => {
      return {
        ...prev,
        [fieldName]: value,
      };
    });
    !isUndefined(value) && setValue(fieldName, value, options);
  };

  const handleStateChange = async (
    value: T[FieldPath<T>],
    fieldName: FieldPath<T>,
    isForcedValidation?: boolean,
  ) => {
    setIsFieldsChanged(true);
    updateStateAndFormState(fieldName, value);
    if (getIsValidating(fieldName) || isForcedValidation) {
      await trigger(fieldName);
    }

    if (relatedFieldsArray) {
      for (const relatedFields of relatedFieldsArray.filter((f) => f.includes(fieldName))) {
        for (const field of relatedFields.filter((f) => f === fieldName)) {
          await trigger(field);
        }
      }
    }
  };

  const getIsValidating = (fieldName: FieldPath<T>) => {
    return !!formState.touchedFields[fieldName] || formState.isValidating || !!updateOnChange;
  };

  const validateAndUpdateFields = () => {
    for (const key of Object.keys(state)) {
      handleBlur(key as FieldPath<T>);
    }
  };

  const handleBlur = (fieldName: FieldPath<T>) => {
    if (isFieldsChanged) {
      setState((prev) => {
        const value = prev[fieldName];
        const trimmedValue: T[FieldPath<T>] = typeof value === 'string' ? value.trim() : value;

        !isUndefined(trimmedValue) && setValue(fieldName, trimmedValue, { shouldTouch: true });

        void trigger(fieldName);

        return {
          ...prev,
          [fieldName]: trimmedValue,
        };
      });
    }
  };

  const handleFormSubmit = async () => {
    validateAndUpdateFields();
    await handleSubmit(
      () => showOrHideSaveModal(true),
      async () => {
        await trigger();
      },
    )();
  };

  const handlePropsChange = useCallback(
    (newState: T) => {
      for (const key of Object.keys({ ...state, ...newState })) {
        setValue(key as FieldPath<T>, newState[key as FieldPath<T>]);
      }
      setState(newState);
      setIsFieldsChanged(false);
      clearErrors();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState, clearErrors, setValue],
  );

  useEffect(() => {
    for (const key of Object.keys(state)) {
      setValue(key as FieldPath<T>, state[key as FieldPath<T>]);
    }
  }, [setValue, state]);

  return [
    {
      isFieldsChanged,
    },
    {
      handleStateChange,
      handlePropsChange,
      handleBlur,
      handleFormSubmit,
      getIsValidating,
    },
  ];
};
