import { Amplify } from 'aws-amplify';
import { Analytics, Auth, AWSKinesisProvider } from 'aws-amplify';
import { AmplifyConfigurationModel } from '../models/AmplifyConfigurationModel';
import { listenAuthEvent } from './AuthListener';
import { AWSKinesisTrackerProvider } from './AWSKinesisTrackerProvider';
import { getAttributesPageView } from './LoggerService';

export const amplifyConfiguration = buildConfiguration();
export const amplifyApiName = 'AdminUiApi';

async function buildConfiguration(): Promise<AWSKinesisTrackerProvider> {
  const response = await fetch(`${window.location.origin}/config.json`);
  if (!response.ok) {
    throw new Error(`Fail to fetch file. Status: ${response.status}`);
  }

  const json = (await response.json()) as AmplifyConfigurationModel;
  return configureAmplify(json);
}

const customHeaderFunc = async () => {
  return {
    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  };
};

async function configureAmplify(configuration: AmplifyConfigurationModel) {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: configuration.auth_region,
      userPoolId: configuration.auth_userPoolId,
      userPoolWebClientId: configuration.auth_userPoolWebClientId,
      identityPoolId: configuration.auth_identityPoolId,
      oauth: {
        domain: configuration.cognitoEndpoint,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: configuration.redirectUrl,
        redirectSignOut: configuration.redirectUrl,
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: 'AdminUiApi',
          endpoint: configuration.api_userApiEndpoint,
          custom_header: customHeaderFunc,
        },
      ],
    },
  });

  return configureAnalytics(configuration);
}

async function configureAnalytics(configuration: AmplifyConfigurationModel) {
  const kinesisTrackerProvider = new AWSKinesisTrackerProvider(
    {
      streamName: configuration.amplify_logKinesisStream,
    },
    new AWSKinesisProvider(),
  );
  const providerName = kinesisTrackerProvider.getProviderName();

  Analytics.addPluggable(kinesisTrackerProvider);

  Analytics.configure({
    AWSKinesis: {
      region: configuration.auth_region,
    },
    [providerName]: {
      region: configuration.auth_region,
    },
  });

  Analytics.autoTrack('pageView', {
    enable: true,
    type: 'SPA',
    provider: providerName,
    attributes: await getAttributesPageView(),
  });

  listenAuthEvent();
  return kinesisTrackerProvider;
}
