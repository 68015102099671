export type SearchTripsFormViewModel = {
  sourceId: string;
  globalCustomerNumber: string;
  accountId: string;
  travelerEmailAddress: string;
  travelerFirstName: string;
  travelerLastName: string;
};

export const defaultSearchTripsFormValue: SearchTripsFormViewModel = {
  sourceId: '',
  globalCustomerNumber: '',
  accountId: '',
  travelerEmailAddress: '',
  travelerFirstName: '',
  travelerLastName: '',
};
