import React, { ReactNode } from 'react';
import { Message, Table } from 'semantic-ui-react';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { i18n } from '../../services/i18n';
import './styles.scss';

interface Props {
  name: string;
  description?: string;
  underDescription?: string;
  list?: (string | string[])[];
  message?: ReactNode;
  table?: { headers: string[]; rows: string[][] };
}

const renderNestedList = (items: string[]) => (
  <ol className="faq-content-list-nested">
    {items.map((nestedItem, nestedIndex) => (
      <li key={nestedIndex}>{nestedItem}</li>
    ))}
  </ol>
);

const renderList = (list: Array<string | string[]>) => (
  <div className="faq-content-section-item-row">
    <ol className="faq-content-list">
      {list.map((item, index) => {
        if (typeof item === 'string') {
          return <li key={index}>{item}</li>;
        } else {
          const [firstItem, ...restItems] = item;
          return (
            <li key={index}>
              {firstItem}
              {restItems.length > 0 && renderNestedList(restItems)}
            </li>
          );
        }
      })}
    </ol>
  </div>
);

const QuestionItem: React.FC<Props> = ({ name, description, underDescription, list, message, table }) => {
  return (
    <div className="faq-content-section-item">
      <h4>{name}</h4>
      {description && <div className="faq-content-section-item-row">{description}</div>}
      {underDescription && <div className="faq-content-section-item-row">{underDescription}</div>}
      {list && list.length > 0 && renderList(list)}

      {message && (
        <Message className="faq-content-section-item-row" color="blue">
          <div className="icon">
            <InfoIcon />
          </div>
          <Message.Content>
            <Message.Header>{i18n('Message_Note')}</Message.Header>
            {message}
          </Message.Content>
        </Message>
      )}

      {table && (
        <Table singleLine className="faq-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              {table.headers?.map((item) => <Table.HeaderCell key={item}>{item}</Table.HeaderCell>)}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {table.rows?.map((row) => (
              <Table.Row key={row[0]}>
                {row.map((cell, indexCell) => (
                  <Table.Cell key={`${row[0]}${indexCell}`}>{cell}</Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

export default QuestionItem;
