import { TripAccessSettingEntry } from '../models/TripAccess/TripAccessSettingEntry';

export class TripAccessService {
  private static readonly settingsFileName = 'trip-access-advanced-settings.json';

  public static async getAdvancedSettings(): Promise<TripAccessSettingEntry[]> {
    const response = await fetch(`${window.location.origin}/${this.settingsFileName}`);
    return (await response.json()) as TripAccessSettingEntry[];
  }
}
