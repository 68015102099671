import React, { useState, useMemo } from 'react';
import { Accordion, Checkbox, Grid, Dimmer, Loader, Icon } from 'semantic-ui-react';

import { DataSourceFilterModel } from '../../../../models/Consumer/DataSourceFilterModel';
import { useDataSourceFilters } from '../../../../store/hooks/useDataSources';
import { i18n } from '../../../../services/i18n';
import {
  getDataProviders,
  isDataProviderExists,
  isDataTypeExists,
  handleToggleProvider,
  handleToggleType,
} from './helpers';
import { getChosenDataSourceFilters } from '../TripAccessInfo/helpers';
import { isEqualWithCase } from '../../../../helpers/StringHelper';

import './styles.scss';

interface Props {
  values?: DataSourceFilterModel[];
  disabled?: boolean;
  onChange?: (v: DataSourceFilterModel[]) => void;
}

const DataSourceAccess: React.FC<Props> = ({ onChange = () => undefined, values = [], disabled }) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
  const [{ dataSourceFilters, isLoading }] = useDataSourceFilters();
  const chosenDataSourceFilters = useMemo(
    () => getChosenDataSourceFilters(dataSourceFilters, values),
    [dataSourceFilters, values],
  );

  const handleChangeDataProvider = (dataSourceFilter: DataSourceFilterModel) => {
    let nextValue = [...chosenDataSourceFilters];
    const dataSourceIndex = nextValue.findIndex((x) =>
      isEqualWithCase(x.dataSource, dataSourceFilter.dataSource),
    );

    if (dataSourceIndex < 0) nextValue = [...nextValue, dataSourceFilter];
    else nextValue[dataSourceIndex] = dataSourceFilter;

    onChange(nextValue);
  };

  const handleToggleActive = (index: number): void => {
    const nextIndexes = activeIndexes.includes(index)
      ? activeIndexes.filter((i) => i !== index)
      : [...activeIndexes, index];
    setActiveIndexes(nextIndexes);
  };

  const isActive = (index: number): boolean => activeIndexes.includes(index);

  if (isLoading)
    return (
      <Dimmer active inverted data-testid="data-source-filters-loader">
        <Loader size="massive" />
      </Dimmer>
    );

  return (
    <Accordion fluid exclusive={false} className="data-source-form-list">
      {dataSourceFilters.map((dataSourceFilter, index) => {
        const isDataTypeChecked = isDataTypeExists(chosenDataSourceFilters, dataSourceFilter);
        const checkedDataProviders = getDataProviders(chosenDataSourceFilters, dataSourceFilter);

        return (
          <div key={dataSourceFilter.dataSource}>
            <Accordion.Title
              active={isActive(index)}
              index={index}
              className="data-source-form-type-container"
              data-testid="data-source-form-type-checkbox-wrapper"
            >
              <div className="data-source-form-checkbox-container">
                <Icon
                  name={isActive(index) ? 'angle up' : 'angle down'}
                  onClick={() => handleToggleActive(index)}
                  data-testid={`data-source-form-type-${index}-dropdown-control`}
                />
                <Checkbox
                  checked={isDataTypeChecked}
                  className="checkbox-input"
                  label={dataSourceFilter.dataSource}
                  disabled={disabled}
                  onChange={() => {
                    onChange(handleToggleType(isDataTypeChecked, chosenDataSourceFilters, dataSourceFilter));
                  }}
                  data-testid={`data-source-form-type-${index}-checkbox`}
                />
              </div>
              {checkedDataProviders.length > 0 && (
                <span data-testid="data-source-form-type-selected-providers">{`${i18n(
                  'DataSource_Selected',
                )} ${checkedDataProviders.length} ${i18n('DataSource_Providers')}`}</span>
              )}
            </Accordion.Title>
            <Accordion.Content
              active={isActive(index)}
              className="data-source-form-providers-container"
              data-testid={`data-source-form-type-${index}-provider-wrapper`}
            >
              <Grid style={{ paddingLeft: '20px' }}>
                <Grid.Row>
                  {dataSourceFilter.dataProviders.map((dataProvider, dataProviderIndex) => {
                    const isDataProviderChecked = isDataProviderExists(
                      chosenDataSourceFilters,
                      dataSourceFilter,
                      dataProvider,
                    );

                    return (
                      <Grid.Column
                        key={dataSourceFilter.dataSource + dataProvider}
                        tablet={8}
                        computer={4}
                        data-testid={`data-source-form-type-${index}-provider-checkbox`}
                      >
                        <Checkbox
                          checked={isDataProviderChecked}
                          className="checkbox-input"
                          label={dataProvider}
                          disabled={disabled}
                          onChange={() => {
                            handleChangeDataProvider({
                              ...dataSourceFilter,
                              dataProviders: handleToggleProvider(
                                isDataProviderChecked,
                                checkedDataProviders,
                                dataProvider,
                              ),
                            });
                          }}
                          data-testid={`data-source-form-type-${index}-provider-${dataProviderIndex}-checkbox`}
                        />
                      </Grid.Column>
                    );
                  })}
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </div>
        );
      })}
    </Accordion>
  );
};

export default DataSourceAccess;
