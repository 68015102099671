import clsx from 'clsx';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

interface Props {
  title: string;
  value?: string;
  className?: string;
  hideContent?: boolean;
  'data-testid'?: string;
}

const NamedLabel: React.FC<Props> = ({
  title,
  value,
  className,
  hideContent = false,
  'data-testid': dataTestId,
}) => {
  const [isContentVisible, setIsContentVisible] = React.useState(false);
  return (
    <div className={clsx(className, 'named-label')}>
      <div className="named-label-title">{title}</div>
      <div className="named-label-value" data-testid={dataTestId}>
        {hideContent && !isContentVisible ? value?.replaceAll(/./g, '•') : value}
        {hideContent && value && (
          <Icon
            className="named-label-value-icon"
            onClick={() => setIsContentVisible((prev) => !prev)}
            name={isContentVisible ? 'eye slash' : 'eye'}
          />
        )}
      </div>
    </div>
  );
};

export default NamedLabel;
