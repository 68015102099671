import { createSlice } from '@reduxjs/toolkit';
import { PageModel } from '../../models/Store/PageModel{T}';
import { TripAccessSettingEntry } from '../../models/TripAccess/TripAccessSettingEntry';
import { tripAccessAdvancedSettingsPrefix } from '../actions/actionTypes';
import { fetchTripAccessAdvancedSettingsAction } from '../actions/tripAccessAdvancedSettingsActions';

const initialState: PageModel<TripAccessSettingEntry[]> = {
  items: [] as TripAccessSettingEntry[],
  isLoading: false,
};

const tripAccessAdvancedSettingsSlice = createSlice({
  name: tripAccessAdvancedSettingsPrefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTripAccessAdvancedSettingsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTripAccessAdvancedSettingsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(fetchTripAccessAdvancedSettingsAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default tripAccessAdvancedSettingsSlice.reducer;
