/* eslint-disable @typescript-eslint/no-floating-promises */
import { useCallback, useEffect } from 'react';
import { mapToConsumerModel } from '../../mappers/ConsumerToDTOMapper';
import { ApiError } from '../../models/Api/ApiError';
import { ConsumerFormViewModel } from '../../models/Consumer/ConsumerFormViewModel';
import { ScopesPresetModel } from '../../models/Consumer/Scopes/ScopesPresetModel';
import {
  clearConsumersErrorAction,
  clearConsumersSuccessMessageAction,
  createConsumerAction,
  fetchConsumersAction,
} from '../actions/consumersActions';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { ConsumerMetadataModel } from '../../models/Consumer/ConsumerMetadataModel';

export const useConsumers: () => [
  {
    consumers: ConsumerMetadataModel[];
    isLoading: boolean;
    error?: ApiError;
    successMessage?: string;
  },
  {
    createConsumer: (consumer: ConsumerFormViewModel, scopesPresets: ScopesPresetModel[]) => Promise<string>;
    handleError: () => void;
    handleSuccessMessage: () => void;
  },
] = () => {
  const dispatch = useAppDispatch();
  const consumers = useAppSelector((s) => s.consumers);

  const fetchConsumers = useCallback(() => {
    dispatch(fetchConsumersAction());
  }, [dispatch]);

  useEffect(() => {
    if (consumers.items.length === 0) {
      fetchConsumers();
    }
  }, [consumers.items.length, dispatch, fetchConsumers]);

  const createConsumer = async (
    model: ConsumerFormViewModel,
    scopesPresets: ScopesPresetModel[],
  ): Promise<string> => {
    const mappedModel = mapToConsumerModel(model, scopesPresets);
    return await dispatch(createConsumerAction(mappedModel))
      .unwrap()
      .then((consumerId: string) => {
        fetchConsumers();
        return Promise.resolve(consumerId);
      });
  };

  const handleError = () => {
    dispatch(clearConsumersErrorAction());
  };

  const handleSuccessMessage = () => {
    dispatch(clearConsumersSuccessMessageAction());
  };

  return [
    {
      consumers: consumers.items,
      isLoading: consumers.isLoading,
      error: consumers.error,
      successMessage: consumers.successMessage,
    },
    {
      createConsumer,
      handleError,
      handleSuccessMessage,
    },
  ];
};
