import { Auth } from 'aws-amplify';
import React, { SyntheticEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';
import { i18n } from '../../../services/i18n';
import ToastMessage from '../../ToastMessage/ToastMessage';
import FormFieldLabel from '../../../shared/FormFieldLabel/FormFieldLabel';

const ResetPassword: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const history = useHistory();

  const { email } = history.location.state as { email: string };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      history.push('/');
      toast.success(<ToastMessage text={i18n('Authentication_ChangePasswordSuccessfully')} />);
    } catch (error) {
      setAlertMessage((error as Error)?.message);
    }
  };

  return (
    <Form
      onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
        void handleSubmit(event);
      }}
      className="form form-changePassword"
    >
      <h2>{i18n('Authentication_PasswordReset')}</h2>
      <div>{i18n('Authentication_ResetPasswordInfo')}</div>
      <Form.Field>
        <FormFieldLabel required>{i18n('Authentication_Code')}</FormFieldLabel>
        <input
          value={code}
          onChange={(e) => setCode(e.target.value)}
          onPaste={(e) => {
            e.preventDefault();
            setCode(e.clipboardData.getData('text').trim());
          }}
        />
      </Form.Field>
      <Form.Field>
        <FormFieldLabel required>{i18n('Authentication_NewPassword')}</FormFieldLabel>
        <input value={password} type="password" onChange={(e) => setPassword(e.target.value)} />
      </Form.Field>
      {alertMessage && <div className="error-message">{alertMessage}</div>}

      <Button primary type="submit" size="medium" fluid>
        {i18n('Authentication_ChangePassword')}
      </Button>

      <div className="center-content">
        <Link to="/" className="link-text">
          {i18n('Authentication_BackToLogin')}
        </Link>
      </div>
    </Form>
  );
};

export default ResetPassword;
