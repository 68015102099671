import React from 'react';
import { useHistory } from 'react-router-dom';
import { i18n } from '../../services/i18n';

interface Props {
  onLogout: () => void;
}

const LogoutPage: React.FC<Props> = ({ onLogout }) => {
  const history = useHistory();
  React.useEffect(() => {
    onLogout();
    history.push('/');
  });

  return <div>{i18n('LogoutPageMessage')}</div>;
};

export default LogoutPage;
