import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { Button, Dimmer, Divider, Grid, Loader } from 'semantic-ui-react';
import { Location } from 'history';

import { getDisabledEnabledOptions } from '../../helpers/OptionHelper';
import { useModals } from '../../hooks/Alert/useModals';
import { useValidationUtils } from '../../hooks/Form/useValidationUtils';
import { mapToConsumerFormViewModel } from '../../mappers/ConsumerMapper';
import { ConsumerFormViewModel } from '../../models/Consumer/ConsumerFormViewModel';
import { ConsumerModel } from '../../models/Consumer/ConsumerModel';
import { ScopesPresetModel } from '../../models/Consumer/Scopes/ScopesPresetModel';
import { i18n } from '../../services/i18n';
import { useConsumerTypes } from '../../store/hooks/useConsumerTypes';
import { useTripAccessScopesPresets } from '../../store/hooks/useTripAccessScopesPresets';
import ModalLeave from '../Modal/ModalLeave/ModalLeave';
import ModalSave from '../Modal/ModalSave/ModalSave';
import NamedDatepicker from '../NamedDatepicker';
import NamedDropdown from '../NamedDropdown';
import NamedInput from '../NamedInput';
import NamedRadioGroup from '../NamedRadioGroup';
import '../styles.scss';
import ConsumerApiAccess from './ConsumerApiAccess/ConsumerApiAccess';
import ConsumerEmails from './ConsumerEmails/ConsumerEmails';
import './ConsumerForm.scss';
import { nameRegex } from './ConsumerFormConstants';
import { getConsumerTypesForDropdown } from '../../helpers/ConsumerTypesHelper';
import { useConsumerTypesValidationUtils } from '../../hooks/Form/useConsumerTypesValidationUtils';
import { usePageNavigation } from '../../hooks/Navigation/usePageNavigation';
import { isEqualWithoutCase } from '../../helpers/StringHelper';
import { ConsumerMetadataModel } from '../../models/Consumer/ConsumerMetadataModel';

interface Props {
  model?: ConsumerModel;
  consumers?: ConsumerMetadataModel[];
  onClose: (redirectUrl?: string) => void;
  onSave: (m: ConsumerFormViewModel, scopes: ScopesPresetModel[]) => void;
}

const ConsumerForm: React.FC<Props> = ({ model, consumers, onClose, onSave }) => {
  const [{ tripAccessScopesPresets, isLoading }] = useTripAccessScopesPresets();
  const [{ consumerTypes, isLoadingConsumerTypes }] = useConsumerTypes();
  const [state, setState] = React.useState<ConsumerFormViewModel>(mapToConsumerFormViewModel(model));
  const useFormValue = useForm<ConsumerFormViewModel>();

  const { register, watch, trigger, formState, getValues } = useFormValue;
  const [
    { isLeavePopupOpen, isSavePopupOpen },
    { showOrHideSaveModal, handleRedirect, handleModalLeaveClose, handleFormClose },
  ] = useModals(onClose);

  const [{ isFieldsChanged }, { handleBlur, handleFormSubmit, handleStateChange }] =
    useValidationUtils<ConsumerFormViewModel>({
      state,
      useFormValue,
      setState,
      showOrHideSaveModal,
      relatedFieldsArray: [['activeStartDate', 'activeEndDate']],
    });

  const [{ isUserConsumerTypesDeleted }, { handleConsumerTypesStateChange }] =
    useConsumerTypesValidationUtils({
      consumerTypes,
      handleStateChange,
    });

  const [, { goToUrl }] = usePageNavigation();

  const onCloseHandler = (isSuccess?: boolean) => {
    if (isSuccess) {
      onSave(state, tripAccessScopesPresets);
      if (isUserConsumerTypesDeleted) {
        goToUrl('/consumers');
      }
    } else {
      showOrHideSaveModal(false);
    }
  };

  const activeEndDate = watch('activeEndDate');
  const activeStartDate = watch('activeStartDate');

  useEffect(() => {
    void trigger(['activeStartDate', 'activeEndDate']);
  }, [activeStartDate, activeEndDate, trigger]);

  useEffect(() => {
    register('consumerKey', {
      required: i18n('Form_RequiredMessage'),
      pattern: {
        value: nameRegex,
        message: i18n('ConsumerForm_InvalidKeyMessage'),
      },
      validate: (v: string | undefined) =>
        consumers?.find((d) => isEqualWithoutCase(d.consumerKey, v ?? ''))
          ? i18n('ConsumerForm_ConsumerExistsMessage')
          : undefined,
    });
    register('activeStartDate', {
      required: i18n('Form_RequiredMessage'),
      validate: (startDate) => {
        const endDate = getValues().activeEndDate;
        return endDate && startDate && startDate >= endDate
          ? i18n('ConsumerForm_StartDateLaterMessage')
          : undefined;
      },
    });
    register('activeEndDate', {
      required: i18n('Form_RequiredMessage'),
      validate: (endDate) => {
        const startDate = getValues().activeStartDate;
        return endDate && startDate && startDate >= endDate
          ? i18n('ConsumerForm_EndDateEarlierMessage')
          : undefined;
      },
    });
    register('consumerTypes', {
      required: i18n('Form_RequiredMessage'),
    });
  }, [consumers, getValues, register]);

  return (
    <>
      {(isLoading || isLoadingConsumerTypes) && (
        <Dimmer active inverted>
          <Loader size="massive" className="consumer-form-loader" />
        </Dimmer>
      )}
      <Prompt when={isFieldsChanged} message={(v: Location) => handleRedirect(v)} />
      <div className={clsx('page-form-container', { 'ui segment': !model?.consumerId })}>
        <div className="page-form">
          {!model?.consumerId && (
            <h3 data-testid="consumer-form-title">{i18n('ConsumerForm_Create_Title')}</h3>
          )}
          <div className="page-form-body">
            <NamedInput
              title={i18n('ConsumerForm_ConsumerKeyTitle')}
              value={state?.consumerKey}
              maxLength={100}
              data-testid="consumer-form-email"
              error={formState.errors.consumerKey}
              autoFocus={!model?.consumerId}
              onChange={(v) => void handleStateChange(v, 'consumerKey')}
              onBlur={() => void handleBlur('consumerKey')}
            />
            <Grid className="dropdown-datepicker-range">
              <Grid.Row stretched columns={2}>
                <Grid.Column stretched>
                  <NamedDatepicker
                    title={`${i18n('ConsumerForm_ConsumerStartDateTitle')} (${i18n('TimeHelper_Utc')})`}
                    className="page-form-body-row"
                    value={state.activeStartDate}
                    selectsStart
                    showYearDropdown
                    startDate={state.activeStartDate}
                    endDate={state.activeEndDate}
                    maxDate={state.activeEndDate}
                    onChange={(v) => void handleStateChange(v, 'activeStartDate')}
                    error={formState.errors.activeStartDate}
                    onBlur={() => void handleBlur('activeStartDate')}
                  />
                </Grid.Column>
                <Grid.Column>
                  <NamedDatepicker
                    title={`${i18n('ConsumerForm_ConsumerEndDateTitle')} (${i18n('TimeHelper_Utc')})`}
                    className="page-form-body-row"
                    value={state.activeEndDate}
                    selectsEnd
                    showYearDropdown
                    startDate={state.activeStartDate}
                    endDate={state.activeEndDate}
                    minDate={state.activeStartDate}
                    onChange={(v) => void handleStateChange(v, 'activeEndDate')}
                    error={formState.errors.activeEndDate}
                    onBlur={() => void handleBlur('activeEndDate')}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <NamedRadioGroup
              title={i18n('ConsumerForm_ConsumerStatusTitle')}
              groupName="page-enabled"
              className="page-form-body-row"
              value={state.enabled}
              onChange={(v) => void handleStateChange(v, 'enabled')}
              options={getDisabledEnabledOptions()}
            />

            <NamedDropdown
              title={i18n('ConsumerForm_ConsumerTypesTitle')}
              value={state.consumerTypes}
              data-testid="consumer-form-consumer-types"
              placeholder={i18n('ConsumerForm_ConsumerTypesPlaceholder')}
              required={true}
              multiple
              search
              className="page-form-body-row"
              options={getConsumerTypesForDropdown(consumerTypes, state.consumerTypes)}
              error={formState.errors.consumerTypes}
              onChange={(v) => handleConsumerTypesStateChange(v as string[])}
              onBlur={() => void handleBlur('consumerTypes')}
            />

            <Divider className="consumer-form-body-divider" />
            <ConsumerEmails
              useFormValue={useFormValue}
              emails={state.contacts}
              onChange={(v) => void handleStateChange(v, 'contacts')}
              onBlur={() => void handleBlur('contacts')}
            />
            {!!(formState.errors.contacts && formState.errors.contacts[-1]?.model?.isPrimary) && (
              <p className="error-message consumer-form-error-message">
                {formState.errors.contacts[-1]?.model?.isPrimary?.message}
              </p>
            )}
            <Divider className="consumer-form-body-divider" />
            <ConsumerApiAccess
              value={state.apiAccess}
              isEditing={true}
              onChange={(v) => void handleStateChange(v, 'apiAccess')}
            />
          </div>
        </div>
        <div className="page-form-footer">
          <Button basic color="blue" onClick={() => handleFormClose(isFieldsChanged)}>
            {i18n('UserForm_CancelButtonTitle')}
          </Button>
          <Button primary disabled={!isFieldsChanged} onClick={() => void handleFormSubmit()}>
            {i18n('UserForm_SaveButtonTitle')}
          </Button>
        </div>
      </div>
      <ModalLeave isOpen={isLeavePopupOpen} isEdit={!!model?.consumerId} onClose={handleModalLeaveClose} />
      <ModalSave
        isEdit={!!model?.consumerId}
        isOpen={isSavePopupOpen}
        header={
          model?.consumerId
            ? i18n('ConsumerForm_ModalSaveEdit_Title')
            : i18n('ConsumerForm_ModalSaveCreate_Title')
        }
        onClose={onCloseHandler}
        isUserConsumerTypesDeleted={isUserConsumerTypesDeleted}
      />
    </>
  );
};

export default ConsumerForm;
