export const idParameter = ':id';

export const usersPath = '/users';
export const usersAddPath = `${usersPath}/add`;
export const usersEditPath = `${usersPath}/edit/${idParameter}`;

export const consumersPath = '/consumers';
export const consumerIdParameter = ':consumerId';
export const accountIdParameter = ':accountId';
export const consumersAddPath = `${consumersPath}/add`;
export const consumerPath = `${consumersPath}/${consumerIdParameter}`;

export const consumerAccountsPath = `${consumerPath}/accounts`;
export const consumerAccountsAddPath = `${consumerAccountsPath}/add`;
export const consumerAccountsAddLookupPath = `${consumerAccountsPath}/add-lookup`;
export const consumerAccountsEditPath = `${consumerAccountsPath}/edit/${idParameter}`;
export const consumerCustomerNumbersPath = `${consumerAccountsPath}/${accountIdParameter}/customerNumbers`;
export const consumerCustomerNumbersAddPath = `${consumerCustomerNumbersPath}/add`;
export const consumerCustomerNumbersEditPath = `${consumerCustomerNumbersPath}/edit/${idParameter}`;

export const consumerTripAccessPath = `${consumerPath}/tripAccess`;

export const eventsPath = '/events';

export const searchTripsPath = '/search';
export const searchTripResponsePath = (id: string | undefined = idParameter) => `/search/${id}`;
export const searchTripItineraryResponsePath = (id: string | undefined = idParameter) =>
  `/search/${id}/itinerary`;
export const searchTripRawResponsePath = (id: string | undefined = idParameter) => `/search/${id}/raw`;

export const logoutPath = '/logout';
