import { createAsyncThunk } from '@reduxjs/toolkit';
import { TripAccessService } from '../../services/TripAccessService';
import { fetchTripAccessAdvancedSettingsActionName } from './actionTypes';

const service = TripAccessService;

export const fetchTripAccessAdvancedSettingsAction = createAsyncThunk(
  fetchTripAccessAdvancedSettingsActionName,
  async () => {
    return service.getAdvancedSettings();
  },
);
