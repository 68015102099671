import { Action, createAsyncThunk } from '@reduxjs/toolkit';
import { ConsumerModel } from '../../models/Consumer/ConsumerModel';
import { ConsumerService } from '../../services/ConsumerService';
import { clearConsumerInfoErrorActionName, fetchConsumerInfoActionName } from './actionTypes';

const service = ConsumerService;

export const fetchConsumerInfoAction = createAsyncThunk<ConsumerModel, string>(
  fetchConsumerInfoActionName,
  async (id, thunkApi) => {
    try {
      return await service.getConsumer(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const clearConsumerInfoErrorAction: () => Action = () => {
  return {
    type: clearConsumerInfoErrorActionName,
  };
};
