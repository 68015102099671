import { isEmpty, sortBy } from 'lodash';
import React from 'react';
import { Table, Visibility } from 'semantic-ui-react';
import { SmIdModel } from '../../../../models/Consumer/SmIdModel';
import { i18n } from '../../../../services/i18n';
import NoDataRow from '../../../Table/NoDataRow';
import { AccountModel } from '../../../../models/Consumer/Account/AccountModel';
import AccountTableAccountRow from './AccountTableAccountRow';
import AccountTableCustomerNumberRow from './AccountTableCustomerNumberRow';
import HeaderCellWithTooltip from '../../../Table/HeaderCellWithTooltip';
import { useLazyItemsView } from '../../../../hooks/Table/useLazyItemsView';

interface Props {
  consumerIsInclusive: boolean;
  activeCustomerNumber?: SmIdModel;
  activeItem?: AccountModel;
  accounts: AccountModel[];
  isSearchApplied: boolean;
  onAccountEdit: (m: AccountModel) => void;
  onCustomerNumberAdd: (accountId: string) => void;
  onCustomerNumberEdit: (accountId: string, customerNumberId: string) => void;
  accountIsSelected: (accountId: string) => boolean;
  handleAccountSelected: (accountId: string, isSelected: boolean | undefined) => void;
}

const AccountsTable: React.FC<Props> = ({
  consumerIsInclusive,
  activeItem,
  accounts,
  activeCustomerNumber,
  isSearchApplied,
  onAccountEdit,
  onCustomerNumberAdd,
  onCustomerNumberEdit,
  accountIsSelected,
  handleAccountSelected,
}) => {
  const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
  const getTextToNoDataRow = isSearchApplied ? i18n('Table_No_Result') : i18n('AccountTable_No_Data');
  const [{ itemsVisible: accountsVisible, refTableWrapper }, { handleUpItemsCount }] =
    useLazyItemsView(accounts);

  const handleRowExpand = (account: AccountModel) => {
    if (isEmpty(account.customerNumbers?.ids)) {
      return;
    }

    const newExpandedRows = expandedRows?.includes(account.id)
      ? expandedRows.filter((id) => id !== account.id)
      : expandedRows.concat(account.id);

    setExpandedRows(newExpandedRows);
  };

  return (
    <div ref={refTableWrapper} className="table-sticky-container accounts-content-container">
      <Visibility
        continuous={true}
        context={refTableWrapper as unknown as HTMLElement}
        onBottomVisible={() => {
          handleUpItemsCount(accounts?.length);
        }}
      >
        <Table
          className="data-table accordion-table accounts-table"
          singleLine
          selectable
          data-testid="accounts-table"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell className="small-header-cell-buttons"></Table.HeaderCell>
              <Table.HeaderCell className="column-account-name">
                {i18n('AccountTable_Header_AccountName')}
              </Table.HeaderCell>
              <Table.HeaderCell className="column-account-id">
                {i18n('AccountTable_Header_AccountId')}
              </Table.HeaderCell>
              <HeaderCellWithTooltip
                className="col-date"
                title={i18n('Table_Header_StartDate')}
                popup={i18n('Table_Header_DateTimeTooltip')}
              />
              <HeaderCellWithTooltip
                className="col-date"
                title={i18n('Table_Header_EndDate')}
                popup={i18n('Table_Header_DateTimeTooltip')}
              />
              <Table.HeaderCell className="column-status">
                {i18n('AccountTable_Header_Status')}
              </Table.HeaderCell>
              <Table.HeaderCell className="column-customer-logic">
                {i18n('AccountTable_Header_CustomerLogic')}
              </Table.HeaderCell>
              <Table.HeaderCell className="column-customer-id">
                {i18n('AccountTable_Header_CustomerId')}
              </Table.HeaderCell>
              <Table.HeaderCell className="small-header-cell-buttons"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body data-testid="accounts-table-body">
            {!isEmpty(accountsVisible) ? (
              accountsVisible?.map((account, index) => {
                // used index as key because app can get items with identical id`is, this case returns react works incorrect and user will see clones of items.
                // prev solution was - key={`row-data-${account.id}`}
                return (
                  <React.Fragment key={`row-data-${index}`}>
                    <AccountTableAccountRow
                      consumerIsInclusive={consumerIsInclusive}
                      activeItem={activeItem}
                      rowId={account.id}
                      account={account}
                      expandedRows={expandedRows}
                      onRowClickToEdit={onAccountEdit}
                      onRowExpand={handleRowExpand}
                      onCustomerNumberAdd={(id) => onCustomerNumberAdd(id)}
                      accountIsSelected={accountIsSelected}
                      handleAccountSelected={handleAccountSelected}
                    />
                    {expandedRows.includes(account.id) && (
                      <>
                        <Table.Row className="expandable-row-header" data-testid="customer-id-row">
                          <Table.Cell data-testid="accounts-table-customer-header-empty-cell-checkbox"></Table.Cell>
                          <Table.Cell data-testid="accounts-table-customer-header-empty-cell-dropdown"></Table.Cell>
                          <Table.Cell className="small-header-cell-buttons" colSpan={8}>
                            {i18n('AccountTable_Header_CustomerId')}
                          </Table.Cell>
                        </Table.Row>
                        {sortBy(account.customerNumbers?.ids, (id) => id.id).map((customer) => (
                          <AccountTableCustomerNumberRow
                            key={`subrow-data-${account.id}-${customer.id}`}
                            active={activeCustomerNumber === customer}
                            customer={customer}
                            accountId={account.id}
                            onCustomerNumberEdit={() => onCustomerNumberEdit(account.id, customer.id)}
                          />
                        ))}
                      </>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <NoDataRow colSpan={9} text={getTextToNoDataRow} />
            )}
          </Table.Body>
        </Table>
      </Visibility>
    </div>
  );
};

export default AccountsTable;
