import React from 'react';
import { useParams } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useItineraryTripQuery } from '../../../queries/hooks/useItineraryTripQuery';
import CodeContent from '../CodeContent/CodeContent';
import NotFoundMessage from './NotFoundMessage';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { i18n } from '../../../services/i18n';

const ItineraryModalContent: React.FC = () => {
  const { id: paramsTripId }: { id: string } = useParams();
  const { trip, isLoading, error } = useItineraryTripQuery(paramsTripId);
  const isNotFound = (error?.response?.status && error.response.status === 404) || false;
  const spacesToIndent = 4;

  return (
    <>
      {isLoading && <ProgressSpinner animationDuration="0.7s" />}
      <ScrollPanel className="trip-modal-content-container">
        {trip && <CodeContent data={JSON.stringify(trip, undefined, spacesToIndent)} />}
        {!isLoading && isNotFound && (
          <NotFoundMessage
            dataTestid="itinerary-modal-not-found-message"
            message={i18n('SearchTrips_Modal_NotFound_Itinerary_Message')}
          />
        )}
        {!isLoading && error && !isNotFound && <ErrorMessage dataTestid="itinerary-modal-error-message" />}
      </ScrollPanel>
    </>
  );
};

export default ItineraryModalContent;
