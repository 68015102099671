import { LabelKeyObject } from 'react-csv/lib/core';
import { mapToAccountExportModels, mapToConsumerExportModels } from '../mappers/ConsumerExportMapper';
import { AccountsModel } from '../models/Consumer/Account/AccountsModel';
import { ConsumerMetadataModel } from '../models/Consumer/ConsumerMetadataModel';
import { ConsumerModel } from '../models/Consumer/ConsumerModel';
import { AccountExportModel } from '../models/Export/AccountExportModel';
import { ConsumerExportModel } from '../models/Export/ConsumerExportModel';
import { i18n } from './i18n';
import { getCurrentISODate } from '../helpers/TimeHelper';

export class ExportService {
  public static getConsumersFilename(): string {
    return `BCD_Consumers_${getCurrentISODate()}.csv`;
  }

  public static getAccountsFilename(consumerName: string): string {
    return `${consumerName}_Accounts_${getCurrentISODate()}.csv`;
  }

  public static getConsumersExportHeaders(): LabelKeyObject[] {
    return [
      { label: i18n('ConsumerTable_Header_ConsumerName'), key: 'consumerKey' },
      { label: i18n('ConsumerTable_Header_ConsumerId'), key: 'consumerId' },
      { label: i18n('ConsumerTable_Header_ConsumerOAuthId'), key: 'oauthClientId' },

      { label: i18n('Table_Header_StartDate'), key: 'activeStartDate' },
      { label: i18n('Table_Header_EndDate'), key: 'activeEndDate' },
      { label: i18n('ConsumerTable_Header_Status'), key: 'status' },
      { label: i18n('ConsumerTable_Header_ApiAccess'), key: 'apiAccess' },

      { label: i18n('ConsumerStaticInfo_CreatedAtTitle'), key: 'createdAt' },
      { label: i18n('ConsumerStaticInfo_CreatedByTitle'), key: 'createdBy' },
      { label: i18n('ConsumerStaticInfo_LastUpdatedAtTitle'), key: 'lastUpdatedAt' },
      { label: i18n('ConsumerStaticInfo_LastUpdatedByTitle'), key: 'lastUpdatedBy' },
    ];
  }

  public static getAccountsExportHeaders(): LabelKeyObject[] {
    return [
      { label: i18n('ConsumerTable_Header_ConsumerName'), key: 'consumer.consumerKey' },
      { label: i18n('ConsumerTable_Header_ConsumerId'), key: 'consumer.consumerId' },
      { label: i18n('ConsumerTable_Header_ConsumerOAuthId'), key: 'consumer.oauthClientId' },
      { label: i18n('ConsumerAccounts_AccountsLabel'), key: 'consumer.isInclusiveAccountsLogic' },

      { label: i18n('AccountTable_Header_AccountId'), key: 'account.id' },
      { label: i18n('AccountTable_Header_AccountName'), key: 'account.accountName' },
      { label: i18n('Table_Header_StartDate'), key: 'account.startDate' },
      { label: i18n('Table_Header_EndDate'), key: 'account.endDate' },
      { label: i18n('ConsumerTable_Header_Status'), key: 'account.status' },
      { label: i18n('AccountTable_Header_CustomerLogic'), key: 'account.isInclusiveCustomersLogic' },

      { label: i18n('AccountTable_Header_CustomerId'), key: 'customerNumber.id' },
      { label: i18n('Table_Header_StartDate'), key: 'customerNumber.startDate' },
      { label: i18n('Table_Header_EndDate'), key: 'customerNumber.endDate' },
      { label: i18n('AccountTable_Header_Status'), key: 'customerNumber.status' },
    ];
  }

  public static getExportedConsumers(consumers?: ConsumerMetadataModel[]): ConsumerExportModel[] {
    return mapToConsumerExportModels(consumers);
  }

  public static getExportedAccounts(
    consumer: ConsumerModel,
    accountsData?: AccountsModel,
  ): AccountExportModel[] {
    return mapToAccountExportModels(consumer, accountsData);
  }
}
