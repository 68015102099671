import React from 'react';
import { Location as HistoryLocation } from 'history';
import { useLocation } from 'react-router-dom';

export const useModals = (
  onFormClose: (redirectUrl?: string) => void,
  onShowHideInclusiveModal?: (isVisible: boolean) => void,
): [
  { isLeavePopupOpen: boolean; isSavePopupOpen: boolean },
  {
    showOrHideSaveModal: (isVisible?: boolean) => void;
    handleRedirect: (location: HistoryLocation) => boolean;
    handleModalLeaveClose: (isLeft?: boolean, needProcessInclusiveModal?: boolean) => void;
    handleFormClose: (isFieldsChanged: boolean) => void;
    handleModalsOnFieldsChange: (isFieldsChanged: boolean, needProcessInclusiveModal?: boolean) => void;
  },
] => {
  const [isLeavePopupOpen, setIsLeavePopupOpen] = React.useState(false);
  const [isSavePopupOpen, setIsSavePopupOpen] = React.useState(false);
  const [redirectUrlToLeave, setRedirectUrlToLeave] = React.useState('');
  const location = useLocation();

  const showOrHideSaveModal = (isVisible?: boolean) => {
    setIsSavePopupOpen(isVisible ?? false);
  };

  const handleRedirect = (newLocation: HistoryLocation) => {
    if (newLocation.pathname === location.pathname) {
      return true;
    }

    const canBeRedirected = isSavePopupOpen || isLeavePopupOpen;
    setIsLeavePopupOpen(!canBeRedirected);
    !canBeRedirected && setRedirectUrlToLeave(newLocation.pathname);
    return canBeRedirected;
  };

  const handleModalsOnFieldsChange = (isFieldsChanged: boolean, needProcessInclusiveModal?: boolean) => {
    if (needProcessInclusiveModal) {
      isFieldsChanged
        ? setIsLeavePopupOpen(true)
        : onShowHideInclusiveModal && onShowHideInclusiveModal(true);
    }
  };

  const handleModalLeaveClose = (isLeft?: boolean, needProcessInclusiveModal?: boolean) => {
    if (!needProcessInclusiveModal) {
      if (isLeft) {
        onFormClose(redirectUrlToLeave);
      } else {
        setRedirectUrlToLeave('');
        setIsLeavePopupOpen(false);
      }
    } else {
      isLeft ? handleFormClose(false) : setIsLeavePopupOpen(false);
      onShowHideInclusiveModal && onShowHideInclusiveModal(isLeft ?? false);
    }
  };

  const handleFormClose = (isFieldsChanged: boolean) => {
    setRedirectUrlToLeave('');
    if (isFieldsChanged) {
      setIsLeavePopupOpen(true);
    } else {
      onFormClose();
    }
  };

  return [
    { isLeavePopupOpen, isSavePopupOpen },
    {
      showOrHideSaveModal,
      handleRedirect,
      handleModalLeaveClose,
      handleFormClose,
      handleModalsOnFieldsChange,
    },
  ];
};
