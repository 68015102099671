import React from 'react';
import { useParams } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useRawTripQuery } from '../../../queries/hooks/useRawTripQuery';
import CodeContent from '../CodeContent/CodeContent';
import NotFoundMessage from './NotFoundMessage';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { i18n } from '../../../services/i18n';
import { RawTripResponseDTO } from '../../../models/Trip/TripModel';
import { formatXml } from '../../../helpers/XmlHelper';

const RawModalContent: React.FC = () => {
  const { id: paramsTripId }: { id: string } = useParams();
  const { trip, isLoading, error } = useRawTripQuery(paramsTripId);
  const isNotFound = (error?.response?.status && error.response.status === 404) || false;
  const spacesToIndent = 4;

  const formatRawData = (tripData: RawTripResponseDTO): string => {
    let rawDataString = '';
    tripData?.forEach((rawDataObject) => {
      const rawDataItemString = rawDataObject.data;
      if (rawDataItemString && rawDataItemString.length > 0) {
        let rawDataItemFormatted = '';
        if (rawDataItemString.startsWith('{')) {
          const jsonObject = JSON.parse(rawDataItemString);
          rawDataItemFormatted = JSON.stringify(jsonObject, undefined, spacesToIndent);
        } else {
          rawDataItemFormatted = formatXml(rawDataItemString);
        }
        rawDataString += `${rawDataItemFormatted}\n`;
      }
    });
    return rawDataString;
  };

  return (
    <>
      {isLoading && <ProgressSpinner animationDuration="0.7s" />}
      <ScrollPanel className="trip-modal-content-container">
        {trip && <CodeContent data={formatRawData(trip)} />}
        {!isLoading && isNotFound && (
          <NotFoundMessage
            dataTestid="raw-modal-not-found-message"
            message={i18n('SearchTrips_Modal_NotFound_Raw_Message')}
          />
        )}
        {!isLoading && error && !isNotFound && <ErrorMessage dataTestid="raw-modal-error-message" />}
      </ScrollPanel>
    </>
  );
};

export default RawModalContent;
