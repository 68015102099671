import { isObject, isString } from 'lodash';
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface Props {
  isOpen: boolean;
  header: string | React.ReactNode;
  content: string | React.ReactNode;
  cancelButtonText: string;
  okButtonText: string;
  okButtonDisabled?: boolean;
  onClose: (isSuccess?: boolean) => void;
}

const ModalBase: React.FC<Props> = ({
  isOpen,
  header,
  content,
  okButtonText,
  okButtonDisabled,
  cancelButtonText,
  onClose,
}) => {
  return (
    <Modal open={isOpen} size="small" className="form-dialog" data-testid="form-dialog">
      <Modal.Header>
        {isObject(header) && header}
        {isString(header) && <h3>{header}</h3>}
      </Modal.Header>
      <Modal.Content>
        {isObject(content) && content}
        {isString(content) && <p data-testid="form-dialog-content">{content}</p>}
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="form-button"
          basic
          color="blue"
          onClick={() => onClose()}
          data-testid="form-dialog-cancel-control"
        >
          {cancelButtonText}
        </Button>
        <Button
          className="form-button"
          primary
          disabled={okButtonDisabled}
          onClick={() => onClose(true)}
          data-testid="form-dialog-submit-control"
        >
          {okButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalBase;
