import { useCallback } from 'react';
import { ApiError } from '../../../models/Api/ApiError';
import { EventsFormViewModel } from '../../../models/Events/EventsFormViewModel';
import { TripModel } from '../../../models/Trip/TripModel';
import { fetchEventsTripsAction, fetchSearchTripsAction, resetTripsAction } from '../../actions/tripsActions';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { SearchTripsFormViewModel } from '../../../models/SearchTrips/SearchTripsFormViewModel';

export interface UseTripsValuesInterface {
  trips: TripModel[];
  isLoading: boolean;
  error?: ApiError;
  successMessage?: string;
}
export interface UseTripsCallbacksInterface {
  getEventsTrips: (payload: EventsFormViewModel) => void;
  getSearchTrips: (payload: SearchTripsFormViewModel) => void;
  handleResetTrips: () => void;
}

export const useTrips: () => [UseTripsValuesInterface, UseTripsCallbacksInterface] = () => {
  const dispatch = useAppDispatch();
  const tripsState = useAppSelector((s) => s.trips);

  const getEventsTrips = useCallback(
    (payload: EventsFormViewModel) => {
      void dispatch(fetchEventsTripsAction(payload));
    },
    [dispatch],
  );

  const getSearchTrips = useCallback(
    (payload: SearchTripsFormViewModel) => {
      void dispatch(fetchSearchTripsAction(payload));
    },
    [dispatch],
  );

  const handleResetTrips = useCallback(() => {
    dispatch(resetTripsAction());
  }, [dispatch]);

  return [
    {
      trips: tripsState.items,
      isLoading: tripsState.isLoading,
      error: tripsState.error,
      successMessage: tripsState.successMessage,
    },
    {
      getEventsTrips,
      getSearchTrips,
      handleResetTrips,
    },
  ];
};
