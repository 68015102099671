import AccountLookupTable from './AccountLookupTable/AccountLookupTable';
import AccountsTable from './AccountsTable/AccountsTable';
import React from 'react';
import {
  accountIdParameter,
  consumerAccountsAddLookupPath,
  consumerAccountsEditPath,
  consumerAccountsPath,
  consumerCustomerNumbersAddPath,
  consumerCustomerNumbersEditPath,
} from '../../../paths';
import { AccountModel } from '../../../models/Consumer/Account/AccountModel';
import { AccountsModel } from '../../../models/Consumer/Account/AccountsModel';
import { consumerIdParameter, idParameter } from './../../../paths';
import { ConsumerModel } from '../../../models/Consumer/ConsumerModel';
import { Grid } from 'semantic-ui-react';
import { Route, Switch } from 'react-router-dom';
import { SmIdModel } from '../../../models/Consumer/SmIdModel';
import { useBreakpoint, useColumnBreakpoint } from '../../../hooks/BreakPointProvider/breakpoint';
import { usePageNavigation } from '../../../hooks/Navigation/usePageNavigation';
import './styles.scss';

interface Props {
  consumer: ConsumerModel;
  activeAccount?: AccountModel;
  activeCustomerNumber?: SmIdModel;
  accountsData?: AccountsModel;
  accountIsSelected: (accountId: string) => boolean;
  handleAccountSelected: (accountId: string, isSelected: boolean | undefined) => void;
}

const AccountsTableSwitcher: React.FC<Props> = ({
  consumer,
  activeAccount,
  activeCustomerNumber,
  accountsData,
  accountIsSelected,
  handleAccountSelected,
}) => {
  const breakpoints = useBreakpoint();
  const [{ getTableColumnsCount }] = useColumnBreakpoint();
  const [{ isFormOpen, searchTerm }, { goToUrl }] = usePageNavigation();

  const consumerId = consumer.consumerId ?? '';

  const handleCustomerNumberAdd = (accountId: string): void => {
    goToUrl(
      consumerCustomerNumbersAddPath
        .replace(consumerIdParameter, consumerId)
        .replace(accountIdParameter, accountId),
    );
  };

  const handleCustomerNumberEdit = (accountId: string, customerNumberId: string): void => {
    goToUrl(
      consumerCustomerNumbersEditPath
        .replace(consumerIdParameter, consumerId)
        .replace(accountIdParameter, accountId)
        .replace(idParameter, customerNumberId),
    );
  };

  const handleAccountEdit = (m: AccountModel): void => {
    m.id
      ? goToUrl(consumerAccountsEditPath.replace(consumerIdParameter, consumerId).replace(idParameter, m.id))
      : goToUrl(consumerAccountsPath.replace(consumerIdParameter, consumerId));
  };

  return (
    <>
      {(!breakpoints.sm || !isFormOpen) && (
        <Grid.Column width={getTableColumnsCount(isFormOpen)}>
          <Switch>
            <Route path={consumerAccountsAddLookupPath} exact>
              <AccountLookupTable accountsIds={accountsData?.ids.map((id) => id.id)} />
            </Route>
            <Route>
              <AccountsTable
                activeItem={activeAccount}
                consumerIsInclusive={consumer.trips?.smIds?.isInclusive ?? true}
                accounts={accountsData?.ids ?? []}
                activeCustomerNumber={activeCustomerNumber}
                isSearchApplied={!!searchTerm}
                onAccountEdit={handleAccountEdit}
                onCustomerNumberAdd={handleCustomerNumberAdd}
                onCustomerNumberEdit={handleCustomerNumberEdit}
                accountIsSelected={accountIsSelected}
                handleAccountSelected={handleAccountSelected}
              />
            </Route>
          </Switch>
        </Grid.Column>
      )}
    </>
  );
};

export default AccountsTableSwitcher;
