import { Role } from '../../../enums/UserRole';
import { UserRolesType } from '../../../helpers/PermissionsHelper';
import { i18n } from '../../../services/i18n';
import { PopupOptionsType } from '../../ConsumerForm/helpers';

export const isAdvancedSettingsPopup = (userRole: UserRolesType): boolean =>
  userRole[Role.Admin] || userRole[Role.Consumers];

export const getTripAccessOptionsRules = (userRole: UserRolesType): PopupOptionsType => ({
  '2': {
    reason: isAdvancedSettingsPopup(userRole),
    message: i18n('TripAccessAdvancedNotification'),
  },
});
