import React from 'react';
import { Header, Icon, Segment, Table } from 'semantic-ui-react';

import './styles.scss';

interface Props {
  colSpan: number;
  text: string;
}

const NoDataRow: React.FC<Props> = (props) => {
  return (
    <Table.Row className="no-data-row" data-testid="no-data-row-message">
      <Table.Cell colSpan={props.colSpan} textAlign="center">
        <Segment vertical>
          <Header icon>
            <Icon name="archive" />
            <div className="text">{props.text}</div>
          </Header>
        </Segment>
      </Table.Cell>
    </Table.Row>
  );
};

export default NoDataRow;
