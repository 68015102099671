import DropdownMenu from '../../features/DropdownMenu/DropdownMenu';
import ExportButton from '../../features/ExportButton/ExportButton';
import React, { useRef, useState } from 'react';
import { ConsumerExportModel } from '../../models/Export/ConsumerExportModel';
import { ConsumerMetadataModel } from '../../models/Consumer/ConsumerMetadataModel';
import { ExportService } from '../../services/ExportService';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import './styles.scss';

interface Props {
  consumers: ConsumerMetadataModel[];
}

const ConsumersActionsMenu: React.FC<Props> = ({ consumers }) => {
  const menu = useRef<Menu>(null);
  const [exportedConsumers, setExportedConsumers] = useState<ConsumerExportModel[]>([]);
  const exportService = ExportService;

  const handleConsumersExport = () => {
    const csvData = exportService.getExportedConsumers(consumers);
    setExportedConsumers(csvData);
  };

  const menuItems: MenuItem[] = [
    {
      items: [
        {
          template: (
            <ExportButton
              headers={exportService.getConsumersExportHeaders()}
              data={exportedConsumers}
              filename={exportService.getConsumersFilename()}
              menu={menu}
              onExport={handleConsumersExport}
            />
          ),
        },
      ],
    },
  ];

  return <DropdownMenu menu={menu} menuItems={menuItems} />;
};

export default ConsumersActionsMenu;
