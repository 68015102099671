import { isEqual } from 'lodash';

import { cleanupTrueValues } from '../helpers/ObjectHelper';
import { ScopesPresetModel } from '../models/Consumer/Scopes/ScopesPresetModel';
import { TripScopesModel } from '../models/Consumer/Scopes/TripScopesModel';
import { DataSourceFilterModel, TripDataSourceFilterModel } from '../models/Consumer/DataSourceFilterModel';

export const mapDataSourceFiltersModel = (data: TripDataSourceFilterModel[]): DataSourceFilterModel[] =>
  data.reduce<DataSourceFilterModel[]>((previousValue, currentValue) => {
    const typeIndex = previousValue.findIndex((item) => item.dataSource === currentValue.dataType);
    if (typeIndex < 0) {
      previousValue.push({
        dataSource: currentValue.dataType,
        dataProviders: (currentValue.dataProvider && [currentValue.dataProvider]) || [],
      } as DataSourceFilterModel);
    } else {
      currentValue.dataProvider && previousValue[typeIndex].dataProviders.push(currentValue.dataProvider);
    }

    return previousValue;
  }, []);

export const mapTripDataSourceFilterModel = (data: DataSourceFilterModel[]): TripDataSourceFilterModel[] =>
  data.flatMap((currentValue) =>
    currentValue.dataProviders.length === 0
      ? [{ dataType: currentValue.dataSource }]
      : currentValue.dataProviders.map((provider) => ({
          dataType: currentValue.dataSource,
          dataProvider: provider,
        })),
  );

export const getSelectedPreset: (
  scopesPreset: ScopesPresetModel[],
  tripPreset: { preset?: TripScopesModel; hasAccessToPii: boolean },
) => ScopesPresetModel | undefined = (scopesPreset, { preset, hasAccessToPii }) => {
  return scopesPreset?.find(
    (presetItem) =>
      isEqual(cleanupTrueValues(presetItem.preset), cleanupTrueValues(preset)) &&
      hasAccessToPii === presetItem.hasAccessToPii,
  );
};
