import clsx from 'clsx';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import React, { forwardRef } from 'react';

import './styles.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  value?: string;
  tooltip?: string;
  'data-testid'?: string;
}

const NamedInputPrime = forwardRef<HTMLInputElement, Props>(
  ({ title, tooltip, className, ...inputProps }, ref) => {
    return (
      <div className={clsx(className, 'named-input-prime')}>
        {tooltip && <Tooltip target=".input-title-label" />}
        <label className="input-title-label" data-pr-tooltip={tooltip} data-pr-position="top">
          {title}
          {tooltip && <i className="pi pi-info-circle tooltip-target-icon"></i>}
        </label>
        <InputText ref={ref} {...inputProps} />
      </div>
    );
  },
);

NamedInputPrime.displayName = 'NamedInputPrime';

export default NamedInputPrime;
