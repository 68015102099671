import { sortBy } from 'lodash';
import { getDataFromApi, postDataToApi, putDataToApi } from '../helpers/ApiHelper';
import { UserModel } from '../models/User/UserModel';
import { ClaimModel } from './../models/User/ClaimModel';
import { getFilterCondition, getSearchFilter } from './serviceUtils';

export class UserService {
  private static readonly endpointUsers = '/users';
  private static readonly endpointClaims = '/claims';
  private static readonly endpointUserEmailAddress = '/users/checkEmail';

  public static async getUsers(): Promise<UserModel[]> {
    const users = await getDataFromApi<UserModel[]>(this.endpointUsers);
    return this.sortUsers(users);
  }

  public static async getClaims(): Promise<ClaimModel[]> {
    return getDataFromApi<ClaimModel[]>(this.endpointClaims);
  }

  public static async insertUser(model: UserModel): Promise<void> {
    return postDataToApi<UserModel, void>(this.endpointUsers, model);
  }

  public static async updateUser(model: UserModel): Promise<void> {
    return putDataToApi<UserModel, void>(this.endpointUsers, model);
  }

  public static async isEmailExists(email: string): Promise<boolean> {
    return postDataToApi<string, boolean>(this.endpointUserEmailAddress, email);
  }

  public static searchUsers(users?: UserModel[], searchText?: string): UserModel[] {
    const searchStringToFilter = getSearchFilter(searchText);
    return (
      users?.filter(
        (user) =>
          getFilterCondition(searchStringToFilter, user.firstName) ||
          getFilterCondition(searchStringToFilter, user.lastName) ||
          getFilterCondition(searchStringToFilter, user.email),
      ) ?? []
    );
  }

  private static sortUsers(users: UserModel[]): UserModel[] {
    return sortBy(users, (x) => x.email?.toLowerCase());
  }
}
