import clsx from 'clsx';
import React from 'react';
import { Button, Grid, Label } from 'semantic-ui-react';
import { ApiAccess } from '../../../enums/ApiAccess';
import { Permission } from '../../../enums/Permission';
import { formatUTCDateTimeWithUtcTextEnding } from '../../../helpers/TimeHelper';
import { mapApiAccessList } from '../../../mappers/ConsumerMapper';
import { i18n } from '../../../services/i18n';
import ConsumerApiAccess from '../../ConsumerForm/ConsumerApiAccess/ConsumerApiAccess';
import LabelGroup from '../../LabelGroup/LabelGroup';
import NamedLabel from '../../NamedLabel/NamedLabel';
import CanUser from '../../Permissions/CanUser';
import BooleanLabelCell from '../../Table/BooleanLabelCell/BooleanLabelCell';
import { ConsumerModel } from './../../../models/Consumer/ConsumerModel';
import './styles.scss';

interface Props {
  consumer: ConsumerModel;
  onEdit: () => void;
}

const ConsumerMainInfo: React.FC<Props> = ({ consumer, onEdit }) => {
  const apiAccess: ApiAccess[] = React.useMemo(() => {
    let access: ApiAccess[] = [];
    if (consumer) {
      access = mapApiAccessList(consumer);
    }
    return access;
  }, [consumer]);

  return (
    <div className="consumer-main-info-container">
      <Grid className="consumer-main-info-grid-row">
        <Grid.Row className="consumer-main-info-grid-row" columns={2} padded="vertically">
          <Grid.Column>
            <NamedLabel
              title={i18n('ConsumerMainInfo_ConsumerNameTitle')}
              value={consumer.consumerKey}
              data-testid="consumer-info-consumer-key"
            />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <CanUser permission={Permission.ConsumersUpdate}>
              <Button primary color="blue" onClick={onEdit} data-testid="consumer-info-edit-button">
                {i18n('ConsumerMainInfo_EditButtonTitle')}
              </Button>
            </CanUser>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="start-end-dates-row" columns={2} padded="vertically">
          <Grid.Column>
            <NamedLabel
              title={i18n('ConsumerMainInfo_StartDateTitle')}
              value={formatUTCDateTimeWithUtcTextEnding(consumer.activeStartDate)}
              data-testid="consumer-info-start-date"
            />
          </Grid.Column>
          <Grid.Column>
            <NamedLabel
              title={i18n('ConsumerMainInfo_EndDateTitle')}
              value={formatUTCDateTimeWithUtcTextEnding(consumer.activeEndDate)}
              data-testid="consumer-info-end-date"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="named-label consumer-main-info-row">
        <div className="named-label-title">{i18n('ConsumerMainInfo_StatusTitle')}</div>
        <div className="named-label-value">
          <BooleanLabelCell
            data-testid="consumer-info-status"
            value={consumer.enabled}
            textTrue={i18n('EnabledStatusName')}
            textFalse={i18n('DisabledStatusName')}
          />
        </div>
      </div>
      <div className="named-label consumer-main-info-row">
        <div className="named-label-title">{i18n('ConsumerMainInfo_ConsumerTypesTitle')}</div>
        <div className="named-label-value">
          <LabelGroup
            data-testid="consumer-types"
            names={consumer.consumerTypes}
            noDataMessage={i18n('ConsumerMainInfo_ConsumerTypesNotSetMessage')}
          />
        </div>
      </div>
      <div className="consumer-contacts-row">
        <h4 className="consumer-contacts-title">{i18n('ConsumerMainInfo_ContactsTitle')}</h4>
        <h5 className="consumer-emails-title">{i18n('ConsumerMainInfo_EmailTitle')}</h5>
        {[...(consumer.contacts ?? [])] // Prevent mutating state in redux
          ?.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary))
          .map((c) => {
            return (
              <div
                key={c.email}
                className={clsx('consumer-contacts-email-entry', {
                  'consumer-contacts-email-main': c.isPrimary,
                })}
                data-testid="consumer-info-email-block"
              >
                {c.email} {c.isPrimary && <Label>{i18n('ConsumerMainInfo_PrimaryTitle')}</Label>}
              </div>
            );
          })}
        {(!consumer.contacts || !consumer.contacts.length) && (
          <div>{i18n('ConsumerMainInfo_NoEmailsTitle')}</div>
        )}
      </div>
      {consumer && apiAccess && <ConsumerApiAccess value={apiAccess} isEditing={false} />}
    </div>
  );
};

export default ConsumerMainInfo;
