import { isEqualWithoutCase } from '../../../../helpers/StringHelper';
import { DataSourceFilterModel } from '../../../../models/Consumer/DataSourceFilterModel';
import { TripAccessPresetEntryType } from '../../../../models/TripAccess/TripAccessPresetEntry';

export const sortTripAccessPreset = (data: TripAccessPresetEntryType[]): TripAccessPresetEntryType[] =>
  data.slice().sort((a, b) => {
    const isABoolean = typeof a[1] === 'boolean';
    const isBBoolean = typeof b[1] === 'boolean';

    if (isABoolean && !isBBoolean) return -1;
    else if (isABoolean && isBBoolean) return a[0].localeCompare(b[0]);
    else if (!isABoolean && !isBBoolean) return a[0].localeCompare(b[0]);
    else return 0;
  });

export const getChosenDataSourceFilters = (
  dataSourceFilters: DataSourceFilterModel[],
  values: DataSourceFilterModel[],
): DataSourceFilterModel[] => {
  return dataSourceFilters.reduce<DataSourceFilterModel[]>((previousValue, currentValue) => {
    const value = values.find((v) => isEqualWithoutCase(v.dataSource, currentValue.dataSource));
    if (value) {
      const lowerCaseProviders = value.dataProviders.map((v) => v.toLocaleLowerCase());

      return [
        ...previousValue,
        {
          dataSource: currentValue.dataSource,
          dataProviders: currentValue.dataProviders.filter((v) =>
            lowerCaseProviders.includes(v.toLocaleLowerCase()),
          ),
        },
      ];
    } else return previousValue;
  }, []);
};
