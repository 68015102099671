import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Prompt, useParams } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { Location } from 'history';

import { getDisabledEnabledOptions } from '../../../../helpers/OptionHelper';
import { useModals } from '../../../../hooks/Alert/useModals';
import { useAutoFocus } from '../../../../hooks/Form/useAutoFocus';
import { useValidationUtils } from '../../../../hooks/Form/useValidationUtils';
import { mapToCustomerNumberViewModel } from '../../../../mappers/ConsumerAccountMapper';
import { CustomerNumbersFormViewModel } from '../../../../models/Consumer/Account/CustomerNumbersFormViewModel';
import { AccountModel } from '../../../../models/Consumer/Account/AccountModel';
import { SmIdModel } from '../../../../models/Consumer/SmIdModel';
import { i18n } from '../../../../services/i18n';
import ModalLeave from '../../../Modal/ModalLeave/ModalLeave';
import ModalSave from '../../../Modal/ModalSave/ModalSave';
import NamedDatepicker from '../../../NamedDatepicker';
import NamedInput from '../../../NamedInput';
import NamedRadioGroup from '../../../NamedRadioGroup';
import { customerNumberIdRegex } from './CustomerNumbersFormConstants';
import './styles.scss';

interface Props {
  model?: SmIdModel;
  existingAccounts: AccountModel[];
  isInclusiveChangeStarted: boolean;
  onClose: (redirectUrl?: string) => void;
  onSave: (accountId: string, m: CustomerNumbersFormViewModel) => void;
  onShowHideInclusiveModal: (isVisible: boolean) => void;
}

const CustomerNumbersForm: React.FC<Props> = ({
  existingAccounts,
  model,
  isInclusiveChangeStarted,
  onClose,
  onSave,
  onShowHideInclusiveModal,
}) => {
  const params: { accountId: string } = useParams();
  const [existingCustomerNumbers, isAccountInclusive] = useMemo(() => {
    const account = existingAccounts.find((acc) => acc.id === params.accountId);
    return [account?.customerNumbers?.ids || [], account?.isInclusive];
  }, [existingAccounts, params]);
  const [state, setState] = useState<CustomerNumbersFormViewModel>({
    enabled: false,
  });
  const [{ inputFocusRef }, { focus }] = useAutoFocus();

  const [
    { isLeavePopupOpen, isSavePopupOpen },
    {
      showOrHideSaveModal,
      handleRedirect,
      handleModalLeaveClose,
      handleFormClose,
      handleModalsOnFieldsChange,
    },
  ] = useModals(onClose, onShowHideInclusiveModal);

  const useFormValue = useForm<CustomerNumbersFormViewModel>();
  const { register, watch, trigger, formState, getValues } = useFormValue;

  const [{ isFieldsChanged }, { handleBlur, handleFormSubmit, handleStateChange, handlePropsChange }] =
    useValidationUtils<CustomerNumbersFormViewModel>({
      state,
      useFormValue,
      setState,
      showOrHideSaveModal,
      relatedFieldsArray: [['startDate', 'endDate']],
    });

  useEffect(() => {
    const newState = model?.id
      ? mapToCustomerNumberViewModel(model)
      : {
          enabled: isAccountInclusive ? false : undefined,
        };
    handlePropsChange(newState);
    focus(!model?.id);
  }, [model, isAccountInclusive, handlePropsChange, focus]);

  const activeEndDate = watch('startDate');
  const activeStartDate = watch('endDate');

  useEffect(() => {
    void trigger(['startDate', 'endDate']);
  }, [activeStartDate, activeEndDate, trigger]);

  useEffect(() => {
    register('id', {
      required: i18n('Form_RequiredMessage'),
      pattern: {
        value: customerNumberIdRegex,
        message: i18n('UserForm_InvalidNameMessage'),
      },
      validate: (v) =>
        existingCustomerNumbers?.filter((cn) => cn.id !== model?.id)?.find((cn) => cn.id === v)
          ? i18n('CustomerNumbersForm_CustomerNumberExistsMessage')
          : undefined,
    });
    register('startDate', {
      required: isAccountInclusive && i18n('Form_RequiredMessage'),
      validate: (startDate) => {
        const endDate = getValues().endDate;
        return endDate && startDate && startDate >= endDate
          ? i18n('ConsumerForm_StartDateLaterMessage')
          : undefined;
      },
    });
    register('endDate', {
      required: isAccountInclusive && i18n('Form_RequiredMessage'),
      validate: (endDate) => {
        const startDate = getValues().startDate;
        return endDate && startDate && startDate >= endDate
          ? i18n('ConsumerForm_EndDateEarlierMessage')
          : undefined;
      },
    });
  }, [register, existingCustomerNumbers, isAccountInclusive, model, getValues]);

  useEffect(() => {
    handleModalsOnFieldsChange(isFieldsChanged, isInclusiveChangeStarted);
  }, [handleModalsOnFieldsChange, isFieldsChanged, isInclusiveChangeStarted]);

  return (
    <>
      <Prompt when={isFieldsChanged} message={(v: Location) => handleRedirect(v)} />
      <div className="customer-numbers-form-container page-form-container ui segment">
        <div className="page-form">
          <h3 data-testid="customer-numbers-form-title">
            {model?.id ? i18n('CustomerNumbersForm_Edit_Title') : i18n('CustomerNumbersForm_Create_Title')}
          </h3>
          <div className="page-form-body">
            <NamedInput
              disabled
              title={i18n('AccountsForm_AccountIdTitle')}
              value={params.accountId}
              data-testid="customer-numbers-form-account-id"
            />
            <NamedInput
              disabled={!!model?.id}
              className="page-form-body-row"
              placeholder="#"
              title={i18n('CustomerNumbersForm_CustomerNumberIdTitle')}
              value={state.id}
              maxLength={100}
              data-testid="customer-numbers-form-customer-number-id"
              error={formState.errors.id}
              inputRef={inputFocusRef}
              onChange={(v) => void handleStateChange(v, 'id')}
              onBlur={() => void handleBlur('id')}
            />
            <Grid className="dropdown-datepicker-range">
              <Grid.Row stretched columns={2}>
                <Grid.Column stretched>
                  <NamedDatepicker
                    disabled={!isAccountInclusive}
                    title={`${i18n('AccountsForm_StartDateTitle')} (${i18n('TimeHelper_Utc')})`}
                    className="page-form-body-row"
                    value={state.startDate}
                    selectsStart
                    showYearDropdown
                    startDate={state.startDate}
                    endDate={state.endDate}
                    maxDate={state.endDate}
                    onChange={(v) => void handleStateChange(v, 'startDate')}
                    error={formState.errors.startDate}
                    onBlur={() => void handleBlur('startDate')}
                  />
                </Grid.Column>
                <Grid.Column>
                  <NamedDatepicker
                    disabled={!isAccountInclusive}
                    title={`${i18n('AccountsForm_EndDateTitle')} (${i18n('TimeHelper_Utc')})`}
                    className="page-form-body-row"
                    value={state.endDate}
                    selectsEnd
                    showYearDropdown
                    startDate={state.startDate}
                    endDate={state.endDate}
                    minDate={state.startDate}
                    onChange={(v) => void handleStateChange(v, 'endDate')}
                    error={formState.errors.endDate}
                    onBlur={() => void handleBlur('endDate')}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <NamedRadioGroup
              disabled={!isAccountInclusive}
              title={i18n('ConsumerForm_ConsumerStatusTitle')}
              groupName="page-enabled"
              className="page-form-body-row"
              value={state.enabled}
              onChange={(v) => void handleStateChange(v, 'enabled')}
              options={getDisabledEnabledOptions()}
            />
          </div>
        </div>
        <div className="page-form-footer">
          <Button basic color="blue" onClick={() => handleFormClose(isFieldsChanged)}>
            {i18n('UserForm_CancelButtonTitle')}
          </Button>
          <Button primary disabled={!isFieldsChanged} onClick={() => void handleFormSubmit()}>
            {i18n('UserForm_SaveButtonTitle')}
          </Button>
        </div>
      </div>
      <ModalLeave
        isOpen={isLeavePopupOpen}
        isEdit={!!model?.id}
        isAdding={!model?.id}
        onClose={(isLeft) => handleModalLeaveClose(isLeft, isInclusiveChangeStarted)}
      />
      <ModalSave
        isAdding
        isEdit={!!model?.id}
        isOpen={isSavePopupOpen}
        header={
          model?.id
            ? i18n('CustomerNumbersForm_ModalSaveEdit_Title')
            : i18n('CustomerNumbersForm_ModalSaveCreate_Title')
        }
        onClose={(isSuccess) => (isSuccess ? onSave(params.accountId, state) : showOrHideSaveModal(false))}
      />
    </>
  );
};

export default CustomerNumbersForm;
