import { Permission } from './Permission';

export enum Role {
  SuperAdmin = 1,
  Admin,
  Consumers,
  ConsumersRead,
  Events,
  Search,
}

export interface UserRole {
  role: Role;
  permissions: Permission[];
}

export const UserRoles: UserRole[] = [
  {
    role: Role.SuperAdmin,
    permissions: [
      Permission.ConsumersRead,
      Permission.ConsumersUpdate,
      Permission.UsersRead,
      Permission.UsersCreate,
      Permission.UsersUpdate,
      Permission.UsersCanAssignUserPermissions,
      Permission.EventsRead,
      Permission.SearchTripsRead,
    ],
  },
  {
    role: Role.Admin,
    permissions: [
      Permission.ConsumersRead,
      Permission.ConsumersUpdate,
      Permission.UsersRead,
      Permission.UsersCreate,
      Permission.UsersUpdate,
      Permission.EventsRead,
      Permission.SearchTripsRead,
    ],
  },
  { role: Role.Consumers, permissions: [Permission.ConsumersRead, Permission.ConsumersUpdate] },
  { role: Role.ConsumersRead, permissions: [Permission.ConsumersRead] },
  { role: Role.Events, permissions: [Permission.EventsRead] },
  { role: Role.Search, permissions: [Permission.SearchTripsRead] },
];
