import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useBreakpoint } from '../../hooks/BreakPointProvider/breakpoint';
import { HeaderActionsDesktop } from './HeaderActionsDesktop/HeaderActionsDesktop';
import { HeaderActionsMobile } from './HeaderActionsMobile/HeaderActionsMobile';
import './styles.scss';

interface Props {
  username: string;
}

const Header: React.FC<Props> = ({ username }) => {
  const breakpoints = useBreakpoint();
  const history = useHistory();

  const handleLogout = () => {
    history.push('/logout');
  };

  return (
    <div className="header-container">
      <Link to="/" className="logo" data-testid="header-home-link"></Link>
      {breakpoints.sm && <HeaderActionsMobile onLogout={handleLogout} />}
      {!breakpoints.sm && <HeaderActionsDesktop username={username} onLogout={handleLogout} />}
    </div>
  );
};

export default Header;
