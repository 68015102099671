import AccountsForm from '../AccountsForm/AccountsForm';
import AddAccountsModal from '../AddAccountsModal/AddAccountsModal';
import ModalLeave from '../../../Modal/ModalLeave/ModalLeave';
import React, { useEffect, useState } from 'react';
import { AccountsFormSaveModel } from '../../../../models/Consumer/Account/AccountsFormSaveModel';
import { AccountsFormViewModel } from '../../../../models/Consumer/Account/AccountsFormViewModel';
import { ModalsModel } from '../../../../models/Common/ModalsModel';
import { resetAccountLookup } from '../../../../store/reducers/accountsLookupReducer';
import { useAppDispatch } from '../../../../store/hooks/useAppDispatch';
import { useAppSelector } from '../../../../store/hooks/useAppSelector';
import { useForm } from 'react-hook-form';
import { useModals } from '../../../../hooks/Alert/useModals';
import { useValidationUtils } from '../../../../hooks/Form/useValidationUtils';
import { ValidationUtilsModel } from '../../../../models/Common/ValidationUtilsModel';
import './styles.scss';

interface Props {
  consumerIsInclusive: boolean;
  isInclusiveChangeStarted: boolean;
  onClose: (redirectUrl?: string) => void;
  onSave: (form: AccountsFormSaveModel) => void;
  onShowHideInclusiveModal: (isVisible: boolean) => void;
}

const AccountLookupForm: React.FC<Props> = (props) => {
  const [state, setState] = useState<AccountsFormViewModel>({});

  const useModalsValue = useModals(props.onClose, props.onShowHideInclusiveModal);
  const modals: ModalsModel = { ...useModalsValue[0], ...useModalsValue[1] };

  const useFormValue = useForm<AccountsFormViewModel>();
  const [{ isFieldsChanged }, { handleStateChange, handleBlur, handleFormSubmit, handlePropsChange }] =
    useValidationUtils<AccountsFormViewModel>({
      state,
      useFormValue,
      setState,
      showOrHideSaveModal: modals.showOrHideSaveModal,
      relatedFieldsArray: [['startDate', 'endDate']],
    });
  const validationUtils: ValidationUtilsModel<AccountsFormViewModel> = {
    isFieldsChanged,
    handleStateChange,
    handleBlur,
    handleFormSubmit,
    handlePropsChange,
  };

  const accountsLookup = useAppSelector((s) => s.accountsLookup);
  const dispatch = useAppDispatch();

  const getAccountsSaveModel = (): AccountsFormSaveModel => ({
    viewModel: state,
    accountsIds: accountsLookup.selectedAccountsIds,
  });

  const getViewModel = (consumerIsInclusive: boolean): AccountsFormViewModel => ({
    enabled: consumerIsInclusive ? false : undefined,
  });

  useEffect(() => {
    handlePropsChange(getViewModel(props.consumerIsInclusive));
  }, [props.consumerIsInclusive, handlePropsChange]);

  useEffect(() => {
    dispatch(resetAccountLookup());
  }, [dispatch]);

  return (
    <div className="account-lookup-form">
      <AccountsForm
        {...props}
        ModalLeave={
          <ModalLeave
            isOpen={modals.isLeavePopupOpen}
            isEdit={false}
            isAdding={true}
            onClose={(isLeft) => modals.handleModalLeaveClose(isLeft, props.isInclusiveChangeStarted)}
          />
        }
        ModalSave={
          <AddAccountsModal
            accountsIds={accountsLookup.selectedAccountsIds}
            modals={modals}
            onSave={() => props.onSave(getAccountsSaveModel())}
          />
        }
        viewModel={state}
        useFormValue={useFormValue}
        modals={modals}
        validationUtils={validationUtils}
        areExternalFieldsChanged={accountsLookup.selectedAccountsIds.length > 0}
        areRequiredFormFields={props.consumerIsInclusive}
        areRequiredExternalFields={true}
      />
    </div>
  );
};

export default AccountLookupForm;
