import React, { memo, useEffect, useMemo, useState, MouseEvent, KeyboardEvent } from 'react';
import { Button, Divider, Form, Grid } from 'semantic-ui-react';
import { Prompt } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';
import { Location } from 'history';

import InclusiveLogicSwitch from './../../../InclusiveLogicSwitch/InclusiveLogicSwitch';
import ModalBase from '../../../Modal/ModalBase/ModalBase';
import NamedDatepicker from '../../../NamedDatepicker';
import NamedRadioGroup from '../../../NamedRadioGroup';
import { AccountsFormViewModel } from '../../../../models/Consumer/Account/AccountsFormViewModel';
import { getDisabledEnabledOptions } from '../../../../helpers/OptionHelper';
import { i18n } from '../../../../services/i18n';
import { ModalsModel } from '../../../../models/Common/ModalsModel';
import { ValidationUtilsModel } from '../../../../models/Common/ValidationUtilsModel';
import './styles.scss';
import WarningModalHeader from '../../../Modal/WarningModalHeader/WarningModalHeader';

interface Props {
  AccountsIds?: React.JSX.Element;
  ModalLeave: React.JSX.Element;
  ModalSave: React.JSX.Element;
  title?: string;
  consumerIsInclusive: boolean;
  isInclusiveChangeStarted: boolean;
  viewModel: AccountsFormViewModel;
  useFormValue: UseFormReturn<AccountsFormViewModel>;
  modals: ModalsModel;
  validationUtils: ValidationUtilsModel<AccountsFormViewModel>;
  areExternalFieldsChanged?: boolean;
  areRequiredFormFields?: boolean;
  areRequiredExternalFields?: boolean;
}

const AccountsForm: React.FC<Props> = ({
  AccountsIds,
  ModalLeave,
  ModalSave,
  title,
  consumerIsInclusive,
  isInclusiveChangeStarted,
  viewModel,
  useFormValue,
  modals,
  validationUtils,
  areExternalFieldsChanged = false,
  areRequiredFormFields = true,
  areRequiredExternalFields = false,
}) => {
  const [isInclusiveModalOpen, setIsInclusiveModalOpen] = useState(false);

  const isInclusiveMessage: string = useMemo(() => {
    if (consumerIsInclusive) {
      return viewModel.isInclusive
        ? i18n('AccountsForm_InclusiveAccountInclusiveCustomerNumbersUserMessage')
        : i18n('AccountsForm_InclusiveAccountExclusiveCustomerNumbersUserMessage');
    }
    return viewModel.isInclusive
      ? i18n('AccountsForm_ExclusiveAccountInclusiveCustomerNumbersUserMessage')
      : i18n('AccountsForm_ExclusiveAccountExclusiveCustomerNumbersUserMessage');
  }, [consumerIsInclusive, viewModel.isInclusive]);

  const { handleRedirect, handleFormClose, handleModalsOnFieldsChange } = modals;

  const isInclusiveButtonNewStatusName: string = useMemo(() => {
    return !viewModel.isInclusive ? i18n('InclusiveCustomerNumbers') : i18n('ExclusiveCustomerNumbers');
  }, [viewModel.isInclusive]);

  const isInclusiveNewStatusName: string = useMemo(() => {
    if (consumerIsInclusive) {
      return !viewModel.isInclusive
        ? i18n('InclusiveAccountInclusiveCustomerNumbers')
        : i18n('InclusiveAccountExclusiveCustomerNumbers');
    }
    return !viewModel.isInclusive
      ? i18n('ExclusiveAccountInclusiveCustomerNumbers')
      : i18n('ExclusiveAccountExclusiveCustomerNumbers');
  }, [consumerIsInclusive, viewModel.isInclusive]);

  const { register, watch, trigger, formState, getValues } = useFormValue;

  const handleInclusiveModalClose = (isChanged?: boolean): void => {
    setIsInclusiveModalOpen(false);
    isChanged && void validationUtils.handleStateChange(!viewModel.isInclusive, 'isInclusive');
  };

  const handleFormSubmitOnMouseDown = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.button === 0) {
      void validationUtils.handleFormSubmit();
    }
  };

  const handleFormSubmitOnKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      void validationUtils.handleFormSubmit();
    }
  };

  const isSaveEnabled = (
    areFormFieldsChanged: boolean,
    areRequiredFormFields: boolean,
    areExternalFieldsChanged: boolean,
    areRequiredExternalFields: boolean,
  ): boolean => {
    if (areRequiredFormFields) {
      return areFormFieldsChanged && (!areRequiredExternalFields || areExternalFieldsChanged);
    }

    return !areRequiredExternalFields || areExternalFieldsChanged;
  };

  const buildModalTitle = (): React.ReactNode => {
    return (
      <span>
        {!viewModel.isInclusive
          ? i18n('AccountsForm_Title_Line1_Inclusive')
          : i18n('AccountsForm_Title_Line1_Exclusive')}
        <br />
        {i18n('AccountsForm_Title_Line2_Part1_Regular')}
        <b>{i18n('AccountsForm_Title_Line2_Part2_Bold')}</b>
        {i18n('AccountsForm_Title_Line2_Part3_Regular')}
      </span>
    );
  };

  const activeEndDate = watch('startDate');
  const activeStartDate = watch('endDate');

  useEffect(() => {
    void trigger(['startDate', 'endDate']);
  }, [activeStartDate, activeEndDate, trigger]);

  useEffect(() => {
    register('startDate', {
      required: consumerIsInclusive && i18n('Form_RequiredMessage'),
      validate: (startDate) => {
        const endDate = getValues().endDate;
        return endDate && startDate && startDate >= endDate
          ? i18n('ConsumerForm_StartDateLaterMessage')
          : undefined;
      },
    });
    register('endDate', {
      required: consumerIsInclusive && i18n('Form_RequiredMessage'),
      validate: (endDate) => {
        const startDate = getValues().startDate;
        return endDate && startDate && startDate >= endDate
          ? i18n('ConsumerForm_EndDateEarlierMessage')
          : undefined;
      },
    });
  }, [register, consumerIsInclusive, getValues]);

  useEffect(() => {
    handleModalsOnFieldsChange(validationUtils.isFieldsChanged, isInclusiveChangeStarted);
  }, [handleModalsOnFieldsChange, validationUtils.isFieldsChanged, isInclusiveChangeStarted]);

  return (
    <>
      <Prompt
        when={validationUtils.isFieldsChanged || areExternalFieldsChanged}
        message={(v: Location) => handleRedirect(v)}
      />
      <Form>
        <div className="accounts-form-container page-form-container ui segment">
          <div className="page-form">
            {title && <h3 data-testid="consumer-form-title">{title}</h3>}
            <div className="page-form-body">
              {AccountsIds}
              <Grid className="dropdown-datepicker-range">
                <Grid.Row stretched columns={2}>
                  <Grid.Column stretched>
                    <NamedDatepicker
                      disabled={!consumerIsInclusive}
                      title={`${i18n('AccountsForm_StartDateTitle')} (${i18n('TimeHelper_Utc')})`}
                      className="page-form-body-row"
                      value={viewModel.startDate}
                      selectsStart
                      showYearDropdown
                      startDate={viewModel.startDate}
                      endDate={viewModel.endDate}
                      maxDate={viewModel.endDate}
                      onChange={(v) => void validationUtils.handleStateChange(v, 'startDate')}
                      error={formState.errors.startDate}
                      onBlur={() => void validationUtils.handleBlur('startDate')}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <NamedDatepicker
                      disabled={!consumerIsInclusive}
                      title={`${i18n('AccountsForm_EndDateTitle')} (${i18n('TimeHelper_Utc')})`}
                      className="page-form-body-row"
                      value={viewModel.endDate}
                      selectsEnd
                      showYearDropdown
                      startDate={viewModel.startDate}
                      endDate={viewModel.endDate}
                      minDate={viewModel.startDate}
                      onChange={(v) => void validationUtils.handleStateChange(v, 'endDate')}
                      error={formState.errors.endDate}
                      onBlur={() => void validationUtils.handleBlur('endDate')}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <NamedRadioGroup
                disabled={!consumerIsInclusive}
                title={i18n('ConsumerForm_ConsumerStatusTitle')}
                groupName="page-enabled"
                className="page-form-body-row"
                value={viewModel.enabled}
                onChange={(v) => void validationUtils.handleStateChange(v, 'enabled')}
                options={getDisabledEnabledOptions()}
              />
              <Divider className="accounts-form-divider" />
              <div className="customers-logic-row">
                <h4>{i18n('AccountsForm_CustomerNumberLogicTitle')}</h4>
                <InclusiveLogicSwitch
                  isInclusive={viewModel.isInclusive ?? false}
                  onChange={() => setIsInclusiveModalOpen(true)}
                />
              </div>
              <div className="accounts-form-inclusive-message">{isInclusiveMessage}</div>
            </div>
          </div>
          <div className="page-form-footer">
            <Button basic color="blue" onClick={() => handleFormClose(validationUtils.isFieldsChanged)}>
              {i18n('UserForm_CancelButtonTitle')}
            </Button>
            <Button
              primary
              disabled={
                !isSaveEnabled(
                  validationUtils.isFieldsChanged,
                  areRequiredFormFields,
                  areExternalFieldsChanged,
                  areRequiredExternalFields,
                )
              }
              onMouseDown={handleFormSubmitOnMouseDown}
              onKeyDown={handleFormSubmitOnKeyDown}
            >
              {i18n('UserForm_SaveButtonTitle')}
            </Button>
          </div>
        </div>
      </Form>
      {ModalLeave}
      {ModalSave}
      <ModalBase
        isOpen={isInclusiveModalOpen}
        header={<WarningModalHeader title={buildModalTitle()} />}
        content={i18n(`AccountsForm_To${isInclusiveNewStatusName}_Content`)}
        cancelButtonText={i18n(`AccountsForm_To${isInclusiveButtonNewStatusName}_NoButton`)}
        okButtonText={i18n(`AccountsForm_To${isInclusiveButtonNewStatusName}_YesButton`)}
        onClose={handleInclusiveModalClose}
      />
    </>
  );
};

export default memo(AccountsForm);
