import { SearchTripsFormViewModel } from '../models/SearchTrips/SearchTripsFormViewModel';
import { i18n } from '../services/i18n';

export const isAnyFieldFilled = (searchModel: SearchTripsFormViewModel): boolean =>
  !!searchModel?.sourceId ||
  !!searchModel?.globalCustomerNumber ||
  !!searchModel?.accountId ||
  !!searchModel?.travelerEmailAddress ||
  !!searchModel?.travelerFirstName ||
  !!searchModel?.travelerLastName;

export const handleValidateSearchTripsForm: (state: SearchTripsFormViewModel) => {
  isValid: boolean;
  message: string;
} = (state) =>
  isAnyFieldFilled(state)
    ? { isValid: true, message: '' }
    : { isValid: false, message: i18n('SearchTrips_EmptyForm_Message') };
