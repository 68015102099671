import clsx from 'clsx';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FieldError } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { Icon, Input } from 'semantic-ui-react';
import { Placement } from '@popperjs/core';

import {
  defaultDateTimeFormat,
  COUNT_AVAILABLE_YEARS_DATEPICKER,
  defaultDateTimeUtcFormatText,
  isContainCorrectDate,
  isDateTimeCorrect,
  getCoverageMaskDate,
  fillDateTimeZeros,
  isInDateRange,
  formatDateTimePicker,
  defaultTimeFormat,
  DATETIME_LENGTH_DATEPICKER,
} from '../../helpers/TimeHelper';
import './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  title: string;
  disabled?: boolean;
  error?: FieldError;
  pointing?: Placement;
  className?: string;
  value?: Date;
  excludeDateIntervals?: [Date, Date];
  showYearDropdown?: boolean;
  showTimeInput?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  placeholderText?: string;
  inputLength?: number;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange: (v?: Date) => void;
  onBlur: () => void;
}

const NamedDatepicker: React.FC<Props> = ({
  value,
  disabled = false,
  title,
  error,
  className,
  pointing = 'bottom-start',
  showYearDropdown = false,
  showTimeInput = false,
  showTimeSelect = true,
  dateFormat = defaultDateTimeFormat,
  timeFormat = defaultTimeFormat,
  placeholderText = defaultDateTimeUtcFormatText,
  inputLength = DATETIME_LENGTH_DATEPICKER,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
}) => {
  const [inputDate, setInputDate] = useState<string>();
  const [isDisabledTime, setIsDisabledTime] = useState<boolean>();

  useEffect(() => {
    setInputDate(formatDateTimePicker(value) || '');
  }, [value]);

  useEffect(() => {
    setIsDisabledTime(!inputDate || !isContainCorrectDate(inputDate));
  }, [inputDate]);

  const isInputDateShouldbeFilledZeros =
    inputDate && isContainCorrectDate(inputDate) && !isDateTimeCorrect(inputDate);

  const isInputDateInvalid =
    !inputDate || !isDateTimeCorrect(inputDate) || !isInDateRange(minDate, maxDate, new Date(inputDate));

  const handleBlur = useCallback(() => {
    if (isInputDateShouldbeFilledZeros) {
      const inputDateFilledZeros = inputDate ? fillDateTimeZeros(inputDate) : '';
      inputDateFilledZeros &&
        onChange(isInDateRange(minDate, maxDate, inputDateFilledZeros) ? inputDateFilledZeros : undefined);
    } else if (isInputDateInvalid) {
      onChange(undefined);
    }
  }, [inputDate, isInputDateInvalid, isInputDateShouldbeFilledZeros, maxDate, minDate, onChange]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      handleBlur();
    }
  };

  return (
    <div
      className={clsx(
        className,
        'named-input',
        'container-datePickerWrapper',
        { 'disabled__time-select': isDisabledTime },
        error ? 'error-datapicker-field' : '',
      )}
    >
      <div className="named-input-title">{title}</div>

      <DatePicker
        disabled={disabled}
        selected={value}
        onChange={(date: Date, e: SyntheticEvent<HTMLInputElement>) => {
          const target = e?.target as HTMLInputElement;
          if (
            (target?.value &&
              isDateTimeCorrect(target.value) &&
              isInDateRange(minDate, maxDate, new Date(target.value))) ||
            target?.value === undefined
          ) {
            onChange(date);
          }
        }}
        onBlur={handleBlur}
        onCalendarClose={handleBlur}
        onKeyDown={handleKeyDown}
        onChangeRaw={(e: SyntheticEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;
          const newMaskDate = target.value && getCoverageMaskDate(target.value);
          setInputDate(newMaskDate);
          if (target?.value) target.value = newMaskDate;
          else if (target?.value === undefined) e.preventDefault();
        }}
        popperPlacement={pointing}
        popperContainer={({ children }) => createPortal(children, document.body)}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        placeholderText={placeholderText}
        customInput={
          <Input
            maxLength={inputLength}
            icon={
              value == null ? (
                <Icon name="calendar outline" fitted />
              ) : (
                <Icon name="delete" fitted link onClick={() => onChange(undefined)} />
              )
            }
            fluid
          />
        }
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        showYearDropdown={showYearDropdown}
        yearDropdownItemNumber={COUNT_AVAILABLE_YEARS_DATEPICKER}
        scrollableYearDropdown
        showTimeInput={showTimeInput}
        showTimeSelect={showTimeSelect}
      />
      {error && <p className="error-message named-input-error-msg">{error?.message}</p>}
    </div>
  );
};

export default NamedDatepicker;
