import React from 'react';
import { Grid } from 'semantic-ui-react';

import { TripsModel } from '../../../../../models/Consumer/ApiAccess/TripsModel';
import { i18n } from '../../../../../services/i18n';
import BooleanLabel from '../../../../BooleanLabel/BooleanLabel';
import DataSourceAccessInfo from './DataSourceAccessInfo';
import { mapDataSourceFiltersModel } from '../../../../../mappers/DataSourceMapper';

import './styles.scss';

interface Props {
  trips?: TripsModel;
}

const GeneralAccessInfo: React.FC<Props> = ({ trips }) => {
  return (
    <div className="page-form-container general-access-container general-access-container-info">
      <div className="page-form">
        <h4 className="general-access-title" data-testid="general-access-title">
          {i18n('TripAccessTab_GeneralAccess_Title')}
        </h4>
        <div className="page-form-body">
          <Grid className="radio-group-container" columns={2}>
            <Grid.Column>
              <div className="boolean-label-row page-form-body-row">
                <h5 className="general-access-header">{i18n('TripAccessTab_PiiAccess_Title')}</h5>
                <BooleanLabel
                  value={!!trips?.hasAccessToPii}
                  textTrue={i18n('EnabledStatusName')}
                  textFalse={i18n('DisabledStatusName')}
                  data-testid="general-access-pii-access"
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="boolean-label-row page-form-body-row">
                <h5 className="general-access-header">{i18n('TripAccessTab_RawData_Title')}</h5>
                <BooleanLabel
                  value={!!trips?.hasAccessToRawData}
                  textTrue={i18n('EnabledStatusName')}
                  textFalse={i18n('DisabledStatusName')}
                  data-testid="general-access-raw-data"
                />
              </div>
            </Grid.Column>
          </Grid>
          <h5 className="general-access-header">{i18n('TripAccessTab_DataSource_Title')}</h5>
          <div className="data-source-text-block" data-testid="general-access-data-source">
            <DataSourceAccessInfo values={mapDataSourceFiltersModel(trips?.dataSourceFilters ?? [])} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralAccessInfo;
