import { CheckboxState } from '../models/Common/CheckboxState';
import { PresetsPiiAccess, ScopesPresetModel } from '../models/Consumer/Scopes/ScopesPresetModel';
import { TripAccessSettingEntry } from '../models/TripAccess/TripAccessSettingEntry';
import { cleanupTrueValues } from './ObjectHelper';

export const getTripAccessEntriesCount = (entry: TripAccessSettingEntry): number => {
  if (entry.children && entry.children.length > 0) {
    return entry.children.reduce((acc, child) => acc + getTripAccessEntriesCount(child), 0) + 1;
  }
  return 1;
};

export const getTripAccessCheckboxState: (entry: TripAccessSettingEntry) => CheckboxState = (
  entry: TripAccessSettingEntry,
) => {
  if (entry.children && entry.children.length > 0) {
    return getTripAccessCheckboxStateFromArray(entry.children);
  } else {
    return entry.enabled ?? true ? CheckboxState.Checked : CheckboxState.Unchecked;
  }
};

export const getTripAccessCheckboxStateFromArray: (entries: TripAccessSettingEntry[]) => CheckboxState = (
  entries: TripAccessSettingEntry[],
) => {
  const childrenCheckboxState = entries.map((child) => getTripAccessCheckboxState(child));
  if (childrenCheckboxState.every((state) => state === CheckboxState.Checked)) {
    return CheckboxState.Checked;
  }
  if (childrenCheckboxState.every((state) => state === CheckboxState.Unchecked)) {
    return CheckboxState.Unchecked;
  }
  return CheckboxState.Indeterminate;
};

export const updateTripAccessChildrenState: (
  children: TripAccessSettingEntry[],
  enabled: boolean,
) => TripAccessSettingEntry[] = (children: TripAccessSettingEntry[], enabled: boolean) => {
  return children.map((child) => {
    return {
      ...child,
      enabled: enabled,
      children: child.children ? updateTripAccessChildrenState(child.children, enabled) : undefined,
    };
  });
};

type ObjectLiteral = { [key: string]: boolean | ObjectLiteral };

export const mapToScopesModel: (entries: TripAccessSettingEntry[]) => ObjectLiteral = (
  entries: TripAccessSettingEntry[],
) => {
  const result = convertTripAccessSettingEntriesToObject(entries);
  return cleanupTrueValues(result);
};

const convertTripAccessSettingEntriesToObject: (entries: TripAccessSettingEntry[]) => ObjectLiteral = (
  entries: TripAccessSettingEntry[],
) => {
  const result: ObjectLiteral = {};
  entries.forEach((entry) => {
    if (entry.children && entry.children.length > 0) {
      const values = convertTripAccessSettingEntriesToObject(entry.children);
      if (Object.values(values).every((value) => !value)) {
        result[entry.value] = false;
      } else {
        result[entry.value] = values;
      }
    } else {
      result[entry.value] = entry.enabled ?? true;
    }
  });

  return result;
};

export const getPresetsPiiAccess: (payload: ScopesPresetModel[]) => PresetsPiiAccess = (payload) => {
  return payload.reduce<PresetsPiiAccess>(
    (previousValue, currentValue) => ({ ...previousValue, [currentValue.id]: currentValue.hasAccessToPii }),
    {},
  );
};
