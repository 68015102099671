import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CustomCognitoUser } from '../../models/User/CustomCognitoUser';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SingIn from './SingIn';
import './styles.scss';
import Logo from './Logo/Logo';
import AuthFooter from './AuthFooter';

interface Props {
  setUser: (newUser: CustomCognitoUser | undefined) => void;
}

const Authentication = ({ setUser }: Props): React.JSX.Element => {
  return (
    <div className="auth-container">
      <div className="form-container">
        <div className="container-logoWrapper">
          <Logo />
        </div>
        <Router>
          <Switch>
            <Route exact path="/">
              <SingIn setUser={setUser} />
            </Route>
            <Route path="/password/forgot" component={ForgotPassword}></Route>
            <Route path="/password/reset" component={ResetPassword}></Route>
            <Route path="*">
              <SingIn setUser={setUser} />
            </Route>
          </Switch>
        </Router>
        <AuthFooter />
      </div>
      <div className="banner"></div>
    </div>
  );
};

export default Authentication;
