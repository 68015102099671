import React, { useState } from 'react';
import { TripAccessAnchorRef } from '../../../../../models/TripAccess/TripAccessAnchorRef';
import { i18n } from '../../../../../services/i18n';

const createRef = (name: string, label: string, parentName: string) => {
  return {
    name,
    ref: React.createRef<HTMLInputElement>(),
    label,
    parentName,
  };
};

export const useAdvancedSettingsRefs: () => [TripAccessAnchorRef[]] = () => {
  const segmentsName = 'segments[]';
  const [refs] = useState<TripAccessAnchorRef[]>([
    createRef('airSegment[]', i18n('ConsumerTripAccess_AirSegmentLabel'), segmentsName),
    createRef('hotelSegment[]', i18n('ConsumerTripAccess_HotelSegmentLabel'), segmentsName),
    createRef('carSegment[]', i18n('ConsumerTripAccess_CarSegmentLabel'), segmentsName),
    createRef('railSegment[]', i18n('ConsumerTripAccess_RailSegmentLabel'), segmentsName),
    createRef('busSegment[]', i18n('ConsumerTripAccess_BusSegmentLabel'), segmentsName),
    createRef('cruiseFerrySegment[]', i18n('ConsumerTripAccess_CruiseFerrySegmentLabel'), segmentsName),
    createRef('tourSegment[]', i18n('ConsumerTripAccess_TourSegmentLabel'), segmentsName),
  ]);

  return [refs];
};
