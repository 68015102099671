export interface MediaQueries {
  [key: string]: string;
}

export const mediaQueries: MediaQueries = {
  xs: '(max-width: 767px)',
  sm: '(max-width: 900px)',
  md: '(min-width: 901px) and (max-width: 1100px)',
  lg: '(min-width: 1101px)',
};
