import React, { useEffect, useState } from 'react';
import { Permission } from '../../enums/Permission';
import { useUser } from '../User/user';

export type UseUserPermissionsType = (permission: Permission) => boolean;

export const useUserPermissions = (): UseUserPermissionsType => {
  const [user] = useUser();
  const [userPermissions, setUserPermissions] = useState<{ [key: string]: string | undefined }>();
  useEffect(() => {
    const session = user?.getSignInUserSession();
    setUserPermissions(session?.getIdToken().payload);
  }, [user]);

  return React.useCallback(
    (permission: Permission) => {
      return (userPermissions && Boolean(JSON.parse(userPermissions[permission] ?? 'false'))) || false;
    },
    [userPermissions],
  );
};
