import { Role, UserRole } from '../../../enums/UserRole';
import { i18n } from '../../../services/i18n';
import { DropdownOption } from '../../NamedDropdown/NamedDropdown';
import { RadioOption } from '../../NamedRadioGroup/NamedRadioGroup';
import { filterUserRoles, filterUserRolesByPermissions } from './helpers';

export const getRolesByPermissions = (roles: Role[], chosenRoles: Role[], userRoles: UserRole[]): Role[] => [
  ...chosenRoles,
  ...filterUserRolesByPermissions(
    filterUserRoles(roles, userRoles),
    filterUserRoles(chosenRoles, userRoles),
  ).map((r) => r.role),
];

export const getUserRolesOptions = (
  roles: Role[],
  chosenRoles: Role[],
  userRoles: UserRole[],
): DropdownOption[] =>
  getRolesByPermissions(roles, chosenRoles, userRoles).map((r) => ({
    key: r.toString(),
    value: r,
    text: i18n(`Users_RoleName_${r}`),
  }));

export const getUserStatusOptions: () => RadioOption[] = () => [
  {
    key: '1',
    value: false,
    text: i18n('InactiveStatusName'),
  },
  {
    key: '2',
    value: true,
    text: i18n('ActiveStatusName'),
  },
];

export const getUserFederatedOptions: () => RadioOption[] = () => [
  {
    key: '1',
    value: false,
    text: i18n('NotFederatedStatusName'),
  },
  {
    key: '2',
    value: true,
    text: i18n('FederatedStatusName'),
  },
];

export const whiteSpaceValueRegex = /^\s+$/i;

export const nameRegex = /^[a-z ,.'-]+$/i;

export const emailRegex =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i;

export const phoneNumberRegex = /^\+[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const federatedEmailDomains = [
  '@bcdtravel.ae',
  '@bcdtravel.at',
  '@bcdtravel.az',
  '@bcdtravel.be',
  '@bcdtravel.bg',
  '@bcdtravel.bh',
  '@bcdtravel.ch',
  '@bcdtravel.ci',
  '@bcdtravel.cl',
  '@bcdtravel.cn',
  '@bcdtravel.co.ke',
  '@bcdtravel.co.kr',
  '@bcdtravel.co.nz',
  '@bcdtravel.co.ug',
  '@bcdtravel.co.uk',
  '@bcdtravel.com',
  '@bcdtravel.com.ar',
  '@bcdtravel.com.au',
  '@bcdtravel.com.bo',
  '@bcdtravel.com.br',
  '@bcdtravel.com.co',
  '@bcdtravel.com.ec',
  '@bcdtravel.com.gt',
  '@bcdtravel.com.hn',
  '@bcdtravel.com.kw',
  '@bcdtravel.com.mx',
  '@bcdtravel.com.my',
  '@bcdtravel.com.ni',
  '@bcdtravel.com.pa',
  '@bcdtravel.com.pe',
  '@bcdtravel.com.pk',
  '@bcdtravel.com.py',
  '@bcdtravel.com.qa',
  '@bcdtravel.com.sv',
  '@bcdtravel.com.tr',
  '@bcdtravel.com.ua',
  '@bcdtravel.com.uy',
  '@bcdtravel.com.ve',
  '@bcdtravel.cr',
  '@bcdtravel.cz',
  '@bcdtravel.de',
  '@bcdtravel.dk',
  '@bcdtravel.es',
  '@bcdtravel.eu',
  '@bcdtravel.fi',
  '@bcdtravel.fr',
  '@bcdtravel.gr',
  '@bcdtravel.hr',
  '@bcdtravel.hu',
  '@bcdtravel.ie',
  '@bcdtravel.il',
  '@bcdtravel.in',
  '@bcdtravel.in.th',
  '@bcdtravel.it',
  '@bcdtravel.jo',
  '@bcdtravel.kz',
  '@bcdtravel.lk',
  '@bcdtravel.lt',
  '@bcdtravel.lu',
  '@bcdtravel.lv',
  '@bcdtravel.mg',
  '@bcdtravel.mr',
  '@bcdtravel.mu',
  '@bcdtravel.mx',
  '@bcdtravel.nl',
  '@bcdtravel.no',
  '@bcdtravel.ph',
  '@bcdtravel.pl',
  '@bcdtravel.pt',
  '@bcdtravel.ro',
  '@bcdtravel.rs',
  '@bcdtravel.ru',
  '@bcdtravel.se',
  '@bcdtravel.sg',
  '@bcdtravel.tw',
  '@bcdtravel.uz',
  '@bcdtravel.vn',
];
