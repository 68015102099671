import React from 'react';
import { Table } from 'semantic-ui-react';
import BooleanLabel, { BooleanLabelProps } from '../../BooleanLabel/BooleanLabel';

interface Props extends BooleanLabelProps {
  colSpan?: string;
  onClick?: () => void;
}

const BooleanLabelCell: React.FC<Props> = ({
  value,
  textTrue,
  textFalse,
  colorFalse,
  colSpan,
  'data-testid': dataTestId,
  onClick,
  children,
}) => {
  return (
    <Table.Cell colSpan={colSpan} className="boolean-label-cell" data-testid={dataTestId} onClick={onClick}>
      <BooleanLabel value={value} textTrue={textTrue} textFalse={textFalse} colorFalse={colorFalse}>
        {children}
      </BooleanLabel>
    </Table.Cell>
  );
};

export default BooleanLabelCell;
