import { Auth } from 'aws-amplify';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Hash } from 'history';
import { Button, Dimmer, Form, Loader } from 'semantic-ui-react';
import { CustomCognitoUser } from '../../../models/User/CustomCognitoUser';
import { i18n } from '../../../services/i18n';
import ChangePassword from '../ChangePassword';
import FederatedSignIn from '../FederatedSignIn/FederatedSignIn';
import FormFieldLabel from '../../../shared/FormFieldLabel/FormFieldLabel';

interface Props {
  setUser: (newUser: CustomCognitoUser | undefined) => void;
}

const SingIn = ({ setUser }: Props): React.JSX.Element => {
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [federatedAlertMessage, setFederatedAlertMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [userWithoutPassword, setUserWithoutPassword] = useState<CustomCognitoUser | undefined>();

  const location = useLocation();
  const hash: Hash = location.hash;

  useEffect(() => {
    if (hash) {
      setFederatedAlertMessage(
        new URLSearchParams(hash)
          .get('#error_description')
          ?.replace('PreTokenGeneration failed with error Not Authorized:', '') ?? '',
      );
    }
  }, [hash]);

  const handleBackButton = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener('pageshow', handleBackButton);
    return () => window.removeEventListener('pageshow', handleBackButton);
  }, []);

  const updateUser = (user: CustomCognitoUser) => {
    setIsLoading(false);
    setUser(user);
  };

  const updateAlertMessage = (message: string) => {
    setFederatedAlertMessage('');
    setAlertMessage(message);
    setIsLoading(false);
  };

  const handleSignIn = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const user = (await Auth.signIn(email, password)) as CustomCognitoUser;

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsChangePassword(true);
        setUserWithoutPassword(user);
        updateAlertMessage('');
      } else {
        updateUser(user);
      }
    } catch (error) {
      updateAlertMessage((error as Error)?.message);
    }
  };

  const handleFederatedAuth = async () => {
    setIsLoading(true);
    try {
      await Auth.federatedSignIn({ customProvider: 'BCD-AzureAD' });
    } catch (error) {
      setFederatedAlertMessage((error as Error)?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isChangePassword ? (
        <div data-testid="signIn-container">
          {isEmailLogin && (
            <Form
              className="form container-emailSignIn"
              onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
                void handleSignIn(event);
              }}
              data-testid="emailSignIn-container"
            >
              <Form.Field>
                <FormFieldLabel required>{i18n('Authentication_EmailLogin')}</FormFieldLabel>
                <input value={email} autoFocus onChange={(e) => setEmail(e.target.value.trim())} />
              </Form.Field>

              <Form.Field>
                <FormFieldLabel required>{i18n('Authentication_Password')}</FormFieldLabel>
                <input
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onPaste={(e) => {
                    e.preventDefault();
                    setPassword(e.clipboardData.getData('text').trim());
                  }}
                />
              </Form.Field>
              {alertMessage && <div className="error-message">{alertMessage}</div>}

              <Button primary type="submit" size="medium" fluid data-testid="signIn-btn">
                {i18n('Authentication_Login')}
              </Button>

              <div className="center-content">
                <Link to="/password/forgot" className="link-text">
                  {i18n('Authentication_ForgotPassword')}
                </Link>
                <div className="separator">{i18n('Authentication_or')}</div>
                {
                  <FederatedSignIn
                    handleSignIn={() => void handleFederatedAuth()}
                    isBasic
                    errorMessage={federatedAlertMessage}
                  />
                }
              </div>
            </Form>
          )}

          {!isEmailLogin && (
            <div className="container-defaultSignIn" data-testid="defaultSignIn-container">
              <FederatedSignIn
                handleSignIn={() => void handleFederatedAuth()}
                errorMessage={federatedAlertMessage}
              />
              <div className="separator">
                <Button
                  type="button"
                  size="small"
                  onClick={() => setIsEmailLogin(true)}
                  className="button-emailSignIn"
                  data-testid="chooseEmailSignIn-control"
                >
                  {i18n('Authentication_EmailSignIn')}
                </Button>
              </div>
            </div>
          )}

          {isLoading && (
            <div className="container-fullSizeLoader" data-testid="fullSizeLoader">
              <Dimmer active inverted data-testid="signIn-form-loader">
                <Loader size="big">{i18n('Authentication_Loading')}</Loader>
              </Dimmer>
            </div>
          )}
        </div>
      ) : (
        <ChangePassword email={email} userWithoutPassword={userWithoutPassword} setUser={setUser} />
      )}
    </>
  );
};

export default SingIn;
