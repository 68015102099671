import React from 'react';

interface Props {
  title: React.ReactNode;
}

const WarningModalHeader: React.FC<Props> = ({ title }) => {
  return (
    <h4 className="modal-header-wrapper">
      <span className="pi pi-exclamation-triangle modal-header-exclamation-icon"></span>
      {title}
    </h4>
  );
};

export default WarningModalHeader;
