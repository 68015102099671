import { useEffect } from 'react';
import {
  mapToAccountsModels,
  mapToCustomerNumberModel,
  updateConsumerAccounts,
  updateConsumerAddCustomerNumbers,
  updateConsumerModelAccountsStatus,
  updateConsumerModelIsInclusive,
} from '../../mappers/ConsumerAccountMapper';
import { mapToTripAccessModel, updateConsumerModelTripAccess } from '../../mappers/ConsumerMapper';
import { mapToConsumerModel } from '../../mappers/ConsumerToDTOMapper';
import { ApiError } from '../../models/Api/ApiError';
import { AccountModel } from '../../models/Consumer/Account/AccountModel';
import { AccountsFormSaveModel } from '../../models/Consumer/Account/AccountsFormSaveModel';
import { CustomerNumbersFormViewModel } from '../../models/Consumer/Account/CustomerNumbersFormViewModel';
import { ConsumerFormViewModel } from '../../models/Consumer/ConsumerFormViewModel';
import { ConsumerModel } from '../../models/Consumer/ConsumerModel';
import { ScopesPresetModel } from '../../models/Consumer/Scopes/ScopesPresetModel';
import { SmIdModel } from '../../models/Consumer/SmIdModel';
import { TripAccessFormViewModel } from '../../models/Consumer/TripAccessFormViewModel';
import { clearConsumerInfoErrorAction, fetchConsumerInfoAction } from '../actions/consumerInfoActions';
import { fetchConsumersAction, updateConsumerAction } from '../actions/consumersActions';
import { resetConsumer, setConsumer } from '../reducers/consumerInfoReducer';
import { resetConsumerTypes } from '../reducers/consumerTypesReducer';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
/* eslint-disable @typescript-eslint/no-floating-promises */

export const useConsumerInfo: (id: string) => [
  {
    consumer: ConsumerModel;
    isLoading: boolean;
    error?: ApiError;
  },
  {
    updateConsumer: (
      model: ConsumerFormViewModel,
      scopesPresets: ScopesPresetModel[],
      prevModel?: ConsumerModel,
    ) => void;
    updateConsumerAccount: (
      consumer: ConsumerModel,
      accountsForm: AccountsFormSaveModel,
      prevModel?: AccountModel,
    ) => void;
    updateConsumerCustomerNumbers: (
      model: ConsumerModel,
      customerNumber: CustomerNumbersFormViewModel,
      accountId: string,
      prevModel?: SmIdModel,
    ) => void;
    updateConsumerTripAccess: (
      model: ConsumerModel,
      tripAccess?: TripAccessFormViewModel,
      scopesPreset?: ScopesPresetModel[],
    ) => void;
    updateConsumerIsInclusive: (model: ConsumerModel, isInclusive: boolean) => void;
    updateConsumerAccountsStatus: (model: ConsumerModel, accountIds: string[], enabled: boolean) => void;
    handleError: () => void;
  },
] = (id) => {
  const dispatch = useAppDispatch();
  const consumerInfos = useAppSelector((s) => s.consumerInfo);

  useEffect(() => {
    if (consumerInfos.items[id] === undefined) {
      dispatch(fetchConsumerInfoAction(id));
    }
  }, [consumerInfos.items, dispatch, id]);

  useEffect(() => {
    Promise.all([dispatch(resetConsumerTypes()), dispatch(resetConsumer())]);
  }, [dispatch]);

  const updateConsumer = (
    model: ConsumerFormViewModel,
    scopesPresets: ScopesPresetModel[],
    prevModel?: ConsumerModel,
  ) => {
    const mappedModel = mapToConsumerModel(model, scopesPresets, prevModel);
    dispatch(updateConsumerAction(mappedModel))
      .unwrap()
      .then(() => {
        dispatch(fetchConsumersAction());
        dispatch(setConsumer(mappedModel));
      });
  };

  const updateConsumerAccount = (
    consumer: ConsumerModel,
    accountsForm: AccountsFormSaveModel,
    prevModel?: AccountModel,
  ) => {
    const mappedAccounts = mapToAccountsModels(accountsForm, prevModel);
    const mappedModel = updateConsumerAccounts(consumer, mappedAccounts, accountsForm.accountsIds);

    dispatch(updateConsumerAction(mappedModel))
      .unwrap()
      .then(() => {
        dispatch(fetchConsumersAction());
        dispatch(fetchConsumerInfoAction(id));
      });
  };

  const updateConsumerCustomerNumbers = (
    model: ConsumerModel,
    customerNumber: CustomerNumbersFormViewModel,
    accountId: string,
    prevModel?: SmIdModel,
  ) => {
    const mappedCustomerNumberModel = mapToCustomerNumberModel(customerNumber);
    const mappedModel: ConsumerModel = updateConsumerAddCustomerNumbers(
      model,
      mappedCustomerNumberModel,
      accountId,
      prevModel?.id,
    );
    dispatch(updateConsumerAction(mappedModel))
      .unwrap()
      .then(() => {
        dispatch(fetchConsumersAction());
        dispatch(fetchConsumerInfoAction(id));
      });
  };

  const updateConsumerTripAccess = (
    model: ConsumerModel,
    tripAccess?: TripAccessFormViewModel,
    scopesPreset?: ScopesPresetModel[],
  ) => {
    const mappedTripAccess = mapToTripAccessModel(tripAccess, scopesPreset);
    const mappedModel: ConsumerModel = updateConsumerModelTripAccess(model, mappedTripAccess);
    dispatch(updateConsumerAction(mappedModel))
      .unwrap()
      .then(() => {
        dispatch(fetchConsumersAction());
        dispatch(fetchConsumerInfoAction(id));
      });
  };

  const updateConsumerIsInclusive = (model: ConsumerModel, isInclusive: boolean) => {
    const mappedModel = updateConsumerModelIsInclusive(model, isInclusive);
    dispatch(updateConsumerAction(mappedModel))
      .unwrap()
      .then(() => {
        dispatch(fetchConsumersAction());
        dispatch(fetchConsumerInfoAction(id));
      });
  };

  const updateConsumerAccountsStatus = (model: ConsumerModel, accountIds: string[], enabled: boolean) => {
    const mappedModel = updateConsumerModelAccountsStatus(model, accountIds, enabled);
    dispatch(updateConsumerAction(mappedModel))
      .unwrap()
      .then(() => {
        dispatch(fetchConsumersAction());
        dispatch(fetchConsumerInfoAction(id));
      });
  };

  const handleError = () => {
    dispatch(clearConsumerInfoErrorAction());
  };

  return [
    { consumer: consumerInfos.items[id], isLoading: consumerInfos.isLoading, error: consumerInfos.error },
    {
      updateConsumer,
      updateConsumerAccount,
      updateConsumerCustomerNumbers,
      updateConsumerTripAccess,
      updateConsumerIsInclusive,
      updateConsumerAccountsStatus,
      handleError,
    },
  ];
};
