import { AccountsIdsHighlightsModel } from '../models/Consumer/Account/AccountsIdsHighlightsModel';
import { getInvalidItems, getExistingItemsDuplicates, getUniqueKeys } from './duplicateItemsUtils';
import { Highlight } from 'react-highlight-within-textarea';
import { ItemPositionModel } from '../models/Consumer/Account/ItemPositionModel';
import { HighlightModel } from '../models/Consumer/Account/HighlightModel';
import { incorrectAccountIdsCharactersRegex } from '../features/ConsumerTab/AccountsTab/AccountsFormConstants';

const defaultHighlightClassName = 'custom-highlight';

export const getAccountsIdsHighlights = (
  value: string,
  consumerAccountsIds: string[],
  highlightClassName = defaultHighlightClassName,
): AccountsIdsHighlightsModel => {
  const { accountsIds, positions } = parseAccountsIds(value);
  const accountsIdsPositions = getAccountsIdsPositions(accountsIds, positions);
  const invalidAccountsIds = getInvalidItems(accountsIdsPositions, 'item');
  const highlights = getHighlights(invalidAccountsIds, highlightClassName);
  const existingAccountsIds = getExistingItemsDuplicates(
    accountsIdsPositions,
    (accountIdPosition) => accountIdPosition['item'],
    consumerAccountsIds,
  );
  const existingAccountsHighlights = getHighlights(existingAccountsIds, highlightClassName);
  const existingDuplicateAccountIds = getUniqueKeys(
    existingAccountsIds,
    (accountIdPosition) => accountIdPosition['item'],
  );

  return {
    accountsState: {
      accountsIds,
      hasInvalidAccountsIds: invalidAccountsIds.length > 0,
      existingDuplicateAccountIds,
    },
    highlights: highlights.concat(existingAccountsHighlights),
  };
};

export const getIncorrectCharactersHighlights = (
  text: string,
  highlightClassName = defaultHighlightClassName,
): HighlightModel[] => {
  const highlights: HighlightModel[] = [];
  const globalRegexp = new RegExp(incorrectAccountIdsCharactersRegex, 'g');
  let match = globalRegexp.exec(text);
  while (match) {
    const highlight = {
      highlight: [match.index, match.index + match[0].length],
      className: highlightClassName,
    };
    highlights.push(highlight);
    globalRegexp.lastIndex = match.index + match[0].length;
    match = globalRegexp.exec(text);
  }
  return highlights;
};

const accountRegexp = /(\s|-|,|^)(\d+)(?:\s|-|,|$)/g;
const parseAccountsIds = (text: string): { accountsIds: string[]; positions: number[] } => {
  const accountsIds: string[] = [];
  const positions: number[] = [];
  let match = accountRegexp.exec(text);
  while (match) {
    const position = match.index + match[1].length;
    accountsIds.push(match[2]);
    positions.push(position);
    accountRegexp.lastIndex = match.index + match[2].length;
    match = accountRegexp.exec(text);
  }

  return { accountsIds, positions };
};

const getAccountsIdsPositions = (accountsIds: string[], positions: number[]): ItemPositionModel[] =>
  accountsIds.map((item, index) => ({ item, position: positions[index] }));

const getHighlights = (itemsPositions: ItemPositionModel[], className: string): Highlight[] => {
  const highlightsExpressions = itemsPositions.map((itemPosition) => [
    itemPosition.position,
    itemPosition.position + itemPosition.item.length,
  ]);

  return highlightsExpressions.map((expression) => ({
    highlight: expression,
    className,
  }));
};
