import HighlightsInTextArea from '../../../HighlightsInTextArea/HighlightsInTextArea';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { consumerAccountsAddLookupPath, consumerIdParameter } from '../../../../paths';
import { FieldError } from 'react-hook-form';
import { HighlightModel } from '../../../../models/Consumer/Account/HighlightModel';
import { i18n } from '../../../../services/i18n';
import { useConsumerInfoNavigation } from '../../../../hooks/Navigation/useConsumerInfoNavigation';
import { usePageNavigation } from '../../../../hooks/Navigation/usePageNavigation';
import './styles.scss';

interface Props {
  highlights: HighlightModel[];
  error?: FieldError;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChangeValue: (text: string) => void;
}

const NewAccountsSection: React.FC<Props> = (props) => {
  const consumerId = useConsumerInfoNavigation()[0].id;
  const [_, { goToUrl }] = usePageNavigation();

  const handleLookupAccountAdd = () =>
    goToUrl(consumerAccountsAddLookupPath.replace(consumerIdParameter, consumerId));

  return (
    <>
      <div className="title-container">
        <span className="named-highlights-title">{i18n('AccountsForm_AccountsIdsTitle')}</span>
        <Button
          icon
          className="icon-square-button-small loupe"
          data-testid="lookup-account-button"
          onClick={() => handleLookupAccountAdd()}
        >
          <Icon name="search" />
        </Button>
      </div>
      <HighlightsInTextArea data-testid="accounts-form-accounts-ids" {...props} />
    </>
  );
};

export default NewAccountsSection;
