import clsx from 'clsx';
import React from 'react';
import { TripAccessAnchorRef } from '../../../../../../models/TripAccess/TripAccessAnchorRef';
import { TripAccessSettingEntry } from '../../../../../../models/TripAccess/TripAccessSettingEntry';
import TripAccessAdvancedSettingsRow from './TripAccessAdvancedSettingsRow';
import './styles.scss';

interface Props {
  isExpanded: boolean;
  disabled?: boolean;
  pageEmbedded?: boolean;
  settingsEntries: TripAccessSettingEntry[];
  refs: TripAccessAnchorRef[];
  onUpdated: (name: string, newState: TripAccessSettingEntry) => void;
}

const TripAccessAdvancedSettingsEntries: React.FC<Props> = ({
  isExpanded,
  disabled,
  pageEmbedded,
  settingsEntries,
  refs,
  onUpdated,
}) => {
  return (
    <div
      className={clsx('advanced-settings-rows-container', {
        'advanced-settings-rows-container-readonly': disabled,
        'page-embedded': pageEmbedded,
        hidden: !isExpanded,
        shown: isExpanded,
      })}
    >
      <div className={clsx({ 'advanced-settings-rows-container-content': pageEmbedded })}>
        {settingsEntries &&
          settingsEntries.length > 0 &&
          settingsEntries.map((item) => (
            <TripAccessAdvancedSettingsRow
              anchorReferences={refs}
              key={item.name}
              model={item}
              onUpdated={onUpdated}
              disabled={disabled}
              pageEmbedded={pageEmbedded}
            />
          ))}
      </div>
    </div>
  );
};

export default TripAccessAdvancedSettingsEntries;
