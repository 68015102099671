import React from 'react';

interface Props {
  text: string | React.JSX.Element;
}

const ToastMessage: React.FC<Props> = ({ text }) => {
  return <span data-testid="toast-message">{text}</span>;
};

export default ToastMessage;
