import { Action, createAsyncThunk } from '@reduxjs/toolkit';
import { UserModel } from '../../models/User/UserModel';
import { UserService } from '../../services/UserService';
import {
  clearUsersErrorActionName,
  clearUsersSuccessMessageActionName,
  createUserActionName,
  fetchUsersActionName,
  updateUserActionName,
} from './actionTypes';

const service = UserService;

export const fetchUsersAction = createAsyncThunk(fetchUsersActionName, async (_, thunkApi) => {
  try {
    return await service.getUsers();
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const createUserAction = createAsyncThunk<void, UserModel>(
  createUserActionName,
  async (model, thunkApi) => {
    try {
      await service.insertUser(model);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateUserAction = createAsyncThunk<void, UserModel>(
  updateUserActionName,
  async (model, thunkApi) => {
    try {
      await service.updateUser(model);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const clearUsersErrorAction: () => Action = () => {
  return {
    type: clearUsersErrorActionName,
  };
};

export const clearUsersSuccessMessageAction: () => Action = () => {
  return {
    type: clearUsersSuccessMessageActionName,
  };
};
