import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { Button, Dropdown, Grid } from 'semantic-ui-react';
import { Location } from 'history';

import { cleanupTrueValues } from '../../../../helpers/ObjectHelper';
import { getDisabledEnabledOptions } from '../../../../helpers/OptionHelper';
import { getPresetsPiiAccess, mapToScopesModel } from '../../../../helpers/TripAccessHelper';
import { useModals } from '../../../../hooks/Alert/useModals';
import { useValidationUtils } from '../../../../hooks/Form/useValidationUtils';
import { mapToTripAccessFormViewModel } from '../../../../mappers/ConsumerMapper';
import { PresetsPiiAccess, ScopesPresetModel } from '../../../../models/Consumer/Scopes/ScopesPresetModel';
import { TripAccessFormViewModel } from '../../../../models/Consumer/TripAccessFormViewModel';
import { TripsModel } from '../../../../models/Consumer/ApiAccess/TripsModel';
import { TripAccessSettingEntry } from '../../../../models/TripAccess/TripAccessSettingEntry';
import { i18n } from '../../../../services/i18n';
import ModalLeave from '../../../Modal/ModalLeave/ModalLeave';
import NamedRadioGroup from '../../../NamedRadioGroup';
import RadioGroup from '../../../RadioGroup/RadioGroup';
import TripAccessBaseContainer from '../TripAccessBaseContainer/TripAccessBaseContainer';
import TripAccessPresetVisualization from '../TripAccessInfo/TripAccessPresetVisualization';
import { getTripAccessPresetOption } from '../TripAccessTabOptionsMapper';
import ModalSave from './../../../Modal/ModalSave/ModalSave';
import DataSourceAccess from './DataSourceAccess';
import TripAccessAdvancedSettings from './TripAccessAdvancedSettings/TripAccessAdvancedSettings';
import { useAdvancedSettings } from './TripAccessAdvancedSettings/useAdvancedSettings';
import { useUserPermissions } from '../../../../hooks/Auth/useUserPermissions';
import { userRoles } from '../../../../helpers/PermissionsHelper';
import CanUser from '../../../Permissions/CanUser';
import { Role } from '../../../../enums/UserRole';
import { getWithPopupTripAccessOptions } from '../../../ConsumerForm/helpers';
import { getTripAccessOptionsRules } from '../helpers';

import './styles.scss';

interface Props {
  model?: TripsModel;
  scopesPresets: ScopesPresetModel[];
  onClose: (redirectUrl?: string) => void;
  onSave: (model: TripAccessFormViewModel) => void;
  getAdvancedSettingsEntries: () => TripAccessSettingEntry[];
}

const TripAccessForm: React.FC<Props> = ({
  model,
  scopesPresets,
  onClose,
  onSave,
  getAdvancedSettingsEntries,
}) => {
  const [settingsEntries, setSettingsEntries] = useAdvancedSettings(getAdvancedSettingsEntries, model);
  const [scopesPresetPiiAccess, setScopesPresetPiiAccess] = useState<PresetsPiiAccess>({});
  const userRole = userRoles(useUserPermissions());
  const tripAccessOptions = getWithPopupTripAccessOptions(getTripAccessOptionsRules(userRole));

  const [state, setState] = useState<TripAccessFormViewModel>(
    mapToTripAccessFormViewModel(model, scopesPresets),
  );
  const isCustomPiiAccess: boolean =
    (!state.isAdvancedSettings &&
      state.presetId &&
      state.hasAccessToPii !== scopesPresetPiiAccess[state.presetId]) ||
    false;

  useEffect(() => {
    setScopesPresetPiiAccess(getPresetsPiiAccess(scopesPresets));
  }, [scopesPresets]);

  const [presetPreviewId, setPresetPreviewId] = useState<number>();

  const useFormValue = useForm<TripAccessFormViewModel>();
  const { register, formState, unregister } = useFormValue;
  const [
    { isLeavePopupOpen, isSavePopupOpen },
    { showOrHideSaveModal, handleRedirect, handleModalLeaveClose, handleFormClose },
  ] = useModals(onClose);
  const [{ isFieldsChanged }, { handleBlur, handleFormSubmit, handleStateChange }] =
    useValidationUtils<TripAccessFormViewModel>({
      state,
      useFormValue,
      setState,
      showOrHideSaveModal,
    });

  const handleSettingsEntriesChanged = useCallback(
    (param: (prev: TripAccessSettingEntry[]) => TripAccessSettingEntry[]) => {
      setSettingsEntries((prev) => {
        const result = param(prev);
        void handleStateChange(mapToScopesModel(result), 'scopes');
        return result;
      });
    },
    [setSettingsEntries, handleStateChange],
  );

  const disabledEnabledOptions = getDisabledEnabledOptions();

  useEffect(() => {
    if (!state.isAdvancedSettings) {
      register('presetId', {
        required: i18n('Form_RequiredMessage'),
      });
    }
    return () => {
      unregister('presetId');
    };
  }, [register, state.isAdvancedSettings, unregister]);

  return (
    <>
      <Prompt when={isFieldsChanged} message={(v: Location) => handleRedirect(v)} />
      <TripAccessBaseContainer
        left={
          <div className="page-form-container general-access-container general-access-container-edit">
            <div className="page-form">
              <h4 className="general-access-title" data-testid="general-access-title">
                {i18n('TripAccessTab_GeneralAccess_Title')}
              </h4>
              <div className="page-form-body">
                <Grid className="radio-group-container" columns={2}>
                  <Grid.Column>
                    <NamedRadioGroup
                      title={i18n('TripAccessTab_PiiAccess_Title')}
                      groupName="pii-access"
                      className="page-form-body-row"
                      value={state.hasAccessToPii}
                      onChange={(v) => void handleStateChange(v, 'hasAccessToPii')}
                      options={disabledEnabledOptions}
                      disabled={!userRole[Role.SuperAdmin]}
                      dataTestId="trip-access-form-pii-access"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <NamedRadioGroup
                      title={i18n('TripAccessTab_RawData_Title')}
                      groupName="raw-data"
                      className="page-form-body-row"
                      value={state.hasAccessToRawData}
                      onChange={(v) => void handleStateChange(v, 'hasAccessToRawData')}
                      options={disabledEnabledOptions}
                    />
                  </Grid.Column>
                </Grid>
                <div className="data-source-text-block" data-testid="general-access-data-source">
                  <h5 className="general-access-header">{i18n('TripAccessTab_DataSource_Title')}</h5>
                  <DataSourceAccess
                    values={state.dataSourceFilters}
                    onChange={(v) => void handleStateChange(v, 'dataSourceFilters')}
                  />
                </div>
              </div>
            </div>
          </div>
        }
        right={
          <div className="page-form-container trip-access-advanced-settings-container">
            <div className="page-form">
              <div className="trip-access-container">
                <h4 className="trip-access-label">{i18n('ConsumerTripAccessInfo_TripsScopeLabel')}</h4>
              </div>
              <RadioGroup
                padded="vertically"
                value={state.isAdvancedSettings}
                groupName="trip-access-settings"
                options={tripAccessOptions}
                onChange={(v) => void handleStateChange(v, 'isAdvancedSettings')}
                disabled={!userRole[Role.SuperAdmin]}
                dataTestId="trip-access-form-is-advanced-settings"
              />
              {state.isAdvancedSettings && (
                <CanUser roles={[Role.SuperAdmin, Role.Admin, Role.Consumers]}>
                  <TripAccessAdvancedSettings
                    disabled={!userRole[Role.SuperAdmin]}
                    settingsEntries={settingsEntries}
                    setSettingsEntries={handleSettingsEntriesChanged}
                  />
                </CanUser>
              )}
              {!state.isAdvancedSettings && (
                <CanUser roles={[Role.ConsumersRead]} isOpposite>
                  <Grid padded="vertically" data-testid="trip-access-scopes-preset">
                    <Grid.Row columns={2}>
                      <Grid.Column className="trip-access-form-dropdown-container">
                        <Dropdown
                          placeholder="-"
                          value={state.presetId}
                          fluid
                          selection
                          onChange={(_, { value: v }) => {
                            void handleBlur('presetId');
                            void handleStateChange(scopesPresetPiiAccess[v as number], 'hasAccessToPii');
                            void handleStateChange(v as number, 'presetId');
                          }}
                          error={!!formState.errors.presetId}
                          onBlur={() => void handleBlur('presetId')}
                          options={getTripAccessPresetOption(scopesPresets).map((o) => {
                            return {
                              ...o,
                              onMouseEnter: () => {
                                setPresetPreviewId(o.value as number);
                              },
                              onMouseLeave: () => {
                                setPresetPreviewId(undefined);
                              },
                            };
                          })}
                        />
                        {formState.errors.presetId && (
                          <p className="error-message named-input-error-msg">
                            {formState.errors.presetId?.message}
                          </p>
                        )}
                        {!!state.presetId && (
                          <TripAccessPresetVisualization
                            className="trip-access-preset-visualization"
                            model={cleanupTrueValues(
                              scopesPresets.find((x) => x.id === state.presetId)?.preset,
                            )}
                          />
                        )}
                      </Grid.Column>
                      <Grid.Column className="trip-access-form-dropdown-container">
                        {presetPreviewId && (
                          <TripAccessPresetVisualization
                            className="trip-access-preset-visualization preset-hover-preview"
                            model={cleanupTrueValues(
                              scopesPresets.find((x) => x.id === presetPreviewId)?.preset,
                            )}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </CanUser>
              )}
            </div>
            <div className="page-form-footer">
              <Button basic color="blue" onClick={() => handleFormClose(isFieldsChanged)}>
                {i18n('UserForm_CancelButtonTitle')}
              </Button>
              <Button
                primary
                disabled={!isFieldsChanged}
                onClick={() => void handleFormSubmit()}
                data-testid="trip-access-form-submit-control"
              >
                {i18n('UserForm_SaveButtonTitle')}
              </Button>
            </div>
          </div>
        }
      />
      <ModalLeave isOpen={isLeavePopupOpen} isEdit onClose={handleModalLeaveClose} />
      <ModalSave
        isEdit
        isOpen={isSavePopupOpen}
        header={i18n('TripAccessForm_ModalSave_Title')}
        onClose={(isSuccess) => (isSuccess ? onSave(state) : showOrHideSaveModal(false))}
        content={isCustomPiiAccess ? i18n('Modals_Save_ConsumerWithCustomPIIAccess_Content') : undefined}
      />
    </>
  );
};

export default TripAccessForm;
