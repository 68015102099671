import { Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Permission } from '../../enums/Permission';
import { useUserPermissions } from '../../hooks/Auth/useUserPermissions';
import { useUser } from '../../hooks/User/user';
import { CustomCognitoUser } from '../../models/User/CustomCognitoUser';
import ConsumerInfoPage from '../../pages/ConsumerInfoPage/ConsumerInfoPage';
import ConsumersPage from '../../pages/ConsumersPage/ConsumersPage';
import HomePage from '../../pages/HomePage/HomePage';
import LogoutPage from '../../pages/LogoutPage/LogoutPage';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';
import UsersPage from '../../pages/UsersPage/UsersPage';
import {
  consumersAddPath,
  consumerPath,
  consumersPath,
  logoutPath,
  usersAddPath,
  usersEditPath,
  usersPath,
  eventsPath,
  searchTripsPath,
} from '../../paths';
import { resetConsumers } from '../../store/reducers/consumersReducer';
import { resetDataSourceFilter } from '../../store/reducers/dataSourceFilterReducer';
import store from '../../store/store';
import Authentication from '../Authentication';
import Footer from '../Footer/Footer';
import Header from '../Header';
import EventsPage from '../../pages/EventsPage/EventsPage';
import SearchTripsPage from '../../pages/SearchTripsPage/SearchTripsPage';

const Root: React.FC = () => {
  const [user, setUser] = useUser();
  const [isUserLoaded, setIsUserLoaded] = useState<boolean>(false);
  const hasPermission = useUserPermissions();
  const location = useLocation();

  const updateUserData = useCallback(async () => {
    try {
      const currentUser = (await Auth.currentAuthenticatedUser()) as CustomCognitoUser;
      setUser(currentUser);
    } catch (error) {
      setUser(undefined);
    }
    setIsUserLoaded(true);
  }, [setUser]);

  useEffect(() => {
    void updateUserData();
  }, [updateUserData, location]);

  const logout = async () => {
    await Auth.signOut();
    setUser(undefined);
    store.dispatch(resetConsumers());
    store.dispatch(resetDataSourceFilter());
  };

  if (!isUserLoaded) {
    return <></>;
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
        hideProgressBar
        autoClose={10000}
      />
      {user ? (
        <>
          <Header username={user?.attributes['email'] ?? ''} />
          <div className="body-container">
            <Switch>
              <Route path="/" exact component={HomePage} />
              {hasPermission(Permission.ConsumersRead) && (
                <Route path={consumersPath} exact component={ConsumersPage} />
              )}
              {hasPermission(Permission.ConsumersUpdate) && (
                <Route path={consumersAddPath} component={ConsumersPage} />
              )}
              {hasPermission(Permission.ConsumersRead) && (
                <Route path={consumerPath} component={ConsumerInfoPage} />
              )}
              <Route path={logoutPath}>
                <LogoutPage onLogout={() => void logout()} />
              </Route>
              {hasPermission(Permission.UsersRead) && <Route path={usersPath} exact component={UsersPage} />}
              {hasPermission(Permission.UsersCreate) && <Route path={usersAddPath} component={UsersPage} />}
              {hasPermission(Permission.UsersUpdate) && <Route path={usersEditPath} component={UsersPage} />}
              {hasPermission(Permission.EventsRead) && (
                <Route path={eventsPath} exact component={EventsPage} />
              )}
              {hasPermission(Permission.SearchTripsRead) && (
                <Route path={searchTripsPath} component={SearchTripsPage} />
              )}
              <Route component={NotFoundPage} />
            </Switch>
          </div>
          <Footer />
        </>
      ) : (
        <Authentication setUser={setUser} />
      )}
    </>
  );
};

export default Root;
