import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { Checkbox, Icon, Popup, Table } from 'semantic-ui-react';
import { Permission } from '../../../../enums/Permission';
import { formatUTCDateTime } from '../../../../helpers/TimeHelper';
import { useUserPermissions } from '../../../../hooks/Auth/useUserPermissions';
import { i18n } from '../../../../services/i18n';
import CanUser from '../../../Permissions/CanUser';
import BooleanLabelCell from '../../../Table/BooleanLabelCell/BooleanLabelCell';
import { AccountModel } from '../../../../models/Consumer/Account/AccountModel';
import './styles.scss';

interface Props {
  consumerIsInclusive: boolean;
  rowId: string;
  account: AccountModel;
  activeItem?: AccountModel;
  expandedRows?: string[];
  onRowClickToEdit: (account: AccountModel) => void;
  onRowExpand: (account: AccountModel) => void;
  onCustomerNumberAdd: (accountId: string) => void;
  accountIsSelected: (accountId: string) => boolean;
  handleAccountSelected: (accountId: string, isSelected: boolean | undefined) => void;
}

const AccountTableAccountRow: React.FC<Props> = ({
  consumerIsInclusive,
  rowId,
  account,
  activeItem,
  expandedRows,
  onRowClickToEdit,
  onRowExpand,
  onCustomerNumberAdd,
  accountIsSelected,
  handleAccountSelected,
}) => {
  const hasPermissions = useUserPermissions();
  const handleAccountEdit = hasPermissions(Permission.ConsumersUpdate)
    ? () => onRowClickToEdit(account)
    : undefined;

  const customerIdsText = useMemo(
    () => account.customerNumbers?.ids.map((id) => id.id).join(', '),
    [account.customerNumbers],
  );

  return (
    <Table.Row
      className={clsx({
        selectable: hasPermissions(Permission.ConsumersUpdate),
        'accordion-opened': expandedRows?.includes(rowId),
        'disabled-status-row': !account.enabled,
      })}
      active={activeItem === account}
      data-testid="account-row"
    >
      <Table.Cell>
        <Checkbox
          className="checkbox-input"
          checked={accountIsSelected(account.id)}
          onChange={(_, props) => handleAccountSelected(account.id, props.checked)}
        />
      </Table.Cell>
      <Table.Cell className="expander-cell" onClick={() => onRowExpand(account)}>
        {!isEmpty(account.customerNumbers?.ids) &&
          (expandedRows?.includes(rowId) ? <Icon name="angle down" /> : <Icon name="angle right" />)}
      </Table.Cell>
      <Table.Cell data-testid="accounts-table-name" onClick={handleAccountEdit}>
        {account.accountName}
      </Table.Cell>
      <Table.Cell data-testid="accounts-table-account-id" onClick={handleAccountEdit}>
        {account.id}
      </Table.Cell>
      <Table.Cell data-testid="accounts-table-start-date" onClick={handleAccountEdit}>
        {formatUTCDateTime(account.startDate)}
      </Table.Cell>
      <Table.Cell data-testid="accounts-table-last-name" onClick={handleAccountEdit}>
        {formatUTCDateTime(account.endDate)}
      </Table.Cell>
      <BooleanLabelCell
        value={account.enabled}
        textTrue={i18n('EnabledStatusName')}
        textFalse={i18n('DisabledStatusName')}
        data-testid="accounts-table-enabled"
        onClick={handleAccountEdit}
      />
      <BooleanLabelCell
        value={!!account.isInclusive}
        textTrue={i18n('ConsumerAccounts_Inclusive')}
        textFalse={i18n('ConsumerAccounts_Exclusive')}
        colorFalse="grey"
        data-testid="accounts-table-customer-numbers"
        onClick={handleAccountEdit}
      >
        {account.isInclusive && account.customerNumbers?.ids.length === 0 && (
          <Popup
            offset={[20]}
            content={i18n(
              consumerIsInclusive
                ? 'ConsumerAccounts_InclusiveNoCustomerNumbers_Hint'
                : 'ConsumerAccounts_ExclusiveNoCustomerNumbers_Hint',
            )}
            trigger={<Icon className="account-table-account-row-hint" name="info circle" color="grey" />}
          />
        )}
      </BooleanLabelCell>
      <Table.Cell
        className="overflow-cell"
        data-testid="accounts-table-customer-id-column"
        onClick={() => onRowExpand(account)}
      >
        {customerIdsText}
      </Table.Cell>
      <Table.Cell data-testid="accounts-table-buttons">
        <CanUser permission={Permission.ConsumersUpdate}>
          <Popup
            content={i18n('AccountTable_AccountRow_AddCustomerIdButton_Tooltip')}
            trigger={<Icon name="plus circle" link onClick={() => onCustomerNumberAdd(account.id)} />}
          />
        </CanUser>
      </Table.Cell>
    </Table.Row>
  );
};

export default AccountTableAccountRow;
