import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';

import {
  searchTripItineraryResponsePath,
  searchTripRawResponsePath,
  searchTripResponsePath,
  searchTripsPath,
} from '../../../paths';
import TripModalContent from '../TripResponse/TripModalContent';
import ItineraryModalContent from '../TripResponse/ItineraryModalContent';
import RawModalContent from '../TripResponse/RawModalContent';
import { i18n } from '../../../services/i18n';

import './styles.scss';

const SearchTripsModal: React.FC = () => {
  const history: History = useHistory();
  const params: { id: string } = useParams();
  const tripResponsePath = searchTripResponsePath(params.id);
  const itineraryResponsePath = searchTripItineraryResponsePath(params.id);
  const rawResponsePath = searchTripRawResponsePath(params.id);

  const tabMapping: Record<string, number> = {
    [tripResponsePath]: 0,
    [itineraryResponsePath]: 1,
    [rawResponsePath]: 2,
  };
  const getCurrentTab = (path: string): number => {
    return tabMapping[path] || 0;
  };
  const defaultActiveTab = getCurrentTab(history.location.pathname);

  const handleTabChange = (e: { index: number }) => {
    const newPath = Object.keys(tabMapping).find((key) => tabMapping[key] === e.index);
    if (newPath) {
      history.push(newPath);
    }
  };

  return (
    <Dialog
      visible
      draggable={false}
      onHide={() => history.push(searchTripsPath)}
      header={i18n('SearchTrips_Modal_Title')}
      className="trip-modal"
      baseZIndex={1001}
    >
      <TabView activeIndex={defaultActiveTab} onTabChange={handleTabChange}>
        <TabPanel header={i18n('SearchTrips_Trips_Title')}>
          <TripModalContent />
        </TabPanel>
        <TabPanel header={i18n('SearchTrips_Itinerary_Title')}>
          <ItineraryModalContent />
        </TabPanel>
        <TabPanel header={i18n('SearchTrips_Raw_Title')}>
          <RawModalContent />
        </TabPanel>
      </TabView>
    </Dialog>
  );
};

export default SearchTripsModal;
