import React from 'react';
import { i18n } from '../../../services/i18n';
import ModalBase from '../ModalBase/ModalBase';

interface Props {
  isOpen: boolean;
  isEdit: boolean;
  isAdding?: boolean;
  isUserConsumerTypesDeleted?: boolean;
  header: string;
  content?: string;
  onButtonText?: string;
  onClose: (isSuccess?: boolean) => void;
}

const ModalSave: React.FC<Props> = ({
  isOpen,
  isEdit,
  isAdding = false,
  isUserConsumerTypesDeleted = false,
  header,
  content,
  onButtonText,
  onClose,
}) => {
  const getCancelButtonTextForAddOrCreate = () =>
    isAdding ? i18n('Modals_SaveAdding_CancelButton') : i18n('Modals_SaveCreation_CancelButton');
  const getContent = () =>
    isUserConsumerTypesDeleted
      ? i18n('Modals_Save_IsUserConsumerTypesDeleted_Content')
      : i18n('Modals_Save_Content');

  return (
    <ModalBase
      isOpen={isOpen}
      header={header}
      content={content ?? getContent()}
      cancelButtonText={
        isEdit ? i18n('Modals_SaveEditing_CancelButton') : getCancelButtonTextForAddOrCreate()
      }
      okButtonText={onButtonText ?? i18n('Modals_Save_SaveButton')}
      onClose={onClose}
    />
  );
};

export default ModalSave;
