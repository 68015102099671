export type EventsFormViewModel = {
  sourceId: string;
  globalCustomerNumber: string;
  accountId: string;
  travelerEmailAddress: string;
  travelerFirstName: string;
  travelerLastName: string;
};

export const defaultEventsFormValue: EventsFormViewModel = {
  sourceId: '',
  globalCustomerNumber: '',
  accountId: '',
  travelerEmailAddress: '',
  travelerFirstName: '',
  travelerLastName: '',
};
