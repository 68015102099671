import React from 'react';
import logo from '../../../assets/logo.svg';
import { i18n } from '../../../services/i18n';

import './styles.scss';

const Logo = (): JSX.Element => {
  return (
    <div className="logoWrapper" data-testid="logo-wrapper">
      <div className="container-logo">
        <img className="logo" src={logo} alt="logo" data-testid="logo-image" />
        <span className="slogan" data-testid="logo-slogan">
          {i18n('Logo_Slogan')}
        </span>
      </div>
      <div className="container-projectName">
        <span className="projectName" data-testid="logo-projectName">
          {i18n('Logo_ProjectName')}
        </span>
      </div>
      <a href="https://www.bcdtravel.com/">{`${i18n('CompanyName')} ${i18n('Logo_ProjectName')}`}</a>
    </div>
  );
};

export default Logo;
