/* eslint-disable @typescript-eslint/no-floating-promises */
import { useCallback, useEffect } from 'react';
import { TripAccessSettingEntry } from '../../models/TripAccess/TripAccessSettingEntry';
import { useAppSelector } from './useAppSelector';
import { cloneDeep } from 'lodash';
import { fetchTripAccessAdvancedSettingsAction } from '../actions/tripAccessAdvancedSettingsActions';
import { useAppDispatch } from './useAppDispatch';

export const useTripAccessAdvancedSettings: () => [
  {
    getAdvancedSettingsEntries: () => TripAccessSettingEntry[];
    isLoading: boolean;
  },
] = () => {
  const dispatch = useAppDispatch();
  const tripAccessAdvancedSettings = useAppSelector((s) => s.tripAccessAdvancedSettings);

  useEffect(() => {
    if (tripAccessAdvancedSettings.items.length === 0) {
      dispatch(fetchTripAccessAdvancedSettingsAction());
    }
  }, [tripAccessAdvancedSettings.items.length, dispatch]);

  const getAdvancedSettingsEntries = useCallback(
    () => cloneDeep(tripAccessAdvancedSettings.items),
    [tripAccessAdvancedSettings.items],
  );

  return [{ getAdvancedSettingsEntries, isLoading: tripAccessAdvancedSettings.isLoading }];
};
