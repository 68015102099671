import { Tag } from 'primereact/tag';
import React from 'react';
import Root from '../Root';

export interface BooleanLabelPrimeProps {
  value?: boolean;
  textTrue?: string;
  textFalse?: string;
  'data-testid'?: string;
}

const BooleanLabelPrime: React.FC<BooleanLabelPrimeProps> = ({
  value,
  textTrue,
  textFalse,
  'data-testid': dataTestId,
}) => {
  return (
    <div className="boolean-label-prime-container">
      {value !== undefined && (
        <Tag
          value={value ? textTrue : textFalse}
          icon="pi pi-circle-fill"
          className="label-tag"
          pt={{ root: { 'data-testid': dataTestId }, icon: { className: value ? 'blue' : 'red' } }}
        />
      )}
    </div>
  );
};

export default BooleanLabelPrime;
