import { TripRequestDTO, TripResponseDTO } from '../../models/Trip/TripModel';
import { ApiError } from '../../models/Api/ApiError';
import { useQuery } from '@tanstack/react-query';
import { TripService } from '../../services/TripService';
import { getBaseQueryOptions } from '../baseQueryOptions';

export interface UseTripValuesInterface {
  trip: TripResponseDTO | undefined;
  isLoading: boolean;
  error: ApiError | null;
}

export const useItineraryTripQuery: (tripId: string) => UseTripValuesInterface = (tripId: string) => {
  const { isFetching, error, data } = useQuery<TripResponseDTO, ApiError>({
    ...getBaseQueryOptions(false),
    queryKey: ['tripItinerary', tripId],
    queryFn: () => TripService.getItineraryTrip(<TripRequestDTO>{ id: tripId }),
  });

  return {
    trip: data,
    isLoading: isFetching,
    error: error,
  };
};
