import React from 'react';

interface Props {
  text: string;
}

const NoDataRowPrime: React.FC<Props> = (props) => {
  return (
    <div className="no-data-row-prime" data-testid="no-data-row-message">
      <i className="pi pi-inbox no-data-icon"></i>
      {props.text}
    </div>
  );
};

export default NoDataRowPrime;
