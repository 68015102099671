import { ApiAccess } from '../enums/ApiAccess';
import { getUTCDateByDate } from '../helpers/TimeHelper';
import { ConsumerFormViewModel } from '../models/Consumer/ConsumerFormViewModel';
import { ScopesPresetModel } from '../models/Consumer/Scopes/ScopesPresetModel';
import { SmIdsModel } from '../models/Consumer/SmIdsModel';
import { TripsModel } from '../models/Consumer/ApiAccess/TripsModel';
import { ConsumerModel } from './../models/Consumer/ConsumerModel';
import { WaiversModel } from '../models/Consumer/ApiAccess/WaiversModel';

export const mapToConsumerModel = (
  model: ConsumerFormViewModel,
  scopesPresets: ScopesPresetModel[],
  prevModel?: ConsumerModel,
): ConsumerModel => {
  const result = {
    ...prevModel,
    consumerId: model.consumerId,
    consumerKey: model.consumerKey ?? '',
    enabled: model.enabled,
    activeStartDate: model.activeStartDate && getUTCDateByDate(model.activeStartDate).toJSON(),
    activeEndDate: model.activeEndDate && getUTCDateByDate(model.activeEndDate).toJSON(),
    contacts: model.contacts.map((c) => c.model),
    consumerTypes: model.consumerTypes,
    trips: mapTripsModel(model, scopesPresets, prevModel),
    expenses: mapApiAuthorizationProperty(model, ApiAccess.Expenses),
    waivers: mapWaiversProperty(model),
    xx1: mapApiAuthorizationProperty(model, ApiAccess.Xx1),
    receipts: mapApiAuthorizationProperty(model, ApiAccess.Receipts),
    tickets: mapApiAuthorizationProperty(model, ApiAccess.Tickets),
    flightAlerts: mapApiAuthorizationProperty(model, ApiAccess.FlightAlerts),
    enhApi: mapApiAuthorizationProperty(model, ApiAccess.Enh),
  };

  return mapObjectPropertiesFromPrevModel(result, prevModel);
};

const mapTripsModel = (
  model: ConsumerFormViewModel,
  scopesPresets: ScopesPresetModel[],
  prevModel?: ConsumerModel,
): TripsModel | undefined => {
  const isTripEnabled = hasApiAccess(model, ApiAccess.Trips);
  const scopesFromDefaultPreset = scopesPresets.find((s) => s.isDefault)?.preset;

  const isNewConsumerWithoutTripAccess = !model.consumerId && !isTripEnabled;
  if (isNewConsumerWithoutTripAccess) {
    return { scopes: scopesFromDefaultPreset };
  }

  if (isTripEnabled) {
    const areTripsDisabledOnPrevModel = !prevModel?.trips?.enabled && !prevModel?.trips?.smIds;
    if (!model.consumerId || (model.consumerId && areTripsDisabledOnPrevModel)) {
      return {
        enabled: true,
        smIds: { isInclusive: true } as SmIdsModel,
        scopes: scopesFromDefaultPreset,
        tripChanges: { enabled: false },
      };
    }
  }

  return { enabled: isTripEnabled };
};

const mapApiAuthorizationProperty = (
  model: ConsumerFormViewModel,
  apiAccess: ApiAccess,
): { enabled: boolean } | undefined => {
  const isEnabled = hasApiAccess(model, apiAccess);

  if (!isEnabled && !model.consumerId) {
    return undefined;
  }

  return { enabled: isEnabled };
};

const mapWaiversProperty = (model: ConsumerFormViewModel): WaiversModel | undefined => {
  return hasApiAccess(model, ApiAccess.WaiversStandAlone)
    ? { enabled: true, match: hasApiAccess(model, ApiAccess.WaiversMatching) }
    : undefined;
};

const mapObjectPropertiesFromPrevModel = (model: ConsumerModel, prevModel?: ConsumerModel): ConsumerModel => {
  if (!prevModel) {
    return model;
  }

  const result: ConsumerModel = { ...model };

  for (const [k, v] of Object.entries(result) as [keyof ConsumerModel, ConsumerModel][]) {
    if (typeof v === 'object' && !Array.isArray(v) && k in prevModel) {
      result[k] = { ...(prevModel[k] as ConsumerModel), ...v } as never;
    }
  }

  return result;
};

const hasApiAccess = (model: ConsumerFormViewModel, apiAccess: ApiAccess): boolean =>
  model.apiAccess?.includes(apiAccess) ?? false;
