import React from 'react';
import { Grid, Icon, Popup, Radio } from 'semantic-ui-react';

import { RadioOption } from '../NamedRadioGroup/NamedRadioGroup';

import './styles.scss';

interface Props {
  value?: boolean;
  groupName: string;
  className?: string;
  disabled?: boolean;
  options: RadioOption[];
  onChange?: (v: boolean) => void;
  padded?: boolean | 'horizontally' | 'vertically';
  dataTestId?: string;
}

const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.code === 'Enter') {
    event.preventDefault();
  }
};

const RadioGroup: React.FC<Props> = ({
  value,
  groupName,
  disabled,
  options,
  onChange,
  padded,
  dataTestId,
}) => {
  return (
    <Grid padded={padded}>
      <Grid.Row columns={2}>
        {options.map((o, i) => {
          return (
            <Grid.Column key={o.key}>
              <Radio
                label={o.text}
                name={groupName}
                value={o.value.toString()}
                disabled={disabled}
                checked={value?.toString() === o.value.toString()}
                onChange={(_, { value: v }) => {
                  onChange && onChange(v === 'true');
                }}
                onKeyDown={handleKeyDown}
                data-testid={`${dataTestId}-radio-${i}`}
              />
              {o.popupMessage && (
                <Popup
                  offset={[11]}
                  position="top right"
                  trigger={
                    <Icon
                      name="info circle"
                      color="grey"
                      className="radio-group-popup-message-icon"
                      data-testid="radio-group-popup-message-icon"
                    />
                  }
                  content={o.popupMessage}
                  data-testid="radio-group-popup-message"
                />
              )}
            </Grid.Column>
          );
        })}
      </Grid.Row>
    </Grid>
  );
};

export default RadioGroup;
