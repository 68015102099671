import React, { useEffect, useMemo } from 'react';
import { Button, Dimmer, Grid, Icon, Input, Loader } from 'semantic-ui-react';

import { Permission } from '../../enums/Permission';
import CanUser from '../../features/Permissions/CanUser';
import UserForm from '../../features/User/UserForm/UserForm';
import UserTable from '../../features/User/UserTable';
import { useErrorToast } from '../../hooks/Alert/useErrorToast';
import { useBreakpoint, useColumnBreakpoint } from '../../hooks/BreakPointProvider/breakpoint';
import { usePageNavigation } from '../../hooks/Navigation/usePageNavigation';
import { useActiveItem } from '../../hooks/Table/useActiveItem';
import { UserFormViewModel } from '../../models/User/UserFormViewModel';
import { UserModel } from '../../models/User/UserModel';
import { i18n } from '../../services/i18n';
import { UserService } from '../../services/UserService';
import { useAutoFocus } from '../../hooks/Form/useAutoFocus';
import { useUsers } from '../../store/hooks/useUsers';
import { useClaims } from '../../store/hooks/useClaims';
import { useSuccessToast } from '../../hooks/Alert/useSuccessToast';

import '../styles.scss';
import './styles.scss';

const UsersPage: React.FC = () => {
  const [
    { users, isLoading, error, successMessage },
    { createUser, updateUser, handleError, handleSuccessMessage },
  ] = useUsers();
  const [{ claims, isLoading: isClaimsLoading }] = useClaims();

  const breakpoints = useBreakpoint();
  const [{ getFormColumnsCount, getTableColumnsCount }] = useColumnBreakpoint();

  const [
    { path, id, haveActiveItem, isFormOpen, searchTerm },
    { goToMainPage, goToUrl, goToModal, handleSearchChanged },
  ] = usePageNavigation();
  const [{ activeItem }, { updateActiveItem }] = useActiveItem<UserModel>();
  const [{ inputFocusRef }, { focus }] = useAutoFocus();

  const service = UserService;
  const filteredUsers = useMemo(() => service.searchUsers(users, searchTerm), [service, users, searchTerm]);

  const handleUserSave = (model: UserFormViewModel) => {
    goToMainPage();
    if (model.id) {
      updateUser(model, claims);
    } else {
      createUser(model, claims);
    }
  };

  useEffect(() => {
    users && updateActiveItem(users, 'id', id, haveActiveItem);
  }, [path, id, haveActiveItem, updateActiveItem, users]);

  useEffect(() => {
    focus(!isFormOpen);
  }, [focus, isFormOpen]);

  useErrorToast(error, handleError);
  useSuccessToast(successMessage, handleSuccessMessage);

  return (
    <>
      {(isLoading || isClaimsLoading) && (
        <Dimmer active inverted>
          <Loader size="massive" className="users-page-loader" />
        </Dimmer>
      )}
      <h2 className="page-title">{i18n('UsersPage_Title')}</h2>
      <div className="users-title-container page-title-container">
        <Input
          data-testid="users-search-input"
          size="mini"
          icon="search"
          iconPosition="left"
          placeholder={i18n('UsersPage_SearchPlaceholder')}
          ref={inputFocusRef}
          value={searchTerm}
          onChange={(e) => handleSearchChanged(e.target.value)}
        />
        <CanUser permission={Permission.UsersCreate}>
          <Button
            data-testid="create-user-button"
            primary
            className="create-button"
            onClick={() => goToModal()}
          >
            <Icon name="plus circle" />
            <span>{i18n('UsersPage_CreateUserButton')}</span>
          </Button>
        </CanUser>
      </div>

      <Grid columns="equal" className="page-body-container">
        {(!breakpoints.sm || !isFormOpen) && (
          <Grid.Column width={getTableColumnsCount(isFormOpen)}>
            <div className="users-content-container table-sticky-container">
              <UserTable
                claims={claims}
                items={filteredUsers}
                activeItem={activeItem}
                onUserEdit={(m) => (m?.id ? goToModal(`${m?.id}`) : goToMainPage())}
                noDataMessage={searchTerm ? i18n('Table_No_Result') : i18n('UserTable_No_Data')}
              />
            </div>
          </Grid.Column>
        )}
        {isFormOpen && activeItem && (
          <Grid.Column width={getFormColumnsCount()}>
            <div className="users-content-container">
              <UserForm
                claims={claims}
                model={activeItem}
                onSave={handleUserSave}
                onClose={(redirectUrl) => goToUrl(redirectUrl)}
              />
            </div>
          </Grid.Column>
        )}
      </Grid>
    </>
  );
};

export default UsersPage;
