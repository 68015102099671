import { RadioOption } from '../NamedRadioGroup/NamedRadioGroup';
import { getTripAccessOptions } from './ConsumerFormConstants';

export type PopupOptionsType = { [key in string]: { reason: boolean; message: string } };

export const getWithPopupTripAccessOptions: (popups: PopupOptionsType) => RadioOption[] = (popups) => {
  const options = getTripAccessOptions();

  return options.map((o) => (popups[o.key]?.reason && { ...o, popupMessage: popups[o.key].message }) || o);
};
