import { createSlice } from '@reduxjs/toolkit';
import { PageModel } from '../../models/Store/PageModel{T}';
import { ClaimModel } from '../../models/User/ClaimModel';
import { claimsPrefix } from '../actions/actionTypes';
import { fetchClaimsAction } from '../actions/claimsActions';

const initialState: PageModel<ClaimModel[]> = {
  items: [] as ClaimModel[],
  isLoading: false,
};

const claimsSlice = createSlice({
  name: claimsPrefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaimsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClaimsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(fetchClaimsAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default claimsSlice.reducer;
