import { Action, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchTripsActionName, resetTripsActionName } from './actionTypes';
import { FetchTripsActionModel, TripDTO, TripsRequestDTO } from '../../models/Trip/TripModel';
import { TripsService } from '../../services/TripsService';
import { mapTripsModel } from '../../mappers/TripsMapper';

const service = TripsService;

const getEventsTrips = async (payload: TripsRequestDTO) => await service.getEventsTrips(payload);
const getSearchTrips = async (payload: TripsRequestDTO) => await service.getSearchTrips(payload);

export const fetchEventsTripsAction = (payload: TripsRequestDTO) =>
  fetchTripsAction({ payload, requestMethod: getEventsTrips });

export const fetchSearchTripsAction = (payload: TripsRequestDTO) =>
  fetchTripsAction({ payload, requestMethod: getSearchTrips });

export const fetchTripsAction = createAsyncThunk<
  FetchTripsActionModel,
  { payload: TripsRequestDTO; requestMethod: (payload: TripsRequestDTO) => Promise<TripDTO[]> }
>(fetchTripsActionName, async (model, thunkApi) => {
  try {
    const response = await model.requestMethod(model.payload);

    return {
      items: mapTripsModel(response),
    };
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const resetTripsAction: () => Action = () => ({
  type: resetTripsActionName,
});
