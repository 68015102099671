import { RadioOption } from '../features/NamedRadioGroup/NamedRadioGroup';
import { i18n } from '../services/i18n';

export const getDisabledEnabledOptions: () => RadioOption[] = () => [
  {
    key: '1',
    value: false,
    text: i18n('DisabledStatusName'),
  },
  {
    key: '2',
    value: true,
    text: i18n('EnabledStatusName'),
  },
];
