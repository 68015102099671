import clsx from 'clsx';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { Permission } from '../../../../enums/Permission';
import { formatUTCDateTime } from '../../../../helpers/TimeHelper';
import { useUserPermissions } from '../../../../hooks/Auth/useUserPermissions';
import { i18n } from '../../../../services/i18n';
import BooleanLabelCell from '../../../Table/BooleanLabelCell/BooleanLabelCell';
import { SmIdModel } from './../../../../models/Consumer/SmIdModel';

interface Props {
  active?: boolean;
  customer: SmIdModel;
  accountId: string;
  onCustomerNumberEdit: () => void;
}

const AccountTableCustomerNumberRow: React.FC<Props> = ({
  customer,
  active,
  accountId,
  onCustomerNumberEdit,
}) => {
  const hasPermissions = useUserPermissions();
  const handleCustomerNumberEdit = hasPermissions(Permission.ConsumersUpdate)
    ? () => onCustomerNumberEdit()
    : undefined;
  return (
    <Table.Row
      active={active}
      className={clsx('expandable-row', {
        selectable: hasPermissions(Permission.ConsumersUpdate),
        'disabled-status-row': customer.enabled === false,
      })}
      data-testid="customer-number-row"
      onClick={handleCustomerNumberEdit}
    >
      <Table.Cell data-testid="accounts-table-customer-empty-cell-checkbox"></Table.Cell>
      <Table.Cell data-testid="accounts-table-customer-empty-cell-dropdown"></Table.Cell>
      <Table.Cell data-testid="accounts-table-customer-numbers">{customer.id}</Table.Cell>
      <Table.Cell data-testid="accounts-table-customer-account-id">{accountId}</Table.Cell>
      <Table.Cell data-testid="accounts-table-customer-start-date">
        {formatUTCDateTime(customer.startDate)}
      </Table.Cell>
      <Table.Cell data-testid="accounts-table-end-date">{formatUTCDateTime(customer.endDate)}</Table.Cell>
      <BooleanLabelCell
        value={customer.enabled}
        textTrue={i18n('EnabledStatusName')}
        textFalse={i18n('DisabledStatusName')}
        colSpan="4"
        data-testid="accounts-table-customer-enabled"
      />
    </Table.Row>
  );
};

export default AccountTableCustomerNumberRow;
