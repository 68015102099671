import { useEffect, useState } from 'react';

export const useChangeAccountsStatusSelection: () => [
  {
    buttonsDisabled: boolean;
    selectedAccountIds: string[];
  },
  {
    accountIsSelected: (accountId: string) => boolean;
    handleAccountSelected: (accountId: string, isChecked: boolean | undefined) => void;
    resetSelection: () => void;
  },
] = () => {
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(true);
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);

  const accountIsSelected = (accountId: string): boolean => {
    return selectedAccountIds.includes(accountId);
  };

  const handleAccountSelected = (accountId: string, isChecked: boolean | undefined) => {
    isChecked
      ? setSelectedAccountIds([...selectedAccountIds, accountId])
      : setSelectedAccountIds(selectedAccountIds.filter((id) => id !== accountId));
  };

  const resetSelection = () => {
    setSelectedAccountIds([]);
  };

  useEffect(() => {
    if (buttonsDisabled && selectedAccountIds.length > 0) {
      setButtonsDisabled(false);
      return;
    }
    if (!buttonsDisabled && selectedAccountIds.length == 0) {
      setButtonsDisabled(true);
    }
  }, [selectedAccountIds]);

  return [
    {
      buttonsDisabled,
      selectedAccountIds,
    },
    {
      accountIsSelected,
      handleAccountSelected,
      resetSelection,
    },
  ];
};
