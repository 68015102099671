import React, { useEffect, useState } from 'react';
import SearchTripsForm from '../../features/SearchTrips/SearchTripsForm/SearchTripsForm';
import SearchTripsResult from './SearchTripsResult';
import { i18n } from '../../services/i18n';
import { Segment } from 'semantic-ui-react';
import { useTripsQuery } from '../../queries/hooks/useTripsQuery/useTripsQuery';
import './styles.scss';

const SearchTripsPage: React.FC = () => {
  const [{ isLoading: isTripsLoading }, { handleResetTrips }] = useTripsQuery();
  const [isSearched, setIsSearched] = useState(false);

  useEffect(() => {
    if (isTripsLoading) {
      setIsSearched(true);
    }
  }, [isTripsLoading]);

  useEffect(() => {
    return () => {
      handleResetTrips();
    };
  }, []);

  return (
    <>
      <Segment>
        <div className="page-title">
          <h2>{i18n('SearchTrips_Link')}</h2>
        </div>
        <div className="page-body-container">
          <SearchTripsForm isDirty={isSearched} onClear={() => setIsSearched(false)} />
        </div>
      </Segment>

      {isSearched && <SearchTripsResult />}
    </>
  );
};

export default SearchTripsPage;
