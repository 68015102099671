import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TripModel } from '../../../models/Trip/TripModel';
import {
  defaultSearchTripsFormValue,
  SearchTripsFormViewModel,
} from '../../../models/SearchTrips/SearchTripsFormViewModel';
import { TripsService } from '../../../services/TripsService';
import { useEffect, useState } from 'react';
import { mapTripsModel } from '../../../mappers/TripsMapper';
import { ApiError } from '../../../models/Api/ApiError';
import { getBaseQueryOptions } from '../../baseQueryOptions';
import { isAnyFieldFilled } from '../../../helpers/SearchTripsFormHelpers';

export interface UseTripsValuesInterface {
  trips: TripModel[] | undefined;
  isLoading: boolean;
  error: ApiError | null;
  isNotFound: boolean;
}
export interface UseTripsCallbacksInterface {
  getSearchTrips: (payload: SearchTripsFormViewModel) => void;
  handleResetTrips: () => void;
}

export const useTripsQuery: () => [UseTripsValuesInterface, UseTripsCallbacksInterface] = () => {
  const [formState, setFormState] = useState<SearchTripsFormViewModel>(defaultSearchTripsFormValue);

  const getTripsFromService = async (formState: SearchTripsFormViewModel) => {
    const tripsData = await TripsService.getSearchTrips(formState);
    return tripsData ? mapTripsModel(tripsData) : undefined;
  };

  const { isFetching, error, data, refetch } = useQuery<TripModel[] | undefined, ApiError>({
    ...getBaseQueryOptions(true),
    queryKey: ['trips'],
    queryFn: () => getTripsFromService(formState),
  });

  const queryClient = useQueryClient();

  const getSearchTrips = (payload: SearchTripsFormViewModel) => {
    setFormState(payload);
  };

  const handleResetTrips = () => {
    void queryClient.resetQueries({ queryKey: ['trips'] });
    void queryClient.invalidateQueries({ queryKey: ['trips'] });
  };

  useEffect(() => {
    if (isAnyFieldFilled(formState)) {
      void refetch();
    }
  }, [formState]);

  return [
    {
      trips: data,
      isLoading: isFetching,
      error: error,
      isNotFound: (error?.response?.status && error.response.status === 404) || false,
    },
    {
      getSearchTrips,
      handleResetTrips,
    },
  ];
};
