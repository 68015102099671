import clsx from 'clsx';
import { debounce, isEqual } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { ReactComponent as ArrowDown } from '../../../../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRight } from '../../../../../../assets/icons/arrow-right.svg';
import {
  getTripAccessCheckboxState,
  getTripAccessEntriesCount,
  updateTripAccessChildrenState,
} from '../../../../../../helpers/TripAccessHelper';
import { CheckboxState } from '../../../../../../models/Common/CheckboxState';
import { TripAccessAnchorRef } from '../../../../../../models/TripAccess/TripAccessAnchorRef';
import { TripAccessSettingEntry } from '../../../../../../models/TripAccess/TripAccessSettingEntry';

const baseIndentPixel = 16;
const baseIndentPixelPageEmbedded = 40;
const indentPixelStep = 25;

interface Props {
  anchorReferences: TripAccessAnchorRef[];
  disabled?: boolean;
  model: TripAccessSettingEntry;
  indent?: number;
  onUpdated: (name: string, model: TripAccessSettingEntry) => void;
  pageEmbedded?: boolean;
}

const TripAccessAdvancedSettingsRow: React.FC<Props> = React.memo(
  ({ disabled = false, model, indent = 0, onUpdated, anchorReferences, pageEmbedded }) => {
    const baseIndent = pageEmbedded ? baseIndentPixelPageEmbedded : baseIndentPixel;

    const handleExpandedChange = debounce(
      () => {
        onUpdated(model.name, {
          ...model,
          isCollapsed: !model.isCollapsed,
        });
      },
      500,
      {
        leading: true,
      },
    );

    const checkboxState = useMemo(() => getTripAccessCheckboxState(model), [model]);

    const handleChange = useCallback(() => {
      const nextCheckboxState = checkboxState !== CheckboxState.Checked;
      onUpdated(model.name, {
        ...model,
        enabled: nextCheckboxState,
        children:
          model.children && model.children.length > 0
            ? updateTripAccessChildrenState(model.children, nextCheckboxState)
            : undefined,
      });
    }, [checkboxState, model, onUpdated]);

    const handleUpdated = useCallback(
      (name: string, newState: TripAccessSettingEntry) => {
        if (model.children && model.children.length > 0) {
          onUpdated(model.name, {
            ...model,
            children: [...(model.children?.filter((c) => c.name !== name) ?? []), newState].sort(
              (a, b) => a.order - b.order,
            ),
          });
        }
      },
      [model, onUpdated],
    );

    const maxHeight = useMemo(() => {
      return (getTripAccessEntriesCount(model) - 1) * 50; // minus self checkbox size
    }, [model]);

    return (
      <>
        <div
          className="trip-access-advanced-settings-row"
          ref={anchorReferences.find((r) => r.name === model.name)?.ref}
        >
          <span
            className={clsx('advanced-settings-dropdown-icon', {
              'advanced-settings-dropdown-icon-root': indent === 0,
              'advanced-settings-dropdown-icon-hidden': !model.children || model.children.length === 0,
              'icon-right': model.isCollapsed,
            })}
            onClick={handleExpandedChange}
            style={{ right: `${indent ? indent * indentPixelStep + baseIndent : 0}px` }}
          >
            {model.isCollapsed ? <ArrowRight /> : <ArrowDown />}
          </span>
          <Checkbox
            disabled={disabled}
            checked={checkboxState === CheckboxState.Checked}
            indeterminate={checkboxState === CheckboxState.Indeterminate}
            label={model.name}
            onChange={handleChange}
          />
        </div>
        {model.children && model.children.length !== 0 && (
          <div
            style={{ maxHeight: model.isCollapsed ? 0 : maxHeight }}
            className={clsx('advanced-settings-indent-container', {
              'root-indent': indent === 0,
              hidden: !!model.isCollapsed,
              shown: !model.isCollapsed,
            })}
          >
            {model.children.map((child) => (
              <TripAccessAdvancedSettingsRow
                anchorReferences={anchorReferences}
                disabled={disabled}
                key={child.name}
                model={child}
                indent={indent + 1}
                onUpdated={handleUpdated}
                pageEmbedded={pageEmbedded}
              />
            ))}
          </div>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.onUpdated, nextProps.onUpdated) && isEqual(prevProps.model, nextProps.model);
  },
);
TripAccessAdvancedSettingsRow.displayName = 'TripAccessAdvancedSettingsRow';

export default TripAccessAdvancedSettingsRow;
