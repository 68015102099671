import AccountLookupForm from './AccountLookupForm/AccountLookupForm';
import AccountsActionsMenu from './AccountsActionsMenu';
import AccountsTableSwitcher from './AccountsTableSwitcher';
import CanUser from '../../Permissions/CanUser';
import CustomerNumbersForm from './CustomerNumbersForm/CustomerNumbersForm';
import EditAccountForm from './EditAccountForm/EditAccountForm';
import ModalBase from '../../Modal/ModalBase/ModalBase';
import ModalSave from '../../Modal/ModalSave/ModalSave';
import NewAccountsForm from './NewAccountsForm/NewAccountsForm';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AccountModel } from '../../../models/Consumer/Account/AccountModel';
import { AccountsFormSaveModel } from '../../../models/Consumer/Account/AccountsFormSaveModel';
import { AccountsModel } from '../../../models/Consumer/Account/AccountsModel';
import { Button, Checkbox, Grid, Icon, Input, Label, Popup } from 'semantic-ui-react';
import { consumerAccountsAddPath, consumerAccountsPath, consumerCustomerNumbersPath } from '../../../paths';
import { consumerIdParameter } from './../../../paths';
import { ConsumerModel } from '../../../models/Consumer/ConsumerModel';
import { ConsumerService } from '../../../services/ConsumerService';
import { CustomerNumbersFormViewModel } from '../../../models/Consumer/Account/CustomerNumbersFormViewModel';
import { i18n } from '../../../services/i18n';
import { Permission } from '../../../enums/Permission';
import { Route, Switch, useParams } from 'react-router-dom';
import { SmIdModel } from '../../../models/Consumer/SmIdModel';
import { useAccountSearch } from '../../../hooks/Navigation/useAccountSearch';
import { useActiveItem } from '../../../hooks/Table/useActiveItem';
import { useAppSelector } from '../../../store/hooks/useAppSelector';
import { useChangeAccountsStatusSelection } from '../../../hooks/Consumer/useChangeAccountsStatusSelection';
import { useColumnBreakpoint } from '../../../hooks/BreakPointProvider/breakpoint';
import { useNotification } from '../../../hooks/Alert/useNotification';
import { usePageNavigation } from '../../../hooks/Navigation/usePageNavigation';
import './styles.scss';
import WarningModalHeader from '../../Modal/WarningModalHeader/WarningModalHeader';

interface Props {
  consumer: ConsumerModel;
  onConsumerIsInclusiveUpdated: (consumer: ConsumerModel, isInclusive: boolean) => void;
  onConsumerAccountUpdated: (
    consumer: ConsumerModel,
    accountsForm: AccountsFormSaveModel,
    prevModel?: AccountModel,
  ) => void;
  onConsumerCustomerNumbersUpdated: (
    model: ConsumerModel,
    customerNumber: CustomerNumbersFormViewModel,
    accountId: string,
    prevModel?: SmIdModel,
  ) => void;
  onConsumerAccountsStatusUpdated: (model: ConsumerModel, accountIds: string[], enabled: boolean) => void;
}

const AccountsTab: React.FC<Props> = ({
  consumer,
  onConsumerIsInclusiveUpdated,
  onConsumerAccountUpdated,
  onConsumerCustomerNumbersUpdated,
  onConsumerAccountsStatusUpdated,
}) => {
  const [isInclusiveChangeStarted, setIsInclusiveChangeStarted] = useState(false);
  const [isInclusiveModalOpen, setIsInclusiveModalOpen] = useState(false);
  const [allAccountIds, setAllAccountIds] = useState<AccountModel[]>();
  const [accountsData, setAccountsData] = useState<AccountsModel>();
  const [activeCustomerNumber, setActiveCustomerNumber] = useState<SmIdModel>();
  const [isChangeAccountsStatusModalOpen, setIsChangeAccountsStatusModalOpen] = useState(false);
  const [newAccountsStatusEnabled, setNewAccountsStatusEnabled] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  const params: { accountId: string } = useParams();
  const [{ getFormColumnsCount }] = useColumnBreakpoint();
  const [
    { path, id, haveActiveItem, isFormOpen, searchTerm },
    { goToMainPage, goToUrl, handleSearchChanged },
  ] = usePageNavigation();
  const { isShowSearch } = useAccountSearch(path);
  const [{ activeItem }, { updateActiveItem }] = useActiveItem<AccountModel>();
  const [{ isNotificationOpen, notificationMessage }, { openNotification, closeNotification }] =
    useNotification();
  const [
    { buttonsDisabled, selectedAccountIds },
    { accountIsSelected, handleAccountSelected, resetSelection },
  ] = useChangeAccountsStatusSelection();

  const service = ConsumerService;
  const consumerId: string = consumer.consumerId ?? '';

  const getIsInclusiveNewStatusName = useCallback((isInclusive?: boolean) => {
    return isInclusive ? i18n('InclusiveLogicName') : i18n('ExclusiveLogicName');
  }, []);

  const isInclusiveStatusName: string = useMemo(() => {
    return getIsInclusiveNewStatusName(accountsData?.isInclusive);
  }, [accountsData, getIsInclusiveNewStatusName]);

  const isInclusiveNewStatusName: string = useMemo(() => {
    return getIsInclusiveNewStatusName(!accountsData?.isInclusive);
  }, [accountsData, getIsInclusiveNewStatusName]);

  const showInclusivePopup = useCallback(
    (condition: boolean) => {
      condition && openNotification(i18n(`ConsumerAccounts_${isInclusiveStatusName}_Hint`));
    },
    [isInclusiveStatusName, openNotification],
  );

  const updateData = useCallback(
    (consumerModel: ConsumerModel) => {
      const newAccounts = service.getAccountsFromConsumer(consumerModel);

      setAllAccountIds(newAccounts.ids);
      setAccountsData(newAccounts);
      resetSelection();
    },
    [service],
  );

  const updateActiveCustomerNumberItem = useCallback(
    (accountId: string, customerNumberId: string) => {
      accountId && haveActiveItem
        ? setActiveCustomerNumber(
            allAccountIds
              ?.find((acc) => `${acc.id}` === accountId)
              ?.customerNumbers?.ids.find((cn) => cn.id === customerNumberId),
          )
        : setActiveCustomerNumber({} as SmIdModel);
    },
    [allAccountIds, haveActiveItem],
  );

  const showHideInclusiveModal = (isVisible: boolean) => {
    setIsInclusiveModalOpen(isVisible);
    !isVisible && setIsInclusiveChangeStarted(false);
  };

  const handleInclusiveModalClose = (isChanged?: boolean) => {
    showHideInclusiveModal(false);
    setIsAcknowledged(false);
    isChanged &&
      handleAccountsDataSave(
        accountsData as unknown as AccountsFormSaveModel,
        true,
        !accountsData?.isInclusive,
      );
  };

  const showChangeAccountsStatusModal = (newStatusEnabled: boolean) => {
    setNewAccountsStatusEnabled(newStatusEnabled);
    setIsChangeAccountsStatusModalOpen(true);
  };

  const handleChangeAccountsStatusModalClose = (isChanged?: boolean) => {
    setIsChangeAccountsStatusModalOpen(false);
    isChanged && handleAccountsStatusSave(selectedAccountIds, newAccountsStatusEnabled);
  };

  const handleCustomerNumbersClose = (redirectUrl?: string) => {
    goToUrl(redirectUrl ?? consumerAccountsPath.replace(consumerIdParameter, consumerId));
  };

  const handleAccountAdd = () => goToUrl(consumerAccountsAddPath.replace(consumerIdParameter, consumerId));

  const handleAccountsDataSave = (
    accountsForm: AccountsFormSaveModel,
    isInclusiveChange?: boolean,
    isInclusive?: boolean,
  ) => {
    !isInclusiveChange && goToMainPage();
    isInclusiveChange
      ? onConsumerIsInclusiveUpdated(consumer, !!isInclusive)
      : accountsForm && onConsumerAccountUpdated(consumer, accountsForm, activeItem);
  };

  const handleAccountsStatusSave = (accountIds: string[], enabled: boolean) => {
    onConsumerAccountsStatusUpdated(consumer, accountIds, enabled);
  };

  const handleCustomerNumbersDataSave = (accountId: string, model: CustomerNumbersFormViewModel) => {
    handleCustomerNumbersClose();
    onConsumerCustomerNumbersUpdated(consumer, model, accountId, activeCustomerNumber);
  };

  const handleChangeInclusiveLogic = () =>
    isFormOpen ? setIsInclusiveChangeStarted(true) : setIsInclusiveModalOpen(true);

  const isConsumerInclusive = consumer.trips?.smIds?.isInclusive ?? true;

  const accountsLookup = useAppSelector((s) => s.accountsLookup);

  const getChangeAccountsStatusContent = () => {
    const template = newAccountsStatusEnabled
      ? i18n('ConsumerAccounts_Enable_Content')
      : i18n('ConsumerAccounts_Disable_Content');
    return template.replace('{{accountCount}}', selectedAccountIds.length.toString());
  };

  const buildModalTitle = (): React.ReactNode => {
    return (
      <span>
        {!accountsData?.isInclusive
          ? i18n('ConsumerAccounts_Title_Line1_Inclusive')
          : i18n('ConsumerAccounts_Title_Line1_Exclusive')}
        <br />
        {i18n('ConsumerAccounts_Title_Line2_Part1_Regular')}
        <b>{i18n('ConsumerAccounts_Title_Line2_Part2_Bold')}</b>
        {i18n('ConsumerAccounts_Title_Line2_Part3_Regular')}
      </span>
    );
  };

  useEffect(() => {
    updateData(consumer);
  }, [consumer, updateData]);

  useEffect(() => {
    allAccountIds && updateActiveItem(allAccountIds, 'id', id, haveActiveItem);
  }, [allAccountIds, id, haveActiveItem, updateActiveItem]);

  useEffect(() => {
    updateActiveCustomerNumberItem(params.accountId, id);
  }, [params.accountId, id, allAccountIds, updateActiveCustomerNumberItem]);

  useEffect(() => {
    const newAccountsData = service.searchAccounts(allAccountIds, searchTerm);

    setAccountsData(
      (prevState) =>
        ({
          ...prevState,
          ids: newAccountsData,
        }) as AccountsModel,
    );
  }, [allAccountIds, searchTerm, service]);

  return (
    <>
      <div className="page-title-container accounts-title-container">
        {isShowSearch && (
          <Input
            value={searchTerm}
            data-testid="accounts-search-input"
            size="mini"
            icon="search"
            iconPosition="left"
            placeholder={i18n('ConsumerAccounts_SearchPlaceholder')}
            onChange={(e) => handleSearchChanged(e.target.value)}
          />
        )}
        {!isShowSearch && accountsLookup.tooManyAccountsSelected && (
          <Label basic color="red">
            {i18n('ConsumerAccounts_MaxNumberOfAccountsSelectedMessage')}
          </Label>
        )}
        <span></span>
        <div className="side-buttons-container">
          <div>
            <div className="accounts-status-container">
              <label>{i18n('ConsumerAccounts_AccountsLabel')}:</label>
              <label>
                {accountsData?.isInclusive
                  ? i18n('ConsumerAccounts_Inclusive')
                  : i18n('ConsumerAccounts_Exclusive')}
              </label>
              <Popup
                offset={[-11]}
                content={notificationMessage}
                open={isNotificationOpen}
                onOpen={() => showInclusivePopup(true)}
                onClose={closeNotification}
                trigger={<Icon name="info circle" color="grey" />}
              />
            </div>
          </div>
          {isShowSearch && (
            <>
              <div>
                <div className="accounts-enable-disable-container">
                  <span className="accounts-enable-disable-buttons-container">
                    <Button
                      basic
                      color="blue"
                      className="disable-accounts-button"
                      data-testid="disable-accounts-button"
                      disabled={buttonsDisabled}
                      onClick={() => showChangeAccountsStatusModal(false)}
                    >
                      <span>{i18n('ConsumerAccounts_DisableButton')}</span>
                    </Button>
                    <Button
                      primary
                      className="enable-accounts-button"
                      data-testid="enable-accounts-button"
                      disabled={buttonsDisabled}
                      onClick={() => showChangeAccountsStatusModal(true)}
                    >
                      <span>{i18n('ConsumerAccounts_EnableButton')}</span>
                    </Button>
                  </span>
                  <Popup
                    offset={[-11]}
                    content={i18n('ConsumerAccounts_BulkEnableDisable_Hint')}
                    trigger={<Icon name="info circle" color="grey" />}
                  />
                </div>
              </div>
              <div>
                <div className="accounts-button-container">
                  <CanUser permission={Permission.ConsumersUpdate}>
                    <Button
                      primary
                      className="create-button"
                      data-testid="create-accounts-button"
                      onClick={() => handleAccountAdd()}
                    >
                      <Icon name="plus circle" />
                      <span>{i18n('ConsumerAccounts_AddButton')}</span>
                    </Button>
                  </CanUser>
                  <AccountsActionsMenu
                    consumer={consumer}
                    accountsData={accountsData}
                    onChangeInclusiveLogic={handleChangeInclusiveLogic}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Grid columns="equal">
        <AccountsTableSwitcher
          consumer={consumer}
          activeAccount={activeItem}
          accountsData={accountsData}
          activeCustomerNumber={activeCustomerNumber}
          accountIsSelected={accountIsSelected}
          handleAccountSelected={handleAccountSelected}
        />

        {isFormOpen && (
          <Switch>
            <Route path={consumerCustomerNumbersPath}>
              <Grid.Column width={getFormColumnsCount()}>
                <div className="accounts-content-container">
                  <CustomerNumbersForm
                    model={activeCustomerNumber}
                    existingAccounts={allAccountIds ?? []}
                    isInclusiveChangeStarted={isInclusiveChangeStarted}
                    onClose={(redirectUrl) => handleCustomerNumbersClose(redirectUrl)}
                    onSave={(accId, m) => handleCustomerNumbersDataSave(accId, m)}
                    onShowHideInclusiveModal={showHideInclusiveModal}
                  />
                </div>
              </Grid.Column>
            </Route>
            <Route>
              <Grid.Column width={getFormColumnsCount()}>
                <div className="accounts-content-container">
                  {!isShowSearch && (
                    <AccountLookupForm
                      consumerIsInclusive={isConsumerInclusive}
                      isInclusiveChangeStarted={isInclusiveChangeStarted}
                      onClose={(redirectUrl) => goToUrl(redirectUrl)}
                      onSave={handleAccountsDataSave}
                      onShowHideInclusiveModal={showHideInclusiveModal}
                    />
                  )}
                  {isShowSearch && activeItem?.id && (
                    <EditAccountForm
                      account={activeItem}
                      consumerIsInclusive={isConsumerInclusive}
                      isInclusiveChangeStarted={isInclusiveChangeStarted}
                      onClose={(redirectUrl) => goToUrl(redirectUrl)}
                      onSave={handleAccountsDataSave}
                      onShowHideInclusiveModal={showHideInclusiveModal}
                    />
                  )}
                  {isShowSearch && !activeItem?.id && (
                    <NewAccountsForm
                      consumerIsInclusive={isConsumerInclusive}
                      isInclusiveChangeStarted={isInclusiveChangeStarted}
                      onClose={(redirectUrl) => goToUrl(redirectUrl)}
                      onSave={handleAccountsDataSave}
                      onShowHideInclusiveModal={showHideInclusiveModal}
                      existingAccounts={allAccountIds ?? []}
                    />
                  )}
                </div>
              </Grid.Column>
            </Route>
          </Switch>
        )}
      </Grid>

      <ModalBase
        isOpen={isInclusiveModalOpen}
        header={<WarningModalHeader title={buildModalTitle()} />}
        content={
          <>
            <div>{i18n(`ConsumerAccounts_To${isInclusiveNewStatusName}_Content`)}</div>
            <Checkbox
              checked={isAcknowledged}
              label={i18n('ConsumerAccounts_AcknowledgementMessage')}
              className="checkbox-input acknowledgement-checkbox"
              onChange={() => setIsAcknowledged(!isAcknowledged)}
            />
          </>
        }
        cancelButtonText={i18n(`ConsumerAccounts_To${isInclusiveNewStatusName}_NoButton`)}
        okButtonText={i18n(`ConsumerAccounts_To${isInclusiveNewStatusName}_YesButton`)}
        okButtonDisabled={!isAcknowledged}
        onClose={handleInclusiveModalClose}
      />

      <ModalSave
        isEdit
        isOpen={isChangeAccountsStatusModalOpen}
        header={i18n('ConsumerAccounts_Status_Title')}
        content={getChangeAccountsStatusContent()}
        onClose={handleChangeAccountsStatusModalClose}
      />
    </>
  );
};

export default AccountsTab;
