import React from 'react';
import { ApiAccess } from '../../../enums/ApiAccess';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';
import { i18n } from '../../../services/i18n';
import { Role, UserRoles } from '../../../enums/UserRole';
import { userRoles } from '../../../helpers/PermissionsHelper';
import { useUserPermissions } from '../../../hooks/Auth/useUserPermissions';
import './styles.scss';

interface Props {
  value?: ApiAccess[];
  isEditing: boolean;
  onChange?: (value: ApiAccess[]) => void;
}

const ConsumerApiAccess: React.FC<Props> = ({ onChange = () => undefined, value = [], isEditing }) => {
  const userRole = userRoles(useUserPermissions());
  const accessTypes = [
    ApiAccess.Expenses,
    ApiAccess.Trips,
    ApiAccess.Tickets,
    ApiAccess.FlightAlerts,
    ApiAccess.Receipts,
    ApiAccess.Enh,
    ApiAccess.WaiversMatching,
    ApiAccess.WaiversStandAlone,
    ApiAccess.Xx1,
  ];

  const handleChange = (checked: boolean, access: ApiAccess) => {
    if (checked) {
      access === ApiAccess.WaiversStandAlone
        ? onChange([
            ...value.filter((a) => a !== ApiAccess.WaiversStandAlone && a !== ApiAccess.WaiversMatching),
          ])
        : onChange([...value.filter((a) => a !== access)]);
    } else {
      access === ApiAccess.WaiversMatching
        ? onChange([...value, ApiAccess.WaiversStandAlone, ApiAccess.WaiversMatching])
        : onChange([...value, access]);
    }
  };

  const isUserAdmin = userRole[Role.SuperAdmin] || userRole[Role.Admin];
  const isApiAccessEnabled = (accessType: ApiAccess) => accessType === ApiAccess.Trips || isUserAdmin;

  return (
    <div>
      <h4 className="create-consumer-api-title">
        {i18n('ConsumerForm_ApiAccessTitle')}
        {isEditing && !isUserAdmin && (
          <Popup
            offset={[-11]}
            content={i18n('ConsumerForm_AllowedApiAccess_Hint')}
            trigger={<Icon name="info circle" color="grey" />}
          />
        )}
      </h4>
      <div className="create-consumer-api-content">
        {accessTypes.map((t) => {
          return (
            <div key={t}>
              <Checkbox
                checked={value.some((v) => v === t)}
                className="checkbox-input"
                label={i18n(`ApiAccess_${ApiAccess[t]}`)}
                disabled={!isEditing || !isApiAccessEnabled(t)}
                onChange={() => {
                  handleChange(
                    value.some((v) => v === t),
                    t,
                  );
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ConsumerApiAccess;
