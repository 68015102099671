import { Permission } from '../enums/Permission';
import { Role } from '../enums/UserRole';
import { UseUserPermissionsType } from '../hooks/Auth/useUserPermissions';

export type UserRolesType = {
  [key in Role]: boolean;
};

export const userRoles = (hasPermissions: UseUserPermissionsType): UserRolesType => ({
  [Role.SuperAdmin]: checkRoles(Role.SuperAdmin, hasPermissions),
  [Role.Admin]: checkRoles(Role.Admin, hasPermissions),
  [Role.Consumers]: checkRoles(Role.Consumers, hasPermissions),
  [Role.ConsumersRead]: checkRoles(Role.ConsumersRead, hasPermissions),
  [Role.Events]: hasPermissions(Permission.EventsRead),
  [Role.Search]: hasPermissions(Permission.SearchTripsRead),
});

const checkRoles = (role: Role, hasPermissions: UseUserPermissionsType): boolean => {
  const isAssignUser = hasAssignUserPermissions(hasPermissions);
  const isConsumersUpdate = hasConsumersUpdatePermissions(hasPermissions);
  const isConsumersRead = hasConsumersReadPermissions(hasPermissions);
  const isUsers = hasUsersPermissions(hasPermissions);

  switch (role) {
    case Role.SuperAdmin:
      return isAssignUser && isUsers && isConsumersUpdate && isConsumersRead;
    case Role.Admin:
      return !isAssignUser && isUsers && isConsumersUpdate && isConsumersRead;
    case Role.Consumers:
      return !isAssignUser && !isUsers && isConsumersUpdate && isConsumersRead;
    case Role.ConsumersRead:
      return !isAssignUser && !isUsers && !isConsumersUpdate && isConsumersRead;
    default:
      return false;
  }
};

const hasAssignUserPermissions = (hasPermissions: UseUserPermissionsType): boolean => {
  return hasPermissions(Permission.UsersCanAssignUserPermissions);
};

const hasConsumersUpdatePermissions = (hasPermissions: UseUserPermissionsType): boolean => {
  return hasPermissions(Permission.ConsumersUpdate);
};

const hasConsumersReadPermissions = (hasPermissions: UseUserPermissionsType): boolean => {
  return hasPermissions(Permission.ConsumersRead);
};

const hasUsersPermissions = (hasPermissions: UseUserPermissionsType): boolean => {
  return (
    hasPermissions(Permission.UsersRead) &&
    hasPermissions(Permission.UsersCreate) &&
    hasPermissions(Permission.UsersUpdate)
  );
};
