/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react';
import { ClaimModel } from '../../models/User/ClaimModel';
import { fetchClaimsAction } from '../actions/claimsActions';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export const useClaims: () => [
  {
    claims: ClaimModel[];
    isLoading: boolean;
  },
] = () => {
  const dispatch = useAppDispatch();
  const claims = useAppSelector((s) => s.claims);

  useEffect(() => {
    if (claims.items.length === 0) {
      dispatch(fetchClaimsAction());
    }
  }, [claims.items.length, dispatch]);

  return [{ claims: claims.items, isLoading: claims.isLoading }];
};
